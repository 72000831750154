var jobSkill = [
  {
    "no": "5001000000",
    "des": "財務會計",
    "eng": "財務會計",
    "n": [
      {
        "no": "5001001000",
        "des": "內稽內控類",
        "eng": "內稽內控類",
        "n": [
          {
            "no": "5001001001",
            "des": "內稽內控制度設計、執行、考核",
            "eng": "內稽內控制度設計、執行、考核"
          },
          {
            "no": "5001001002",
            "des": "財務報告查核及簽證",
            "eng": "財務報告查核及簽證"
          },
          {
            "no": "5001001003",
            "des": "申報內部稽核計劃",
            "eng": "申報內部稽核計劃"
          },
          {
            "no": "5001001004",
            "des": "帳務查核與會計稽查",
            "eng": "帳務查核與會計稽查"
          },
          {
            "no": "5001001005",
            "des": "企業內部控制",
            "eng": "企業內部控制"
          },
          {
            "no": "5001001006",
            "des": "編制內部稽核計劃",
            "eng": "編制內部稽核計劃"
          },
          {
            "no": "5001001007",
            "des": "企業風險管理",
            "eng": "企業風險管理"
          },
          {
            "no": "5001001008",
            "des": "覆核報表作業流程",
            "eng": "覆核報表作業流程"
          },
          {
            "no": "5001001009",
            "des": "呈核稽核報告",
            "eng": "呈核稽核報告"
          }
        ]
      },
      {
        "no": "5001002000",
        "des": "財務類",
        "eng": "財務類",
        "n": [
          {
            "no": "5001002001",
            "des": "核閱財務報表",
            "eng": "核閱財務報表"
          },
          {
            "no": "5001002002",
            "des": "國內財稅規章",
            "eng": "國內財稅規章"
          },
          {
            "no": "5001002003",
            "des": "財務及營業分析",
            "eng": "財務及營業分析"
          },
          {
            "no": "5001002004",
            "des": "資金管理",
            "eng": "資金管理"
          },
          {
            "no": "5001002005",
            "des": "財務金融與風險管理",
            "eng": "財務金融與風險管理"
          },
          {
            "no": "5001002006",
            "des": "審核年度預算",
            "eng": "審核年度預算"
          },
          {
            "no": "5001002007",
            "des": "財務規劃與投資管理",
            "eng": "財務規劃與投資管理"
          },
          {
            "no": "5001002008",
            "des": "編制財務報告",
            "eng": "編制財務報告"
          },
          {
            "no": "5001002009",
            "des": "財務報表製作",
            "eng": "財務報表製作"
          },
          {
            "no": "5001002010",
            "des": "擬定各項籌資方案",
            "eng": "擬定各項籌資方案"
          },
          {
            "no": "5001002011",
            "des": "財務策略建議與分析",
            "eng": "財務策略建議與分析"
          },
          {
            "no": "5001002012",
            "des": "籌資規劃送件",
            "eng": "籌資規劃送件"
          }
        ]
      },
      {
        "no": "5001003000",
        "des": "稅務類",
        "eng": "稅務類",
        "n": [
          {
            "no": "5001003001",
            "des": "了解全球租稅規定",
            "eng": "了解全球租稅規定"
          },
          {
            "no": "5001003002",
            "des": "稅務規劃與稅報╱納稅事項",
            "eng": "稅務規劃與稅報╱納稅事項"
          },
          {
            "no": "5001003003",
            "des": "所得稅結算申報",
            "eng": "所得稅結算申報"
          },
          {
            "no": "5001003004",
            "des": "稅務業務處理",
            "eng": "稅務業務處理"
          },
          {
            "no": "5001003005",
            "des": "稅收及買賣契約相關事項",
            "eng": "稅收及買賣契約相關事項"
          },
          {
            "no": "5001003006",
            "des": "稅務顧問服務",
            "eng": "稅務顧問服務"
          }
        ]
      },
      {
        "no": "5001004000",
        "des": "會計類",
        "eng": "會計類",
        "n": [
          {
            "no": "5001004001",
            "des": "分析管理會計資訊",
            "eng": "分析管理會計資訊"
          },
          {
            "no": "5001004002",
            "des": "會計核算和帳務處理",
            "eng": "會計核算和帳務處理"
          },
          {
            "no": "5001004003",
            "des": "代辦會計相關事項",
            "eng": "代辦會計相關事項"
          },
          {
            "no": "5001004004",
            "des": "會計清算╱設計",
            "eng": "會計清算╱設計"
          },
          {
            "no": "5001004005",
            "des": "財務報表分析",
            "eng": "財務報表分析"
          },
          {
            "no": "5001004006",
            "des": "會計管理╱調查",
            "eng": "會計管理╱調查"
          },
          {
            "no": "5001004007",
            "des": "規劃檢討會計作業流程",
            "eng": "規劃檢討會計作業流程"
          },
          {
            "no": "5001004008",
            "des": "會計整理╱鑑定",
            "eng": "會計整理╱鑑定"
          },
          {
            "no": "5001004009",
            "des": "結帳作業與帳務處理",
            "eng": "結帳作業與帳務處理"
          },
          {
            "no": "5001004010",
            "des": "編製帳務報表",
            "eng": "編製帳務報表"
          },
          {
            "no": "5001004011",
            "des": "會計或審計專業知識",
            "eng": "會計或審計專業知識"
          }
        ]
      }
    ]
  },
  {
    "no": "5002000000",
    "des": "人事╱行政╱法務",
    "eng": "人事╱行政╱法務",
    "n": [
      {
        "no": "5002001000",
        "des": "招募與徵選",
        "eng": "招募與徵選",
        "n": [
          {
            "no": "5002001001",
            "des": "召募任用制度設計",
            "eng": "召募任用制度設計"
          },
          {
            "no": "5002001002",
            "des": "轉職技巧",
            "eng": "轉職技巧"
          },
          {
            "no": "5002001003",
            "des": "執行日常招募業務",
            "eng": "執行日常招募業務"
          },
          {
            "no": "5002001004",
            "des": "履歷自傳撰寫",
            "eng": "履歷自傳撰寫"
          },
          {
            "no": "5002001005",
            "des": "面試技巧",
            "eng": "面試技巧"
          },
          {
            "no": "5002001006",
            "des": "其它求職轉職相關",
            "eng": "其它求職轉職相關"
          },
          {
            "no": "5002001007",
            "des": "求職技巧",
            "eng": "求職技巧"
          }
        ]
      },
      {
        "no": "5002002000",
        "des": "訓練與發展",
        "eng": "訓練與發展",
        "n": [
          {
            "no": "5002002001",
            "des": "員工教育訓練與需求分析",
            "eng": "員工教育訓練與需求分析"
          },
          {
            "no": "5002002002",
            "des": "職涯探索",
            "eng": "職涯探索"
          },
          {
            "no": "5002002003",
            "des": "蒐集與分析就業市場資訊",
            "eng": "蒐集與分析就業市場資訊"
          },
          {
            "no": "5002002004",
            "des": "人員培訓、激勵、輔導與管理",
            "eng": "人員培訓、激勵、輔導與管理"
          },
          {
            "no": "5002002005",
            "des": "職業心理測驗分析",
            "eng": "職業心理測驗分析"
          }
        ]
      },
      {
        "no": "5002003000",
        "des": "薪資與績效管理",
        "eng": "薪資與績效管理",
        "n": [
          {
            "no": "5002003001",
            "des": "工作說明書撰寫",
            "eng": "工作說明書撰寫"
          },
          {
            "no": "5002003002",
            "des": "職務分析與職等設計",
            "eng": "職務分析與職等設計"
          },
          {
            "no": "5002003003",
            "des": "教育訓練總體規劃與績效評估",
            "eng": "教育訓練總體規劃與績效評估"
          },
          {
            "no": "5002003004",
            "des": "制定與管理部門各類績效指標",
            "eng": "制定與管理部門各類績效指標"
          },
          {
            "no": "5002003005",
            "des": "績效與薪酬管理",
            "eng": "績效與薪酬管理"
          },
          {
            "no": "5002003006",
            "des": "薪資談判",
            "eng": "薪資談判"
          },
          {
            "no": "5002003007",
            "des": "薪資與績效獎酬辦法設計",
            "eng": "薪資與績效獎酬辦法設計"
          }
        ]
      },
      {
        "no": "5002004000",
        "des": "行政事務",
        "eng": "行政事務",
        "n": [
          {
            "no": "5002004001",
            "des": "文件收發與檔案管理",
            "eng": "文件收發與檔案管理"
          },
          {
            "no": "5002004002",
            "des": "文件或資料輸入建檔處理",
            "eng": "文件或資料輸入建檔處理"
          },
          {
            "no": "5002004003",
            "des": "文書處理╱排版能力",
            "eng": "文書處理╱排版能力"
          },
          {
            "no": "5002004004",
            "des": "文件檔案資料處理、轉換及整合工作",
            "eng": "文件檔案資料處理、轉換及整合工作"
          },
          {
            "no": "5002004005",
            "des": "行政事務處理",
            "eng": "行政事務處理"
          },
          {
            "no": "5002004006",
            "des": "零用金管理",
            "eng": "零用金管理"
          },
          {
            "no": "5002004007",
            "des": "報表彙整與管理",
            "eng": "報表彙整與管理"
          },
          {
            "no": "5002004008",
            "des": "管理行事曆與會議協調安排",
            "eng": "管理行事曆與會議協調安排"
          },
          {
            "no": "5002004009",
            "des": "電話接聽與人員接待事項",
            "eng": "電話接聽與人員接待事項"
          },
          {
            "no": "5002004010",
            "des": "執行藥物管理業務",
            "eng": "執行藥物管理業務"
          }
        ]
      },
      {
        "no": "5002005000",
        "des": "勞工相關法規",
        "eng": "勞工相關法規",
        "n": [
          {
            "no": "5002005001",
            "des": "公共衛生相關法規",
            "eng": "公共衛生相關法規"
          },
          {
            "no": "5002005002",
            "des": "勞工安全相關法規",
            "eng": "勞工安全相關法規"
          },
          {
            "no": "5002005003",
            "des": "勞工保險相關法規",
            "eng": "勞工保險相關法規"
          }
        ]
      },
      {
        "no": "5002006000",
        "des": "金融保險法規",
        "eng": "金融保險法規",
        "n": [
          {
            "no": "5002006001",
            "des": "人身保險規範",
            "eng": "人身保險規範"
          },
          {
            "no": "5002006002",
            "des": "金融相關法規",
            "eng": "金融相關法規"
          },
          {
            "no": "5002006003",
            "des": "股務作業法規",
            "eng": "股務作業法規"
          },
          {
            "no": "5002006004",
            "des": "保險相關法規",
            "eng": "保險相關法規"
          }
        ]
      },
      {
        "no": "5002007000",
        "des": "交通相關法規",
        "eng": "交通相關法規",
        "n": [
          {
            "no": "5002007001",
            "des": "交通安全相關法律",
            "eng": "交通安全相關法律"
          },
          {
            "no": "5002007002",
            "des": "鐵路相關法規",
            "eng": "鐵路相關法規"
          },
          {
            "no": "5002007003",
            "des": "航海相關法令",
            "eng": "航海相關法令"
          }
        ]
      },
      {
        "no": "5002008000",
        "des": "營建相關法規",
        "eng": "營建相關法規",
        "n": [
          {
            "no": "5002008001",
            "des": "下水道相關法規",
            "eng": "下水道相關法規"
          },
          {
            "no": "5002008002",
            "des": "建築物室內裝修相關法規",
            "eng": "建築物室內裝修相關法規"
          },
          {
            "no": "5002008003",
            "des": "不動產相關法規",
            "eng": "不動產相關法規"
          },
          {
            "no": "5002008004",
            "des": "建築相關法令",
            "eng": "建築相關法令"
          },
          {
            "no": "5002008005",
            "des": "水土保持法規",
            "eng": "水土保持法規"
          },
          {
            "no": "5002008006",
            "des": "建築相關消防法規",
            "eng": "建築相關消防法規"
          },
          {
            "no": "5002008007",
            "des": "水利法規相關法規",
            "eng": "水利法規相關法規"
          },
          {
            "no": "5002008008",
            "des": "消防相關法規",
            "eng": "消防相關法規"
          },
          {
            "no": "5002008009",
            "des": "污水處理設施操作維護相關法規",
            "eng": "污水處理設施操作維護相關法規"
          },
          {
            "no": "5002008010",
            "des": "營建業相關法令",
            "eng": "營建業相關法令"
          },
          {
            "no": "5002008011",
            "des": "室內設計相關法規",
            "eng": "室內設計相關法規"
          },
          {
            "no": "5002008012",
            "des": "營造業相關法規",
            "eng": "營造業相關法規"
          }
        ]
      },
      {
        "no": "5002009000",
        "des": "其他法規",
        "eng": "其他法規",
        "n": [
          {
            "no": "5002009001",
            "des": "用電相關法規",
            "eng": "用電相關法規"
          },
          {
            "no": "5002009002",
            "des": "進出口相關法令",
            "eng": "進出口相關法令"
          },
          {
            "no": "5002009003",
            "des": "保全相關法令",
            "eng": "保全相關法令"
          },
          {
            "no": "5002009004",
            "des": "電信相關法規",
            "eng": "電信相關法規"
          },
          {
            "no": "5002009005",
            "des": "食品衛生相關法令",
            "eng": "食品衛生相關法令"
          },
          {
            "no": "5002009006",
            "des": "關務相關法規",
            "eng": "關務相關法規"
          },
          {
            "no": "5002009007",
            "des": "喪禮服務相關法規",
            "eng": "喪禮服務相關法規"
          },
          {
            "no": "5002009008",
            "des": "觀光相關法規",
            "eng": "觀光相關法規"
          },
          {
            "no": "5002009009",
            "des": "智慧財產權",
            "eng": "智慧財產權"
          }
        ]
      },
      {
        "no": "5002010000",
        "des": "法務行政",
        "eng": "法務行政",
        "n": [
          {
            "no": "5002010001",
            "des": "建築許可申請",
            "eng": "建築許可申請"
          },
          {
            "no": "5002010002",
            "des": "追蹤及審閱合約流程",
            "eng": "追蹤及審閱合約流程"
          },
          {
            "no": "5002010003",
            "des": "通行權說明撰寫",
            "eng": "通行權說明撰寫"
          },
          {
            "no": "5002010004",
            "des": "執行公司設立變更相關作業",
            "eng": "執行公司設立變更相關作業"
          },
          {
            "no": "5002010005",
            "des": "環境影響聲明撰寫",
            "eng": "環境影響聲明撰寫"
          },
          {
            "no": "5002010006",
            "des": "執行辦理工商登記案件服務",
            "eng": "執行辦理工商登記案件服務"
          },
          {
            "no": "5002010007",
            "des": "執行法律文件及契約撰寫",
            "eng": "執行法律文件及契約撰寫"
          },
          {
            "no": "5002010008",
            "des": "處理並完成公司股份登記",
            "eng": "處理並完成公司股份登記"
          },
          {
            "no": "5002010009",
            "des": "代理土地登記與移轉變更等業務",
            "eng": "代理土地登記與移轉變更等業務"
          },
          {
            "no": "5002010010",
            "des": "檢驗各項文件之法律效力",
            "eng": "檢驗各項文件之法律效力"
          },
          {
            "no": "5002010011",
            "des": "代擬契約、簡單申請狀、仲介土地業務",
            "eng": "代擬契約、簡單申請狀、仲介土地業務"
          },
          {
            "no": "5002010012",
            "des": "檢驗法律紀錄和相關文件",
            "eng": "檢驗法律紀錄和相關文件"
          },
          {
            "no": "5002010013",
            "des": "向政府機關申請土地相關資料",
            "eng": "向政府機關申請土地相關資料"
          },
          {
            "no": "5002010014",
            "des": "熟悉藥事行政與法規",
            "eng": "熟悉藥事行政與法規"
          },
          {
            "no": "5002010015",
            "des": "合約或備忘錄內容審查檢驗",
            "eng": "合約或備忘錄內容審查檢驗"
          }
        ]
      },
      {
        "no": "5002011000",
        "des": "人力資源",
        "eng": "人力資源",
        "n": [
          {
            "no": "5002011001",
            "des": "人力規劃控管",
            "eng": "人力規劃控管"
          },
          {
            "no": "5002011002",
            "des": "具備人力資源相關知識",
            "eng": "具備人力資源相關知識"
          },
          {
            "no": "5002011003",
            "des": "用人管理",
            "eng": "用人管理"
          }
        ]
      },
      {
        "no": "5002012000",
        "des": "法律事務",
        "eng": "法律事務",
        "n": [
          {
            "no": "5002012001",
            "des": "申請權讓渡、各種權利名義變更登記",
            "eng": "申請權讓渡、各種權利名義變更登記"
          },
          {
            "no": "5002012002",
            "des": "建立並推動法務規章制度",
            "eng": "建立並推動法務規章制度"
          },
          {
            "no": "5002012003",
            "des": "協助法律問題分析與擔任辯護",
            "eng": "協助法律問題分析與擔任辯護"
          },
          {
            "no": "5002012004",
            "des": "草擬法令及政府法規",
            "eng": "草擬法令及政府法規"
          },
          {
            "no": "5002012005",
            "des": "法律相關條文諮詢服務",
            "eng": "法律相關條文諮詢服務"
          },
          {
            "no": "5002012006",
            "des": "執行法律行為及文書之認證",
            "eng": "執行法律行為及文書之認證"
          },
          {
            "no": "5002012007",
            "des": "法律理論、原理及其相關特殊法律研究",
            "eng": "法律理論、原理及其相關特殊法律研究"
          },
          {
            "no": "5002012008",
            "des": "撰寫法律、貸款及保險等相關報告書",
            "eng": "撰寫法律、貸款及保險等相關報告書"
          },
          {
            "no": "5002012009",
            "des": "侵害事件之相關調查及鑑定服務",
            "eng": "侵害事件之相關調查及鑑定服務"
          },
          {
            "no": "5002012010",
            "des": "執行公司法令解析",
            "eng": "執行公司法令解析"
          }
        ]
      },
      {
        "no": "5002013000",
        "des": "商標╱專利╱智財類",
        "eng": "商標╱專利╱智財類",
        "n": [
          {
            "no": "5002013001",
            "des": "建立產品專利地圖及專利資料庫",
            "eng": "建立產品專利地圖及專利資料庫"
          },
          {
            "no": "5002013002",
            "des": "教導人員智慧財產權之產出、記錄、申請及保存",
            "eng": "教導人員智慧財產權之產出、記錄、申請及保存"
          },
          {
            "no": "5002013003",
            "des": "建立智慧財產權管理作業流程、規章及表單",
            "eng": "建立智慧財產權管理作業流程、規章及表單"
          },
          {
            "no": "5002013004",
            "des": "智慧財產權之申請、答辯、登錄、維護事項",
            "eng": "智慧財產權之申請、答辯、登錄、維護事項"
          },
          {
            "no": "5002013005",
            "des": "指導智慧財產權管理作業進行",
            "eng": "指導智慧財產權管理作業進行"
          },
          {
            "no": "5002013006",
            "des": "處理智慧財產權移轉之權利金或授權金事項",
            "eng": "處理智慧財產權移轉之權利金或授權金事項"
          },
          {
            "no": "5002013007",
            "des": "訂定執行智慧財產權管理作業計畫",
            "eng": "訂定執行智慧財產權管理作業計畫"
          },
          {
            "no": "5002013008",
            "des": "搜集與分析侵犯企業智慧財產權證據",
            "eng": "搜集與分析侵犯企業智慧財產權證據"
          },
          {
            "no": "5002013009",
            "des": "國內外專利申請相關業務處理",
            "eng": "國內外專利申請相關業務處理"
          },
          {
            "no": "5002013010",
            "des": "辦理商標、專利、工商登記、土地登記事務",
            "eng": "辦理商標、專利、工商登記、土地登記事務"
          },
          {
            "no": "5002013011",
            "des": "專利申請之事前調查分析",
            "eng": "專利申請之事前調查分析"
          }
        ]
      },
      {
        "no": "5002014000",
        "des": "總務╱採購類",
        "eng": "總務╱採購類",
        "n": [
          {
            "no": "5002014001",
            "des": "供應商尋找及評選執行",
            "eng": "供應商尋找及評選執行"
          },
          {
            "no": "5002014002",
            "des": "設備維護與營繕工程規劃",
            "eng": "設備維護與營繕工程規劃"
          },
          {
            "no": "5002014003",
            "des": "庶務採購管理與策略規劃",
            "eng": "庶務採購管理與策略規劃"
          },
          {
            "no": "5002014004",
            "des": "資產移轉╱盤點管理規劃",
            "eng": "資產移轉╱盤點管理規劃"
          },
          {
            "no": "5002014005",
            "des": "採購及維護事務機器及家電用品",
            "eng": "採購及維護事務機器及家電用品"
          },
          {
            "no": "5002014006",
            "des": "資產管理制度與流程規劃",
            "eng": "資產管理制度與流程規劃"
          },
          {
            "no": "5002014007",
            "des": "採購並維護辦公事物及庶務用品",
            "eng": "採購並維護辦公事物及庶務用品"
          },
          {
            "no": "5002014008",
            "des": "電子化企業資源規劃執行",
            "eng": "電子化企業資源規劃執行"
          },
          {
            "no": "5002014009",
            "des": "採購談判與議價技巧",
            "eng": "採購談判與議價技巧"
          },
          {
            "no": "5002014010",
            "des": "辦公室租賃與買賣事務辦理",
            "eng": "辦公室租賃與買賣事務辦理"
          },
          {
            "no": "5002014011",
            "des": "規劃、組織、指導及協調組織內部行政作業",
            "eng": "規劃、組織、指導及協調組織內部行政作業"
          },
          {
            "no": "5002014012",
            "des": "總務管理制度與實施總務工作計畫",
            "eng": "總務管理制度與實施總務工作計畫"
          },
          {
            "no": "5002014013",
            "des": "規範公務器材及公務車使用管理規則",
            "eng": "規範公務器材及公務車使用管理規則"
          }
        ]
      }
    ]
  },
  {
    "no": "5003000000",
    "des": "管理類",
    "eng": "管理類",
    "n": [
      {
        "no": "5003001000",
        "des": "成本控制",
        "eng": "成本控制",
        "n": [
          {
            "no": "5003001001",
            "des": "部門人力成本控制",
            "eng": "部門人力成本控制"
          },
          {
            "no": "5003001002",
            "des": "預算編製與成本控管",
            "eng": "預算編製與成本控管"
          }
        ]
      },
      {
        "no": "5003002000",
        "des": "專案管理",
        "eng": "專案管理",
        "n": [
          {
            "no": "5003002001",
            "des": "專案人力資源管理",
            "eng": "專案人力資源管理"
          },
          {
            "no": "5003002002",
            "des": "專案規劃執行╱範圍管理",
            "eng": "專案規劃執行╱範圍管理"
          },
          {
            "no": "5003002003",
            "des": "專案成本╱品質╱風險管理",
            "eng": "專案成本╱品質╱風險管理"
          },
          {
            "no": "5003002004",
            "des": "專案溝通╱整合管理",
            "eng": "專案溝通╱整合管理"
          },
          {
            "no": "5003002005",
            "des": "專案時間╱進度控管",
            "eng": "專案時間╱進度控管"
          },
          {
            "no": "5003002006",
            "des": "專案管理架構及專案說明",
            "eng": "專案管理架構及專案說明"
          },
          {
            "no": "5003002007",
            "des": "專案採購管理",
            "eng": "專案採購管理"
          }
        ]
      },
      {
        "no": "5003003000",
        "des": "人員管理",
        "eng": "人員管理",
        "n": [
          {
            "no": "5003003001",
            "des": "工作任務╱業績項目分配",
            "eng": "工作任務╱業績項目分配"
          },
          {
            "no": "5003003002",
            "des": "業績目標達成",
            "eng": "業績目標達成"
          },
          {
            "no": "5003003003",
            "des": "培育人員職涯發展",
            "eng": "培育人員職涯發展"
          },
          {
            "no": "5003003004",
            "des": "督導人員工作績效",
            "eng": "督導人員工作績效"
          }
        ]
      },
      {
        "no": "5003004000",
        "des": "領導管理",
        "eng": "領導管理",
        "n": [
          {
            "no": "5003004001",
            "des": "工廠管理能力",
            "eng": "工廠管理能力"
          },
          {
            "no": "5003004002",
            "des": "賣場╱門市商圈經營",
            "eng": "賣場╱門市商圈經營"
          },
          {
            "no": "5003004003",
            "des": "危機與議題管理",
            "eng": "危機與議題管理"
          },
          {
            "no": "5003004004",
            "des": "賣場╱門市營運計畫與管理",
            "eng": "賣場╱門市營運計畫與管理"
          },
          {
            "no": "5003004005",
            "des": "協商談判能力",
            "eng": "協商談判能力"
          },
          {
            "no": "5003004006",
            "des": "職工福利社營運管理",
            "eng": "職工福利社營運管理"
          },
          {
            "no": "5003004007",
            "des": "部門績效目標管理",
            "eng": "部門績效目標管理"
          },
          {
            "no": "5003004008",
            "des": "統籌工廠之生產排程、交期及產量",
            "eng": "統籌工廠之生產排程、交期及產量"
          },
          {
            "no": "5003004009",
            "des": "實驗室管理規劃",
            "eng": "實驗室管理規劃"
          }
        ]
      },
      {
        "no": "5003005000",
        "des": "文件管理",
        "eng": "文件管理",
        "n": [
          {
            "no": "5003005001",
            "des": "ISO文件發行管理",
            "eng": "ISO文件發行管理"
          },
          {
            "no": "5003005002",
            "des": "熟悉圖書借閱作業流程",
            "eng": "熟悉圖書借閱作業流程"
          },
          {
            "no": "5003005003",
            "des": "負責文件更新、發行、審核與跟催作業",
            "eng": "負責文件更新、發行、審核與跟催作業"
          },
          {
            "no": "5003005004",
            "des": "輸入資料與原始文件檔案比對審核",
            "eng": "輸入資料與原始文件檔案比對審核"
          },
          {
            "no": "5003005005",
            "des": "圖書、光碟、期刊資料編目上架",
            "eng": "圖書、光碟、期刊資料編目上架"
          },
          {
            "no": "5003005006",
            "des": "辦理檔案調出、收回、催還、整理及上架",
            "eng": "辦理檔案調出、收回、催還、整理及上架"
          },
          {
            "no": "5003005007",
            "des": "圖書館管理與知識推廣",
            "eng": "圖書館管理與知識推廣"
          },
          {
            "no": "5003005008",
            "des": "檔案管理之電腦化策略",
            "eng": "檔案管理之電腦化策略"
          },
          {
            "no": "5003005009",
            "des": "熟悉文件管制作業程序（DCC）",
            "eng": "熟悉文件管制作業程序（DCC）"
          },
          {
            "no": "5003005010",
            "des": "文件管理中心之建立與維護",
            "eng": "文件管理中心之建立與維護"
          }
        ]
      }
    ]
  },
  {
    "no": "5004000000",
    "des": "金融保險",
    "eng": "金融保險",
    "n": [
      {
        "no": "5004001000",
        "des": "基金",
        "eng": "基金",
        "n": [
          {
            "no": "5004001001",
            "des": "投資評估報告撰寫",
            "eng": "投資評估報告撰寫"
          },
          {
            "no": "5004001002",
            "des": "建立統計精算模型",
            "eng": "建立統計精算模型"
          },
          {
            "no": "5004001003",
            "des": "具備財金專業知識",
            "eng": "具備財金專業知識"
          },
          {
            "no": "5004001004",
            "des": "財產信託理論與實務",
            "eng": "財產信託理論與實務"
          },
          {
            "no": "5004001005",
            "des": "金融市場分析與資料蒐集",
            "eng": "金融市場分析與資料蒐集"
          },
          {
            "no": "5004001006",
            "des": "財富管理規劃諮詢",
            "eng": "財富管理規劃諮詢"
          },
          {
            "no": "5004001007",
            "des": "金融商品企劃分析",
            "eng": "金融商品企劃分析"
          },
          {
            "no": "5004001008",
            "des": "基金股票下單業務",
            "eng": "基金股票下單業務"
          },
          {
            "no": "5004001009",
            "des": "金融商品業務銷售",
            "eng": "金融商品業務銷售"
          },
          {
            "no": "5004001010",
            "des": "基金保險理財售後服務",
            "eng": "基金保險理財售後服務"
          },
          {
            "no": "5004001011",
            "des": "金融專題研究分析",
            "eng": "金融專題研究分析"
          },
          {
            "no": "5004001012",
            "des": "理財規劃實務與工具運用",
            "eng": "理財規劃實務與工具運用"
          },
          {
            "no": "5004001013",
            "des": "金融產品風險評估",
            "eng": "金融產品風險評估"
          },
          {
            "no": "5004001014",
            "des": "管理金融商品櫃檯業務",
            "eng": "管理金融商品櫃檯業務"
          }
        ]
      },
      {
        "no": "5004002000",
        "des": "證券",
        "eng": "證券",
        "n": [
          {
            "no": "5004002001",
            "des": "各項債券、證券業務處理",
            "eng": "各項債券、證券業務處理"
          },
          {
            "no": "5004002002",
            "des": "信託業務事項",
            "eng": "信託業務事項"
          },
          {
            "no": "5004002003",
            "des": "有價證券之借貸與代理",
            "eng": "有價證券之借貸與代理"
          },
          {
            "no": "5004002004",
            "des": "執行股票除權作業",
            "eng": "執行股票除權作業"
          },
          {
            "no": "5004002005",
            "des": "有價證券之融資╱融券",
            "eng": "有價證券之融資╱融券"
          },
          {
            "no": "5004002006",
            "des": "期貨交易與證券分析",
            "eng": "期貨交易與證券分析"
          },
          {
            "no": "5004002007",
            "des": "有價證券買賣交割之帳簿劃撥",
            "eng": "有價證券買賣交割之帳簿劃撥"
          },
          {
            "no": "5004002008",
            "des": "輔導企業辦理上市櫃、公開發行",
            "eng": "輔導企業辦理上市櫃、公開發行"
          },
          {
            "no": "5004002009",
            "des": "受理股東基本資料作業",
            "eng": "受理股東基本資料作業"
          },
          {
            "no": "5004002010",
            "des": "辦理召開股東會事項",
            "eng": "辦理召開股東會事項"
          },
          {
            "no": "5004002011",
            "des": "股務作業實務與工具運用",
            "eng": "股務作業實務與工具運用"
          },
          {
            "no": "5004002012",
            "des": "辦理股票之合併與分割作業",
            "eng": "辦理股票之合併與分割作業"
          },
          {
            "no": "5004002013",
            "des": "股務專業諮詢服務",
            "eng": "股務專業諮詢服務"
          },
          {
            "no": "5004002014",
            "des": "辦理現金增資股票事務",
            "eng": "辦理現金增資股票事務"
          }
        ]
      },
      {
        "no": "5004003000",
        "des": "保險",
        "eng": "保險",
        "n": [
          {
            "no": "5004003001",
            "des": "一般保險公證",
            "eng": "一般保險公證"
          },
          {
            "no": "5004003002",
            "des": "保險經營理論與實務",
            "eng": "保險經營理論與實務"
          },
          {
            "no": "5004003003",
            "des": "人身保險代理與經紀業務",
            "eng": "人身保險代理與經紀業務"
          },
          {
            "no": "5004003004",
            "des": "核保醫務常識",
            "eng": "核保醫務常識"
          },
          {
            "no": "5004003005",
            "des": "各類保險服務與諮詢",
            "eng": "各類保險服務與諮詢"
          },
          {
            "no": "5004003006",
            "des": "海事保險公證",
            "eng": "海事保險公證"
          },
          {
            "no": "5004003007",
            "des": "年金商品管理",
            "eng": "年金商品管理"
          },
          {
            "no": "5004003008",
            "des": "執行理賠核定",
            "eng": "執行理賠核定"
          },
          {
            "no": "5004003009",
            "des": "投資型保險商品業務",
            "eng": "投資型保險商品業務"
          },
          {
            "no": "5004003010",
            "des": "產物與財產保險業務",
            "eng": "產物與財產保險業務"
          },
          {
            "no": "5004003011",
            "des": "受理與執行理賠案件",
            "eng": "受理與執行理賠案件"
          },
          {
            "no": "5004003012",
            "des": "撰寫保險事故調查報告",
            "eng": "撰寫保險事故調查報告"
          },
          {
            "no": "5004003013",
            "des": "保單健診與節稅規劃",
            "eng": "保單健診與節稅規劃"
          },
          {
            "no": "5004003014",
            "des": "辦理保險理賠、契約變更",
            "eng": "辦理保險理賠、契約變更"
          },
          {
            "no": "5004003015",
            "des": "保險業務管理",
            "eng": "保險業務管理"
          }
        ]
      },
      {
        "no": "5004004000",
        "des": "不動產",
        "eng": "不動產",
        "n": [
          {
            "no": "5004004001",
            "des": "土地登記專業代理",
            "eng": "土地登記專業代理"
          },
          {
            "no": "5004004002",
            "des": "不動產經紀營業",
            "eng": "不動產經紀營業"
          },
          {
            "no": "5004004003",
            "des": "不動產分析與資料蒐集",
            "eng": "不動產分析與資料蒐集"
          },
          {
            "no": "5004004004",
            "des": "不動產諮詢與服務",
            "eng": "不動產諮詢與服務"
          },
          {
            "no": "5004004005",
            "des": "不動產估價與實務應用",
            "eng": "不動產估價與實務應用"
          },
          {
            "no": "5004004006",
            "des": "房屋租賃仲介服務",
            "eng": "房屋租賃仲介服務"
          },
          {
            "no": "5004004007",
            "des": "不動產投資業務",
            "eng": "不動產投資業務"
          },
          {
            "no": "5004004008",
            "des": "房屋買賣仲介服務",
            "eng": "房屋買賣仲介服務"
          },
          {
            "no": "5004004009",
            "des": "不動產投資應用能力",
            "eng": "不動產投資應用能力"
          },
          {
            "no": "5004004010",
            "des": "租賃契約書擬定",
            "eng": "租賃契約書擬定"
          }
        ]
      },
      {
        "no": "5004005000",
        "des": "金融行政業務",
        "eng": "金融行政業務",
        "n": [
          {
            "no": "5004005001",
            "des": "收租、呆帳催款業務",
            "eng": "收租、呆帳催款業務"
          },
          {
            "no": "5004005002",
            "des": "貸款專業諮詢服務",
            "eng": "貸款專業諮詢服務"
          },
          {
            "no": "5004005003",
            "des": "客訴處理及控管",
            "eng": "客訴處理及控管"
          },
          {
            "no": "5004005004",
            "des": "銀行內部控制法規與制度",
            "eng": "銀行內部控制法規與制度"
          },
          {
            "no": "5004005005",
            "des": "執行貸款流程能力",
            "eng": "執行貸款流程能力"
          },
          {
            "no": "5004005006",
            "des": "審核貸款條件的能力",
            "eng": "審核貸款條件的能力"
          },
          {
            "no": "5004005007",
            "des": "執行鑑價與貸款覆審能力",
            "eng": "執行鑑價與貸款覆審能力"
          },
          {
            "no": "5004005008",
            "des": "擔保品鑑價能力",
            "eng": "擔保品鑑價能力"
          },
          {
            "no": "5004005009",
            "des": "產業分析與財務評估的能力",
            "eng": "產業分析與財務評估的能力"
          },
          {
            "no": "5004005010",
            "des": "辦理貸款業務的能力",
            "eng": "辦理貸款業務的能力"
          }
        ]
      },
      {
        "no": "5004006000",
        "des": "儲匯業務",
        "eng": "儲匯業務",
        "n": [
          {
            "no": "5004006001",
            "des": "出口、進口外匯業務",
            "eng": "出口、進口外匯業務"
          },
          {
            "no": "5004006002",
            "des": "匯率利率分析預測",
            "eng": "匯率利率分析預測"
          },
          {
            "no": "5004006003",
            "des": "初階外匯業務處理",
            "eng": "初階外匯業務處理"
          },
          {
            "no": "5004006004",
            "des": "跨行資金調度",
            "eng": "跨行資金調度"
          },
          {
            "no": "5004006005",
            "des": "國外匯兌業務交易",
            "eng": "國外匯兌業務交易"
          },
          {
            "no": "5004006006",
            "des": "銀行櫃檯收付業務",
            "eng": "銀行櫃檯收付業務"
          },
          {
            "no": "5004006007",
            "des": "帳戶轉帳管理",
            "eng": "帳戶轉帳管理"
          }
        ]
      }
    ]
  },
  {
    "no": "5005000000",
    "des": "行銷╱企劃",
    "eng": "行銷╱企劃",
    "n": [
      {
        "no": "5005001000",
        "des": "公關活動類",
        "eng": "公關活動類",
        "n": [
          {
            "no": "5005001001",
            "des": "公關活動規劃與執行",
            "eng": "公關活動規劃與執行"
          },
          {
            "no": "5005001002",
            "des": "活動主持與場控",
            "eng": "活動主持與場控"
          },
          {
            "no": "5005001003",
            "des": "公關新聞稿撰寫與報導",
            "eng": "公關新聞稿撰寫與報導"
          },
          {
            "no": "5005001004",
            "des": "記者會規劃與執行",
            "eng": "記者會規劃與執行"
          },
          {
            "no": "5005001005",
            "des": "公關議題與危機處理",
            "eng": "公關議題與危機處理"
          }
        ]
      },
      {
        "no": "5005002000",
        "des": "行銷企劃類",
        "eng": "行銷企劃類",
        "n": [
          {
            "no": "5005002001",
            "des": "行銷製作物規劃與執行",
            "eng": "行銷製作物規劃與執行"
          },
          {
            "no": "5005002002",
            "des": "網站企劃能力",
            "eng": "網站企劃能力"
          },
          {
            "no": "5005002003",
            "des": "品牌行銷管理",
            "eng": "品牌行銷管理"
          },
          {
            "no": "5005002004",
            "des": "網路活動規劃與執行",
            "eng": "網路活動規劃與執行"
          },
          {
            "no": "5005002005",
            "des": "異業合作規劃與執行",
            "eng": "異業合作規劃與執行"
          },
          {
            "no": "5005002006",
            "des": "廣告製作規劃與執行",
            "eng": "廣告製作規劃與執行"
          },
          {
            "no": "5005002007",
            "des": "提案與簡報技巧",
            "eng": "提案與簡報技巧"
          },
          {
            "no": "5005002008",
            "des": "研討會╱講座活動規劃與執行",
            "eng": "研討會╱講座活動規劃與執行"
          },
          {
            "no": "5005002009",
            "des": "實體活動規劃與執行",
            "eng": "實體活動規劃與執行"
          }
        ]
      },
      {
        "no": "5005003000",
        "des": "行銷管理類",
        "eng": "行銷管理類",
        "n": [
          {
            "no": "5005003001",
            "des": "行銷宣傳預算編製與控管",
            "eng": "行銷宣傳預算編製與控管"
          },
          {
            "no": "5005003002",
            "des": "廣告預算控管",
            "eng": "廣告預算控管"
          },
          {
            "no": "5005003003",
            "des": "行銷策略擬定",
            "eng": "行銷策略擬定"
          },
          {
            "no": "5005003004",
            "des": "顧客關係管理",
            "eng": "顧客關係管理"
          },
          {
            "no": "5005003005",
            "des": "品牌知名度推廣",
            "eng": "品牌知名度推廣"
          },
          {
            "no": "5005003006",
            "des": "社群媒體經營管理",
            "eng": "社群媒體經營管理"
          },
          {
            "no": "5005003007",
            "des": "產品開發預算與成本控管",
            "eng": "產品開發預算與成本控管"
          }
        ]
      },
      {
        "no": "5005004000",
        "des": "調查分析類",
        "eng": "調查分析類",
        "n": [
          {
            "no": "5005004001",
            "des": "市場調查企劃與執行",
            "eng": "市場調查企劃與執行"
          },
          {
            "no": "5005004002",
            "des": "訪員教育訓練",
            "eng": "訪員教育訓練"
          },
          {
            "no": "5005004003",
            "des": "市場調查資料分析與報告撰寫",
            "eng": "市場調查資料分析與報告撰寫"
          },
          {
            "no": "5005004004",
            "des": "網站流量成效追蹤",
            "eng": "網站流量成效追蹤"
          },
          {
            "no": "5005004005",
            "des": "問卷設計",
            "eng": "問卷設計"
          },
          {
            "no": "5005004006",
            "des": "調查樣本統計分析",
            "eng": "調查樣本統計分析"
          },
          {
            "no": "5005004007",
            "des": "統計軟體操作",
            "eng": "統計軟體操作"
          }
        ]
      },
      {
        "no": "5005005000",
        "des": "媒體廣告類",
        "eng": "媒體廣告類",
        "n": [
          {
            "no": "5005005001",
            "des": "傳播媒體採購規劃",
            "eng": "傳播媒體採購規劃"
          },
          {
            "no": "5005005002",
            "des": "播報新聞稿或節目廣告",
            "eng": "播報新聞稿或節目廣告"
          },
          {
            "no": "5005005003",
            "des": "廣告企劃案╱文案撰寫",
            "eng": "廣告企劃案╱文案撰寫"
          },
          {
            "no": "5005005004",
            "des": "影視節目現場副控與指揮",
            "eng": "影視節目現場副控與指揮"
          },
          {
            "no": "5005005005",
            "des": "廣告效益評估",
            "eng": "廣告效益評估"
          },
          {
            "no": "5005005006",
            "des": "管理規劃現場節目或燈光音響設施",
            "eng": "管理規劃現場節目或燈光音響設施"
          },
          {
            "no": "5005005007",
            "des": "廣告創意發想與提案",
            "eng": "廣告創意發想與提案"
          },
          {
            "no": "5005005008",
            "des": "節目內容包裝與規劃製作",
            "eng": "節目內容包裝與規劃製作"
          }
        ]
      },
      {
        "no": "5005006000",
        "des": "攝影╱影片拍攝類",
        "eng": "攝影╱影片拍攝類",
        "n": [
          {
            "no": "5005006001",
            "des": "各式影片剪接後製作業",
            "eng": "各式影片剪接後製作業"
          },
          {
            "no": "5005006002",
            "des": "影像輸出作業",
            "eng": "影像輸出作業"
          },
          {
            "no": "5005006003",
            "des": "執行影片拍攝作業",
            "eng": "執行影片拍攝作業"
          },
          {
            "no": "5005006004",
            "des": "操作使用各式相機及週邊設備功能",
            "eng": "操作使用各式相機及週邊設備功能"
          },
          {
            "no": "5005006005",
            "des": "執行攝影作業",
            "eng": "執行攝影作業"
          }
        ]
      }
    ]
  },
  {
    "no": "5006000000",
    "des": "客服╱門市╱業務╱貿易",
    "eng": "客服╱門市╱業務╱貿易",
    "n": [
      {
        "no": "5006001000",
        "des": "進口出口",
        "eng": "進口出口",
        "n": [
          {
            "no": "5006001001",
            "des": "出口貨物報關",
            "eng": "出口貨物報關"
          },
          {
            "no": "5006001002",
            "des": "進出口作業流程控管",
            "eng": "進出口作業流程控管"
          },
          {
            "no": "5006001003",
            "des": "出口價格核算",
            "eng": "出口價格核算"
          },
          {
            "no": "5006001004",
            "des": "進出口貨物查驗",
            "eng": "進出口貨物查驗"
          },
          {
            "no": "5006001005",
            "des": "商業信用狀分析",
            "eng": "商業信用狀分析"
          },
          {
            "no": "5006001006",
            "des": "進出口報關管理",
            "eng": "進出口報關管理"
          },
          {
            "no": "5006001007",
            "des": "貿易單據製作",
            "eng": "貿易單據製作"
          },
          {
            "no": "5006001008",
            "des": "熟悉進出口業務",
            "eng": "熟悉進出口業務"
          }
        ]
      },
      {
        "no": "5006002000",
        "des": "貿易行政",
        "eng": "貿易行政",
        "n": [
          {
            "no": "5006002001",
            "des": "保稅帳務管理",
            "eng": "保稅帳務管理"
          },
          {
            "no": "5006002002",
            "des": "貨品報廢處理",
            "eng": "貨品報廢處理"
          },
          {
            "no": "5006002003",
            "des": "國際貿易基本概念",
            "eng": "國際貿易基本概念"
          }
        ]
      },
      {
        "no": "5006003000",
        "des": "業務開發",
        "eng": "業務開發",
        "n": [
          {
            "no": "5006003001",
            "des": "客戶資料更新維護",
            "eng": "客戶資料更新維護"
          },
          {
            "no": "5006003002",
            "des": "業績目標分配與績效達成",
            "eng": "業績目標分配與績效達成"
          },
          {
            "no": "5006003003",
            "des": "國外業務開發",
            "eng": "國外業務開發"
          },
          {
            "no": "5006003004",
            "des": "業績與管理報表撰寫",
            "eng": "業績與管理報表撰寫"
          },
          {
            "no": "5006003005",
            "des": "業務或通路開發",
            "eng": "業務或通路開發"
          },
          {
            "no": "5006003006",
            "des": "廣告業務銷售能力",
            "eng": "廣告業務銷售能力"
          }
        ]
      },
      {
        "no": "5006004000",
        "des": "客服╱門市類",
        "eng": "客服╱門市類",
        "n": [
          {
            "no": "5006004001",
            "des": "客戶情報蒐集",
            "eng": "客戶情報蒐集"
          },
          {
            "no": "5006004002",
            "des": "櫃台服務與文具伙食供應管理",
            "eng": "櫃台服務與文具伙食供應管理"
          },
          {
            "no": "5006004003",
            "des": "客訴案件處理",
            "eng": "客訴案件處理"
          },
          {
            "no": "5006004004",
            "des": "產品進銷存統計分析",
            "eng": "產品進銷存統計分析"
          },
          {
            "no": "5006004005",
            "des": "櫃檯門市接待與需求服務",
            "eng": "櫃檯門市接待與需求服務"
          },
          {
            "no": "5006004006",
            "des": "產品介紹及解說銷售",
            "eng": "產品介紹及解說銷售"
          }
        ]
      }
    ]
  },
  {
    "no": "5007000000",
    "des": "美容美髮",
    "eng": "美容美髮",
    "n": [
      {
        "no": "5007001000",
        "des": "美容",
        "eng": "美容",
        "n": [
          {
            "no": "5007001001",
            "des": "了解皮膚構造與特性",
            "eng": "了解皮膚構造與特性"
          },
          {
            "no": "5007001002",
            "des": "基礎潔膚╱卸妝技巧",
            "eng": "基礎潔膚╱卸妝技巧"
          },
          {
            "no": "5007001003",
            "des": "美容業經營概念",
            "eng": "美容業經營概念"
          },
          {
            "no": "5007001004",
            "des": "眼部彩妝設計",
            "eng": "眼部彩妝設計"
          },
          {
            "no": "5007001005",
            "des": "美容器材使用",
            "eng": "美容器材使用"
          },
          {
            "no": "5007001006",
            "des": "鼻唇型彩妝設計",
            "eng": "鼻唇型彩妝設計"
          },
          {
            "no": "5007001007",
            "des": "基本素描能力",
            "eng": "基本素描能力"
          },
          {
            "no": "5007001008",
            "des": "臉部保養技巧",
            "eng": "臉部保養技巧"
          },
          {
            "no": "5007001009",
            "des": "基礎彩妝設計",
            "eng": "基礎彩妝設計"
          },
          {
            "no": "5007001010",
            "des": "臉部輪廓彩妝設計",
            "eng": "臉部輪廓彩妝設計"
          }
        ]
      },
      {
        "no": "5007002000",
        "des": "美髮",
        "eng": "美髮",
        "n": [
          {
            "no": "5007002001",
            "des": "辨識髮型操作圖",
            "eng": "辨識髮型操作圖"
          },
          {
            "no": "5007002002",
            "des": "修剃臉部與髮型整理",
            "eng": "修剃臉部與髮型整理"
          },
          {
            "no": "5007002003",
            "des": "燙髮╱染髮設計",
            "eng": "燙髮╱染髮設計"
          },
          {
            "no": "5007002004",
            "des": "美髮機器與器具使用",
            "eng": "美髮機器與器具使用"
          },
          {
            "no": "5007002005",
            "des": "熟悉美髮用品及功能",
            "eng": "熟悉美髮用品及功能"
          },
          {
            "no": "5007002006",
            "des": "美髮業經營概念",
            "eng": "美髮業經營概念"
          },
          {
            "no": "5007002007",
            "des": "剪髮技術與髮型設計",
            "eng": "剪髮技術與髮型設計"
          },
          {
            "no": "5007002008",
            "des": "毛髮與皮膚基礎保養",
            "eng": "毛髮與皮膚基礎保養"
          },
          {
            "no": "5007002009",
            "des": "假髮配戴操作",
            "eng": "假髮配戴操作"
          }
        ]
      },
      {
        "no": "5007003000",
        "des": "身體保養",
        "eng": "身體保養",
        "n": [
          {
            "no": "5007003001",
            "des": "SPA護理知識",
            "eng": "SPA護理知識"
          },
          {
            "no": "5007003002",
            "des": "精油芳香療法知識",
            "eng": "精油芳香療法知識"
          },
          {
            "no": "5007003003",
            "des": "手足部保養美化",
            "eng": "手足部保養美化"
          },
          {
            "no": "5007003004",
            "des": "精油舒壓按摩技巧",
            "eng": "精油舒壓按摩技巧"
          },
          {
            "no": "5007003005",
            "des": "手足部按摩",
            "eng": "手足部按摩"
          },
          {
            "no": "5007003006",
            "des": "各式按摩臨床應用",
            "eng": "各式按摩臨床應用"
          },
          {
            "no": "5007003007",
            "des": "手足部構造基本認識",
            "eng": "手足部構造基本認識"
          },
          {
            "no": "5007003008",
            "des": "健康問題之護理評估",
            "eng": "健康問題之護理評估"
          },
          {
            "no": "5007003009",
            "des": "身體保養技巧",
            "eng": "身體保養技巧"
          },
          {
            "no": "5007003010",
            "des": "基本按摩手法",
            "eng": "基本按摩手法"
          },
          {
            "no": "5007003011",
            "des": "指甲藝術造型彩繪修護",
            "eng": "指甲藝術造型彩繪修護"
          }
        ]
      },
      {
        "no": "5007004000",
        "des": "服裝設計",
        "eng": "服裝設計",
        "n": [
          {
            "no": "5007004001",
            "des": "服裝配搭技巧",
            "eng": "服裝配搭技巧"
          },
          {
            "no": "5007004002",
            "des": "髮型服飾造型搭配",
            "eng": "髮型服飾造型搭配"
          },
          {
            "no": "5007004003",
            "des": "提供服裝指導",
            "eng": "提供服裝指導"
          },
          {
            "no": "5007004004",
            "des": "整體造型設計",
            "eng": "整體造型設計"
          }
        ]
      },
      {
        "no": "5007005000",
        "des": "美姿美儀",
        "eng": "美姿美儀",
        "n": [
          {
            "no": "5007005001",
            "des": "提供肢體語言訓練",
            "eng": "提供肢體語言訓練"
          },
          {
            "no": "5007005002",
            "des": "聲音表達指導",
            "eng": "聲音表達指導"
          },
          {
            "no": "5007005003",
            "des": "提供儀態訓練",
            "eng": "提供儀態訓練"
          },
          {
            "no": "5007005004",
            "des": "臉部表情╱儀容指導",
            "eng": "臉部表情╱儀容指導"
          },
          {
            "no": "5007005005",
            "des": "提供餐桌禮儀指導",
            "eng": "提供餐桌禮儀指導"
          }
        ]
      },
      {
        "no": "5007006000",
        "des": "寵物美容",
        "eng": "寵物美容",
        "n": [
          {
            "no": "5007006001",
            "des": "寵物保健衛生常識",
            "eng": "寵物保健衛生常識"
          },
          {
            "no": "5007006002",
            "des": "寵物造型設計",
            "eng": "寵物造型設計"
          },
          {
            "no": "5007006003",
            "des": "寵物的飼養常識",
            "eng": "寵物的飼養常識"
          },
          {
            "no": "5007006004",
            "des": "寵物身體清潔與修整",
            "eng": "寵物身體清潔與修整"
          }
        ]
      }
    ]
  },
  {
    "no": "5008000000",
    "des": "餐飲旅遊",
    "eng": "餐飲旅遊",
    "n": [
      {
        "no": "5008001000",
        "des": "吧台╱調酒類",
        "eng": "吧台╱調酒類",
        "n": [
          {
            "no": "5008001001",
            "des": "飲料調製",
            "eng": "飲料調製"
          },
          {
            "no": "5008001002",
            "des": "吧台前置作業準備",
            "eng": "吧台前置作業準備"
          }
        ]
      },
      {
        "no": "5008002000",
        "des": "旅遊類",
        "eng": "旅遊類",
        "n": [
          {
            "no": "5008002001",
            "des": "出團作業處理事項",
            "eng": "出團作業處理事項"
          },
          {
            "no": "5008002002",
            "des": "團體旅遊相關食宿安排",
            "eng": "團體旅遊相關食宿安排"
          },
          {
            "no": "5008002003",
            "des": "行程規劃業務",
            "eng": "行程規劃業務"
          },
          {
            "no": "5008002004",
            "des": "團體旅遊業務銷售",
            "eng": "團體旅遊業務銷售"
          },
          {
            "no": "5008002005",
            "des": "旅遊安全與緊急事件處理",
            "eng": "旅遊安全與緊急事件處理"
          },
          {
            "no": "5008002006",
            "des": "緊急事件危機處理",
            "eng": "緊急事件危機處理"
          },
          {
            "no": "5008002007",
            "des": "航空票務處理",
            "eng": "航空票務處理"
          },
          {
            "no": "5008002008",
            "des": "領隊帶團技巧",
            "eng": "領隊帶團技巧"
          },
          {
            "no": "5008002009",
            "des": "景點文物導覽解說",
            "eng": "景點文物導覽解說"
          },
          {
            "no": "5008002010",
            "des": "辦理各國護照及簽證業務",
            "eng": "辦理各國護照及簽證業務"
          },
          {
            "no": "5008002011",
            "des": "結團作業處理事項",
            "eng": "結團作業處理事項"
          }
        ]
      },
      {
        "no": "5008003000",
        "des": "餐飲類",
        "eng": "餐飲類",
        "n": [
          {
            "no": "5008003001",
            "des": "門市餐飲管理",
            "eng": "門市餐飲管理"
          },
          {
            "no": "5008003002",
            "des": "廚房設備維護管理",
            "eng": "廚房設備維護管理"
          },
          {
            "no": "5008003003",
            "des": "食材成本控制",
            "eng": "食材成本控制"
          },
          {
            "no": "5008003004",
            "des": "廚房器具使用維護",
            "eng": "廚房器具使用維護"
          },
          {
            "no": "5008003005",
            "des": "食材貯存管理",
            "eng": "食材貯存管理"
          },
          {
            "no": "5008003006",
            "des": "熟悉食物性質與營養知識",
            "eng": "熟悉食物性質與營養知識"
          },
          {
            "no": "5008003007",
            "des": "食材選購採買",
            "eng": "食材選購採買"
          },
          {
            "no": "5008003008",
            "des": "規劃與監督營養食譜作業",
            "eng": "規劃與監督營養食譜作業"
          },
          {
            "no": "5008003009",
            "des": "食物製前準備",
            "eng": "食物製前準備"
          },
          {
            "no": "5008003010",
            "des": "提供營養及飲食設計",
            "eng": "提供營養及飲食設計"
          },
          {
            "no": "5008003011",
            "des": "排盤與食品裝飾",
            "eng": "排盤與食品裝飾"
          },
          {
            "no": "5008003012",
            "des": "團體膳食管理",
            "eng": "團體膳食管理"
          },
          {
            "no": "5008003013",
            "des": "製定標準菜譜",
            "eng": "製定標準菜譜"
          }
        ]
      },
      {
        "no": "5008004000",
        "des": "麵包糕點類",
        "eng": "麵包糕點類",
        "n": [
          {
            "no": "5008004001",
            "des": "烘焙食品包裝",
            "eng": "烘焙食品包裝"
          },
          {
            "no": "5008004002",
            "des": "認識各種烘焙食品",
            "eng": "認識各種烘焙食品"
          },
          {
            "no": "5008004003",
            "des": "烘焙食品作業規範",
            "eng": "烘焙食品作業規範"
          },
          {
            "no": "5008004004",
            "des": "麵包原料選用",
            "eng": "麵包原料選用"
          },
          {
            "no": "5008004005",
            "des": "烘焙食品品質判定",
            "eng": "烘焙食品品質判定"
          },
          {
            "no": "5008004006",
            "des": "麵包食品製作",
            "eng": "麵包食品製作"
          }
        ]
      }
    ]
  },
  {
    "no": "5009000000",
    "des": "資訊網路",
    "eng": "資訊網路",
    "n": [
      {
        "no": "5009001000",
        "des": "作業系統與伺服器類",
        "eng": "作業系統與伺服器類",
        "n": [
          {
            "no": "5009001001",
            "des": "伺服器網站管理維護",
            "eng": "伺服器網站管理維護"
          },
          {
            "no": "5009001002",
            "des": "電腦設備裝配",
            "eng": "電腦設備裝配"
          },
          {
            "no": "5009001003",
            "des": "作業系統基本操作",
            "eng": "作業系統基本操作"
          },
          {
            "no": "5009001004",
            "des": "資訊設備操作檢修",
            "eng": "資訊設備操作檢修"
          },
          {
            "no": "5009001005",
            "des": "資料備份與復原",
            "eng": "資料備份與復原"
          },
          {
            "no": "5009001006",
            "des": "資訊設備環境設定",
            "eng": "資訊設備環境設定"
          }
        ]
      },
      {
        "no": "5009002000",
        "des": "軟體開發類",
        "eng": "軟體開發類",
        "n": [
          {
            "no": "5009002001",
            "des": "BIOS工程規格及流程撰寫",
            "eng": "BIOS工程規格及流程撰寫"
          },
          {
            "no": "5009002002",
            "des": "系統整合分析",
            "eng": "系統整合分析"
          },
          {
            "no": "5009002003",
            "des": "BIOS程式研發",
            "eng": "BIOS程式研發"
          },
          {
            "no": "5009002004",
            "des": "軟硬體設備成本控制",
            "eng": "軟硬體設備成本控制"
          },
          {
            "no": "5009002005",
            "des": "BIOS程式偵錯與維護",
            "eng": "BIOS程式偵錯與維護"
          },
          {
            "no": "5009002006",
            "des": "軟體工程系統開發",
            "eng": "軟體工程系統開發"
          },
          {
            "no": "5009002007",
            "des": "系統架構規劃",
            "eng": "系統架構規劃"
          },
          {
            "no": "5009002008",
            "des": "軟體品質與保證",
            "eng": "軟體品質與保證"
          },
          {
            "no": "5009002009",
            "des": "系統維護操作",
            "eng": "系統維護操作"
          },
          {
            "no": "5009002010",
            "des": "韌體工程開發",
            "eng": "韌體工程開發"
          }
        ]
      },
      {
        "no": "5009003000",
        "des": "通訊傳輸類",
        "eng": "通訊傳輸類",
        "n": [
          {
            "no": "5009003001",
            "des": "網路資料傳輸",
            "eng": "網路資料傳輸"
          },
          {
            "no": "5009003002",
            "des": "電信設備安裝",
            "eng": "電信設備安裝"
          },
          {
            "no": "5009003003",
            "des": "有線電視工程架設",
            "eng": "有線電視工程架設"
          },
          {
            "no": "5009003004",
            "des": "電信設備儀表工具使用",
            "eng": "電信設備儀表工具使用"
          },
          {
            "no": "5009003005",
            "des": "信號與系統設計",
            "eng": "信號與系統設計"
          },
          {
            "no": "5009003006",
            "des": "電信線路工程架設",
            "eng": "電信線路工程架設"
          },
          {
            "no": "5009003007",
            "des": "訊號壓縮技術",
            "eng": "訊號壓縮技術"
          },
          {
            "no": "5009003008",
            "des": "數位通訊技術訊號處理",
            "eng": "數位通訊技術訊號處理"
          },
          {
            "no": "5009003009",
            "des": "通訊工程技術開發",
            "eng": "通訊工程技術開發"
          },
          {
            "no": "5009003010",
            "des": "整合無線通訊模組",
            "eng": "整合無線通訊模組"
          },
          {
            "no": "5009003011",
            "des": "通訊技術發展研究",
            "eng": "通訊技術發展研究"
          },
          {
            "no": "5009003012",
            "des": "繪製電信線路圖",
            "eng": "繪製電信線路圖"
          },
          {
            "no": "5009003013",
            "des": "無線通訊技術開發",
            "eng": "無線通訊技術開發"
          },
          {
            "no": "5009003014",
            "des": "纜線障礙查測",
            "eng": "纜線障礙查測"
          },
          {
            "no": "5009003015",
            "des": "資料通訊與網路應用",
            "eng": "資料通訊與網路應用"
          },
          {
            "no": "5009003016",
            "des": "通訊工作安全",
            "eng": "通訊工作安全"
          }
        ]
      },
      {
        "no": "5009004000",
        "des": "測試類",
        "eng": "測試類",
        "n": [
          {
            "no": "5009004001",
            "des": "功能測試(function test)",
            "eng": "功能測試(function test)"
          },
          {
            "no": "5009004002",
            "des": "無線通訊模組測試",
            "eng": "無線通訊模組測試"
          },
          {
            "no": "5009004003",
            "des": "安全性測試(Security test)",
            "eng": "安全性測試(Security test)"
          },
          {
            "no": "5009004004",
            "des": "硬體整合測試",
            "eng": "硬體整合測試"
          },
          {
            "no": "5009004005",
            "des": "使用者測試(Usability test)",
            "eng": "使用者測試(Usability test)"
          },
          {
            "no": "5009004006",
            "des": "韌體整合測試",
            "eng": "韌體整合測試"
          },
          {
            "no": "5009004007",
            "des": "問題追蹤處理(Bug tracking)",
            "eng": "問題追蹤處理(Bug tracking)"
          },
          {
            "no": "5009004008",
            "des": "電信設備測試",
            "eng": "電信設備測試"
          },
          {
            "no": "5009004009",
            "des": "軟體整合測試",
            "eng": "軟體整合測試"
          },
          {
            "no": "5009004010",
            "des": "壓力測試(stress test)",
            "eng": "壓力測試(stress test)"
          },
          {
            "no": "5009004011",
            "des": "測試環境建置規劃",
            "eng": "測試環境建置規劃"
          },
          {
            "no": "5009004012",
            "des": "測試計劃及測試報告書撰寫",
            "eng": "測試計劃及測試報告書撰寫"
          }
        ]
      },
      {
        "no": "5009005000",
        "des": "程式設計類",
        "eng": "程式設計類",
        "n": [
          {
            "no": "5009005001",
            "des": "軟體程式設計",
            "eng": "軟體程式設計"
          },
          {
            "no": "5009005002",
            "des": "網路程式設計",
            "eng": "網路程式設計"
          },
          {
            "no": "5009005003",
            "des": "結構化程式設計",
            "eng": "結構化程式設計"
          },
          {
            "no": "5009005004",
            "des": "模組化系統設計",
            "eng": "模組化系統設計"
          },
          {
            "no": "5009005005",
            "des": "韌體程式設計",
            "eng": "韌體程式設計"
          },
          {
            "no": "5009005006",
            "des": "Machine Learning",
            "eng": "Machine Learning"
          },
          {
            "no": "5009005007",
            "des": "微電腦軟體設計",
            "eng": "微電腦軟體設計"
          }
        ]
      },
      {
        "no": "5009006000",
        "des": "資料庫設計類",
        "eng": "資料庫設計類",
        "n": [
          {
            "no": "5009006001",
            "des": "資料庫系統管理維護",
            "eng": "資料庫系統管理維護"
          },
          {
            "no": "5009006002",
            "des": "資料庫軟體應用",
            "eng": "資料庫軟體應用"
          },
          {
            "no": "5009006003",
            "des": "資料庫程式設計",
            "eng": "資料庫程式設計"
          }
        ]
      },
      {
        "no": "5009007000",
        "des": "資訊管理類",
        "eng": "資訊管理類",
        "n": [
          {
            "no": "5009007001",
            "des": "MES工廠執行系統規劃管理",
            "eng": "MES工廠執行系統規劃管理"
          },
          {
            "no": "5009007002",
            "des": "系統架構規劃與維護",
            "eng": "系統架構規劃與維護"
          },
          {
            "no": "5009007003",
            "des": "企業資源規劃管理(ERP)",
            "eng": "企業資源規劃管理(ERP)"
          },
          {
            "no": "5009007004",
            "des": "軟硬體設備預算與採買規劃",
            "eng": "軟硬體設備預算與採買規劃"
          }
        ]
      },
      {
        "no": "5009008000",
        "des": "網路安全",
        "eng": "網路安全",
        "n": [
          {
            "no": "5009008001",
            "des": "安裝與維護網路安全系統",
            "eng": "安裝與維護網路安全系統"
          },
          {
            "no": "5009008002",
            "des": "設計網路安全系統",
            "eng": "設計網路安全系統"
          },
          {
            "no": "5009008003",
            "des": "規劃與管理入侵防護系統（IPS）",
            "eng": "規劃與管理入侵防護系統（IPS）"
          },
          {
            "no": "5009008004",
            "des": "網路安全架構分析與設計",
            "eng": "網路安全架構分析與設計"
          },
          {
            "no": "5009008005",
            "des": "規劃與管理防火牆",
            "eng": "規劃與管理防火牆"
          },
          {
            "no": "5009008006",
            "des": "網路系統危機管理",
            "eng": "網路系統危機管理"
          },
          {
            "no": "5009008007",
            "des": "規劃與管理網路入侵檢測系統（NIDS）",
            "eng": "規劃與管理網路入侵檢測系統（NIDS）"
          }
        ]
      },
      {
        "no": "5009009000",
        "des": "網路技術類",
        "eng": "網路技術類",
        "n": [
          {
            "no": "5009009001",
            "des": "網路系統配置",
            "eng": "網路系統配置"
          },
          {
            "no": "5009009002",
            "des": "網路符號辨認",
            "eng": "網路符號辨認"
          },
          {
            "no": "5009009003",
            "des": "網路配線工具使用",
            "eng": "網路配線工具使用"
          },
          {
            "no": "5009009004",
            "des": "網路規劃管理",
            "eng": "網路規劃管理"
          },
          {
            "no": "5009009005",
            "des": "網路設備儀錶使用",
            "eng": "網路設備儀錶使用"
          },
          {
            "no": "5009009006",
            "des": "網路設備設定安裝",
            "eng": "網路設備設定安裝"
          },
          {
            "no": "5009009007",
            "des": "網路元件安裝及管理",
            "eng": "網路元件安裝及管理"
          },
          {
            "no": "5009009008",
            "des": "網路應用軟體操作",
            "eng": "網路應用軟體操作"
          },
          {
            "no": "5009009009",
            "des": "網路架設佈線",
            "eng": "網路架設佈線"
          }
        ]
      },
      {
        "no": "5009010000",
        "des": "辦公室應用類",
        "eng": "辦公室應用類",
        "n": [
          {
            "no": "5009010001",
            "des": "文書處理軟體操作",
            "eng": "文書處理軟體操作"
          },
          {
            "no": "5009010002",
            "des": "電子試算表軟體操作",
            "eng": "電子試算表軟體操作"
          },
          {
            "no": "5009010003",
            "des": "流程圖軟體操作",
            "eng": "流程圖軟體操作"
          },
          {
            "no": "5009010004",
            "des": "簡報軟體操作",
            "eng": "簡報軟體操作"
          },
          {
            "no": "5009010005",
            "des": "專案管理軟體操作",
            "eng": "專案管理軟體操作"
          }
        ]
      }
    ]
  },
  {
    "no": "5010000000",
    "des": "電子電機",
    "eng": "電子電機",
    "n": [
      {
        "no": "5010001000",
        "des": "汽機操作╱修護類",
        "eng": "汽機操作╱修護類",
        "n": [
          {
            "no": "5010001001",
            "des": "汽車定期保養作業",
            "eng": "汽車定期保養作業"
          },
          {
            "no": "5010001002",
            "des": "汽車電系修護",
            "eng": "汽車電系修護"
          },
          {
            "no": "5010001003",
            "des": "汽車引擎機件檢查及修護",
            "eng": "汽車引擎機件檢查及修護"
          },
          {
            "no": "5010001004",
            "des": "汽車檢驗與品質鑑定",
            "eng": "汽車檢驗與品質鑑定"
          },
          {
            "no": "5010001005",
            "des": "汽車底盤修護",
            "eng": "汽車底盤修護"
          }
        ]
      },
      {
        "no": "5010002000",
        "des": "PCB",
        "eng": "PCB",
        "n": [
          {
            "no": "5010002001",
            "des": "PCB Layout軟體操作",
            "eng": "PCB Layout軟體操作"
          },
          {
            "no": "5010002002",
            "des": "PCB製板廠商開發與評鑑",
            "eng": "PCB製板廠商開發與評鑑"
          },
          {
            "no": "5010002003",
            "des": "PCB產品認證作業",
            "eng": "PCB產品認證作業"
          },
          {
            "no": "5010002004",
            "des": "PCB樣品測試檢驗",
            "eng": "PCB樣品測試檢驗"
          },
          {
            "no": "5010002005",
            "des": "PCB電路板設計",
            "eng": "PCB電路板設計"
          },
          {
            "no": "5010002006",
            "des": "PCB樣品跟催",
            "eng": "PCB樣品跟催"
          },
          {
            "no": "5010002007",
            "des": "PCB電路板圖表繪製",
            "eng": "PCB電路板圖表繪製"
          },
          {
            "no": "5010002008",
            "des": "PCB機台操作設定與維修",
            "eng": "PCB機台操作設定與維修"
          },
          {
            "no": "5010002009",
            "des": "PCB零件庫管理",
            "eng": "PCB零件庫管理"
          }
        ]
      },
      {
        "no": "5010003000",
        "des": "半導體類",
        "eng": "半導體類",
        "n": [
          {
            "no": "5010003001",
            "des": "IC版圖佈局佈線",
            "eng": "IC版圖佈局佈線"
          },
          {
            "no": "5010003002",
            "des": "半導體材料採購",
            "eng": "半導體材料採購"
          },
          {
            "no": "5010003003",
            "des": "IC電路模擬測試",
            "eng": "IC電路模擬測試"
          },
          {
            "no": "5010003004",
            "des": "半導體材料穩定度分析",
            "eng": "半導體材料穩定度分析"
          },
          {
            "no": "5010003005",
            "des": "半導體元件設計製作",
            "eng": "半導體元件設計製作"
          },
          {
            "no": "5010003006",
            "des": "半導體產業市場分析",
            "eng": "半導體產業市場分析"
          },
          {
            "no": "5010003007",
            "des": "半導體元件測試檢修",
            "eng": "半導體元件測試檢修"
          },
          {
            "no": "5010003008",
            "des": "半導體測試程式開發",
            "eng": "半導體測試程式開發"
          },
          {
            "no": "5010003009",
            "des": "半導體材料良率分析",
            "eng": "半導體材料良率分析"
          }
        ]
      },
      {
        "no": "5010004000",
        "des": "光電類",
        "eng": "光電類",
        "n": [
          {
            "no": "5010004001",
            "des": "光電工程技術開發",
            "eng": "光電工程技術開發"
          },
          {
            "no": "5010004002",
            "des": "光電材料元件良率控制",
            "eng": "光電材料元件良率控制"
          },
          {
            "no": "5010004003",
            "des": "光電元件材料採購",
            "eng": "光電元件材料採購"
          },
          {
            "no": "5010004004",
            "des": "光電材料管控與故障分析",
            "eng": "光電材料管控與故障分析"
          },
          {
            "no": "5010004005",
            "des": "光電元件材料設計",
            "eng": "光電元件材料設計"
          },
          {
            "no": "5010004006",
            "des": "光電材料與元件產品製作",
            "eng": "光電材料與元件產品製作"
          },
          {
            "no": "5010004007",
            "des": "光電元件材料測試",
            "eng": "光電元件材料測試"
          },
          {
            "no": "5010004008",
            "des": "光電儀器修護",
            "eng": "光電儀器修護"
          },
          {
            "no": "5010004009",
            "des": "光電元件檢測儀器設計",
            "eng": "光電元件檢測儀器設計"
          },
          {
            "no": "5010004010",
            "des": "光電儀器設備採購",
            "eng": "光電儀器設備採購"
          }
        ]
      },
      {
        "no": "5010005000",
        "des": "光學",
        "eng": "光學",
        "n": [
          {
            "no": "5010005001",
            "des": "光學元件檢測儀器設計",
            "eng": "光學元件檢測儀器設計"
          },
          {
            "no": "5010005002",
            "des": "光學電子整合開發",
            "eng": "光學電子整合開發"
          },
          {
            "no": "5010005003",
            "des": "光學系統研發設計",
            "eng": "光學系統研發設計"
          },
          {
            "no": "5010005004",
            "des": "光學模擬分析作業",
            "eng": "光學模擬分析作業"
          },
          {
            "no": "5010005005",
            "des": "光學系統測試分析",
            "eng": "光學系統測試分析"
          },
          {
            "no": "5010005006",
            "des": "協助驗光與眼鏡配製服務",
            "eng": "協助驗光與眼鏡配製服務"
          },
          {
            "no": "5010005007",
            "des": "光學設備採購作業",
            "eng": "光學設備採購作業"
          }
        ]
      },
      {
        "no": "5010006000",
        "des": "產品設計驗證",
        "eng": "產品設計驗證",
        "n": [
          {
            "no": "5010006001",
            "des": "產品或零件測試驗證",
            "eng": "產品或零件測試驗證"
          },
          {
            "no": "5010006002",
            "des": "產品機構設計",
            "eng": "產品機構設計"
          },
          {
            "no": "5010006003",
            "des": "產品結構評估",
            "eng": "產品結構評估"
          },
          {
            "no": "5010006004",
            "des": "產品諮詢與服務",
            "eng": "產品諮詢與服務"
          },
          {
            "no": "5010006005",
            "des": "產品量產導入計劃",
            "eng": "產品量產導入計劃"
          },
          {
            "no": "5010006006",
            "des": "新產品研發與測試",
            "eng": "新產品研發與測試"
          },
          {
            "no": "5010006007",
            "des": "產品開發預算與成本控管",
            "eng": "產品開發預算與成本控管"
          },
          {
            "no": "5010006008",
            "des": "新產品檢驗",
            "eng": "新產品檢驗"
          },
          {
            "no": "5010006009",
            "des": "產品試產檢討",
            "eng": "產品試產檢討"
          },
          {
            "no": "5010006010",
            "des": "製作工程樣品與管理",
            "eng": "製作工程樣品與管理"
          },
          {
            "no": "5010006011",
            "des": "產品操作文件製作",
            "eng": "產品操作文件製作"
          },
          {
            "no": "5010006012",
            "des": "製作規格說明書",
            "eng": "製作規格說明書"
          }
        ]
      },
      {
        "no": "5010007000",
        "des": "硬體開發類",
        "eng": "硬體開發類",
        "n": [
          {
            "no": "5010007001",
            "des": "主機板相關零件控制",
            "eng": "主機板相關零件控制"
          },
          {
            "no": "5010007002",
            "des": "電路板測試",
            "eng": "電路板測試"
          },
          {
            "no": "5010007003",
            "des": "自動化系統設計整合",
            "eng": "自動化系統設計整合"
          },
          {
            "no": "5010007004",
            "des": "撰寫IC電路佈局軟體",
            "eng": "撰寫IC電路佈局軟體"
          },
          {
            "no": "5010007005",
            "des": "軟硬體設備成本控制",
            "eng": "軟硬體設備成本控制"
          },
          {
            "no": "5010007006",
            "des": "撰寫硬體語言程式",
            "eng": "撰寫硬體語言程式"
          },
          {
            "no": "5010007007",
            "des": "軟硬體設備採購",
            "eng": "軟硬體設備採購"
          },
          {
            "no": "5010007008",
            "des": "積體電路測試封裝",
            "eng": "積體電路測試封裝"
          },
          {
            "no": "5010007009",
            "des": "硬體工程技術開發",
            "eng": "硬體工程技術開發"
          }
        ]
      },
      {
        "no": "5010008000",
        "des": "電子工程類",
        "eng": "電子工程類",
        "n": [
          {
            "no": "5010008001",
            "des": "建立╱維護零件資料庫",
            "eng": "建立╱維護零件資料庫"
          },
          {
            "no": "5010008002",
            "des": "電子零件產品資訊蒐集",
            "eng": "電子零件產品資訊蒐集"
          },
          {
            "no": "5010008003",
            "des": "測量儀器之檢驗與校正",
            "eng": "測量儀器之檢驗與校正"
          },
          {
            "no": "5010008004",
            "des": "電子圖表繪製",
            "eng": "電子圖表繪製"
          },
          {
            "no": "5010008005",
            "des": "開發電子電路系統",
            "eng": "開發電子電路系統"
          },
          {
            "no": "5010008006",
            "des": "電子儀表工具使用",
            "eng": "電子儀表工具使用"
          },
          {
            "no": "5010008007",
            "des": "電子產品技術轉移作業",
            "eng": "電子產品技術轉移作業"
          },
          {
            "no": "5010008008",
            "des": "電子儀表設備測試",
            "eng": "電子儀表設備測試"
          },
          {
            "no": "5010008009",
            "des": "電子產品系統測試",
            "eng": "電子產品系統測試"
          },
          {
            "no": "5010008010",
            "des": "電子儀表設備裝配",
            "eng": "電子儀表設備裝配"
          },
          {
            "no": "5010008011",
            "des": "電子產品系統導入作業",
            "eng": "電子產品系統導入作業"
          },
          {
            "no": "5010008012",
            "des": "零件品質系統維護分析",
            "eng": "零件品質系統維護分析"
          },
          {
            "no": "5010008013",
            "des": "電子設備操作簡修",
            "eng": "電子設備操作簡修"
          },
          {
            "no": "5010008014",
            "des": "調整與維修視訊╱音訊器材",
            "eng": "調整與維修視訊╱音訊器材"
          },
          {
            "no": "5010008015",
            "des": "電子電路設計",
            "eng": "電子電路設計"
          }
        ]
      },
      {
        "no": "5010009000",
        "des": "電機工程類",
        "eng": "電機工程類",
        "n": [
          {
            "no": "5010009001",
            "des": "設計導入製造執行系統（MES）",
            "eng": "設計導入製造執行系統（MES）"
          },
          {
            "no": "5010009002",
            "des": "電源供應器設計",
            "eng": "電源供應器設計"
          },
          {
            "no": "5010009003",
            "des": "設計機台設備自動化",
            "eng": "設計機台設備自動化"
          },
          {
            "no": "5010009004",
            "des": "電源管理IC設計",
            "eng": "電源管理IC設計"
          },
          {
            "no": "5010009005",
            "des": "微機電元件製程驗證",
            "eng": "微機電元件製程驗證"
          },
          {
            "no": "5010009006",
            "des": "電機設備研發",
            "eng": "電機設備研發"
          },
          {
            "no": "5010009007",
            "des": "微機電系統設計",
            "eng": "微機電系統設計"
          },
          {
            "no": "5010009008",
            "des": "電機設備採購",
            "eng": "電機設備採購"
          },
          {
            "no": "5010009009",
            "des": "微機電製程研發",
            "eng": "微機電製程研發"
          },
          {
            "no": "5010009010",
            "des": "電機設備設計開發",
            "eng": "電機設備設計開發"
          },
          {
            "no": "5010009011",
            "des": "電力工程裝修與控制",
            "eng": "電力工程裝修與控制"
          },
          {
            "no": "5010009012",
            "des": "電機設備測試",
            "eng": "電機設備測試"
          },
          {
            "no": "5010009013",
            "des": "電工圖識圖與繪圖",
            "eng": "電工圖識圖與繪圖"
          },
          {
            "no": "5010009014",
            "des": "蒐集電機設備產品資訊",
            "eng": "蒐集電機設備產品資訊"
          },
          {
            "no": "5010009015",
            "des": "電工儀表裝置使用",
            "eng": "電工儀表裝置使用"
          },
          {
            "no": "5010009016",
            "des": "CNC程式設計與程式模擬",
            "eng": "CNC程式設計與程式模擬"
          },
          {
            "no": "5010009017",
            "des": "電動機及電氣相關知識",
            "eng": "電動機及電氣相關知識"
          },
          {
            "no": "5010009018",
            "des": "冷凍空調工程之規劃施工",
            "eng": "冷凍空調工程之規劃施工"
          },
          {
            "no": "5010009019",
            "des": "電源供應器規格開發",
            "eng": "電源供應器規格開發"
          }
        ]
      },
      {
        "no": "5010010000",
        "des": "數位╱類比電路",
        "eng": "數位╱類比電路",
        "n": [
          {
            "no": "5010010001",
            "des": "前端類比系統設計",
            "eng": "前端類比系統設計"
          },
          {
            "no": "5010010002",
            "des": "數位電路驗證",
            "eng": "數位電路驗證"
          },
          {
            "no": "5010010003",
            "des": "高頻電路產品故障分析",
            "eng": "高頻電路產品故障分析"
          },
          {
            "no": "5010010004",
            "des": "模擬類比IC電路",
            "eng": "模擬類比IC電路"
          },
          {
            "no": "5010010005",
            "des": "高頻電路產品管控",
            "eng": "高頻電路產品管控"
          },
          {
            "no": "5010010006",
            "des": "類比IC電路設計",
            "eng": "類比IC電路設計"
          },
          {
            "no": "5010010007",
            "des": "高頻電路設計",
            "eng": "高頻電路設計"
          },
          {
            "no": "5010010008",
            "des": "類比IC電路維護",
            "eng": "類比IC電路維護"
          },
          {
            "no": "5010010009",
            "des": "基礎數位電路",
            "eng": "基礎數位電路"
          },
          {
            "no": "5010010010",
            "des": "類比IC電路驗證",
            "eng": "類比IC電路驗證"
          },
          {
            "no": "5010010011",
            "des": "基礎類比電路",
            "eng": "基礎類比電路"
          },
          {
            "no": "5010010012",
            "des": "類比信號處理運算能力",
            "eng": "類比信號處理運算能力"
          },
          {
            "no": "5010010013",
            "des": "數位晶片產品開發",
            "eng": "數位晶片產品開發"
          },
          {
            "no": "5010010014",
            "des": "類比電路設計",
            "eng": "類比電路設計"
          },
          {
            "no": "5010010015",
            "des": "數位電路分析設計",
            "eng": "數位電路分析設計"
          }
        ]
      },
      {
        "no": "5010011000",
        "des": "機械加工類",
        "eng": "機械加工類",
        "n": [
          {
            "no": "5010011001",
            "des": "表面處理",
            "eng": "表面處理"
          },
          {
            "no": "5010011002",
            "des": "刀具選用、研磨及配置",
            "eng": "刀具選用、研磨及配置"
          },
          {
            "no": "5010011003",
            "des": "金屬管裝置及配線",
            "eng": "金屬管裝置及配線"
          },
          {
            "no": "5010011004",
            "des": "車床╱銑床維護及調整校正",
            "eng": "車床╱銑床維護及調整校正"
          },
          {
            "no": "5010011005",
            "des": "非金屬管裝置及配線",
            "eng": "非金屬管裝置及配線"
          },
          {
            "no": "5010011006",
            "des": "車削╱銑削情況判斷及處理",
            "eng": "車削╱銑削情況判斷及處理"
          },
          {
            "no": "5010011007",
            "des": "高分子材料設計與合成",
            "eng": "高分子材料設計與合成"
          },
          {
            "no": "5010011008",
            "des": "負責製模加工技術之提升訓練",
            "eng": "負責製模加工技術之提升訓練"
          },
          {
            "no": "5010011009",
            "des": "塗裝工程減廢",
            "eng": "塗裝工程減廢"
          },
          {
            "no": "5010011010",
            "des": "射出成形之中止、換料、換色處理",
            "eng": "射出成形之中止、換料、換色處理"
          },
          {
            "no": "5010011011",
            "des": "塗裝作業",
            "eng": "塗裝作業"
          },
          {
            "no": "5010011012",
            "des": "鋁合金外殼表面整修與拋光",
            "eng": "鋁合金外殼表面整修與拋光"
          },
          {
            "no": "5010011013",
            "des": "塗裝機具設備使用保養",
            "eng": "塗裝機具設備使用保養"
          },
          {
            "no": "5010011014",
            "des": "機械加工設備之維護保養",
            "eng": "機械加工設備之維護保養"
          },
          {
            "no": "5010011015",
            "des": "模板檢視",
            "eng": "模板檢視"
          },
          {
            "no": "5010011016",
            "des": "印花系統操作與裝修",
            "eng": "印花系統操作與裝修"
          },
          {
            "no": "5010011017",
            "des": "銲接施工與銲道檢測",
            "eng": "銲接施工與銲道檢測"
          },
          {
            "no": "5010011018",
            "des": "染色前處理機械安裝修護",
            "eng": "染色前處理機械安裝修護"
          },
          {
            "no": "5010011019",
            "des": "CNC加工機及相關機械操作",
            "eng": "CNC加工機及相關機械操作"
          },
          {
            "no": "5010011020",
            "des": "母材加工及組合",
            "eng": "母材加工及組合"
          }
        ]
      },
      {
        "no": "5010012000",
        "des": "機械模具╱製造",
        "eng": "機械模具╱製造",
        "n": [
          {
            "no": "5010012001",
            "des": "金屬粉末冶金技術開發",
            "eng": "金屬粉末冶金技術開發"
          },
          {
            "no": "5010012002",
            "des": "機械產品╱材料測試",
            "eng": "機械產品╱材料測試"
          },
          {
            "no": "5010012003",
            "des": "自動化機構裝配",
            "eng": "自動化機構裝配"
          },
          {
            "no": "5010012004",
            "des": "機械產品採購",
            "eng": "機械產品採購"
          },
          {
            "no": "5010012005",
            "des": "產品模具開發設計",
            "eng": "產品模具開發設計"
          },
          {
            "no": "5010012006",
            "des": "機械產品設計",
            "eng": "機械產品設計"
          },
          {
            "no": "5010012007",
            "des": "電控╱感測迴路裝配",
            "eng": "電控╱感測迴路裝配"
          },
          {
            "no": "5010012008",
            "des": "機電與消防系統規劃",
            "eng": "機電與消防系統規劃"
          },
          {
            "no": "5010012009",
            "des": "電源供應器心體裝配",
            "eng": "電源供應器心體裝配"
          },
          {
            "no": "5010012010",
            "des": "流路系統設計與製作",
            "eng": "流路系統設計與製作"
          },
          {
            "no": "5010012011",
            "des": "電源供應器心體調整",
            "eng": "電源供應器心體調整"
          },
          {
            "no": "5010012012",
            "des": "電鍍槽的維護與保養",
            "eng": "電鍍槽的維護與保養"
          },
          {
            "no": "5010012013",
            "des": "電源供應器裝設",
            "eng": "電源供應器裝設"
          },
          {
            "no": "5010012014",
            "des": "模具設計製作及修整",
            "eng": "模具設計製作及修整"
          },
          {
            "no": "5010012015",
            "des": "電源供應器檢驗",
            "eng": "電源供應器檢驗"
          },
          {
            "no": "5010012016",
            "des": "模具維修及保養作業",
            "eng": "模具維修及保養作業"
          },
          {
            "no": "5010012017",
            "des": "標準機件繪製",
            "eng": "標準機件繪製"
          },
          {
            "no": "5010012018",
            "des": "鍍浴分析及維護",
            "eng": "鍍浴分析及維護"
          },
          {
            "no": "5010012019",
            "des": "線圈製作與安裝",
            "eng": "線圈製作與安裝"
          },
          {
            "no": "5010012020",
            "des": "鑄後處理與檢測",
            "eng": "鑄後處理與檢測"
          },
          {
            "no": "5010012021",
            "des": "機械相關圖表繪製",
            "eng": "機械相關圖表繪製"
          },
          {
            "no": "5010012022",
            "des": "模具安裝與調整",
            "eng": "模具安裝與調整"
          }
        ]
      },
      {
        "no": "5010013000",
        "des": "機械操作╱修護類",
        "eng": "機械操作╱修護類",
        "n": [
          {
            "no": "5010013001",
            "des": "配電裝修維護",
            "eng": "配電裝修維護"
          },
          {
            "no": "5010013002",
            "des": "負責空調和機電設備系統異常處理",
            "eng": "負責空調和機電設備系統異常處理"
          },
          {
            "no": "5010013003",
            "des": "農業機械修護技術",
            "eng": "農業機械修護技術"
          },
          {
            "no": "5010013004",
            "des": "規劃電儀設備年度歲修排程",
            "eng": "規劃電儀設備年度歲修排程"
          },
          {
            "no": "5010013005",
            "des": "電動機操作使用",
            "eng": "電動機操作使用"
          },
          {
            "no": "5010013006",
            "des": "監督廠務工程的配置、測試及安裝",
            "eng": "監督廠務工程的配置、測試及安裝"
          },
          {
            "no": "5010013007",
            "des": "電源供應器維修",
            "eng": "電源供應器維修"
          },
          {
            "no": "5010013008",
            "des": "吊車╱起重機設備操作與指揮",
            "eng": "吊車╱起重機設備操作與指揮"
          },
          {
            "no": "5010013009",
            "des": "電機設備保養修護",
            "eng": "電機設備保養修護"
          },
          {
            "no": "5010013010",
            "des": "挖土機相關機械操作",
            "eng": "挖土機相關機械操作"
          },
          {
            "no": "5010013011",
            "des": "電機設備操作",
            "eng": "電機設備操作"
          },
          {
            "no": "5010013012",
            "des": "起重機設備之準備與檢點",
            "eng": "起重機設備之準備與檢點"
          },
          {
            "no": "5010013013",
            "des": "漏電保護裝置",
            "eng": "漏電保護裝置"
          },
          {
            "no": "5010013014",
            "des": "組合裝配機件及安裝與校驗機械",
            "eng": "組合裝配機件及安裝與校驗機械"
          },
          {
            "no": "5010013015",
            "des": "機務維修保養",
            "eng": "機務維修保養"
          },
          {
            "no": "5010013016",
            "des": "設備器材使用及維護",
            "eng": "設備器材使用及維護"
          },
          {
            "no": "5010013017",
            "des": "機械產品故障排除檢修",
            "eng": "機械產品故障排除檢修"
          },
          {
            "no": "5010013018",
            "des": "準備及操作車床╱銑床",
            "eng": "準備及操作車床╱銑床"
          },
          {
            "no": "5010013019",
            "des": "機械產品操作",
            "eng": "機械產品操作"
          },
          {
            "no": "5010013020",
            "des": "農機材料油料及元件識別",
            "eng": "農機材料油料及元件識別"
          },
          {
            "no": "5010013021",
            "des": "機械零組件使用",
            "eng": "機械零組件使用"
          },
          {
            "no": "5010013022",
            "des": "標準鑑定量具使用及維護",
            "eng": "標準鑑定量具使用及維護"
          },
          {
            "no": "5010013023",
            "des": "機電儀表工具使用",
            "eng": "機電儀表工具使用"
          },
          {
            "no": "5010013024",
            "des": "操作混凝土及瀝青調製",
            "eng": "操作混凝土及瀝青調製"
          }
        ]
      },
      {
        "no": "5010014000",
        "des": "飾品╱樂器加工修護",
        "eng": "飾品╱樂器加工修護",
        "n": [
          {
            "no": "5010014001",
            "des": "工具製作裝配及修理樂器零件",
            "eng": "工具製作裝配及修理樂器零件"
          },
          {
            "no": "5010014002",
            "des": "樂器斷絃與換弦修理",
            "eng": "樂器斷絃與換弦修理"
          },
          {
            "no": "5010014003",
            "des": "平行鋼琴整調與測試作業",
            "eng": "平行鋼琴整調與測試作業"
          },
          {
            "no": "5010014004",
            "des": "豎型鋼琴整調與測試作業",
            "eng": "豎型鋼琴整調與測試作業"
          },
          {
            "no": "5010014005",
            "des": "金屬或珠寶飾品加工製作",
            "eng": "金屬或珠寶飾品加工製作"
          },
          {
            "no": "5010014006",
            "des": "寶石鑲嵌主石座、支撐製作",
            "eng": "寶石鑲嵌主石座、支撐製作"
          },
          {
            "no": "5010014007",
            "des": "金屬或珠寶飾品焊接作業",
            "eng": "金屬或珠寶飾品焊接作業"
          }
        ]
      },
      {
        "no": "5010015000",
        "des": "熱媒╱鍋爐操作類",
        "eng": "熱媒╱鍋爐操作類",
        "n": [
          {
            "no": "5010015001",
            "des": "動力系統操作與維護",
            "eng": "動力系統操作與維護"
          },
          {
            "no": "5010015002",
            "des": "鍋爐設備事故處理",
            "eng": "鍋爐設備事故處理"
          },
          {
            "no": "5010015003",
            "des": "蒸氣及熱媒系統之維護管理",
            "eng": "蒸氣及熱媒系統之維護管理"
          },
          {
            "no": "5010015004",
            "des": "鍋爐設備保存",
            "eng": "鍋爐設備保存"
          },
          {
            "no": "5010015005",
            "des": "鍋爐故障排除與腐蝕處理",
            "eng": "鍋爐故障排除與腐蝕處理"
          },
          {
            "no": "5010015006",
            "des": "鍋爐設備運轉操作",
            "eng": "鍋爐設備運轉操作"
          }
        ]
      }
    ]
  },
  {
    "no": "5011000000",
    "des": "化學╱化工╱醫藥",
    "eng": "化學╱化工╱醫藥",
    "n": [
      {
        "no": "5011001000",
        "des": "儀器使用",
        "eng": "儀器使用",
        "n": [
          {
            "no": "5011001001",
            "des": "化學檢測儀器操作",
            "eng": "化學檢測儀器操作"
          },
          {
            "no": "5011001002",
            "des": "放射性儀器設備之維護檢修",
            "eng": "放射性儀器設備之維護檢修"
          },
          {
            "no": "5011001003",
            "des": "生產設備維護管理",
            "eng": "生產設備維護管理"
          },
          {
            "no": "5011001004",
            "des": "放射線器材使用操作",
            "eng": "放射線器材使用操作"
          },
          {
            "no": "5011001005",
            "des": "實驗室設備操作",
            "eng": "實驗室設備操作"
          },
          {
            "no": "5011001006",
            "des": "施行或安排放射線或其他檢驗",
            "eng": "施行或安排放射線或其他檢驗"
          },
          {
            "no": "5011001007",
            "des": "玻璃加工處理作業",
            "eng": "玻璃加工處理作業"
          },
          {
            "no": "5011001008",
            "des": "規劃放射性物質之輻射防護檢測",
            "eng": "規劃放射性物質之輻射防護檢測"
          },
          {
            "no": "5011001009",
            "des": "玻璃器具裝配與操作",
            "eng": "玻璃器具裝配與操作"
          },
          {
            "no": "5011001010",
            "des": "進行醫事檢驗相關作業",
            "eng": "進行醫事檢驗相關作業"
          },
          {
            "no": "5011001011",
            "des": "秤量測定與校正",
            "eng": "秤量測定與校正"
          },
          {
            "no": "5011001012",
            "des": "督導輻射工作人員劑量紀錄管理",
            "eng": "督導輻射工作人員劑量紀錄管理"
          },
          {
            "no": "5011001013",
            "des": "儀器設備需求分析",
            "eng": "儀器設備需求分析"
          },
          {
            "no": "5011001014",
            "des": "擬具檢查及檢驗報告結果",
            "eng": "擬具檢查及檢驗報告結果"
          },
          {
            "no": "5011001015",
            "des": "完成放射影像判讀報告",
            "eng": "完成放射影像判讀報告"
          },
          {
            "no": "5011001016",
            "des": "檢查及檢驗報告結果判讀",
            "eng": "檢查及檢驗報告結果判讀"
          },
          {
            "no": "5011001017",
            "des": "協助執行放射線檢查作業",
            "eng": "協助執行放射線檢查作業"
          }
        ]
      },
      {
        "no": "5011002000",
        "des": "化學╱紡織╱材料",
        "eng": "化學╱紡織╱材料",
        "n": [
          {
            "no": "5011002001",
            "des": "化學分析能力",
            "eng": "化學分析能力"
          },
          {
            "no": "5011002002",
            "des": "高分子材料設計與合成",
            "eng": "高分子材料設計與合成"
          },
          {
            "no": "5011002003",
            "des": "化學實驗室管理",
            "eng": "化學實驗室管理"
          },
          {
            "no": "5011002004",
            "des": "產品材料分析",
            "eng": "產品材料分析"
          },
          {
            "no": "5011002005",
            "des": "生產線設計規劃",
            "eng": "生產線設計規劃"
          },
          {
            "no": "5011002006",
            "des": "設計與監督紡織流程",
            "eng": "設計與監督紡織流程"
          },
          {
            "no": "5011002007",
            "des": "材料評估測試",
            "eng": "材料評估測試"
          },
          {
            "no": "5011002008",
            "des": "紡織材料製品之製作、更改、補綴縫紉",
            "eng": "紡織材料製品之製作、更改、補綴縫紉"
          }
        ]
      },
      {
        "no": "5011003000",
        "des": "研發",
        "eng": "研發",
        "n": [
          {
            "no": "5011003001",
            "des": "化學製程研發",
            "eng": "化學製程研發"
          },
          {
            "no": "5011003002",
            "des": "進行生藥與藥學研究發展",
            "eng": "進行生藥與藥學研究發展"
          },
          {
            "no": "5011003003",
            "des": "生產程序改良與開發",
            "eng": "生產程序改良與開發"
          },
          {
            "no": "5011003004",
            "des": "藥物分析與生藥學",
            "eng": "藥物分析與生藥學"
          },
          {
            "no": "5011003005",
            "des": "新藥研發實驗規劃執行",
            "eng": "新藥研發實驗規劃執行"
          },
          {
            "no": "5011003006",
            "des": "奈米元件整合技術",
            "eng": "奈米元件整合技術"
          },
          {
            "no": "5011003007",
            "des": "試藥配製作業",
            "eng": "試藥配製作業"
          },
          {
            "no": "5011003008",
            "des": "高介電材料與金屬閘極整合",
            "eng": "高介電材料與金屬閘極整合"
          },
          {
            "no": "5011003009",
            "des": "製程相關技術開發",
            "eng": "製程相關技術開發"
          }
        ]
      },
      {
        "no": "5011004000",
        "des": "食品",
        "eng": "食品",
        "n": [
          {
            "no": "5011004001",
            "des": "食品加工相關知識",
            "eng": "食品加工相關知識"
          },
          {
            "no": "5011004002",
            "des": "食品原料蒐集分析",
            "eng": "食品原料蒐集分析"
          },
          {
            "no": "5011004003",
            "des": "食品包裝作業",
            "eng": "食品包裝作業"
          },
          {
            "no": "5011004004",
            "des": "食品衛生保健",
            "eng": "食品衛生保健"
          },
          {
            "no": "5011004005",
            "des": "食品研發技術",
            "eng": "食品研發技術"
          }
        ]
      },
      {
        "no": "5011005000",
        "des": "化工",
        "eng": "化工",
        "n": [
          {
            "no": "5011005001",
            "des": "化工反應工程作業",
            "eng": "化工反應工程作業"
          },
          {
            "no": "5011005002",
            "des": "提供化學工程技術服務",
            "eng": "提供化學工程技術服務"
          },
          {
            "no": "5011005003",
            "des": "化工熱力學相關知識",
            "eng": "化工熱力學相關知識"
          },
          {
            "no": "5011005004",
            "des": "熟悉化學工程原理",
            "eng": "熟悉化學工程原理"
          },
          {
            "no": "5011005005",
            "des": "判讀化工相關製程圖",
            "eng": "判讀化工相關製程圖"
          },
          {
            "no": "5011005006",
            "des": "瞭解質能均衡原理",
            "eng": "瞭解質能均衡原理"
          },
          {
            "no": "5011005007",
            "des": "客戶端技術服務業務",
            "eng": "客戶端技術服務業務"
          }
        ]
      },
      {
        "no": "5011006000",
        "des": "農藝",
        "eng": "農藝",
        "n": [
          {
            "no": "5011006001",
            "des": "收穫與調製相關知識",
            "eng": "收穫與調製相關知識"
          },
          {
            "no": "5011006002",
            "des": "製造疫苗及藥物",
            "eng": "製造疫苗及藥物"
          },
          {
            "no": "5011006003",
            "des": "動植物品種研究",
            "eng": "動植物品種研究"
          },
          {
            "no": "5011006004",
            "des": "整地作畦與田間管理",
            "eng": "整地作畦與田間管理"
          },
          {
            "no": "5011006005",
            "des": "基本有性╱無性繁殖操作",
            "eng": "基本有性╱無性繁殖操作"
          },
          {
            "no": "5011006006",
            "des": "環境改良作業規劃",
            "eng": "環境改良作業規劃"
          },
          {
            "no": "5011006007",
            "des": "種苗生產管理",
            "eng": "種苗生產管理"
          },
          {
            "no": "5011006008",
            "des": "認識農藝作物",
            "eng": "認識農藝作物"
          },
          {
            "no": "5011006009",
            "des": "種植栽培能力",
            "eng": "種植栽培能力"
          }
        ]
      },
      {
        "no": "5011007000",
        "des": "禽畜",
        "eng": "禽畜",
        "n": [
          {
            "no": "5011007001",
            "des": "牧場經營管理",
            "eng": "牧場經營管理"
          },
          {
            "no": "5011007002",
            "des": "注射預防及動物保健",
            "eng": "注射預防及動物保健"
          },
          {
            "no": "5011007003",
            "des": "畜產運銷作業",
            "eng": "畜產運銷作業"
          },
          {
            "no": "5011007004",
            "des": "動物保健衛生常識",
            "eng": "動物保健衛生常識"
          },
          {
            "no": "5011007005",
            "des": "禽畜保健衛生",
            "eng": "禽畜保健衛生"
          },
          {
            "no": "5011007006",
            "des": "動物繁殖常識",
            "eng": "動物繁殖常識"
          },
          {
            "no": "5011007007",
            "des": "禽畜繁殖相關知識",
            "eng": "禽畜繁殖相關知識"
          },
          {
            "no": "5011007008",
            "des": "認識養殖禁藥",
            "eng": "認識養殖禁藥"
          },
          {
            "no": "5011007009",
            "des": "營養與飼料相關知識",
            "eng": "營養與飼料相關知識"
          }
        ]
      },
      {
        "no": "5011008000",
        "des": "水產養殖",
        "eng": "水產養殖",
        "n": [
          {
            "no": "5011008001",
            "des": "水生生物之包裝與處理",
            "eng": "水生生物之包裝與處理"
          },
          {
            "no": "5011008002",
            "des": "水族箱之設計",
            "eng": "水族箱之設計"
          },
          {
            "no": "5011008003",
            "des": "水生生物認識",
            "eng": "水生生物認識"
          },
          {
            "no": "5011008004",
            "des": "水族箱之裝配與操作",
            "eng": "水族箱之裝配與操作"
          },
          {
            "no": "5011008005",
            "des": "水族生物病害處理",
            "eng": "水族生物病害處理"
          },
          {
            "no": "5011008006",
            "des": "養殖水質檢測",
            "eng": "養殖水質檢測"
          },
          {
            "no": "5011008007",
            "des": "水族生物飼育",
            "eng": "水族生物飼育"
          },
          {
            "no": "5011008008",
            "des": "養殖污水排放處理",
            "eng": "養殖污水排放處理"
          }
        ]
      },
      {
        "no": "5011009000",
        "des": "中醫",
        "eng": "中醫",
        "n": [
          {
            "no": "5011009001",
            "des": "指導養生及解脫精神鬱結方法",
            "eng": "指導養生及解脫精神鬱結方法"
          },
          {
            "no": "5011009002",
            "des": "運用針灸或物理方式進行治療",
            "eng": "運用針灸或物理方式進行治療"
          },
          {
            "no": "5011009003",
            "des": "施行神經遮斷術及針灸",
            "eng": "施行神經遮斷術及針灸"
          },
          {
            "no": "5011009004",
            "des": "熟悉中醫內科學",
            "eng": "熟悉中醫內科學"
          },
          {
            "no": "5011009005",
            "des": "進行中醫把脈與病因診斷",
            "eng": "進行中醫把脈與病因診斷"
          },
          {
            "no": "5011009006",
            "des": "熟悉中醫基礎理論",
            "eng": "熟悉中醫基礎理論"
          },
          {
            "no": "5011009007",
            "des": "進行中藥之方劑與藥物調制",
            "eng": "進行中藥之方劑與藥物調制"
          }
        ]
      },
      {
        "no": "5011010000",
        "des": "西醫",
        "eng": "西醫",
        "n": [
          {
            "no": "5011010001",
            "des": "協同專科醫師進行會診或轉診",
            "eng": "協同專科醫師進行會診或轉診"
          },
          {
            "no": "5011010002",
            "des": "預防保護之護理措施",
            "eng": "預防保護之護理措施"
          },
          {
            "no": "5011010003",
            "des": "指導義肢裝具之裝配",
            "eng": "指導義肢裝具之裝配"
          },
          {
            "no": "5011010004",
            "des": "監督實施各種復健醫學治療法",
            "eng": "監督實施各種復健醫學治療法"
          },
          {
            "no": "5011010005",
            "des": "施行各種專科手術之麻醉",
            "eng": "施行各種專科手術之麻醉"
          },
          {
            "no": "5011010006",
            "des": "檢查、評估及診斷各種生理殘障",
            "eng": "檢查、評估及診斷各種生理殘障"
          },
          {
            "no": "5011010007",
            "des": "計畫及實施職能治療",
            "eng": "計畫及實施職能治療"
          },
          {
            "no": "5011010008",
            "des": "職能評鑑諮詢輔導",
            "eng": "職能評鑑諮詢輔導"
          },
          {
            "no": "5011010009",
            "des": "執行放射線臨床治療作業",
            "eng": "執行放射線臨床治療作業"
          },
          {
            "no": "5011010010",
            "des": "護理指導及諮詢",
            "eng": "護理指導及諮詢"
          },
          {
            "no": "5011010011",
            "des": "執行核子醫學臨床診療作業",
            "eng": "執行核子醫學臨床診療作業"
          },
          {
            "no": "5011010012",
            "des": "吞嚥障礙評估與治療",
            "eng": "吞嚥障礙評估與治療"
          },
          {
            "no": "5011010013",
            "des": "執行調劑醫師處方",
            "eng": "執行調劑醫師處方"
          },
          {
            "no": "5011010014",
            "des": "兒童╱成人溝通障礙評估與治療",
            "eng": "兒童╱成人溝通障礙評估與治療"
          },
          {
            "no": "5011010015",
            "des": "執行靜脈或肌肉注射相關作業",
            "eng": "執行靜脈或肌肉注射相關作業"
          },
          {
            "no": "5011010016",
            "des": "呼吸治療器材之使用與維護",
            "eng": "呼吸治療器材之使用與維護"
          },
          {
            "no": "5011010017",
            "des": "規劃與執行感覺統合測驗及治療",
            "eng": "規劃與執行感覺統合測驗及治療"
          },
          {
            "no": "5011010018",
            "des": "急救甦醒器之使用與維護",
            "eng": "急救甦醒器之使用與維護"
          },
          {
            "no": "5011010019",
            "des": "提供輻射防護管理資訊及建議",
            "eng": "提供輻射防護管理資訊及建議"
          },
          {
            "no": "5011010020",
            "des": "高壓氧氣治療之操作與維護",
            "eng": "高壓氧氣治療之操作與維護"
          },
          {
            "no": "5011010021",
            "des": "進行身心功能及障礙程度評估",
            "eng": "進行身心功能及障礙程度評估"
          },
          {
            "no": "5011010022",
            "des": "語言發展遲緩之評估與治療",
            "eng": "語言發展遲緩之評估與治療"
          }
        ]
      },
      {
        "no": "5011011000",
        "des": "牙醫",
        "eng": "牙醫",
        "n": [
          {
            "no": "5011011001",
            "des": "使用人造牙冠牙橋及義齒修復缺牙",
            "eng": "使用人造牙冠牙橋及義齒修復缺牙"
          },
          {
            "no": "5011011002",
            "des": "填補蛀牙並治療牙疾",
            "eng": "填補蛀牙並治療牙疾"
          },
          {
            "no": "5011011003",
            "des": "執行拔牙及作小型口腔外科手術",
            "eng": "執行拔牙及作小型口腔外科手術"
          },
          {
            "no": "5011011004",
            "des": "齒部及口腔檢查與決定治療方法",
            "eng": "齒部及口腔檢查與決定治療方法"
          },
          {
            "no": "5011011005",
            "des": "診治牙周病及牙根尖周圍疾患",
            "eng": "診治牙周病及牙根尖周圍疾患"
          },
          {
            "no": "5011011006",
            "des": "應用牙科矯正方法矯正齒列不正",
            "eng": "應用牙科矯正方法矯正齒列不正"
          }
        ]
      },
      {
        "no": "5011012000",
        "des": "獸醫",
        "eng": "獸醫",
        "n": [
          {
            "no": "5011012001",
            "des": "動物流行病學及比較醫學研究",
            "eng": "動物流行病學及比較醫學研究"
          },
          {
            "no": "5011012002",
            "des": "執行動物人工受精作業",
            "eng": "執行動物人工受精作業"
          },
          {
            "no": "5011012003",
            "des": "動物解剖生理常識",
            "eng": "動物解剖生理常識"
          },
          {
            "no": "5011012004",
            "des": "調劑動物用藥處方",
            "eng": "調劑動物用藥處方"
          },
          {
            "no": "5011012005",
            "des": "動物檢疫作業",
            "eng": "動物檢疫作業"
          }
        ]
      }
    ]
  },
  {
    "no": "5012000000",
    "des": "交通運輸",
    "eng": "交通運輸",
    "n": [
      {
        "no": "5012001000",
        "des": "物流類-行政規劃作業",
        "eng": "物流類-行政規劃作業",
        "n": [
          {
            "no": "5012001001",
            "des": "車故╱貨故問題處理能力",
            "eng": "車故╱貨故問題處理能力"
          },
          {
            "no": "5012001002",
            "des": "運送費用報價與收費",
            "eng": "運送費用報價與收費"
          },
          {
            "no": "5012001003",
            "des": "訂單╱管理報表製作",
            "eng": "訂單╱管理報表製作"
          },
          {
            "no": "5012001004",
            "des": "運輸路線規劃管理",
            "eng": "運輸路線規劃管理"
          }
        ]
      },
      {
        "no": "5012002000",
        "des": "物流類-倉儲作業",
        "eng": "物流類-倉儲作業",
        "n": [
          {
            "no": "5012002001",
            "des": "倉庫貨物標示╱置放╱盤點",
            "eng": "倉庫貨物標示╱置放╱盤點"
          },
          {
            "no": "5012002002",
            "des": "庫存管理規劃作業",
            "eng": "庫存管理規劃作業"
          },
          {
            "no": "5012002003",
            "des": "倉管流程訂定與管理",
            "eng": "倉管流程訂定與管理"
          },
          {
            "no": "5012002004",
            "des": "售後服務資料彙整",
            "eng": "售後服務資料彙整"
          },
          {
            "no": "5012002005",
            "des": "倉儲系統維護與管理",
            "eng": "倉儲系統維護與管理"
          },
          {
            "no": "5012002006",
            "des": "貨品包裝作業",
            "eng": "貨品包裝作業"
          }
        ]
      },
      {
        "no": "5012003000",
        "des": "物流類-運輸作業",
        "eng": "物流類-運輸作業",
        "n": [
          {
            "no": "5012003001",
            "des": "商品收貨╱退貨作業",
            "eng": "商品收貨╱退貨作業"
          },
          {
            "no": "5012003002",
            "des": "載送乘客與行李運送服務",
            "eng": "載送乘客與行李運送服務"
          },
          {
            "no": "5012003003",
            "des": "商品調撥作業",
            "eng": "商品調撥作業"
          },
          {
            "no": "5012003004",
            "des": "點收╱代收貨款作業",
            "eng": "點收╱代收貨款作業"
          },
          {
            "no": "5012003005",
            "des": "理貨╱揀貨╱補貨作業",
            "eng": "理貨╱揀貨╱補貨作業"
          },
          {
            "no": "5012003006",
            "des": "簽收╱驗收作業",
            "eng": "簽收╱驗收作業"
          },
          {
            "no": "5012003007",
            "des": "貨物運送與資材配送",
            "eng": "貨物運送與資材配送"
          }
        ]
      },
      {
        "no": "5012004000",
        "des": "物流類-安全清潔",
        "eng": "物流類-安全清潔",
        "n": [
          {
            "no": "5012004001",
            "des": "安全設施維護保養",
            "eng": "安全設施維護保養"
          },
          {
            "no": "5012004002",
            "des": "車輛清潔保管與檢修",
            "eng": "車輛清潔保管與檢修"
          },
          {
            "no": "5012004003",
            "des": "安全演習╱安全檢查",
            "eng": "安全演習╱安全檢查"
          }
        ]
      },
      {
        "no": "5012005000",
        "des": "採購類-採購作業",
        "eng": "採購類-採購作業",
        "n": [
          {
            "no": "5012005001",
            "des": "市場供應資訊蒐集評估分析",
            "eng": "市場供應資訊蒐集評估分析"
          },
          {
            "no": "5012005002",
            "des": "商品採購作業",
            "eng": "商品採購作業"
          },
          {
            "no": "5012005003",
            "des": "年度採購計劃制定",
            "eng": "年度採購計劃制定"
          },
          {
            "no": "5012005004",
            "des": "貨物出口通關處理",
            "eng": "貨物出口通關處理"
          },
          {
            "no": "5012005005",
            "des": "供應商評鑑與開發",
            "eng": "供應商評鑑與開發"
          },
          {
            "no": "5012005006",
            "des": "貨物延遲╱遺失處理",
            "eng": "貨物延遲╱遺失處理"
          },
          {
            "no": "5012005007",
            "des": "供應商資料管理維護",
            "eng": "供應商資料管理維護"
          },
          {
            "no": "5012005008",
            "des": "貨物品質驗收",
            "eng": "貨物品質驗收"
          },
          {
            "no": "5012005009",
            "des": "原物料和設備折讓處理程序建立",
            "eng": "原物料和設備折讓處理程序建立"
          },
          {
            "no": "5012005010",
            "des": "進料商品開發",
            "eng": "進料商品開發"
          },
          {
            "no": "5012005011",
            "des": "原物料和設備品質驗收程序建立",
            "eng": "原物料和設備品質驗收程序建立"
          },
          {
            "no": "5012005012",
            "des": "廠商樣品管理建檔",
            "eng": "廠商樣品管理建檔"
          },
          {
            "no": "5012005013",
            "des": "原物料和設備報廢程序建立",
            "eng": "原物料和設備報廢程序建立"
          },
          {
            "no": "5012005014",
            "des": "採購對帳作業",
            "eng": "採購對帳作業"
          },
          {
            "no": "5012005015",
            "des": "原物料管理系統建立",
            "eng": "原物料管理系統建立"
          },
          {
            "no": "5012005016",
            "des": "負責主料╱物料庫儲之統籌與控管",
            "eng": "負責主料╱物料庫儲之統籌與控管"
          }
        ]
      },
      {
        "no": "5012006000",
        "des": "陸海空-火車",
        "eng": "陸海空-火車",
        "n": [
          {
            "no": "5012006001",
            "des": "火車安全檢查作業",
            "eng": "火車安全檢查作業"
          },
          {
            "no": "5012006002",
            "des": "進出車站事項聯絡與確認",
            "eng": "進出車站事項聯絡與確認"
          },
          {
            "no": "5012006003",
            "des": "火車速度調節作業",
            "eng": "火車速度調節作業"
          },
          {
            "no": "5012006004",
            "des": "路況及號誌監視",
            "eng": "路況及號誌監視"
          }
        ]
      },
      {
        "no": "5012007000",
        "des": "陸海空-航空",
        "eng": "陸海空-航空",
        "n": [
          {
            "no": "5012007001",
            "des": "指導管制飛機進出機場",
            "eng": "指導管制飛機進出機場"
          },
          {
            "no": "5012007002",
            "des": "航空器機體維護技術",
            "eng": "航空器機體維護技術"
          },
          {
            "no": "5012007003",
            "des": "飛航日記簿管理",
            "eng": "飛航日記簿管理"
          },
          {
            "no": "5012007004",
            "des": "審查及批准飛行計畫作業",
            "eng": "審查及批准飛行計畫作業"
          },
          {
            "no": "5012007005",
            "des": "飛機引擎與機身修護技術",
            "eng": "飛機引擎與機身修護技術"
          },
          {
            "no": "5012007006",
            "des": "飛機修護基礎技術",
            "eng": "飛機修護基礎技術"
          },
          {
            "no": "5012007007",
            "des": "飛機駕駛技術",
            "eng": "飛機駕駛技術"
          },
          {
            "no": "5012007008",
            "des": "飛機渦輪發動機修護",
            "eng": "飛機渦輪發動機修護"
          },
          {
            "no": "5012007009",
            "des": "飛機檢驗作業",
            "eng": "飛機檢驗作業"
          },
          {
            "no": "5012007010",
            "des": "飛機電氣及儀表系統修護",
            "eng": "飛機電氣及儀表系統修護"
          }
        ]
      },
      {
        "no": "5012008000",
        "des": "陸海空-航海類",
        "eng": "陸海空-航海類",
        "n": [
          {
            "no": "5012008001",
            "des": "物料用品監督管理",
            "eng": "物料用品監督管理"
          },
          {
            "no": "5012008002",
            "des": "船舶檢疫作業",
            "eng": "船舶檢疫作業"
          },
          {
            "no": "5012008003",
            "des": "航海工具使用與航道校正",
            "eng": "航海工具使用與航道校正"
          },
          {
            "no": "5012008004",
            "des": "船舶檢修作業",
            "eng": "船舶檢修作業"
          },
          {
            "no": "5012008005",
            "des": "航管指示認知",
            "eng": "航管指示認知"
          },
          {
            "no": "5012008006",
            "des": "船舶繫泊作業",
            "eng": "船舶繫泊作業"
          },
          {
            "no": "5012008007",
            "des": "船舶下錨作業",
            "eng": "船舶下錨作業"
          },
          {
            "no": "5012008008",
            "des": "貨物裝卸作業",
            "eng": "貨物裝卸作業"
          },
          {
            "no": "5012008009",
            "des": "船舶航行指示",
            "eng": "船舶航行指示"
          }
        ]
      }
    ]
  },
  {
    "no": "5013000000",
    "des": "營建土木",
    "eng": "營建土木",
    "n": [
      {
        "no": "5013001000",
        "des": "土木工程",
        "eng": "土木工程",
        "n": [
          {
            "no": "5013001001",
            "des": "土木工程施工與監造",
            "eng": "土木工程施工與監造"
          },
          {
            "no": "5013001002",
            "des": "土木工程養護",
            "eng": "土木工程養護"
          },
          {
            "no": "5013001003",
            "des": "土木工程調查規劃與設計",
            "eng": "土木工程調查規劃與設計"
          },
          {
            "no": "5013001004",
            "des": "基礎土木工程應用",
            "eng": "基礎土木工程應用"
          },
          {
            "no": "5013001005",
            "des": "土木工程評價",
            "eng": "土木工程評價"
          }
        ]
      },
      {
        "no": "5013002000",
        "des": "工地安全衛生",
        "eng": "工地安全衛生",
        "n": [
          {
            "no": "5013002001",
            "des": "吊籠安全作業要領",
            "eng": "吊籠安全作業要領"
          },
          {
            "no": "5013002002",
            "des": "建築工地與外牆清理",
            "eng": "建築工地與外牆清理"
          },
          {
            "no": "5013002003",
            "des": "安全措施與假設工程",
            "eng": "安全措施與假設工程"
          },
          {
            "no": "5013002004",
            "des": "建築物與週邊物清潔",
            "eng": "建築物與週邊物清潔"
          }
        ]
      },
      {
        "no": "5013003000",
        "des": "工程行政類",
        "eng": "工程行政類",
        "n": [
          {
            "no": "5013003001",
            "des": "工地行政相關報表製作",
            "eng": "工地行政相關報表製作"
          },
          {
            "no": "5013003002",
            "des": "移交清冊製作",
            "eng": "移交清冊製作"
          },
          {
            "no": "5013003003",
            "des": "申請室內裝修竣工查驗作業",
            "eng": "申請室內裝修竣工查驗作業"
          },
          {
            "no": "5013003004",
            "des": "發包標單製作",
            "eng": "發包標單製作"
          },
          {
            "no": "5013003005",
            "des": "申請室內裝修圖說審核作業",
            "eng": "申請室內裝修圖說審核作業"
          },
          {
            "no": "5013003006",
            "des": "標單製作與管理",
            "eng": "標單製作與管理"
          }
        ]
      },
      {
        "no": "5013004000",
        "des": "工程施作類",
        "eng": "工程施作類",
        "n": [
          {
            "no": "5013004001",
            "des": "土方工程作業",
            "eng": "土方工程作業"
          },
          {
            "no": "5013004002",
            "des": "金屬╱非金屬管裝置及配線",
            "eng": "金屬╱非金屬管裝置及配線"
          },
          {
            "no": "5013004003",
            "des": "工程地質探測與調查能力",
            "eng": "工程地質探測與調查能力"
          },
          {
            "no": "5013004004",
            "des": "施工設備架設",
            "eng": "施工設備架設"
          },
          {
            "no": "5013004005",
            "des": "工程放樣",
            "eng": "工程放樣"
          },
          {
            "no": "5013004006",
            "des": "砌磚作業",
            "eng": "砌磚作業"
          },
          {
            "no": "5013004007",
            "des": "木作╱石材施工",
            "eng": "木作╱石材施工"
          },
          {
            "no": "5013004008",
            "des": "面材舖貼作業",
            "eng": "面材舖貼作業"
          },
          {
            "no": "5013004009",
            "des": "木屬材料結構組裝",
            "eng": "木屬材料結構組裝"
          },
          {
            "no": "5013004010",
            "des": "起重與吊裝工作",
            "eng": "起重與吊裝工作"
          },
          {
            "no": "5013004011",
            "des": "水泥粉刷飾面作業",
            "eng": "水泥粉刷飾面作業"
          },
          {
            "no": "5013004012",
            "des": "配電裝修技能",
            "eng": "配電裝修技能"
          },
          {
            "no": "5013004013",
            "des": "地下工程(含基礎工程)",
            "eng": "地下工程(含基礎工程)"
          },
          {
            "no": "5013004014",
            "des": "配電器材搬運",
            "eng": "配電器材搬運"
          },
          {
            "no": "5013004015",
            "des": "地層改良方法",
            "eng": "地層改良方法"
          },
          {
            "no": "5013004016",
            "des": "結構體工程執行",
            "eng": "結構體工程執行"
          },
          {
            "no": "5013004017",
            "des": "坡地工程施作",
            "eng": "坡地工程施作"
          },
          {
            "no": "5013004018",
            "des": "塗裝機具設備使用保養",
            "eng": "塗裝機具設備使用保養"
          },
          {
            "no": "5013004019",
            "des": "表面修繕與塗裝工程",
            "eng": "表面修繕與塗裝工程"
          },
          {
            "no": "5013004020",
            "des": "道路修繕維護",
            "eng": "道路修繕維護"
          },
          {
            "no": "5013004021",
            "des": "金屬建材組立預裝",
            "eng": "金屬建材組立預裝"
          },
          {
            "no": "5013004022",
            "des": "管渠及其附屬設備維修",
            "eng": "管渠及其附屬設備維修"
          },
          {
            "no": "5013004023",
            "des": "金屬建材螺栓接合",
            "eng": "金屬建材螺栓接合"
          },
          {
            "no": "5013004024",
            "des": "導線槽裝置及配線",
            "eng": "導線槽裝置及配線"
          }
        ]
      },
      {
        "no": "5013005000",
        "des": "工程施作類-器具使用",
        "eng": "工程施作類-器具使用",
        "n": [
          {
            "no": "5013005001",
            "des": "五金配件使用",
            "eng": "五金配件使用"
          },
          {
            "no": "5013005002",
            "des": "抽(揚)水站之操作、維護",
            "eng": "抽(揚)水站之操作、維護"
          },
          {
            "no": "5013005003",
            "des": "手工具使用",
            "eng": "手工具使用"
          },
          {
            "no": "5013005004",
            "des": "電工儀表裝置及使用",
            "eng": "電工儀表裝置及使用"
          },
          {
            "no": "5013005005",
            "des": "木工機器使用",
            "eng": "木工機器使用"
          },
          {
            "no": "5013005006",
            "des": "截流站操作、維護",
            "eng": "截流站操作、維護"
          },
          {
            "no": "5013005007",
            "des": "吊籠操作使用",
            "eng": "吊籠操作使用"
          },
          {
            "no": "5013005008",
            "des": "漏電保護裝置使用",
            "eng": "漏電保護裝置使用"
          },
          {
            "no": "5013005009",
            "des": "材料識別與應用",
            "eng": "材料識別與應用"
          },
          {
            "no": "5013005010",
            "des": "廢物處理系統操作",
            "eng": "廢物處理系統操作"
          }
        ]
      },
      {
        "no": "5013006000",
        "des": "水利水土",
        "eng": "水利水土",
        "n": [
          {
            "no": "5013006001",
            "des": "土壤沖蝕防治規劃與執行",
            "eng": "土壤沖蝕防治規劃與執行"
          },
          {
            "no": "5013006002",
            "des": "水利工程設計",
            "eng": "水利工程設計"
          },
          {
            "no": "5013006003",
            "des": "水土保持工程規劃與執行",
            "eng": "水土保持工程規劃與執行"
          },
          {
            "no": "5013006004",
            "des": "水資源工程規劃與執行",
            "eng": "水資源工程規劃與執行"
          },
          {
            "no": "5013006005",
            "des": "水文與水理分析",
            "eng": "水文與水理分析"
          },
          {
            "no": "5013006006",
            "des": "生態工法在工程之應用",
            "eng": "生態工法在工程之應用"
          },
          {
            "no": "5013006007",
            "des": "水文與水理規劃與執行",
            "eng": "水文與水理規劃與執行"
          },
          {
            "no": "5013006008",
            "des": "植生工程規劃與執行",
            "eng": "植生工程規劃與執行"
          },
          {
            "no": "5013006009",
            "des": "水利工程計劃管理",
            "eng": "水利工程計劃管理"
          }
        ]
      },
      {
        "no": "5013007000",
        "des": "交通工程",
        "eng": "交通工程",
        "n": [
          {
            "no": "5013007001",
            "des": "交通工程施工和監造",
            "eng": "交通工程施工和監造"
          },
          {
            "no": "5013007002",
            "des": "交通工程養護",
            "eng": "交通工程養護"
          },
          {
            "no": "5013007003",
            "des": "交通工程研究調查與評估分析",
            "eng": "交通工程研究調查與評估分析"
          },
          {
            "no": "5013007004",
            "des": "交通工程鑑定",
            "eng": "交通工程鑑定"
          },
          {
            "no": "5013007005",
            "des": "交通工程規劃與設計",
            "eng": "交通工程規劃與設計"
          },
          {
            "no": "5013007006",
            "des": "交通計畫預測",
            "eng": "交通計畫預測"
          },
          {
            "no": "5013007007",
            "des": "交通工程評價監督",
            "eng": "交通工程評價監督"
          }
        ]
      },
      {
        "no": "5013008000",
        "des": "泥作工程",
        "eng": "泥作工程",
        "n": [
          {
            "no": "5013008001",
            "des": "泥作工程估價",
            "eng": "泥作工程估價"
          },
          {
            "no": "5013008002",
            "des": "混凝土品質管制",
            "eng": "混凝土品質管制"
          },
          {
            "no": "5013008003",
            "des": "泥作工程施工管理",
            "eng": "泥作工程施工管理"
          },
          {
            "no": "5013008004",
            "des": "混凝土澆置作業規劃與執行",
            "eng": "混凝土澆置作業規劃與執行"
          },
          {
            "no": "5013008005",
            "des": "混凝土生產製作",
            "eng": "混凝土生產製作"
          },
          {
            "no": "5013008006",
            "des": "混凝土驗收作業",
            "eng": "混凝土驗收作業"
          },
          {
            "no": "5013008007",
            "des": "混凝土作業工具使用",
            "eng": "混凝土作業工具使用"
          },
          {
            "no": "5013008008",
            "des": "模板檢視作業",
            "eng": "模板檢視作業"
          },
          {
            "no": "5013008009",
            "des": "混凝土表面處理",
            "eng": "混凝土表面處理"
          }
        ]
      },
      {
        "no": "5013009000",
        "des": "都市規劃設計",
        "eng": "都市規劃設計",
        "n": [
          {
            "no": "5013009001",
            "des": "室內設計規劃",
            "eng": "室內設計規劃"
          },
          {
            "no": "5013009002",
            "des": "機電與消防系統規劃",
            "eng": "機電與消防系統規劃"
          },
          {
            "no": "5013009003",
            "des": "建築計畫與設計",
            "eng": "建築計畫與設計"
          },
          {
            "no": "5013009004",
            "des": "環境規劃與設計",
            "eng": "環境規劃與設計"
          },
          {
            "no": "5013009005",
            "des": "運輸規劃",
            "eng": "運輸規劃"
          },
          {
            "no": "5013009006",
            "des": "都市計畫研究調查與管理",
            "eng": "都市計畫研究調查與管理"
          },
          {
            "no": "5013009007",
            "des": "敷地計畫",
            "eng": "敷地計畫"
          },
          {
            "no": "5013009008",
            "des": "都市計畫規劃分析與設計",
            "eng": "都市計畫規劃分析與設計"
          }
        ]
      },
      {
        "no": "5013010000",
        "des": "景觀工程",
        "eng": "景觀工程",
        "n": [
          {
            "no": "5013010001",
            "des": "景觀工程水電系統管理",
            "eng": "景觀工程水電系統管理"
          },
          {
            "no": "5013010002",
            "des": "景觀相關設施維護管理",
            "eng": "景觀相關設施維護管理"
          },
          {
            "no": "5013010003",
            "des": "景觀工程施工執行",
            "eng": "景觀工程施工執行"
          },
          {
            "no": "5013010004",
            "des": "景觀研究分析與設計規劃",
            "eng": "景觀研究分析與設計規劃"
          },
          {
            "no": "5013010005",
            "des": "景觀工程管理",
            "eng": "景觀工程管理"
          }
        ]
      },
      {
        "no": "5013011000",
        "des": "測量類",
        "eng": "測量類",
        "n": [
          {
            "no": "5013011001",
            "des": "GPS 測量",
            "eng": "GPS 測量"
          },
          {
            "no": "5013011002",
            "des": "面積計算",
            "eng": "面積計算"
          },
          {
            "no": "5013011003",
            "des": "土地複丈",
            "eng": "土地複丈"
          },
          {
            "no": "5013011004",
            "des": "高程測量",
            "eng": "高程測量"
          },
          {
            "no": "5013011005",
            "des": "工程測量",
            "eng": "工程測量"
          },
          {
            "no": "5013011006",
            "des": "現場測量",
            "eng": "現場測量"
          },
          {
            "no": "5013011007",
            "des": "尺寸測量",
            "eng": "尺寸測量"
          },
          {
            "no": "5013011008",
            "des": "野外調繪補測",
            "eng": "野外調繪補測"
          },
          {
            "no": "5013011009",
            "des": "尺度標註與測量",
            "eng": "尺度標註與測量"
          },
          {
            "no": "5013011010",
            "des": "測量儀器之檢驗與校正",
            "eng": "測量儀器之檢驗與校正"
          },
          {
            "no": "5013011011",
            "des": "平面測量",
            "eng": "平面測量"
          },
          {
            "no": "5013011012",
            "des": "測量及繪圖儀器之保養與維護",
            "eng": "測量及繪圖儀器之保養與維護"
          },
          {
            "no": "5013011013",
            "des": "地面攝影測量",
            "eng": "地面攝影測量"
          },
          {
            "no": "5013011014",
            "des": "測量與放樣",
            "eng": "測量與放樣"
          },
          {
            "no": "5013011015",
            "des": "地圖及航照圖測量",
            "eng": "地圖及航照圖測量"
          },
          {
            "no": "5013011016",
            "des": "導線測量及交會點測量",
            "eng": "導線測量及交會點測量"
          },
          {
            "no": "5013011017",
            "des": "建物測量",
            "eng": "建物測量"
          }
        ]
      },
      {
        "no": "5013012000",
        "des": "結構工程",
        "eng": "結構工程",
        "n": [
          {
            "no": "5013012001",
            "des": "結構工程施工與監造",
            "eng": "結構工程施工與監造"
          },
          {
            "no": "5013012002",
            "des": "結構工程養護",
            "eng": "結構工程養護"
          },
          {
            "no": "5013012003",
            "des": "結構工程規劃與設計",
            "eng": "結構工程規劃與設計"
          },
          {
            "no": "5013012004",
            "des": "結構工程鑑定驗收",
            "eng": "結構工程鑑定驗收"
          },
          {
            "no": "5013012005",
            "des": "結構工程調查",
            "eng": "結構工程調查"
          }
        ]
      },
      {
        "no": "5013013000",
        "des": "營建管理",
        "eng": "營建管理",
        "n": [
          {
            "no": "5013013001",
            "des": "工程分包管理",
            "eng": "工程分包管理"
          },
          {
            "no": "5013013002",
            "des": "招商投標與招標報告製作",
            "eng": "招商投標與招標報告製作"
          },
          {
            "no": "5013013003",
            "des": "工程材料之研究與分析",
            "eng": "工程材料之研究與分析"
          },
          {
            "no": "5013013004",
            "des": "建築工地物料管理",
            "eng": "建築工地物料管理"
          },
          {
            "no": "5013013005",
            "des": "工程協調與問題處理",
            "eng": "工程協調與問題處理"
          },
          {
            "no": "5013013006",
            "des": "結構設計說明書製作",
            "eng": "結構設計說明書製作"
          },
          {
            "no": "5013013007",
            "des": "工程計畫書製作",
            "eng": "工程計畫書製作"
          },
          {
            "no": "5013013008",
            "des": "監工日報表填寫",
            "eng": "監工日報表填寫"
          },
          {
            "no": "5013013009",
            "des": "工程管理",
            "eng": "工程管理"
          },
          {
            "no": "5013013010",
            "des": "標單審核作業",
            "eng": "標單審核作業"
          }
        ]
      },
      {
        "no": "5013014000",
        "des": "營建管理-品質管理",
        "eng": "營建管理-品質管理",
        "n": [
          {
            "no": "5013014001",
            "des": "工地工程稽核與驗收",
            "eng": "工地工程稽核與驗收"
          },
          {
            "no": "5013014002",
            "des": "工程驗收資料管理",
            "eng": "工程驗收資料管理"
          },
          {
            "no": "5013014003",
            "des": "工程施工監督管理",
            "eng": "工程施工監督管理"
          },
          {
            "no": "5013014004",
            "des": "修繕進度追蹤與管理",
            "eng": "修繕進度追蹤與管理"
          },
          {
            "no": "5013014005",
            "des": "工程管理-品質管理",
            "eng": "工程管理-品質管理"
          }
        ]
      },
      {
        "no": "5013015000",
        "des": "營建管理-財務管理",
        "eng": "營建管理-財務管理",
        "n": [
          {
            "no": "5013015001",
            "des": "工程估驗與計價",
            "eng": "工程估驗與計價"
          },
          {
            "no": "5013015002",
            "des": "承辦商計價",
            "eng": "承辦商計價"
          },
          {
            "no": "5013015003",
            "des": "工程預算編製與控理",
            "eng": "工程預算編製與控理"
          },
          {
            "no": "5013015004",
            "des": "室內設計成本預估",
            "eng": "室內設計成本預估"
          },
          {
            "no": "5013015005",
            "des": "工程標單估算",
            "eng": "工程標單估算"
          },
          {
            "no": "5013015006",
            "des": "建築物估價與鑑定",
            "eng": "建築物估價與鑑定"
          },
          {
            "no": "5013015007",
            "des": "水電工程估價詢價",
            "eng": "水電工程估價詢價"
          }
        ]
      },
      {
        "no": "5013016000",
        "des": "識圖與繪圖",
        "eng": "識圖與繪圖",
        "n": [
          {
            "no": "5013016001",
            "des": "土木建築工程圖判讀",
            "eng": "土木建築工程圖判讀"
          },
          {
            "no": "5013016002",
            "des": "結構工程識圖與繪圖",
            "eng": "結構工程識圖與繪圖"
          },
          {
            "no": "5013016003",
            "des": "工程現況描繪",
            "eng": "工程現況描繪"
          },
          {
            "no": "5013016004",
            "des": "裝修工程圖識圖",
            "eng": "裝修工程圖識圖"
          },
          {
            "no": "5013016005",
            "des": "工程圖識圖與繪圖",
            "eng": "工程圖識圖與繪圖"
          },
          {
            "no": "5013016006",
            "des": "裝修輸配電識圖及繪圖",
            "eng": "裝修輸配電識圖及繪圖"
          },
          {
            "no": "5013016007",
            "des": "工業相關圖表繪製",
            "eng": "工業相關圖表繪製"
          },
          {
            "no": "5013016008",
            "des": "電工圖識圖與繪圖",
            "eng": "電工圖識圖與繪圖"
          },
          {
            "no": "5013016009",
            "des": "水電相關圖表繪製",
            "eng": "水電相關圖表繪製"
          },
          {
            "no": "5013016010",
            "des": "圖形顯示幕使用",
            "eng": "圖形顯示幕使用"
          },
          {
            "no": "5013016011",
            "des": "室內設計施工圖繪製",
            "eng": "室內設計施工圖繪製"
          },
          {
            "no": "5013016012",
            "des": "實物測量繪圖",
            "eng": "實物測量繪圖"
          },
          {
            "no": "5013016013",
            "des": "建築圖識圖與繪圖",
            "eng": "建築圖識圖與繪圖"
          },
          {
            "no": "5013016014",
            "des": "機械相關圖表繪製",
            "eng": "機械相關圖表繪製"
          },
          {
            "no": "5013016015",
            "des": "施工圖與裝配圖繪製",
            "eng": "施工圖與裝配圖繪製"
          },
          {
            "no": "5013016016",
            "des": "機械設備交線展開繪製",
            "eng": "機械設備交線展開繪製"
          },
          {
            "no": "5013016017",
            "des": "發包圖說繪製整理",
            "eng": "發包圖說繪製整理"
          },
          {
            "no": "5013016018",
            "des": "鋼構工作圖識圖及繪圖",
            "eng": "鋼構工作圖識圖及繪圖"
          },
          {
            "no": "5013016019",
            "des": "竣工圖說製作",
            "eng": "竣工圖說製作"
          }
        ]
      }
    ]
  },
  {
    "no": "5014000000",
    "des": "生產╱製程",
    "eng": "生產╱製程",
    "n": [
      {
        "no": "5014001000",
        "des": "生產製造",
        "eng": "生產製造",
        "n": [
          {
            "no": "5014001001",
            "des": "工廠設備設計與改善",
            "eng": "工廠設備設計與改善"
          },
          {
            "no": "5014001002",
            "des": "日常檢查及異常狀況初步研判",
            "eng": "日常檢查及異常狀況初步研判"
          },
          {
            "no": "5014001003",
            "des": "生產工作日誌建檔管理",
            "eng": "生產工作日誌建檔管理"
          },
          {
            "no": "5014001004",
            "des": "安排出貨管理及統計",
            "eng": "安排出貨管理及統計"
          },
          {
            "no": "5014001005",
            "des": "商品進貨入庫作業",
            "eng": "商品進貨入庫作業"
          },
          {
            "no": "5014001006",
            "des": "協助產線自動化系統推展",
            "eng": "協助產線自動化系統推展"
          },
          {
            "no": "5014001007",
            "des": "熟悉包裝材料與結構",
            "eng": "熟悉包裝材料與結構"
          },
          {
            "no": "5014001008",
            "des": "研究、評估與建立封裝工程技術",
            "eng": "研究、評估與建立封裝工程技術"
          },
          {
            "no": "5014001009",
            "des": "盤點作業規劃執行",
            "eng": "盤點作業規劃執行"
          },
          {
            "no": "5014001010",
            "des": "開立工單及生產交期管理",
            "eng": "開立工單及生產交期管理"
          },
          {
            "no": "5014001011",
            "des": "操作控制及故障排除",
            "eng": "操作控制及故障排除"
          },
          {
            "no": "5014001012",
            "des": "達成產能及出貨目標",
            "eng": "達成產能及出貨目標"
          },
          {
            "no": "5014001013",
            "des": "整理分析生產紀錄報告",
            "eng": "整理分析生產紀錄報告"
          },
          {
            "no": "5014001014",
            "des": "預測銷售量並擬定生產計畫",
            "eng": "預測銷售量並擬定生產計畫"
          },
          {
            "no": "5014001015",
            "des": "SMT設備之產品換線生產作業",
            "eng": "SMT設備之產品換線生產作業"
          },
          {
            "no": "5014001016",
            "des": "維持產線正常運轉並跟催生產進度",
            "eng": "維持產線正常運轉並跟催生產進度"
          }
        ]
      },
      {
        "no": "5014002000",
        "des": "品質管理",
        "eng": "品質管理",
        "n": [
          {
            "no": "5014002001",
            "des": "制訂新產品檢驗標準",
            "eng": "制訂新產品檢驗標準"
          },
          {
            "no": "5014002002",
            "des": "建立並推動工作經驗知識庫",
            "eng": "建立並推動工作經驗知識庫"
          },
          {
            "no": "5014002003",
            "des": "紡織品檢驗作業",
            "eng": "紡織品檢驗作業"
          },
          {
            "no": "5014002004",
            "des": "負責產銷協調溝通",
            "eng": "負責產銷協調溝通"
          },
          {
            "no": "5014002005",
            "des": "產品驗證作業",
            "eng": "產品驗證作業"
          },
          {
            "no": "5014002006",
            "des": "執行EMC╱EMI安規測試驗證與報告",
            "eng": "執行EMC╱EMI安規測試驗證與報告"
          },
          {
            "no": "5014002007",
            "des": "進料問題處理",
            "eng": "進料問題處理"
          },
          {
            "no": "5014002008",
            "des": "執行產品可靠度測試與問題分析",
            "eng": "執行產品可靠度測試與問題分析"
          },
          {
            "no": "5014002009",
            "des": "新產品與進出貨檢驗",
            "eng": "新產品與進出貨檢驗"
          },
          {
            "no": "5014002010",
            "des": "規劃並執行品質管理系統",
            "eng": "規劃並執行品質管理系統"
          },
          {
            "no": "5014002011",
            "des": "零件供應商評鑑考核",
            "eng": "零件供應商評鑑考核"
          },
          {
            "no": "5014002012",
            "des": "規劃測試機台運作流程與排程",
            "eng": "規劃測試機台運作流程與排程"
          },
          {
            "no": "5014002013",
            "des": "原料及產品品質管制監控",
            "eng": "原料及產品品質管制監控"
          },
          {
            "no": "5014002014",
            "des": "新產品導入的材料評核與確認",
            "eng": "新產品導入的材料評核與確認"
          },
          {
            "no": "5014002015",
            "des": "供應商原物料異常分析處理",
            "eng": "供應商原物料異常分析處理"
          },
          {
            "no": "5014002016",
            "des": "維護與設置測試系統與設備校正",
            "eng": "維護與設置測試系統與設備校正"
          },
          {
            "no": "5014002017",
            "des": "可靠度分析報告撰寫與彙整",
            "eng": "可靠度分析報告撰寫與彙整"
          },
          {
            "no": "5014002018",
            "des": "製作管理LCD材料與元件產品",
            "eng": "製作管理LCD材料與元件產品"
          },
          {
            "no": "5014002019",
            "des": "申請與取得各國安規測試認證",
            "eng": "申請與取得各國安規測試認證"
          },
          {
            "no": "5014002020",
            "des": "撰寫可靠度分析測試軟體程式",
            "eng": "撰寫可靠度分析測試軟體程式"
          },
          {
            "no": "5014002021",
            "des": "客戶服務品保程序規劃",
            "eng": "客戶服務品保程序規劃"
          }
        ]
      },
      {
        "no": "5014003000",
        "des": "製程規劃",
        "eng": "製程規劃",
        "n": [
          {
            "no": "5014003001",
            "des": "生產成本控制",
            "eng": "生產成本控制"
          },
          {
            "no": "5014003002",
            "des": "自動化設備╱系統介面之維護改善",
            "eng": "自動化設備╱系統介面之維護改善"
          },
          {
            "no": "5014003003",
            "des": "估算生產裝設工料量價",
            "eng": "估算生產裝設工料量價"
          },
          {
            "no": "5014003004",
            "des": "改善設備問題及功能提昇",
            "eng": "改善設備問題及功能提昇"
          },
          {
            "no": "5014003005",
            "des": "協助改善產品良率",
            "eng": "協助改善產品良率"
          },
          {
            "no": "5014003006",
            "des": "定期檢測製程設備與故障排除",
            "eng": "定期檢測製程設備與故障排除"
          },
          {
            "no": "5014003007",
            "des": "建立標準生產流程",
            "eng": "建立標準生產流程"
          },
          {
            "no": "5014003008",
            "des": "負責生產製程管制與調配",
            "eng": "負責生產製程管制與調配"
          },
          {
            "no": "5014003009",
            "des": "產品估價作業",
            "eng": "產品估價作業"
          },
          {
            "no": "5014003010",
            "des": "規劃、維護自動控制系統",
            "eng": "規劃、維護自動控制系統"
          },
          {
            "no": "5014003011",
            "des": "產品規格書編制管理",
            "eng": "產品規格書編制管理"
          },
          {
            "no": "5014003012",
            "des": "提出製程計畫與產能分配掌握",
            "eng": "提出製程計畫與產能分配掌握"
          },
          {
            "no": "5014003013",
            "des": "提出生產標準改良方案",
            "eng": "提出生產標準改良方案"
          },
          {
            "no": "5014003014",
            "des": "提昇SMT產能稼動率與生產效率",
            "eng": "提昇SMT產能稼動率與生產效率"
          },
          {
            "no": "5014003015",
            "des": "新技術製程開發",
            "eng": "新技術製程開發"
          },
          {
            "no": "5014003016",
            "des": "提昇設備產能稼動率與生產效率",
            "eng": "提昇設備產能稼動率與生產效率"
          },
          {
            "no": "5014003017",
            "des": "標準作業流程規劃",
            "eng": "標準作業流程規劃"
          },
          {
            "no": "5014003018",
            "des": "新產品封裝導入及封裝良率改善",
            "eng": "新產品封裝導入及封裝良率改善"
          },
          {
            "no": "5014003019",
            "des": "產品生產製程規劃及工時估算",
            "eng": "產品生產製程規劃及工時估算"
          },
          {
            "no": "5014003020",
            "des": "試產╱量產時的產品異常分析改善",
            "eng": "試產╱量產時的產品異常分析改善"
          }
        ]
      },
      {
        "no": "5014004000",
        "des": "環境安全衛生",
        "eng": "環境安全衛生",
        "n": [
          {
            "no": "5014004001",
            "des": "申請空污、廢水、廢棄物等許可證",
            "eng": "申請空污、廢水、廢棄物等許可證"
          },
          {
            "no": "5014004002",
            "des": "規劃危險性機械及設備安全檢查規則",
            "eng": "規劃危險性機械及設備安全檢查規則"
          },
          {
            "no": "5014004003",
            "des": "改善及預防衛生品質與環境污染",
            "eng": "改善及預防衛生品質與環境污染"
          },
          {
            "no": "5014004004",
            "des": "規劃督導安全衛生設施之檢點與檢查",
            "eng": "規劃督導安全衛生設施之檢點與檢查"
          },
          {
            "no": "5014004005",
            "des": "協助ISO╱OHSAS與環保相關認證工作",
            "eng": "協助ISO╱OHSAS與環保相關認證工作"
          },
          {
            "no": "5014004006",
            "des": "規劃實施勞工作業區域環境檢測",
            "eng": "規劃實施勞工作業區域環境檢測"
          },
          {
            "no": "5014004007",
            "des": "建立安全衛生政策計畫程序及標準",
            "eng": "建立安全衛生政策計畫程序及標準"
          },
          {
            "no": "5014004008",
            "des": "製作安全衛生管理規章及工作守則",
            "eng": "製作安全衛生管理規章及工作守則"
          },
          {
            "no": "5014004009",
            "des": "執行安全衛生督導及稽核",
            "eng": "執行安全衛生督導及稽核"
          },
          {
            "no": "5014004010",
            "des": "廢棄物管理與申報處理",
            "eng": "廢棄物管理與申報處理"
          },
          {
            "no": "5014004011",
            "des": "清除災害防止處理",
            "eng": "清除災害防止處理"
          },
          {
            "no": "5014004012",
            "des": "擬定各項安全衛生管理辦法",
            "eng": "擬定各項安全衛生管理辦法"
          },
          {
            "no": "5014004013",
            "des": "規劃、維護保養廢棄物排放系統",
            "eng": "規劃、維護保養廢棄物排放系統"
          },
          {
            "no": "5014004014",
            "des": "檢定保健方案影響與個案分析",
            "eng": "檢定保健方案影響與個案分析"
          }
        ]
      }
    ]
  },
  {
    "no": "5015000000",
    "des": "設計╱美工",
    "eng": "設計╱美工",
    "n": [
      {
        "no": "5015001000",
        "des": "工程製圖類",
        "eng": "工程製圖類",
        "n": [
          {
            "no": "5015001001",
            "des": "提供樣品圖樣",
            "eng": "提供樣品圖樣"
          },
          {
            "no": "5015001002",
            "des": "繪製電腦相關配圖",
            "eng": "繪製電腦相關配圖"
          },
          {
            "no": "5015001003",
            "des": "電腦圖示辨認",
            "eng": "電腦圖示辨認"
          },
          {
            "no": "5015001004",
            "des": "繪製變壓器工程圖",
            "eng": "繪製變壓器工程圖"
          },
          {
            "no": "5015001005",
            "des": "電腦輔助設計軟體操作",
            "eng": "電腦輔助設計軟體操作"
          },
          {
            "no": "5015001006",
            "des": "繪製2D╱3D模具設計圖",
            "eng": "繪製2D╱3D模具設計圖"
          }
        ]
      },
      {
        "no": "5015002000",
        "des": "包裝╱印刷類",
        "eng": "包裝╱印刷類",
        "n": [
          {
            "no": "5015002001",
            "des": "打樣機版使用",
            "eng": "打樣機版使用"
          },
          {
            "no": "5015002002",
            "des": "產品包裝設計",
            "eng": "產品包裝設計"
          },
          {
            "no": "5015002003",
            "des": "商品包裝及陳列",
            "eng": "商品包裝及陳列"
          },
          {
            "no": "5015002004",
            "des": "產品外型設計",
            "eng": "產品外型設計"
          },
          {
            "no": "5015002005",
            "des": "商品選購建議",
            "eng": "商品選購建議"
          },
          {
            "no": "5015002006",
            "des": "設計印刷基本認知",
            "eng": "設計印刷基本認知"
          }
        ]
      },
      {
        "no": "5015003000",
        "des": "平面設計類",
        "eng": "平面設計類",
        "n": [
          {
            "no": "5015003001",
            "des": "CIS企畫設計",
            "eng": "CIS企畫設計"
          },
          {
            "no": "5015003002",
            "des": "視覺設計相關知識",
            "eng": "視覺設計相關知識"
          },
          {
            "no": "5015003003",
            "des": "平面設計理論",
            "eng": "平面設計理論"
          },
          {
            "no": "5015003004",
            "des": "電腦印前設計",
            "eng": "電腦印前設計"
          },
          {
            "no": "5015003005",
            "des": "各式封面設計",
            "eng": "各式封面設計"
          },
          {
            "no": "5015003006",
            "des": "電腦排版設計",
            "eng": "電腦排版設計"
          },
          {
            "no": "5015003007",
            "des": "色彩調配設計",
            "eng": "色彩調配設計"
          },
          {
            "no": "5015003008",
            "des": "電腦繪圖軟體操作",
            "eng": "電腦繪圖軟體操作"
          },
          {
            "no": "5015003009",
            "des": "色彩應用繪製",
            "eng": "色彩應用繪製"
          },
          {
            "no": "5015003010",
            "des": "廣告設計概念",
            "eng": "廣告設計概念"
          },
          {
            "no": "5015003011",
            "des": "素材辨識與處理",
            "eng": "素材辨識與處理"
          },
          {
            "no": "5015003012",
            "des": "繪圖工具與軟體操作",
            "eng": "繪圖工具與軟體操作"
          },
          {
            "no": "5015003013",
            "des": "插畫表現技巧",
            "eng": "插畫表現技巧"
          },
          {
            "no": "5015003014",
            "des": "繪圖軟體安裝設定",
            "eng": "繪圖軟體安裝設定"
          }
        ]
      },
      {
        "no": "5015004000",
        "des": "多媒體設計類",
        "eng": "多媒體設計類",
        "n": [
          {
            "no": "5015004001",
            "des": "多媒體企劃設計",
            "eng": "多媒體企劃設計"
          },
          {
            "no": "5015004002",
            "des": "動畫程式撰寫",
            "eng": "動畫程式撰寫"
          },
          {
            "no": "5015004003",
            "des": "多媒體技術開發",
            "eng": "多媒體技術開發"
          },
          {
            "no": "5015004004",
            "des": "設計表現能力",
            "eng": "設計表現能力"
          },
          {
            "no": "5015004005",
            "des": "多媒體影像處理",
            "eng": "多媒體影像處理"
          },
          {
            "no": "5015004006",
            "des": "媒體動畫應用設計諮詢",
            "eng": "媒體動畫應用設計諮詢"
          },
          {
            "no": "5015004007",
            "des": "多媒體簡報製作",
            "eng": "多媒體簡報製作"
          },
          {
            "no": "5015004008",
            "des": "電腦動畫設計",
            "eng": "電腦動畫設計"
          },
          {
            "no": "5015004009",
            "des": "行銷工具設計開發",
            "eng": "行銷工具設計開發"
          },
          {
            "no": "5015004010",
            "des": "網頁軟體操作",
            "eng": "網頁軟體操作"
          },
          {
            "no": "5015004011",
            "des": "使用者介面設計",
            "eng": "使用者介面設計"
          },
          {
            "no": "5015004012",
            "des": "網頁語法撰寫",
            "eng": "網頁語法撰寫"
          },
          {
            "no": "5015004013",
            "des": "動畫人物故事流程製作",
            "eng": "動畫人物故事流程製作"
          },
          {
            "no": "5015004014",
            "des": "數位攝影技巧",
            "eng": "數位攝影技巧"
          }
        ]
      },
      {
        "no": "5015005000",
        "des": "服裝設計類",
        "eng": "服裝設計類",
        "n": [
          {
            "no": "5015005001",
            "des": "女裝設計製作",
            "eng": "女裝設計製作"
          },
          {
            "no": "5015005002",
            "des": "電繡技術",
            "eng": "電繡技術"
          },
          {
            "no": "5015005003",
            "des": "男裝設計製作",
            "eng": "男裝設計製作"
          },
          {
            "no": "5015005004",
            "des": "各式手繪設計稿打版",
            "eng": "各式手繪設計稿打版"
          },
          {
            "no": "5015005005",
            "des": "服裝設計工具使用",
            "eng": "服裝設計工具使用"
          },
          {
            "no": "5015005006",
            "des": "各式男裝╱女裝之開版及打樣",
            "eng": "各式男裝╱女裝之開版及打樣"
          },
          {
            "no": "5015005007",
            "des": "服裝設計表現能力",
            "eng": "服裝設計表現能力"
          },
          {
            "no": "5015005008",
            "des": "各式禮服款式之開版及打樣",
            "eng": "各式禮服款式之開版及打樣"
          },
          {
            "no": "5015005009",
            "des": "服裝樣式排版與裁剪",
            "eng": "服裝樣式排版與裁剪"
          },
          {
            "no": "5015005010",
            "des": "各類皮包款式之開版及打樣",
            "eng": "各類皮包款式之開版及打樣"
          },
          {
            "no": "5015005011",
            "des": "國服設計製作技術",
            "eng": "國服設計製作技術"
          }
        ]
      },
      {
        "no": "5015006000",
        "des": "實體佈置類",
        "eng": "實體佈置類",
        "n": [
          {
            "no": "5015006001",
            "des": "年度商品陳列規劃",
            "eng": "年度商品陳列規劃"
          },
          {
            "no": "5015006002",
            "des": "壁架商品視覺陳列",
            "eng": "壁架商品視覺陳列"
          },
          {
            "no": "5015006003",
            "des": "活動會場佈置",
            "eng": "活動會場佈置"
          },
          {
            "no": "5015006004",
            "des": "櫥窗設計規劃",
            "eng": "櫥窗設計規劃"
          },
          {
            "no": "5015006005",
            "des": "展場設計規劃",
            "eng": "展場設計規劃"
          },
          {
            "no": "5015006006",
            "des": "櫥窗視覺陳列",
            "eng": "櫥窗視覺陳列"
          },
          {
            "no": "5015006007",
            "des": "展場設計監工",
            "eng": "展場設計監工"
          }
        ]
      },
      {
        "no": "5015007000",
        "des": "男女鞋設計類",
        "eng": "男女鞋設計類",
        "n": [
          {
            "no": "5015007001",
            "des": "各式鞋款配底",
            "eng": "各式鞋款配底"
          },
          {
            "no": "5015007002",
            "des": "男女鞋製配底樣板縫製",
            "eng": "男女鞋製配底樣板縫製"
          },
          {
            "no": "5015007003",
            "des": "各類鞋款之開版及打樣",
            "eng": "各類鞋款之開版及打樣"
          },
          {
            "no": "5015007004",
            "des": "設計各式鞋樣與模具",
            "eng": "設計各式鞋樣與模具"
          },
          {
            "no": "5015007005",
            "des": "男女鞋結幫製作",
            "eng": "男女鞋結幫製作"
          }
        ]
      }
    ]
  },
  {
    "no": "5016000000",
    "des": "語言類",
    "eng": "語言類",
    "n": [
      {
        "no": "5016001000",
        "des": "文件翻譯能力",
        "eng": "文件翻譯能力",
        "n": [
          {
            "no": "5016001001",
            "des": "技術文件翻譯",
            "eng": "技術文件翻譯"
          },
          {
            "no": "5016001002",
            "des": "新聞╱報紙翻譯",
            "eng": "新聞╱報紙翻譯"
          },
          {
            "no": "5016001003",
            "des": "書籍翻譯",
            "eng": "書籍翻譯"
          },
          {
            "no": "5016001004",
            "des": "雜誌翻譯",
            "eng": "雜誌翻譯"
          },
          {
            "no": "5016001005",
            "des": "教案╱教材翻譯",
            "eng": "教案╱教材翻譯"
          }
        ]
      },
      {
        "no": "5016002000",
        "des": "現場即時口譯",
        "eng": "現場即時口譯",
        "n": [
          {
            "no": "5016002001",
            "des": "講座╱研討會即時口譯",
            "eng": "講座╱研討會即時口譯"
          },
          {
            "no": "5016002002",
            "des": "會議╱談判即時口譯",
            "eng": "會議╱談判即時口譯"
          }
        ]
      }
    ]
  },
  {
    "no": "5017000000",
    "des": "其它",
    "eng": "其它",
    "n": [
      {
        "no": "5017001000",
        "des": "大廈管理",
        "eng": "大廈管理",
        "n": [
          {
            "no": "5017001001",
            "des": "大廈管理顧客服務",
            "eng": "大廈管理顧客服務"
          },
          {
            "no": "5017001002",
            "des": "管費收繳與催收作業",
            "eng": "管費收繳與催收作業"
          },
          {
            "no": "5017001003",
            "des": "社區活動規劃與執行",
            "eng": "社區活動規劃與執行"
          },
          {
            "no": "5017001004",
            "des": "擬訂住戶規約",
            "eng": "擬訂住戶規約"
          },
          {
            "no": "5017001005",
            "des": "管理委員會運作",
            "eng": "管理委員會運作"
          },
          {
            "no": "5017001006",
            "des": "環保資源回收作業",
            "eng": "環保資源回收作業"
          },
          {
            "no": "5017001007",
            "des": "管理服務中心作業",
            "eng": "管理服務中心作業"
          }
        ]
      },
      {
        "no": "5017002000",
        "des": "保全類",
        "eng": "保全類",
        "n": [
          {
            "no": "5017002001",
            "des": "犯罪偵查作業",
            "eng": "犯罪偵查作業"
          },
          {
            "no": "5017002002",
            "des": "社區維安事件處理與協調",
            "eng": "社區維安事件處理與協調"
          },
          {
            "no": "5017002003",
            "des": "住戶安全防護處理",
            "eng": "住戶安全防護處理"
          },
          {
            "no": "5017002004",
            "des": "門禁管理作業",
            "eng": "門禁管理作業"
          },
          {
            "no": "5017002005",
            "des": "社區安全業務行政工作",
            "eng": "社區安全業務行政工作"
          },
          {
            "no": "5017002006",
            "des": "負責社區事件通報",
            "eng": "負責社區事件通報"
          },
          {
            "no": "5017002007",
            "des": "社區安寧維護作業",
            "eng": "社區安寧維護作業"
          },
          {
            "no": "5017002008",
            "des": "停車場管理與交通指揮",
            "eng": "停車場管理與交通指揮"
          }
        ]
      },
      {
        "no": "5017003000",
        "des": "消防安全類",
        "eng": "消防安全類",
        "n": [
          {
            "no": "5017003001",
            "des": "化學系統消防安全設備",
            "eng": "化學系統消防安全設備"
          },
          {
            "no": "5017003002",
            "des": "防火管理對策",
            "eng": "防火管理對策"
          },
          {
            "no": "5017003003",
            "des": "日常檢查及異常狀況初步研判",
            "eng": "日常檢查及異常狀況初步研判"
          },
          {
            "no": "5017003004",
            "des": "傷患處理及搬運",
            "eng": "傷患處理及搬運"
          },
          {
            "no": "5017003005",
            "des": "水系統消防安全設備",
            "eng": "水系統消防安全設備"
          },
          {
            "no": "5017003006",
            "des": "意外事故防範與急救",
            "eng": "意外事故防範與急救"
          },
          {
            "no": "5017003007",
            "des": "火災預防與防火管理",
            "eng": "火災預防與防火管理"
          },
          {
            "no": "5017003008",
            "des": "緊急甦醒術CPR",
            "eng": "緊急甦醒術CPR"
          },
          {
            "no": "5017003009",
            "des": "火災類型辨識",
            "eng": "火災類型辨識"
          },
          {
            "no": "5017003010",
            "des": "編組急救及搜救服務",
            "eng": "編組急救及搜救服務"
          },
          {
            "no": "5017003011",
            "des": "共同防火管理作業",
            "eng": "共同防火管理作業"
          },
          {
            "no": "5017003012",
            "des": "警報系統消防安全設備",
            "eng": "警報系統消防安全設備"
          },
          {
            "no": "5017003013",
            "des": "安全衛生防護",
            "eng": "安全衛生防護"
          },
          {
            "no": "5017003014",
            "des": "規劃與執行安全衛生教育訓練",
            "eng": "規劃與執行安全衛生教育訓練"
          },
          {
            "no": "5017003015",
            "des": "災害預防與搶救",
            "eng": "災害預防與搶救"
          },
          {
            "no": "5017003016",
            "des": "推廣督導及評量國民保健教育計畫",
            "eng": "推廣督導及評量國民保健教育計畫"
          },
          {
            "no": "5017003017",
            "des": "系統災害防治",
            "eng": "系統災害防治"
          },
          {
            "no": "5017003018",
            "des": "工作安全與環保衛生",
            "eng": "工作安全與環保衛生"
          }
        ]
      },
      {
        "no": "5017004000",
        "des": "照護類",
        "eng": "照護類",
        "n": [
          {
            "no": "5017004001",
            "des": "日常生活及身體照顧",
            "eng": "日常生活及身體照顧"
          },
          {
            "no": "5017004002",
            "des": "臨終關懷及認識安寧照顧",
            "eng": "臨終關懷及認識安寧照顧"
          },
          {
            "no": "5017004003",
            "des": "協助家務處理",
            "eng": "協助家務處理"
          }
        ]
      },
      {
        "no": "5017005000",
        "des": "天文╱地理",
        "eng": "天文╱地理",
        "n": [
          {
            "no": "5017005001",
            "des": "了解太陽系結構與物質分佈",
            "eng": "了解太陽系結構與物質分佈"
          },
          {
            "no": "5017005002",
            "des": "進行消霧、造雨控制試驗",
            "eng": "進行消霧、造雨控制試驗"
          },
          {
            "no": "5017005003",
            "des": "天氣預測與綜觀氣象學研究",
            "eng": "天氣預測與綜觀氣象學研究"
          },
          {
            "no": "5017005004",
            "des": "影響地球變動之地震、重力測量",
            "eng": "影響地球變動之地震、重力測量"
          },
          {
            "no": "5017005005",
            "des": "發展定量降雨和強風機率預報技術",
            "eng": "發展定量降雨和強風機率預報技術"
          },
          {
            "no": "5017005006",
            "des": "編製地圖、航海圖和地質報告",
            "eng": "編製地圖、航海圖和地質報告"
          }
        ]
      },
      {
        "no": "5017006000",
        "des": "生命禮儀",
        "eng": "生命禮儀",
        "n": [
          {
            "no": "5017006001",
            "des": "協助弔唁與守靈",
            "eng": "協助弔唁與守靈"
          },
          {
            "no": "5017006002",
            "des": "設計與指導奠禮場地",
            "eng": "設計與指導奠禮場地"
          },
          {
            "no": "5017006003",
            "des": "初終與入殮服務",
            "eng": "初終與入殮服務"
          },
          {
            "no": "5017006004",
            "des": "熟悉與執行司儀技能",
            "eng": "熟悉與執行司儀技能"
          },
          {
            "no": "5017006005",
            "des": "指導與撰寫喪葬文書",
            "eng": "指導與撰寫喪葬文書"
          },
          {
            "no": "5017006006",
            "des": "辦理治喪協調與發喪作業",
            "eng": "辦理治喪協調與發喪作業"
          },
          {
            "no": "5017006007",
            "des": "規劃與執行治喪流程",
            "eng": "規劃與執行治喪流程"
          },
          {
            "no": "5017006008",
            "des": "臨終服務諮詢與輔導",
            "eng": "臨終服務諮詢與輔導"
          },
          {
            "no": "5017006009",
            "des": "規劃與執行奠禮╱移靈儀式",
            "eng": "規劃與執行奠禮╱移靈儀式"
          }
        ]
      },
      {
        "no": "5017007000",
        "des": "音樂╱配音",
        "eng": "音樂╱配音",
        "n": [
          {
            "no": "5017007001",
            "des": "動畫角色或外語戲劇配音",
            "eng": "動畫角色或外語戲劇配音"
          },
          {
            "no": "5017007002",
            "des": "擔任獨奏與樂器演奏",
            "eng": "擔任獨奏與樂器演奏"
          },
          {
            "no": "5017007003",
            "des": "寫作創新樂譜",
            "eng": "寫作創新樂譜"
          }
        ]
      },
      {
        "no": "5017008000",
        "des": "運動╱舞蹈",
        "eng": "運動╱舞蹈",
        "n": [
          {
            "no": "5017008001",
            "des": "運動評估與5Q",
            "eng": "運動評估與5Q"
          },
          {
            "no": "5017008002",
            "des": "各類國際標準舞表現技巧",
            "eng": "各類國際標準舞表現技巧"
          },
          {
            "no": "5017008003",
            "des": "各類民俗舞蹈表現技巧",
            "eng": "各類民俗舞蹈表現技巧"
          }
        ]
      },
      {
        "no": "5017009000",
        "des": "學術╱教育╱輔導",
        "eng": "學術╱教育╱輔導",
        "n": [
          {
            "no": "5017009001",
            "des": "申請與執行研究計畫",
            "eng": "申請與執行研究計畫"
          },
          {
            "no": "5017009002",
            "des": "設計規劃教材及教具",
            "eng": "設計規劃教材及教具"
          },
          {
            "no": "5017009003",
            "des": "建立宇宙學之模型",
            "eng": "建立宇宙學之模型"
          },
          {
            "no": "5017009004",
            "des": "設計及使用各種教學媒體",
            "eng": "設計及使用各種教學媒體"
          },
          {
            "no": "5017009005",
            "des": "校內環境美化與佈置",
            "eng": "校內環境美化與佈置"
          },
          {
            "no": "5017009006",
            "des": "進行教學成果評量",
            "eng": "進行教學成果評量"
          },
          {
            "no": "5017009007",
            "des": "教導心算運用技巧",
            "eng": "教導心算運用技巧"
          },
          {
            "no": "5017009008",
            "des": "撰寫研究報告與論文",
            "eng": "撰寫研究報告與論文"
          },
          {
            "no": "5017009009",
            "des": "教導音樂演奏技巧",
            "eng": "教導音樂演奏技巧"
          },
          {
            "no": "5017009010",
            "des": "擬訂教學計畫",
            "eng": "擬訂教學計畫"
          },
          {
            "no": "5017009011",
            "des": "教導珠算運用技巧",
            "eng": "教導珠算運用技巧"
          },
          {
            "no": "5017009012",
            "des": "鑑定、分類及保存標本",
            "eng": "鑑定、分類及保存標本"
          },
          {
            "no": "5017009013",
            "des": "規劃與執行教學活動",
            "eng": "規劃與執行教學活動"
          }
        ]
      },
      {
        "no": "5017010000",
        "des": "藝術品╱珠寶鑑價",
        "eng": "藝術品╱珠寶鑑價",
        "n": [
          {
            "no": "5017010001",
            "des": "拍賣所有物或藝術品",
            "eng": "拍賣所有物或藝術品"
          },
          {
            "no": "5017010002",
            "des": "所有物或藝術品之鑑估報告",
            "eng": "所有物或藝術品之鑑估報告"
          },
          {
            "no": "5017010003",
            "des": "鑑定藝術品或珠寶品質與價值",
            "eng": "鑑定藝術品或珠寶品質與價值"
          }
        ]
      }
    ]
  }
];

export const jobSkillList = [
  ...jobSkill,
];

