import { LitElement, html, css } from 'lit';
import install from '@twind/with-web-components';
import config from '../../twind.config';

import { addressList } from "../../constants/address.js";
import { classifyList } from '../../constants/classify.js';
import { jobSkillList } from '../../constants/jobSkill.js';
import { toolList } from '../../constants/tool.js';
import { certificateList } from '../../constants/certificate.js';

import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';

const withTwind = install(config);

class CommonModalComponent extends withTwind(LitElement) {

  static properties = {
    showModal: { type: Boolean },
    transparent: { type: Boolean },
    checkedList: { type: Array },
    maxLength: { type: Number },
    allJson: { type: Array },
    param: { type: Object },
    isShowMainItem: { type: Boolean },
    selectItem: { type: Object },
    isSearch: { type: Boolean },
    type: { type: String },
    dataCode: { type: String },
  };

  static styles = css`
    .modal-background {
      background-color: var(--modal-bg-color, rgba(107, 114, 128, 0.75));
    }
    .highlight {
      color: #F6696B;
    }
    .search-bar {
      display: flex;
      align-items: center;
      background-color: #F6F4F4;
      border-radius: 4px;
      gap: 8px;
      padding: 5px 12px;
      min-width: 450px;
    }

    .search-bar input {
      border: none;
      background-color: transparent;
      width: 100%;
      outline: none;
    }
  `;

  constructor() {
    super();
    this.showModal = false;
    this.transparent = false;
    this.checkedList = [];
    this.maxLength = 5;
    this.allJson = [];
    this.param = {};
    this.selectItem = {};
    this.isSearch = false;
    this.searchText = '';
  }

  updated(changedProperties) {
    if (changedProperties.has('transparent')) {
      this.style.setProperty('--modal-bg-color', this.transparent ? 'rgba(107, 114, 128, 0.5)' : 'rgba(107, 114, 128, 0.75)');
    }
    if (this.type && !this.loaded) {
      switch (this.type) {
        case "address":
          this.allJson = addressList;
          break;
        // Add more cases as needed
        case "classify":
          this.allJson = classifyList;
          break;
        case 'jobSkill':
          this.allJson = jobSkillList;
          break;
        case 'tool':
          this.allJson = toolList;
          break;
        case 'certificate':
          this.allJson = certificateList;
          break;
        default:
          this.allJson = null;
          break;
      }

      this.loaded = true; // Set loaded state to true to avoid re-loading
    }

    if (changedProperties.has('dataCode')) {
      this.checkedList = JSON.parse(this.dataCode);

      this.allJson.forEach(first => {
        first.n.forEach(second => {
          second.isDone = this.checkedList.includes(second.no);
          second.n.forEach(item => {
            item.isDone = this.checkedList.includes(item.no) || second.isDone;
          });
        });
      });
      this.requestUpdate();
    }
  }

  getTitle() {
    return this.title || '標題';
  }

  clear() {
    this.checkedList = [];
    [this.searchData, this.allJson].forEach(
      data => {
        data?.forEach(first => {
          first.n.forEach(second => {
            second.isDone = false;
            second.n.forEach(item => {
              item.isDone = false;
            });
          });
        });
      });
  }

  changeFirst(no) {
    this.param.first = no;
    this.param.second = null;
    this.selectItem = {};
    this.requestUpdate();
  }

  headerTemplate() {

    return html`
        <div class='flex w-full justify-between items-center'>
          <div class="flex items-center">
            <button
              class="w-[22px] h-[22px]"
              @click="${() => this.clickSearch()}"
              ?hidden="${this.isSearch}"
              type="button">
              <i>
                <img class='w-[22px] h-[22px]' src='/images/icon/search.svg'>
              </i>
            </button>
            <div class="flex gap-2"
              ?hidden="${!this.isSearch}">
              <button @click="${() => this.clickBack()}" type="button" ?hidden="${!this.isSearch}">
                <img class='w-[22px] h-[22px]' src='/images/icon/left.svg'>
              </button>
              <div class="search-bar" ?hidden="${!this.isSearch}">
                <i class="w-5 h-5">
                  <img class='w-[20px] h-[20px]' src='/images/icon/search.svg'>
                </i>
                <input .value="${this.searchText}" @change="${e => this.searchInput(e.target.value)}" .placeholder=${this.placeholder()}>
              </div>
            </div>
          </div>
          <div class="text-center text-lg font-semibold m-auto" ?hidden="${this.isSearch}">
            ${this.getTitle()}
          </div>
          <div class="flex items-center">
            <button @click="${this.cancel}" type="button">
              <img class='w-[22px] h-[22px]' src='/images/icon/close.svg'>
          </div>
        </div>
        `;
  }

  placeholder() {
    switch (this.type) {
      case "address":
        return '輸入地點關鍵字';
      // Add more cases as needed
      case "classify":
        return '輸入職務列別關鍵字';
      case 'jobSkill':
        return '輸入技能關鍵字';
      case 'tool':
        return '輸入工具關鍵字';
      case 'certificate':
        return '輸入證照關鍵字';
      default:
        return "不符合參數";
    }
  }

  changeSecond(no) {
    this.param.second = no;
    const data = (this.isSearch ? this.searchData : this.allJson).find(item => item.no === this.param.first)?.n;
    this.selectItem = data.find(item => item.no === no);
    this.requestUpdate();
  }

  deleteSelected(itemNo) {
    this.checkedList = this.checkedList.filter(i => i !== itemNo);
    this.allJson.forEach(first => {
      first.n.forEach(second => {
        second.isDone = this.checkedList.includes(second.no);
        second.n.forEach(item => {
          item.isDone = this.checkedList.includes(item.no) || second.isDone;
        });
      });
    });
    this.requestUpdate();
  }

  clickMainItem(event) {
    const itemNo = event.target.value;

    const mainItem = (this.isSearch ? this.searchData : this.allJson)
      .find(item => item.no === this.param.first)?.n
      .find(item => itemNo === item.no);

    const hasChildChecked = mainItem.n.some(item => this.checkedList.includes(item.no));

    if (!hasChildChecked) {
      if (this.checkedList.length >= this.maxLength) {
        event.target.checked = false;
        return;
      }
    }

    mainItem.isDone = event.target.checked;

    mainItem.n.forEach(item => { item.isDone = mainItem.isDone; });

    if (mainItem.isDone) {
      this.checkedList.push(mainItem.no);
      this.checkedList = this.checkedList.filter(i => !mainItem.n.map(item => item.no).includes(i));
    }
    else {
      this.checkedList = this.checkedList.filter(i => i !== mainItem.no);
    }

    this.requestUpdate();
  }

  clickChecked(event) {
    const itemNo = event.target.value;

    const hasChildChecked = this.checkedList.includes(itemNo);

    if (!hasChildChecked) {
      if (this.checkedList.length >= this.maxLength) {
        event.target.checked = false;
        return;
      }
    }

    const item = (this.isSearch ? this.searchData : this.allJson)
      .find(item => item.no === this.param.first)?.n
      .find(item => item.no === this.param.second)?.n
      .find(item => item.no === itemNo);

    item.isDone = event.target.checked;

    if (event.target.checked) {
      this.checkedList.push(itemNo);
    } else {
      this.checkedList = this.checkedList.filter(i => i !== itemNo);
    }
    this.requestUpdate();
  }

  clickSearch() {
    this.isSearch = true;
    this.searchText = '';
    this.searchData = this.allJson;
    this.requestUpdate();
  }
  clickBack() {
    this.isSearch = false;
    this.searchText = '';
    this.param.second = '';
    this.requestUpdate();
  }
  cancel() {
    this.showModal = false;
  }

  confirmBtn() {
    if (this.confirm) {
      if (typeof this.confirm === 'string') {
        // 如果 userLoginFn 是字符串，尝试将其解析为函数
        const fn = new Function(`return ${this.confirm}`)();
        this.hidden = true;
        this.requestUpdate();
      } else if (typeof this.confirm === 'function') {
        // 如果 userLoginFn 是函数，则直接执行它
        this.confirm(this.checkedList);
        this.hidden = true;
        this.requestUpdate();
      } else {
        console.error('userLoginFn 不是字符串或函数');
      }
      return;
    }
    const options = {
      detail: {
        data: this.checkedList,
        type: this.type
      },
      bubbles: true,
      composed: true,
    };

    this.dispatchEvent(new CustomEvent('confirm', options));
    this.cancel();
  }


  searchInput(value) {
    this.searchText = value || this.searchText;
    this.param.second = '';
    const highlightedValue = `<span class="highlight">${value}</span>`;

    const filteredData = this.allJson.map(first => {
      const data = first.n.map(second => {
        const matchingItems = second.n.filter(item => item.des.toLowerCase().includes(value.toLowerCase()))
          .map(item => ({
            ...item,
            des: item.des,
            highlightedDes: item.des.replace(new RegExp(value, 'gi'), highlightedValue),
            isDone: this.checkedList.includes(item.no)
          }));

        if (second.des.includes(value) || matchingItems.length > 0) {
          return {
            ...second,
            des: second.des,
            highlightedDes: second.des.replace(new RegExp(value, 'gi'), highlightedValue),
            n: matchingItems,
            findCount: matchingItems.length + (second.des.includes(value) ? 1 : 0),
            isShowMainItem: second.des.includes(value)
          };
        }
        return null;
      }).filter(item => item !== null);

      if (data.length > 0) {
        return {
          ...first,
          n: data
        };
      }
      return null;
    }).filter(item => item !== null);
    console.log(filteredData);
    this.searchData = filteredData;

    this.requestUpdate();
  }
  menuFirst() {
    if (this.isSearch) {
      return html`
        <div class="divide-y divide-gray-400 border-r overflow-y-auto h-96">
          ${this.searchData?.map(item => html`
            <button @click="${() => this.changeFirst(item.no)}" class="text-lg p-2 w-full text-left focus:outline-none hover:bg-lightBlue-100 focus:bg-lightBlue-200 text-nowrap">
              ${item.des}
            </button>
          `)}
        </div>
        `;
    }
    else {
      return html`
        <div class="divide-y divide-gray-400 border-r overflow-y-auto h-96">
          ${this.allJson.map(item => html`
            <button @click="${() => this.changeFirst(item.no)}" class="text-lg p-2 w-full text-left focus:outline-none hover:bg-lightBlue-100 focus:bg-lightBlue-200 text-nowrap">
              ${item.des}
            </button>
          `)}
        </div>
        `;
    }
  }

  menuSecond() {
    const data = (this.isSearch && this.searchText) ? this.searchData : this.allJson;

    return html`
    <div class="divide-y divide-gray-400 border-r overflow-y-auto h-96">
      ${(data.find(item => item.no === this.param.first)?.n || []).map(item => html`
          <button @click="${() => this.changeSecond(item.no)}" class="text-lg p-2 w-full text-left focus:outline-none hover:bg-lightBlue-100 focus:bg-lightBlue-200">
            ${unsafeHTML(item?.des)}
          </button>
        `)}
      `;
  }

  menuThird() {
    const data = (this.isSearch && this.searchText) ? this.searchData : this.allJson;

    return html`
    <div class="overflow-y-auto h-96" ?hidden="${!this.param.second}">
      <div>
        <div class="text-lg p-2 w-full text-left focus:outline-none hover:bg-lightBlue-100 focus:bg-lightBlue-200" ?hidden="${!this.isSearch}">
          <span class="text-[#A5A5A5]" ?hidden="${!this.isSearch}">搜尋結果(共${this.selectItem.findCount || this.selectItem?.n?.length}筆)</span>
        </div>
        ${this.isShowMainItem ? html`
          <div class="flex items-center gap-2 p-2">
            <input
              @change="${this.clickMainItem}"
              .id="${this.selectItem?.no}"
              .value="${this.selectItem?.no}"
              type="checkbox"
              .checked="${this.selectItem.isDone}")}"
              class="focus:outline-none focus:ring-0 h-5 w-5 text-lightBlue-500 border-gray-300 rounded">
            <label for="${this.selectItem?.des}" class="text-lg">${unsafeHTML(this.selectItem?.highlightedDes ?? this.selectItem?.des)}</label>
          </div>
      ` : ''}

      ${(this.selectItem?.n || []).map((item, ADCIndex) => html`
      <div class="flex items-center gap-2 p-2" key="${ADCIndex}">
        <input
          @change="${this.clickChecked}"
          .id="${item.no}"
          .value="${item.no}"
          type="checkbox"
          .checked="${item.isDone}" \
          class="focus:outline-none focus:ring-0 h-4 w-4 text-lightBlue-500 border-gray-300 rounded"
          ?disabled="${this.selectItem.isDone}">
        <label for="${item.no}">${unsafeHTML(item.highlightedDes ?? item.des)}</label>
      </div>
    `)}
    </div>
    `;
  }


  render() {
    return html`
        <style>
          ${this.customizeStyle}
        </style>

        <div style="${this.showModal ? 'display: block;' : 'display: none;'}" class="fixed z-[9000] inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div class="fixed z-[200] inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div class="fixed inset-0 modal-background transition-opacity" aria-hidden="true"></div>
              <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <div>
                  ${this.headerTemplate()}
                  <div class="mt-4">
                    <div>已選擇： <span id="ws_num_checked">${this.checkedList.length}</span>/${this.maxLength}
                      <span @click="${this.clear}" class="ml-2 cursor-pointer text-blue-500 font-semibold">清除全部</span>
                    </div>
                  </div>
                  <div class="flex flex-wrap item-center gap-2 mt-2">
                    ${this.checkedList.map(itemNo => html`
                      <span @click="${() => this.deleteSelected(itemNo)}" class="bg-lightBlue-100 px-2 py-1 rounded-xl cursor-pointer">
                        ${this.findDescriptionByNo(itemNo)} x
                      </span>
                    `)}
                  </div>
                  <div class="grid grid-cols-3 mt-[13px] border-t border-gray-500">
                    ${this.menuFirst()}
                    ${this.menuSecond()}
                    ${this.menuThird()}
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 text-center">
                  <!-- <button @click="${this.cancel}" type="button" class="mr-[4px] rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightBlue-400">
                    取消
                  </button> -->
                  <button @click="${() => this.confirmBtn()}" type="button" class="rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightBlue-400">
                    確定
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      `;
  }

  findObjectsByNo(data, targetNo, foundObjects = []) {
    if (!Array.isArray(targetNo)) {
      targetNo = [targetNo];
    }

    if (typeof data === 'object' && data !== null && 'no' in data) {
      if (targetNo.includes(data.no)) {
        foundObjects.push(data);
      }
    }

    for (const key in data) {
      if (Array.isArray(data[key])) {
        for (const item of data[key]) {
          this.findObjectsByNo(item, targetNo, foundObjects);
        }
      } else if (typeof data[key] === 'object') {
        this.findObjectsByNo(data[key], targetNo, foundObjects);
      }
    }

    return foundObjects.length > 0 ? foundObjects : [];
  }
  findDescriptionByNo(no) {
    const item = this.findObjectsByNo(this.allJson, no);
    return item ? item.map(item => item.des) : '';
  }
}

customElements.define('wb-common-modal', CommonModalComponent);;
