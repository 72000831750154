var toolJson = [
  {
    "no": "12001000000",
    "des": "電腦╱網路類",
    "n": [
      {
        "no": "12001001000",
        "des": "作業系統類",
        "n": [
          {
            "no": "12001001001",
            "des": "AIX",
            "eng": "AIX"
          },
          {
            "no": "12001001002",
            "des": "Apple",
            "eng": "Apple"
          },
          {
            "no": "12001001003",
            "des": "DOS",
            "eng": "DOS"
          },
          {
            "no": "12001001004",
            "des": "FreeBSD",
            "eng": "FreeBSD"
          },
          {
            "no": "12001001005",
            "des": "HP-UX",
            "eng": "HP-UX"
          },
          {
            "no": "12001001006",
            "des": "IDMS",
            "eng": "IDMS"
          },
          {
            "no": "12001001007",
            "des": "Linux",
            "eng": "Linux"
          },
          {
            "no": "12001001008",
            "des": "Mac OS",
            "eng": "Mac OS"
          },
          {
            "no": "12001001009",
            "des": "Mac/Macintosh",
            "eng": "Mac/Macintosh"
          },
          {
            "no": "12001001010",
            "des": "Mainframe",
            "eng": "Mainframe"
          },
          {
            "no": "12001001011",
            "des": "Microsoft SmartPhone",
            "eng": "Microsoft SmartPhone"
          },
          {
            "no": "12001001012",
            "des": "NDS/Novell Directory Services",
            "eng": "NDS/Novell Directory Services"
          },
          {
            "no": "12001001013",
            "des": "Novell",
            "eng": "Novell"
          },
          {
            "no": "12001001014",
            "des": "OS X",
            "eng": "OS X"
          },
          {
            "no": "12001001015",
            "des": "OS/2",
            "eng": "OS/2"
          },
          {
            "no": "12001001016",
            "des": "OS/390",
            "eng": "OS/390"
          },
          {
            "no": "12001001017",
            "des": "OS/400",
            "eng": "OS/400"
          },
          {
            "no": "12001001018",
            "des": "Palm OS",
            "eng": "Palm OS"
          },
          {
            "no": "12001001019",
            "des": "SCO UNIX",
            "eng": "SCO UNIX"
          },
          {
            "no": "12001001020",
            "des": "Shell",
            "eng": "Shell"
          },
          {
            "no": "12001001021",
            "des": "Solaris",
            "eng": "Solaris"
          },
          {
            "no": "12001001022",
            "des": "SUN OS",
            "eng": "SUN OS"
          },
          {
            "no": "12001001023",
            "des": "Sun Solaris",
            "eng": "Sun Solaris"
          },
          {
            "no": "12001001024",
            "des": "Symbian S60",
            "eng": "Symbian S60"
          },
          {
            "no": "12001001025",
            "des": "Tandem",
            "eng": "Tandem"
          },
          {
            "no": "12001001026",
            "des": "TK",
            "eng": "TK"
          },
          {
            "no": "12001001027",
            "des": "TSO",
            "eng": "TSO"
          },
          {
            "no": "12001001028",
            "des": "TSO/ISPF",
            "eng": "TSO/ISPF"
          },
          {
            "no": "12001001029",
            "des": "UNIX",
            "eng": "UNIX"
          },
          {
            "no": "12001001030",
            "des": "VMS",
            "eng": "VMS"
          },
          {
            "no": "12001001031",
            "des": "WIN CE",
            "eng": "WIN CE"
          },
          {
            "no": "12001001032",
            "des": "Windows 2000",
            "eng": "Windows 2000"
          },
          {
            "no": "12001001033",
            "des": "Windows 2003",
            "eng": "Windows 2003"
          },
          {
            "no": "12001001034",
            "des": "Windows 95",
            "eng": "Windows 95"
          },
          {
            "no": "12001001035",
            "des": "Windows 98",
            "eng": "Windows 98"
          },
          {
            "no": "12001001036",
            "des": "Windows Mobile",
            "eng": "Windows Mobile"
          },
          {
            "no": "12001001037",
            "des": "Windows NT",
            "eng": "Windows NT"
          },
          {
            "no": "12001001038",
            "des": "Windows Vista",
            "eng": "Windows Vista"
          },
          {
            "no": "12001001040",
            "des": "Windows 7",
            "eng": "Windows 7"
          },
          {
            "no": "12001001041",
            "des": "Windows Server 2008",
            "eng": "Windows Server 2008"
          },
          {
            "no": "12001001042",
            "des": "Android",
            "eng": "Android"
          },
          {
            "no": "12001001043",
            "des": "iOS",
            "eng": "iOS"
          },
          {
            "no": "12001001044",
            "des": "Windows 8",
            "eng": "Windows 8"
          },
          {
            "no": "12001001045",
            "des": "Windows Server 2012",
            "eng": "Windows Server 2012"
          },
          {
            "no": "12001001039",
            "des": "Windows XP",
            "eng": "Windows XP"
          },
          {
            "no": "12001001046",
            "des": "Windows 10",
            "eng": "Windows 10"
          },
          {
            "no": "12001001047",
            "des": "Windows Server 2019",
            "eng": "Windows Server 2019"
          }
        ],
        "eng": "Operating System"
      },
      {
        "no": "12001002000",
        "des": "軟體開發類",
        "n": [
          {
            "no": "12001002016",
            "des": "Github",
            "eng": "Github"
          },
          {
            "no": "12001002001",
            "des": "ABAQUS",
            "eng": "ABAQUS"
          },
          {
            "no": "12001002002",
            "des": "DDK",
            "eng": "DDK"
          },
          {
            "no": "12001002003",
            "des": "MCU",
            "eng": "MCU"
          },
          {
            "no": "12001002004",
            "des": "OOAD",
            "eng": "OOAD"
          },
          {
            "no": "12001002005",
            "des": "OOP",
            "eng": "OOP"
          },
          {
            "no": "12001002006",
            "des": "Oracle Forms",
            "eng": "Oracle Forms"
          },
          {
            "no": "12001002007",
            "des": "PVCS",
            "eng": "PVCS"
          },
          {
            "no": "12001002008",
            "des": "SDLC",
            "eng": "SDLC"
          },
          {
            "no": "12001002009",
            "des": "Servlets",
            "eng": "Servlets"
          },
          {
            "no": "12001002010",
            "des": "STL",
            "eng": "STL"
          },
          {
            "no": "12001002011",
            "des": "Systems Administration",
            "eng": "Systems Administration"
          },
          {
            "no": "12001002012",
            "des": "Systems Analysis",
            "eng": "Systems Analysis"
          },
          {
            "no": "12001002013",
            "des": "Systems Analyst",
            "eng": "Systems Analyst"
          },
          {
            "no": "12001002014",
            "des": "UML",
            "eng": "UML"
          },
          {
            "no": "12001002015",
            "des": "VxWorks",
            "eng": "VxWorks"
          },
          {
            "no": "12001002017",
            "des": "Jenkins",
            "eng": "Jenkins"
          },
          {
            "no": "12001002018",
            "des": "Git",
            "eng": "Git"
          },
          {
            "no": "12001002019",
            "des": "Fortify",
            "eng": "Fortify"
          }
        ],
        "eng": "Software Development "
      },
      {
        "no": "12001003000",
        "des": "程式設計類",
        "n": [
          {
            "no": "12001003001",
            "des": "A+",
            "eng": "A+"
          },
          {
            "no": "12001003002",
            "des": "ActionScript",
            "eng": "ActionScript"
          },
          {
            "no": "12001003003",
            "des": "ADA",
            "eng": "ADA"
          },
          {
            "no": "12001003004",
            "des": "AJAX",
            "eng": "AJAX"
          },
          {
            "no": "12001003005",
            "des": "ASP",
            "eng": "ASP"
          },
          {
            "no": "12001003006",
            "des": "ASP.NET",
            "eng": "ASP.NET"
          },
          {
            "no": "12001003007",
            "des": "ATL",
            "eng": "ATL"
          },
          {
            "no": "12001003008",
            "des": "C",
            "eng": "C"
          },
          {
            "no": "12001003009",
            "des": "C#",
            "eng": "C#"
          },
          {
            "no": "12001003010",
            "des": "C++",
            "eng": "C++"
          },
          {
            "no": "12001003011",
            "des": "C++.Net",
            "eng": "C++.Net"
          },
          {
            "no": "12001003012",
            "des": "CGI",
            "eng": "CGI"
          },
          {
            "no": "12001003013",
            "des": "Clipper",
            "eng": "Clipper"
          },
          {
            "no": "12001003014",
            "des": "COBOL",
            "eng": "COBOL"
          },
          {
            "no": "12001003015",
            "des": "COM/DCOM",
            "eng": "COM/DCOM"
          },
          {
            "no": "12001003016",
            "des": "COOL:Gen",
            "eng": "COOL:Gen"
          },
          {
            "no": "12001003017",
            "des": "CORBA",
            "eng": "CORBA"
          },
          {
            "no": "12001003018",
            "des": "Delphi",
            "eng": "Delphi"
          },
          {
            "no": "12001003019",
            "des": "Developer/ Designer 2000",
            "eng": "Developer/ Designer 2000"
          },
          {
            "no": "12001003020",
            "des": "DirectX",
            "eng": "DirectX"
          },
          {
            "no": "12001003021",
            "des": "FORTRAN",
            "eng": "FORTRAN"
          },
          {
            "no": "12001003022",
            "des": "Fox Pro",
            "eng": "Fox Pro"
          },
          {
            "no": "12001003023",
            "des": "FoxBASE+",
            "eng": "FoxBASE+"
          },
          {
            "no": "12001003024",
            "des": "Infobasic",
            "eng": "Infobasic"
          },
          {
            "no": "12001003025",
            "des": "Java",
            "eng": "Java"
          },
          {
            "no": "12001003026",
            "des": "JCL",
            "eng": "JCL"
          },
          {
            "no": "12001003027",
            "des": "JCreator Pro",
            "eng": "JCreator Pro"
          },
          {
            "no": "12001003028",
            "des": "JMS",
            "eng": "JMS"
          },
          {
            "no": "12001003029",
            "des": "JSF",
            "eng": "JSF"
          },
          {
            "no": "12001003030",
            "des": "JSP",
            "eng": "JSP"
          },
          {
            "no": "12001003031",
            "des": "Kylix",
            "eng": "Kylix"
          },
          {
            "no": "12001003032",
            "des": "Lotus Notes",
            "eng": "Lotus Notes"
          },
          {
            "no": "12001003033",
            "des": "LotusScript",
            "eng": "LotusScript"
          },
          {
            "no": "12001003034",
            "des": "Matlab",
            "eng": "Matlab"
          },
          {
            "no": "12001003035",
            "des": "MFC",
            "eng": "MFC"
          },
          {
            "no": "12001003036",
            "des": "PAL",
            "eng": "PAL"
          },
          {
            "no": "12001003037",
            "des": "Pascal",
            "eng": "Pascal"
          },
          {
            "no": "12001003038",
            "des": "PECL",
            "eng": "PECL"
          },
          {
            "no": "12001003039",
            "des": "PeopleCode",
            "eng": "PeopleCode"
          },
          {
            "no": "12001003040",
            "des": "Perl",
            "eng": "Perl"
          },
          {
            "no": "12001003041",
            "des": "PHP",
            "eng": "PHP"
          },
          {
            "no": "12001003042",
            "des": "PL/1",
            "eng": "PL/1"
          },
          {
            "no": "12001003043",
            "des": "PowerBuilder",
            "eng": "PowerBuilder"
          },
          {
            "no": "12001003044",
            "des": "Pro*C",
            "eng": "Pro*C"
          },
          {
            "no": "12001003045",
            "des": "Python",
            "eng": "Python"
          },
          {
            "no": "12001003046",
            "des": "Rexx",
            "eng": "Rexx"
          },
          {
            "no": "12001003047",
            "des": "RMI",
            "eng": "RMI"
          },
          {
            "no": "12001003048",
            "des": "RPG",
            "eng": "RPG"
          },
          {
            "no": "12001003049",
            "des": "Ruby",
            "eng": "Ruby"
          },
          {
            "no": "12001003050",
            "des": "Simula",
            "eng": "Simula"
          },
          {
            "no": "12001003051",
            "des": "SIR",
            "eng": "SIR"
          },
          {
            "no": "12001003052",
            "des": "Smalltalk",
            "eng": "Smalltalk"
          },
          {
            "no": "12001003053",
            "des": "Spring",
            "eng": "Spring"
          },
          {
            "no": "12001003054",
            "des": "SQR",
            "eng": "SQR"
          },
          {
            "no": "12001003055",
            "des": "StarTeam",
            "eng": "StarTeam"
          },
          {
            "no": "12001003056",
            "des": "Struts",
            "eng": "Struts"
          },
          {
            "no": "12001003057",
            "des": "TCL",
            "eng": "TCL"
          },
          {
            "no": "12001003058",
            "des": "VBA",
            "eng": "VBA"
          },
          {
            "no": "12001003059",
            "des": "Visual Basic",
            "eng": "Visual Basic"
          },
          {
            "no": "12001003060",
            "des": "Visual Basic .net",
            "eng": "Visual Basic .net"
          },
          {
            "no": "12001003061",
            "des": "Visual C#",
            "eng": "Visual C#"
          },
          {
            "no": "12001003062",
            "des": "Visual C++",
            "eng": "Visual C++"
          },
          {
            "no": "12001003063",
            "des": "Visual J#",
            "eng": "Visual J#"
          },
          {
            "no": "12001003064",
            "des": "Visual J++",
            "eng": "Visual J++"
          },
          {
            "no": "12001003065",
            "des": "Visual SourceSafe",
            "eng": "Visual SourceSafe"
          },
          {
            "no": "12001003066",
            "des": "Visual Studio",
            "eng": "Visual Studio"
          },
          {
            "no": "12001003067",
            "des": "Visual Studio .net",
            "eng": "Visual Studio .net"
          },
          {
            "no": "12001003068",
            "des": "Win32",
            "eng": "Win32"
          },
          {
            "no": "12001003069",
            "des": "WinForm",
            "eng": "WinForm"
          },
          {
            "no": "12001003070",
            "des": "WML",
            "eng": "WML"
          },
          {
            "no": "12001003071",
            "des": "XSL",
            "eng": "XSL"
          },
          {
            "no": "12001003072",
            "des": "XSLT",
            "eng": "XSLT"
          },
          {
            "no": "12001003082",
            "des": "Scala",
            "eng": "Scala"
          },
          {
            "no": "12001003081",
            "des": "Kotlin",
            "eng": "Kotlin"
          },
          {
            "no": "12001003080",
            "des": "Go",
            "eng": "Go"
          },
          {
            "no": "12001003079",
            "des": "Rails",
            "eng": "Rails"
          },
          {
            "no": "12001003073",
            "des": "LabVIEW",
            "eng": "LabVIEW"
          },
          {
            "no": "12001003074",
            "des": "Silverlight",
            "eng": "Silverlight"
          },
          {
            "no": "12001003075",
            "des": "Objective-C",
            "eng": "Objective-C"
          },
          {
            "no": "12001003077",
            "des": "SWIFT",
            "eng": "SWIFT"
          },
          {
            "no": "12001003076",
            "des": "X++",
            "eng": "X++"
          },
          {
            "no": "12001003078",
            "des": "R",
            "eng": "R"
          },
          {
            "no": "12001003084",
            "des": "Flutter",
            "eng": "Flutter"
          },
          {
            "no": "12001003085",
            "des": "Dart",
            "eng": "Dart"
          }
        ],
        "eng": "Programming"
      },
      {
        "no": "12001004000",
        "des": "資料庫設計類",
        "n": [
          {
            "no": "12001004001",
            "des": "Adabas",
            "eng": "Adabas"
          },
          {
            "no": "12001004002",
            "des": "ADO",
            "eng": "ADO"
          },
          {
            "no": "12001004003",
            "des": "ANSI SQL",
            "eng": "ANSI SQL"
          },
          {
            "no": "12001004004",
            "des": "Brio",
            "eng": "Brio"
          },
          {
            "no": "12001004005",
            "des": "Capacity Planning",
            "eng": "Capacity Planning"
          },
          {
            "no": "12001004006",
            "des": "CMMS",
            "eng": "CMMS"
          },
          {
            "no": "12001004007",
            "des": "Cognos",
            "eng": "Cognos"
          },
          {
            "no": "12001004008",
            "des": "Data Guard",
            "eng": "Data Guard"
          },
          {
            "no": "12001004009",
            "des": "Data Modeling",
            "eng": "Data Modeling"
          },
          {
            "no": "12001004010",
            "des": "Database Administrator",
            "eng": "Database Administrator"
          },
          {
            "no": "12001004011",
            "des": "Database Management",
            "eng": "Database Management"
          },
          {
            "no": "12001004012",
            "des": "DataStage",
            "eng": "DataStage"
          },
          {
            "no": "12001004013",
            "des": "DB2",
            "eng": "DB2"
          },
          {
            "no": "12001004014",
            "des": "Dbase",
            "eng": "Dbase"
          },
          {
            "no": "12001004015",
            "des": "Endevor",
            "eng": "Endevor"
          },
          {
            "no": "12001004016",
            "des": "ERwin",
            "eng": "ERwin"
          },
          {
            "no": "12001004017",
            "des": "Essbase",
            "eng": "Essbase"
          },
          {
            "no": "12001004018",
            "des": "ETL",
            "eng": "ETL"
          },
          {
            "no": "12001004019",
            "des": "FoWindows Xpro",
            "eng": "FoWindows Xpro"
          },
          {
            "no": "12001004020",
            "des": "FoxBase",
            "eng": "FoxBase"
          },
          {
            "no": "12001004021",
            "des": "FoxPro 2",
            "eng": "FoxPro 2"
          },
          {
            "no": "12001004022",
            "des": "IMS",
            "eng": "IMS"
          },
          {
            "no": "12001004023",
            "des": "Informix",
            "eng": "Informix"
          },
          {
            "no": "12001004024",
            "des": "Informatica",
            "eng": "Informatica"
          },
          {
            "no": "12001004025",
            "des": "Ingres",
            "eng": "Ingres"
          },
          {
            "no": "12001004026",
            "des": "Interbase",
            "eng": "Interbase"
          },
          {
            "no": "12001004027",
            "des": "ISPF",
            "eng": "ISPF"
          },
          {
            "no": "12001004028",
            "des": "Jasmine",
            "eng": "Jasmine"
          },
          {
            "no": "12001004029",
            "des": "JDBC",
            "eng": "JDBC"
          },
          {
            "no": "12001004030",
            "des": "Access",
            "eng": "Access"
          },
          {
            "no": "12001004031",
            "des": "MS SQL",
            "eng": "MS SQL"
          },
          {
            "no": "12001004032",
            "des": "MySQL",
            "eng": "MySQL"
          },
          {
            "no": "12001004033",
            "des": "ODBC",
            "eng": "ODBC"
          },
          {
            "no": "12001004034",
            "des": "OLAP",
            "eng": "OLAP"
          },
          {
            "no": "12001004035",
            "des": "Oracle",
            "eng": "Oracle"
          },
          {
            "no": "12001004036",
            "des": "OWB",
            "eng": "OWB"
          },
          {
            "no": "12001004037",
            "des": "ParaDox",
            "eng": "ParaDox"
          },
          {
            "no": "12001004038",
            "des": "PL/SQL",
            "eng": "PL/SQL"
          },
          {
            "no": "12001004039",
            "des": "PostgreSQL",
            "eng": "PostgreSQL"
          },
          {
            "no": "12001004040",
            "des": "Progress",
            "eng": "Progress"
          },
          {
            "no": "12001004041",
            "des": "RDBMS",
            "eng": "RDBMS"
          },
          {
            "no": "12001004042",
            "des": "Sagent",
            "eng": "Sagent"
          },
          {
            "no": "12001004043",
            "des": "SAPDB",
            "eng": "SAPDB"
          },
          {
            "no": "12001004044",
            "des": "Sybase",
            "eng": "Sybase"
          },
          {
            "no": "12001004045",
            "des": "SYSBASE",
            "eng": "SYSBASE"
          },
          {
            "no": "12001004046",
            "des": "Toad",
            "eng": "Toad"
          },
          {
            "no": "12001004047",
            "des": "Visual Foxpro",
            "eng": "Visual Foxpro"
          }
        ],
        "eng": "Database"
      },
      {
        "no": "12001005000",
        "des": "伺服器(Server)",
        "n": [
          {
            "no": "12001005001",
            "des": "AS/400",
            "eng": "AS/400"
          },
          {
            "no": "12001005002",
            "des": "BizTalk",
            "eng": "BizTalk"
          },
          {
            "no": "12001005003",
            "des": "CC Mail",
            "eng": "CC Mail"
          },
          {
            "no": "12001005004",
            "des": "CICS",
            "eng": "CICS"
          },
          {
            "no": "12001005005",
            "des": "Citrix",
            "eng": "Citrix"
          },
          {
            "no": "12001005006",
            "des": "ClearCase",
            "eng": "ClearCase"
          },
          {
            "no": "12001005007",
            "des": "ClearQuest",
            "eng": "ClearQuest"
          },
          {
            "no": "12001005008",
            "des": "Domino",
            "eng": "Domino"
          },
          {
            "no": "12001005009",
            "des": "FileNet",
            "eng": "FileNet"
          },
          {
            "no": "12001005010",
            "des": "Focus",
            "eng": "Focus"
          },
          {
            "no": "12001005011",
            "des": "Hyperion (Brio)",
            "eng": "Hyperion (Brio)"
          },
          {
            "no": "12001005012",
            "des": "Microsoft Exchange",
            "eng": "Microsoft Exchange"
          },
          {
            "no": "12001005013",
            "des": "Microsoft SharePoint",
            "eng": "Microsoft SharePoint"
          },
          {
            "no": "12001005014",
            "des": "MQSeries",
            "eng": "MQSeries"
          },
          {
            "no": "12001005015",
            "des": "Silverstream",
            "eng": "Silverstream"
          },
          {
            "no": "12001005016",
            "des": "Tomcat",
            "eng": "Tomcat"
          },
          {
            "no": "12001005017",
            "des": "Vmware",
            "eng": "Vmware"
          },
          {
            "no": "12001005018",
            "des": "VSAM",
            "eng": "VSAM"
          },
          {
            "no": "12001005019",
            "des": "WebLogic",
            "eng": "WebLogic"
          },
          {
            "no": "12001005020",
            "des": "WebMethods",
            "eng": "WebMethods"
          },
          {
            "no": "12001005021",
            "des": "WebSphere",
            "eng": "WebSphere"
          },
          {
            "no": "12001005022",
            "des": "hadoop",
            "eng": "hadoop"
          },
          {
            "no": "12001005023",
            "des": "AWS",
            "eng": "AWS"
          }
        ],
        "eng": "Server"
      },
      {
        "no": "12001006000",
        "des": "網頁技術類",
        "n": [
          {
            "no": "12001006001",
            "des": "ActiveX",
            "eng": "ActiveX"
          },
          {
            "no": "12001006002",
            "des": "Apache SOAP",
            "eng": "Apache SOAP"
          },
          {
            "no": "12001006005",
            "des": "Cold Fusion",
            "eng": "Cold Fusion"
          },
          {
            "no": "12001006006",
            "des": "DHTML",
            "eng": "DHTML"
          },
          {
            "no": "12001006007",
            "des": "Dreamweaver",
            "eng": "Dreamweaver"
          },
          {
            "no": "12001006008",
            "des": "EJB",
            "eng": "EJB"
          },
          {
            "no": "12001006009",
            "des": "Electronic Payment",
            "eng": "Electronic Payment"
          },
          {
            "no": "12001006010",
            "des": "Fireworks",
            "eng": "Fireworks"
          },
          {
            "no": "12001006011",
            "des": "FrontPage",
            "eng": "FrontPage"
          },
          {
            "no": "12001006012",
            "des": "GoLive",
            "eng": "GoLive"
          },
          {
            "no": "12001006013",
            "des": "HTML",
            "eng": "HTML"
          },
          {
            "no": "12001006014",
            "des": "J2EE",
            "eng": "J2EE"
          },
          {
            "no": "12001006015",
            "des": "J2ME",
            "eng": "J2ME"
          },
          {
            "no": "12001006016",
            "des": "J2SE",
            "eng": "J2SE"
          },
          {
            "no": "12001006017",
            "des": "JavaScript",
            "eng": "JavaScript"
          },
          {
            "no": "12001006018",
            "des": "NetObjects Fusion",
            "eng": "NetObjects Fusion"
          },
          {
            "no": "12001006019",
            "des": "RoboHelp",
            "eng": "RoboHelp"
          },
          {
            "no": "12001006020",
            "des": "SGML",
            "eng": "SGML"
          },
          {
            "no": "12001006021",
            "des": "Shtml",
            "eng": "Shtml"
          },
          {
            "no": "12001006022",
            "des": "SMIL",
            "eng": "SMIL"
          },
          {
            "no": "12001006023",
            "des": "VBScript",
            "eng": "VBScript"
          },
          {
            "no": "12001006024",
            "des": "VRML",
            "eng": "VRML"
          },
          {
            "no": "12001006025",
            "des": "Web Master/Developer",
            "eng": "Web Master/Developer"
          },
          {
            "no": "12001006026",
            "des": "XHTML",
            "eng": "XHTML"
          },
          {
            "no": "12001006027",
            "des": "XML",
            "eng": "XML"
          },
          {
            "no": "12001006028",
            "des": "XML Web services",
            "eng": "XML Web services"
          },
          {
            "no": "12001006029",
            "des": "XSP",
            "eng": "XSP"
          },
          {
            "no": "12001006043",
            "des": "Django",
            "eng": "Django"
          },
          {
            "no": "12001006042",
            "des": "WebAssembly",
            "eng": "WebAssembly"
          },
          {
            "no": "12001006041",
            "des": "D3.js",
            "eng": "D3.js"
          },
          {
            "no": "12001006040",
            "des": "Sublime",
            "eng": "Sublime"
          },
          {
            "no": "12001006039",
            "des": "Redux",
            "eng": "Redux"
          },
          {
            "no": "12001006038",
            "des": "VueJS",
            "eng": "VueJS"
          },
          {
            "no": "12001006037",
            "des": "AngularJS",
            "eng": "AngularJS"
          },
          {
            "no": "12001006036",
            "des": "ReactJS",
            "eng": "ReactJS"
          },
          {
            "no": "12001006035",
            "des": "ReactNative",
            "eng": "ReactNative"
          },
          {
            "no": "12001006034",
            "des": "Node.js",
            "eng": "Node.js"
          },
          {
            "no": "12001006033",
            "des": "Sass",
            "eng": "Sass"
          },
          {
            "no": "12001006032",
            "des": "CSS",
            "eng": "CSS"
          },
          {
            "no": "12001006030",
            "des": "jQuery",
            "eng": "jQuery"
          },
          {
            "no": "12001006031",
            "des": "Flex",
            "eng": "Flex"
          },
          {
            "no": "12001006044",
            "des": "Angular",
            "eng": "Angular"
          }
        ],
        "eng": "Web Technology"
      },
      {
        "no": "12001007000",
        "des": "網路技術類",
        "n": [
          {
            "no": "12001007001",
            "des": "AdvanceLink",
            "eng": "AdvanceLink"
          },
          {
            "no": "12001007002",
            "des": "Asynch",
            "eng": "Asynch"
          },
          {
            "no": "12001007003",
            "des": "Banyan",
            "eng": "Banyan"
          },
          {
            "no": "12001007004",
            "des": "Banyan Vines",
            "eng": "Banyan Vines"
          },
          {
            "no": "12001007005",
            "des": "Bay",
            "eng": "Bay"
          },
          {
            "no": "12001007007",
            "des": "BGP",
            "eng": "BGP"
          },
          {
            "no": "12001007008",
            "des": "Bridges",
            "eng": "Bridges"
          },
          {
            "no": "12001007009",
            "des": "BroadVision",
            "eng": "BroadVision"
          },
          {
            "no": "12001007014",
            "des": "Checkpoint",
            "eng": "Checkpoint"
          },
          {
            "no": "12001007015",
            "des": "Cisco",
            "eng": "Cisco"
          },
          {
            "no": "12001007017",
            "des": "DHCP",
            "eng": "DHCP"
          },
          {
            "no": "12001007018",
            "des": "DNS",
            "eng": "DNS"
          },
          {
            "no": "12001007019",
            "des": "e-commerce",
            "eng": "e-commerce"
          },
          {
            "no": "12001007020",
            "des": "EDI",
            "eng": "EDI"
          },
          {
            "no": "12001007021",
            "des": "Ethernet",
            "eng": "Ethernet"
          },
          {
            "no": "12001007022",
            "des": "Firewall",
            "eng": "Firewall"
          },
          {
            "no": "12001007023",
            "des": "Frame Relay",
            "eng": "Frame Relay"
          },
          {
            "no": "12001007024",
            "des": "FTP",
            "eng": "FTP"
          },
          {
            "no": "12001007025",
            "des": "HP Open View",
            "eng": "HP Open View"
          },
          {
            "no": "12001007026",
            "des": "HTTP",
            "eng": "HTTP"
          },
          {
            "no": "12001007027",
            "des": "Hubs",
            "eng": "Hubs"
          },
          {
            "no": "12001007028",
            "des": "Hubs/ Routers",
            "eng": "Hubs/ Routers"
          },
          {
            "no": "12001007029",
            "des": "IDS",
            "eng": "IDS"
          },
          {
            "no": "12001007030",
            "des": "IIS",
            "eng": "IIS"
          },
          {
            "no": "12001007031",
            "des": "Intrusion",
            "eng": "Intrusion"
          },
          {
            "no": "12001007032",
            "des": "iptables",
            "eng": "iptables"
          },
          {
            "no": "12001007033",
            "des": "ISAM",
            "eng": "ISAM"
          },
          {
            "no": "12001007034",
            "des": "ISAPI",
            "eng": "ISAPI"
          },
          {
            "no": "12001007035",
            "des": "ISDN",
            "eng": "ISDN"
          },
          {
            "no": "12001007036",
            "des": "ISIS",
            "eng": "ISIS"
          },
          {
            "no": "12001007037",
            "des": "Juniper",
            "eng": "Juniper"
          },
          {
            "no": "12001007038",
            "des": "LAN",
            "eng": "LAN"
          },
          {
            "no": "12001007039",
            "des": "LanManager",
            "eng": "LanManager"
          },
          {
            "no": "12001007040",
            "des": "LanServer",
            "eng": "LanServer"
          },
          {
            "no": "12001007041",
            "des": "Lantastic",
            "eng": "Lantastic"
          },
          {
            "no": "12001007042",
            "des": "Lap Link",
            "eng": "Lap Link"
          },
          {
            "no": "12001007043",
            "des": "LDAP",
            "eng": "LDAP"
          },
          {
            "no": "12001007044",
            "des": "Lease Lines",
            "eng": "Lease Lines"
          },
          {
            "no": "12001007045",
            "des": "Load Balancing",
            "eng": "Load Balancing"
          },
          {
            "no": "12001007046",
            "des": "MOM",
            "eng": "MOM"
          },
          {
            "no": "12001007047",
            "des": "MPLS",
            "eng": "MPLS"
          },
          {
            "no": "12001007048",
            "des": "Multinet",
            "eng": "Multinet"
          },
          {
            "no": "12001007049",
            "des": "MVX",
            "eng": "MVX"
          },
          {
            "no": "12001007050",
            "des": "NatMaster",
            "eng": "NatMaster"
          },
          {
            "no": "12001007051",
            "des": "Netbios",
            "eng": "Netbios"
          },
          {
            "no": "12001007052",
            "des": "NetMaster",
            "eng": "NetMaster"
          },
          {
            "no": "12001007053",
            "des": "NetWare",
            "eng": "NetWare"
          },
          {
            "no": "12001007054",
            "des": "Network Cards",
            "eng": "Network Cards"
          },
          {
            "no": "12001007055",
            "des": "Optical Fibre",
            "eng": "Optical Fibre"
          },
          {
            "no": "12001007056",
            "des": "OSPF",
            "eng": "OSPF"
          },
          {
            "no": "12001007057",
            "des": "Pathworks",
            "eng": "Pathworks"
          },
          {
            "no": "12001007058",
            "des": "PPPoE",
            "eng": "PPPoE"
          },
          {
            "no": "12001007059",
            "des": "Reflection",
            "eng": "Reflection"
          },
          {
            "no": "12001007060",
            "des": "RIP",
            "eng": "RIP"
          },
          {
            "no": "12001007061",
            "des": "Routers",
            "eng": "Routers"
          },
          {
            "no": "12001007062",
            "des": "SAN",
            "eng": "SAN"
          },
          {
            "no": "12001007063",
            "des": "Security",
            "eng": "Security"
          },
          {
            "no": "12001007064",
            "des": "SNA",
            "eng": "SNA"
          },
          {
            "no": "12001007065",
            "des": "Sniffer",
            "eng": "Sniffer"
          },
          {
            "no": "12001007066",
            "des": "SOAP",
            "eng": "SOAP"
          },
          {
            "no": "12001007067",
            "des": "Socket",
            "eng": "Socket"
          },
          {
            "no": "12001007068",
            "des": "Sonet",
            "eng": "Sonet"
          },
          {
            "no": "12001007069",
            "des": "Squid",
            "eng": "Squid"
          },
          {
            "no": "12001007070",
            "des": "ssh",
            "eng": "ssh"
          },
          {
            "no": "12001007071",
            "des": "TCP/IP",
            "eng": "TCP/IP"
          },
          {
            "no": "12001007072",
            "des": "TcpDump",
            "eng": "TcpDump"
          },
          {
            "no": "12001007073",
            "des": "UDP",
            "eng": "UDP"
          },
          {
            "no": "12001007074",
            "des": "VLAN",
            "eng": "VLAN"
          },
          {
            "no": "12001007075",
            "des": "VPN",
            "eng": "VPN"
          },
          {
            "no": "12001007076",
            "des": "WAN",
            "eng": "WAN"
          },
          {
            "no": "12001007077",
            "des": "WAP",
            "eng": "WAP"
          },
          {
            "no": "12001007078",
            "des": "X.25",
            "eng": "X.25"
          },
          {
            "no": "12001007079",
            "des": "X400",
            "eng": "X400"
          }
        ],
        "eng": "Network Technology"
      },
      {
        "no": "12001008000",
        "des": "辦公室應用類",
        "n": [
          {
            "no": "12001008001",
            "des": "Adobe Acrobat",
            "eng": "Adobe Acrobat"
          },
          {
            "no": "12001008002",
            "des": "Communicator",
            "eng": "Communicator"
          },
          {
            "no": "12001008003",
            "des": "Excel",
            "eng": "Excel"
          },
          {
            "no": "12001008004",
            "des": "ForeHelp",
            "eng": "ForeHelp"
          },
          {
            "no": "12001008005",
            "des": "Ghost",
            "eng": "Ghost"
          },
          {
            "no": "12001008006",
            "des": "Internet Explorer",
            "eng": "Internet Explorer"
          },
          {
            "no": "12001008007",
            "des": "Lotus 123",
            "eng": "Lotus 123"
          },
          {
            "no": "12001008008",
            "des": "Netscape Communication",
            "eng": "Netscape Communication"
          },
          {
            "no": "12001008009",
            "des": "OneNote",
            "eng": "OneNote"
          },
          {
            "no": "12001008010",
            "des": "Oracle Financials",
            "eng": "Oracle Financials"
          },
          {
            "no": "12001008011",
            "des": "Outlook",
            "eng": "Outlook"
          },
          {
            "no": "12001008012",
            "des": "PowerPoint",
            "eng": "PowerPoint"
          },
          {
            "no": "12001008013",
            "des": "Project",
            "eng": "Project"
          },
          {
            "no": "12001008014",
            "des": "Publisher",
            "eng": "Publisher"
          },
          {
            "no": "12001008015",
            "des": "Visio",
            "eng": "Visio"
          },
          {
            "no": "12001008016",
            "des": "Word",
            "eng": "Word"
          },
          {
            "no": "12001008017",
            "des": "Wordperfect",
            "eng": "Wordperfect"
          },
          {
            "no": "12001008018",
            "des": "WPS",
            "eng": "WPS"
          },
          {
            "no": "12001008029",
            "des": "Xmind",
            "eng": "Xmind"
          },
          {
            "no": "12001008028",
            "des": "OmniGraffle",
            "eng": "OmniGraffle"
          },
          {
            "no": "12001008027",
            "des": "Mindnode",
            "eng": "Mindnode"
          },
          {
            "no": "12001008019",
            "des": "LibreOffice Writer",
            "eng": "LibreOffice Writer"
          },
          {
            "no": "12001008020",
            "des": "Calc",
            "eng": "Calc"
          },
          {
            "no": "12001008021",
            "des": "Impress",
            "eng": "Impress"
          },
          {
            "no": "12001008022",
            "des": "Base",
            "eng": "Base"
          },
          {
            "no": "12001008023",
            "des": "Math",
            "eng": "Math"
          },
          {
            "no": "12001008024",
            "des": "Draw",
            "eng": "Draw"
          },
          {
            "no": "12001008025",
            "des": "Dia",
            "eng": "Dia"
          },
          {
            "no": "12001008026",
            "des": "Planner",
            "eng": "Planner"
          }
        ],
        "eng": "Office Applications"
      },
      {
        "no": "12001009000",
        "des": "排版軟體類",
        "n": [
          {
            "no": "12001009001",
            "des": "FrameMaker",
            "eng": "FrameMaker"
          },
          {
            "no": "12001009002",
            "des": "Adobe InDesign",
            "eng": "Adobe InDesign"
          },
          {
            "no": "12001009003",
            "des": "Pagemaker",
            "eng": "Pagemaker"
          },
          {
            "no": "12001009004",
            "des": "QuarkXPress",
            "eng": "QuarkXPress"
          },
          {
            "no": "12001009005",
            "des": "北大方正",
            "eng": "北大方正"
          },
          {
            "no": "12001009006",
            "des": "Scribus",
            "eng": "Scribus"
          }
        ],
        "eng": "Typesetting Software"
      },
      {
        "no": "12001010000",
        "des": "中╱英文打字",
        "n": [
          {
            "no": "12001010001",
            "des": "中文打字20以下",
            "eng": "Chinese Typing below 20"
          },
          {
            "no": "12001010002",
            "des": "中文打字20~50",
            "eng": "Chinese Typing 20~50"
          },
          {
            "no": "12001010003",
            "des": "中文打字50~75",
            "eng": "Chinese Typing 50~75"
          },
          {
            "no": "12001010004",
            "des": "中文打字75~100",
            "eng": "Chinese Typing 75~100"
          },
          {
            "no": "12001010005",
            "des": "中文打字100~125",
            "eng": "Chinese Typing 100~125"
          },
          {
            "no": "12001010006",
            "des": "中文打字125~150",
            "eng": "Chinese Typing 125~150"
          },
          {
            "no": "12001010007",
            "des": "中文打字150以上",
            "eng": "Chinese Typing above 150"
          },
          {
            "no": "12001010008",
            "des": "英文打字20以下",
            "eng": "English Typing below 20"
          },
          {
            "no": "12001010009",
            "des": "英文打字20~50",
            "eng": "English Typing 20~50"
          },
          {
            "no": "12001010010",
            "des": "英文打字50~75",
            "eng": "English Typing 50~75"
          },
          {
            "no": "12001010011",
            "des": "英文打字75~100",
            "eng": "English Typing 75~100"
          },
          {
            "no": "12001010012",
            "des": "英文打字100~125",
            "eng": "English Typing 100~125"
          },
          {
            "no": "12001010013",
            "des": "英文打字125~150",
            "eng": "English Typing 125~150"
          },
          {
            "no": "12001010014",
            "des": "英文打字150以上",
            "eng": "English Typing above 150"
          }
        ],
        "eng": "Chinese/English Typing"
      },
      {
        "no": "12001011000",
        "des": "數據管理類",
        "n": [
          {
            "no": "12001011007",
            "des": "Google Trend",
            "eng": "Google Trend"
          },
          {
            "no": "12001011006",
            "des": "Moz",
            "eng": "Moz"
          },
          {
            "no": "12001011005",
            "des": "Screaming Frog SEO Spider",
            "eng": "Screaming Frog SEO Spider"
          },
          {
            "no": "12001011004",
            "des": "Google Display Network",
            "eng": "Google Display Network"
          },
          {
            "no": "12001011001",
            "des": "Tableau",
            "eng": "Tableau"
          },
          {
            "no": "12001011003",
            "des": "Google Tag Manager",
            "eng": "Google Tag Manager"
          },
          {
            "no": "12001011002",
            "des": "Google Analytics",
            "eng": "Google Analytics"
          },
          {
            "no": "12001011008",
            "des": "Alexa",
            "eng": "Alexa"
          },
          {
            "no": "12001011009",
            "des": "SimilarWeb",
            "eng": "SimilarWeb"
          },
          {
            "no": "12001011010",
            "des": "Google Data Studio",
            "eng": "Google Data Studio"
          },
          {
            "no": "12001011011",
            "des": "Firebase",
            "eng": "Firebase"
          },
          {
            "no": "12001011012",
            "des": "Power BI​",
            "eng": "Power BI​"
          }
        ],
        "eng": "Data Management"
      }
    ],
    "eng": "Computer/Network"
  },
  {
    "no": "12002000000",
    "des": "設計╱美工類",
    "n": [
      {
        "no": "12002001000",
        "des": "多媒體設計類",
        "n": [
          {
            "no": "12002001001",
            "des": "3ds Max",
            "eng": "3ds Max"
          },
          {
            "no": "12002001002",
            "des": "After Effects",
            "eng": "After Effects"
          },
          {
            "no": "12002001003",
            "des": "Alias",
            "eng": "Alias"
          },
          {
            "no": "12002001004",
            "des": "Alias studio",
            "eng": "Alias studio"
          },
          {
            "no": "12002001005",
            "des": "Authorware",
            "eng": "Authorware"
          },
          {
            "no": "12002001006",
            "des": "CADAM",
            "eng": "CADAM"
          },
          {
            "no": "12002001007",
            "des": "Cakewalk",
            "eng": "Cakewalk"
          },
          {
            "no": "12002001008",
            "des": "Dimensions 3D",
            "eng": "Dimensions 3D"
          },
          {
            "no": "12002001009",
            "des": "Director",
            "eng": "Director"
          },
          {
            "no": "12002001010",
            "des": "Flash",
            "eng": "Flash"
          },
          {
            "no": "12002001011",
            "des": "Freehand",
            "eng": "Freehand"
          },
          {
            "no": "12002001012",
            "des": "Games",
            "eng": "Games"
          },
          {
            "no": "12002001013",
            "des": "Graphics",
            "eng": "Graphics"
          },
          {
            "no": "12002001014",
            "des": "HomeSite",
            "eng": "HomeSite"
          },
          {
            "no": "12002001015",
            "des": "IconWorkshop",
            "eng": "IconWorkshop"
          },
          {
            "no": "12002001016",
            "des": "Image/Imaging",
            "eng": "Image/Imaging"
          },
          {
            "no": "12002001017",
            "des": "Lightwave",
            "eng": "Lightwave"
          },
          {
            "no": "12002001018",
            "des": "Macromedia Director",
            "eng": "Macromedia Director"
          },
          {
            "no": "12002001019",
            "des": "MAYA",
            "eng": "MAYA"
          },
          {
            "no": "12002001020",
            "des": "Microsoft Photo Editor",
            "eng": "Microsoft Photo Editor"
          },
          {
            "no": "12002001021",
            "des": "Motion Builder",
            "eng": "Motion Builder"
          },
          {
            "no": "12002001022",
            "des": "Multimedia Builder",
            "eng": "Multimedia Builder"
          },
          {
            "no": "12002001023",
            "des": "Painter",
            "eng": "Painter"
          },
          {
            "no": "12002001024",
            "des": "Premiere",
            "eng": "Premiere"
          },
          {
            "no": "12002001025",
            "des": "Quark Express",
            "eng": "Quark Express"
          },
          {
            "no": "12002001026",
            "des": "Rhino",
            "eng": "Rhino"
          },
          {
            "no": "12002001027",
            "des": "Shockwave",
            "eng": "Shockwave"
          },
          {
            "no": "12002001028",
            "des": "Softimage",
            "eng": "Softimage"
          },
          {
            "no": "12002001029",
            "des": "Strata",
            "eng": "Strata"
          },
          {
            "no": "12002001030",
            "des": "Streamline",
            "eng": "Streamline"
          },
          {
            "no": "12002001031",
            "des": "Vegas",
            "eng": "Vegas"
          },
          {
            "no": "12002001032",
            "des": "Vizact 2000",
            "eng": "Vizact 2000"
          },
          {
            "no": "12002001033",
            "des": "Shade",
            "eng": "Shade"
          },
          {
            "no": "12002001040",
            "des": "Principle",
            "eng": "Principle"
          },
          {
            "no": "12002001034",
            "des": "Cinema 4D",
            "eng": "Cinema 4D"
          },
          {
            "no": "12002001035",
            "des": "Showcase",
            "eng": "Showcase"
          },
          {
            "no": "12002001036",
            "des": "3ds Max Design",
            "eng": "3ds Max Design"
          },
          {
            "no": "12002001037",
            "des": "Unity3D",
            "eng": "Unity3D"
          },
          {
            "no": "12002001038",
            "des": "Krita",
            "eng": "Krita"
          },
          {
            "no": "12002001039",
            "des": "Blender",
            "eng": "Blender"
          },
          {
            "no": "12002001041",
            "des": "Unreal Engine",
            "eng": "Unreal Engine"
          },
          {
            "no": "12002001042",
            "des": "Lumion",
            "eng": "Lumion"
          },
          {
            "no": "12002001043",
            "des": "Adobe Animate",
            "eng": "Adobe Animate"
          }
        ],
        "eng": "Multimedia Design"
      },
      {
        "no": "12002002000",
        "des": "影像處理類",
        "n": [
          {
            "no": "12002002001",
            "des": "Adobe Photoshop",
            "eng": "Adobe Photoshop"
          },
          {
            "no": "12002002002",
            "des": "CoolDraw",
            "eng": "CoolDraw"
          },
          {
            "no": "12002002003",
            "des": "CorelDraw",
            "eng": "CorelDraw"
          },
          {
            "no": "12002002005",
            "des": "Illustrator",
            "eng": "Illustrator"
          },
          {
            "no": "12002002006",
            "des": "ImageReady",
            "eng": "ImageReady"
          },
          {
            "no": "12002002007",
            "des": "Paint Shop Pro",
            "eng": "Paint Shop Pro"
          },
          {
            "no": "12002002008",
            "des": "PhotoImpact",
            "eng": "PhotoImpact"
          },
          {
            "no": "12002002018",
            "des": "V-Ray",
            "eng": "V-Ray"
          },
          {
            "no": "12002002017",
            "des": "Sketch",
            "eng": "Sketch"
          },
          {
            "no": "12002002009",
            "des": "Final Cut ProX",
            "eng": "Final Cut ProX"
          },
          {
            "no": "12002002010",
            "des": "Key Shot",
            "eng": "Key Shot"
          },
          {
            "no": "12002002011",
            "des": "PTC Creo Elements/Direct",
            "eng": "PTC Creo Elements/Direct"
          },
          {
            "no": "12002002012",
            "des": "OpenShot",
            "eng": "OpenShot"
          },
          {
            "no": "12002002013",
            "des": "Kdenlive",
            "eng": "Kdenlive"
          },
          {
            "no": "12002002014",
            "des": "Gimp",
            "eng": "Gimp"
          },
          {
            "no": "12002002015",
            "des": "Darktable",
            "eng": "Darktable"
          },
          {
            "no": "12002002016",
            "des": "Inkscape",
            "eng": "Inkscape"
          }
        ],
        "eng": "Image Processing"
      },
      {
        "no": "12002003000",
        "des": "工程製圖類",
        "n": [
          {
            "no": "12002003001",
            "des": "AutoCAD",
            "eng": "AutoCAD"
          },
          {
            "no": "12002003002",
            "des": "AutoCad 2D",
            "eng": "AutoCad 2D"
          },
          {
            "no": "12002003003",
            "des": "AutoCad 3D",
            "eng": "AutoCad 3D"
          },
          {
            "no": "12002003004",
            "des": "Catia",
            "eng": "Catia"
          },
          {
            "no": "12002003005",
            "des": "iDeaS",
            "eng": "iDeaS"
          },
          {
            "no": "12002003007",
            "des": "LightsCape",
            "eng": "LightsCape"
          },
          {
            "no": "12002003008",
            "des": "MicroStation",
            "eng": "MicroStation"
          },
          {
            "no": "12002003009",
            "des": "Pro/E",
            "eng": "Pro/E"
          },
          {
            "no": "12002003010",
            "des": "Revit",
            "eng": "Revit"
          },
          {
            "no": "12002003011",
            "des": "Sketch up",
            "eng": "Sketch up"
          },
          {
            "no": "12002003012",
            "des": "SolidWorks",
            "eng": "SolidWorks"
          },
          {
            "no": "12002003013",
            "des": "Unigraphics",
            "eng": "Unigraphics"
          },
          {
            "no": "12002003014",
            "des": "Zbrush",
            "eng": "Zbrush"
          },
          {
            "no": "12002003015",
            "des": "Solid Edge",
            "eng": "Solid Edge"
          },
          {
            "no": "12002003016",
            "des": "Inventor",
            "eng": "Inventor"
          },
          {
            "no": "12002003017",
            "des": "DIALux",
            "eng": "DIALux"
          },
          {
            "no": "12002003018",
            "des": "Photopia",
            "eng": "Photopia"
          },
          {
            "no": "12002003019",
            "des": "ArchiCAD",
            "eng": "ArchiCAD"
          },
          {
            "no": "12002003020",
            "des": "EPLAN",
            "eng": "EPLAN"
          },
          {
            "no": "12002003021",
            "des": "SOLIDWORKS Electrical",
            "eng": "SOLIDWORKS Electrical"
          },
          {
            "no": "12002003022",
            "des": "ANSYS",
            "eng": "ANSYS"
          }
        ],
        "eng": "Engineering Drafting "
      },
      {
        "no": "12002004000",
        "des": "介面設計類",
        "n": [
          {
            "no": "12002004007",
            "des": "InVision",
            "eng": "InVision"
          },
          {
            "no": "12002004006",
            "des": "Zeplin",
            "eng": "Zeplin"
          },
          {
            "no": "12002004005",
            "des": "PowerMockup",
            "eng": "PowerMockup"
          },
          {
            "no": "12002004004",
            "des": "Origami Studio",
            "eng": "Origami Studio"
          },
          {
            "no": "12002004003",
            "des": "ProtoPie",
            "eng": "ProtoPie"
          },
          {
            "no": "12002004002",
            "des": "Framer",
            "eng": "Framer"
          },
          {
            "no": "12002004001",
            "des": "Axure RP",
            "eng": "Axure RP"
          },
          {
            "no": "12002004008",
            "des": "Adobe XD",
            "eng": "Adobe XD"
          },
          {
            "no": "12002004009",
            "des": "Figma",
            "eng": "Figma"
          }
        ],
        "eng": "Interface Design"
      }
    ],
    "eng": "Design/Artwork"
  },
  {
    "no": "12003000000",
    "des": "通訊╱資訊管理類",
    "n": [
      {
        "no": "12003001000",
        "des": "地理資訊類",
        "n": [
          {
            "no": "12003001001",
            "des": "ArcGis",
            "eng": "ArcGis"
          },
          {
            "no": "12003001002",
            "des": "ArcView",
            "eng": "ArcView"
          },
          {
            "no": "12003001003",
            "des": "GIS",
            "eng": "GIS"
          },
          {
            "no": "12003001004",
            "des": "MapBasic",
            "eng": "MapBasic"
          },
          {
            "no": "12003001005",
            "des": "MapInfo",
            "eng": "MapInfo"
          },
          {
            "no": "12003001006",
            "des": "MapGIS",
            "eng": "MapGIS"
          },
          {
            "no": "12003001007",
            "des": "MapPoint",
            "eng": "MapPoint"
          },
          {
            "no": "12003001008",
            "des": "SuperGIS",
            "eng": "SuperGIS"
          },
          {
            "no": "12003001009",
            "des": "SuperPad",
            "eng": "SuperPad"
          }
        ],
        "eng": "Geographic Information"
      },
      {
        "no": "12003002000",
        "des": "通訊傳輸類",
        "n": [
          {
            "no": "12003002001",
            "des": "3G",
            "eng": "3G"
          },
          {
            "no": "12003002002",
            "des": "ADSL",
            "eng": "ADSL"
          },
          {
            "no": "12003002003",
            "des": "AppleTalk",
            "eng": "AppleTalk"
          },
          {
            "no": "12003002004",
            "des": "Avaya",
            "eng": "Avaya"
          },
          {
            "no": "12003002005",
            "des": "Bluetooth",
            "eng": "Bluetooth"
          },
          {
            "no": "12003002006",
            "des": "CDMA",
            "eng": "CDMA"
          },
          {
            "no": "12003002007",
            "des": "CIP",
            "eng": "CIP"
          },
          {
            "no": "12003002008",
            "des": "FDDI",
            "eng": "FDDI"
          },
          {
            "no": "12003002009",
            "des": "FPT",
            "eng": "FPT"
          },
          {
            "no": "12003002010",
            "des": "GMS",
            "eng": "GMS"
          },
          {
            "no": "12003002011",
            "des": "GPRS",
            "eng": "GPRS"
          },
          {
            "no": "12003002012",
            "des": "GSM",
            "eng": "GSM"
          },
          {
            "no": "12003002013",
            "des": "MMS",
            "eng": "MMS"
          },
          {
            "no": "12003002015",
            "des": "PABX",
            "eng": "PABX"
          },
          {
            "no": "12003002016",
            "des": "PBX",
            "eng": "PBX"
          },
          {
            "no": "12003002017",
            "des": "PHS",
            "eng": "PHS"
          },
          {
            "no": "12003002018",
            "des": "PSTN",
            "eng": "PSTN"
          },
          {
            "no": "12003002019",
            "des": "Relay Gold",
            "eng": "Relay Gold"
          },
          {
            "no": "12003002020",
            "des": "RF",
            "eng": "RF"
          },
          {
            "no": "12003002021",
            "des": "RTSP",
            "eng": "RTSP"
          },
          {
            "no": "12003002022",
            "des": "SMS",
            "eng": "SMS"
          },
          {
            "no": "12003002023",
            "des": "SNMP",
            "eng": "SNMP"
          },
          {
            "no": "12003002024",
            "des": "SS7",
            "eng": "SS7"
          },
          {
            "no": "12003002025",
            "des": "Telecom",
            "eng": "Telecom"
          },
          {
            "no": "12003002026",
            "des": "USB OTG",
            "eng": "USB OTG"
          },
          {
            "no": "12003002027",
            "des": "VoIP",
            "eng": "VoIP"
          },
          {
            "no": "12003002028",
            "des": "Wimax",
            "eng": "Wimax"
          },
          {
            "no": "12003002029",
            "des": "WLAN",
            "eng": "WLAN"
          },
          {
            "no": "12003002030",
            "des": "XDSL",
            "eng": "XDSL"
          }
        ],
        "eng": "Communication Transmission"
      },
      {
        "no": "12003003000",
        "des": "測試╱安全",
        "n": [
          {
            "no": "12003003001",
            "des": "ACT",
            "eng": "ACT"
          },
          {
            "no": "12003003002",
            "des": "Astra LoadTest",
            "eng": "Astra LoadTest"
          },
          {
            "no": "12003003003",
            "des": "Astra QuickTest",
            "eng": "Astra QuickTest"
          },
          {
            "no": "12003003004",
            "des": "Attoltestware",
            "eng": "Attoltestware"
          },
          {
            "no": "12003003005",
            "des": "BounderChecker",
            "eng": "BounderChecker"
          },
          {
            "no": "12003003006",
            "des": "Bugzilla",
            "eng": "Bugzilla"
          },
          {
            "no": "12003003007",
            "des": "C++test",
            "eng": "C++test"
          },
          {
            "no": "12003003008",
            "des": "Cantata",
            "eng": "Cantata"
          },
          {
            "no": "12003003009",
            "des": "CodeTest",
            "eng": "CodeTest"
          },
          {
            "no": "12003003010",
            "des": "DevPartner Studio",
            "eng": "DevPartner Studio"
          },
          {
            "no": "12003003011",
            "des": "DriverStudio",
            "eng": "DriverStudio"
          },
          {
            "no": "12003003012",
            "des": "EcoScope",
            "eng": "EcoScope"
          },
          {
            "no": "12003003013",
            "des": "e-Test suite",
            "eng": "e-Test suite"
          },
          {
            "no": "12003003014",
            "des": "GammaRay",
            "eng": "GammaRay"
          },
          {
            "no": "12003003015",
            "des": "HttpUnit",
            "eng": "HttpUnit"
          },
          {
            "no": "12003003016",
            "des": "IPS",
            "eng": "IPS"
          },
          {
            "no": "12003003017",
            "des": "Jtest",
            "eng": "Jtest"
          },
          {
            "no": "12003003018",
            "des": "Junit",
            "eng": "Junit"
          },
          {
            "no": "12003003019",
            "des": "Link Sleuth",
            "eng": "Link Sleuth"
          },
          {
            "no": "12003003020",
            "des": "LoadRunner",
            "eng": "LoadRunner"
          },
          {
            "no": "12003003021",
            "des": "LogiScope",
            "eng": "LogiScope"
          },
          {
            "no": "12003003022",
            "des": "Macabe",
            "eng": "Macabe"
          },
          {
            "no": "12003003023",
            "des": "Mantis",
            "eng": "Mantis"
          },
          {
            "no": "12003003024",
            "des": "OpenSTA",
            "eng": "OpenSTA"
          },
          {
            "no": "12003003025",
            "des": "Optimizeit Suite",
            "eng": "Optimizeit Suite"
          },
          {
            "no": "12003003026",
            "des": "PC—lint",
            "eng": "PC—lint"
          },
          {
            "no": "12003003027",
            "des": "PureCoverage",
            "eng": "PureCoverage"
          },
          {
            "no": "12003003028",
            "des": "PVCS Tracker",
            "eng": "PVCS Tracker"
          },
          {
            "no": "12003003029",
            "des": "QADirector",
            "eng": "QADirector"
          },
          {
            "no": "12003003030",
            "des": "QALoad",
            "eng": "QALoad"
          },
          {
            "no": "12003003031",
            "des": "QARun",
            "eng": "QARun"
          },
          {
            "no": "12003003032",
            "des": "QATester",
            "eng": "QATester"
          },
          {
            "no": "12003003033",
            "des": "QTP",
            "eng": "QTP"
          },
          {
            "no": "12003003034",
            "des": "Quality Control",
            "eng": "Quality Control"
          },
          {
            "no": "12003003035",
            "des": "QuickTest Professional",
            "eng": "QuickTest Professional"
          },
          {
            "no": "12003003036",
            "des": "Rational ClearCase",
            "eng": "Rational ClearCase"
          },
          {
            "no": "12003003037",
            "des": "Rational ClearQuest",
            "eng": "Rational ClearQuest"
          },
          {
            "no": "12003003038",
            "des": "Rational Performance",
            "eng": "Rational Performance"
          },
          {
            "no": "12003003039",
            "des": "Rational PureCoverage",
            "eng": "Rational PureCoverage"
          },
          {
            "no": "12003003040",
            "des": "Rational Purify",
            "eng": "Rational Purify"
          },
          {
            "no": "12003003041",
            "des": "Rational Quantify",
            "eng": "Rational Quantify"
          },
          {
            "no": "12003003042",
            "des": "Rational Robot",
            "eng": "Rational Robot"
          },
          {
            "no": "12003003043",
            "des": "Rational Rose",
            "eng": "Rational Rose"
          },
          {
            "no": "12003003044",
            "des": "Rational SQA load",
            "eng": "Rational SQA load"
          },
          {
            "no": "12003003045",
            "des": "Rational TeamTest",
            "eng": "Rational TeamTest"
          },
          {
            "no": "12003003046",
            "des": "Rational Test RealTime",
            "eng": "Rational Test RealTime"
          },
          {
            "no": "12003003047",
            "des": "Rational TestFactory",
            "eng": "Rational TestFactory"
          },
          {
            "no": "12003003048",
            "des": "Rational TestManager",
            "eng": "Rational TestManager"
          },
          {
            "no": "12003003049",
            "des": "Robot",
            "eng": "Robot"
          },
          {
            "no": "12003003050",
            "des": "SilkPerformer",
            "eng": "SilkPerformer"
          },
          {
            "no": "12003003051",
            "des": "SilkPlan Pro",
            "eng": "SilkPlan Pro"
          },
          {
            "no": "12003003052",
            "des": "SilkRadar",
            "eng": "SilkRadar"
          },
          {
            "no": "12003003053",
            "des": "SilkTest",
            "eng": "SilkTest"
          },
          {
            "no": "12003003054",
            "des": "SilkVision",
            "eng": "SilkVision"
          },
          {
            "no": "12003003055",
            "des": "Source Safe/Visual Source Safe",
            "eng": "Source Safe/Visual Source Safe"
          },
          {
            "no": "12003003056",
            "des": "Test Scripts",
            "eng": "Test Scripts"
          },
          {
            "no": "12003003057",
            "des": "TestBed",
            "eng": "TestBed"
          },
          {
            "no": "12003003058",
            "des": "TestBytes",
            "eng": "TestBytes"
          },
          {
            "no": "12003003059",
            "des": "TestDirector",
            "eng": "TestDirector"
          },
          {
            "no": "12003003060",
            "des": "TestManager",
            "eng": "TestManager"
          },
          {
            "no": "12003003061",
            "des": "TestPartner",
            "eng": "TestPartner"
          },
          {
            "no": "12003003062",
            "des": "TestQuestPro",
            "eng": "TestQuestPro"
          },
          {
            "no": "12003003063",
            "des": "TestTrack Pro",
            "eng": "TestTrack Pro"
          },
          {
            "no": "12003003064",
            "des": "TrackRecord",
            "eng": "TrackRecord"
          },
          {
            "no": "12003003065",
            "des": "TrueCoverage",
            "eng": "TrueCoverage"
          },
          {
            "no": "12003003066",
            "des": "TrueTime",
            "eng": "TrueTime"
          },
          {
            "no": "12003003067",
            "des": "Version Control",
            "eng": "Version Control"
          },
          {
            "no": "12003003068",
            "des": "VisualTest",
            "eng": "VisualTest"
          },
          {
            "no": "12003003069",
            "des": "WAS",
            "eng": "WAS"
          },
          {
            "no": "12003003070",
            "des": "WebFT",
            "eng": "WebFT"
          },
          {
            "no": "12003003071",
            "des": "WebLoad",
            "eng": "WebLoad"
          },
          {
            "no": "12003003072",
            "des": "WinRunner",
            "eng": "WinRunner"
          },
          {
            "no": "12003003073",
            "des": "XDE Tester",
            "eng": "XDE Tester"
          },
          {
            "no": "12003003074",
            "des": "Xrunner",
            "eng": "Xrunner"
          }
        ],
        "eng": "Testing / Security"
      },
      {
        "no": "12003004000",
        "des": "資料倉儲",
        "n": [
          {
            "no": "12003004001",
            "des": "CASE",
            "eng": "CASE"
          },
          {
            "no": "12003004002",
            "des": "Data Architect",
            "eng": "Data Architect"
          },
          {
            "no": "12003004003",
            "des": "Data Marts",
            "eng": "Data Marts"
          },
          {
            "no": "12003004004",
            "des": "Hive",
            "eng": "Hive"
          }
        ],
        "eng": "Data Warehousing"
      },
      {
        "no": "12003005000",
        "des": "資訊管理類",
        "n": [
          {
            "no": "12003005001",
            "des": "CleverPath",
            "eng": "CleverPath"
          },
          {
            "no": "12003005002",
            "des": "CVS",
            "eng": "CVS"
          },
          {
            "no": "12003005003",
            "des": "MicroStrategy",
            "eng": "MicroStrategy"
          },
          {
            "no": "12003005004",
            "des": "PeopleSoft",
            "eng": "PeopleSoft"
          },
          {
            "no": "12003005005",
            "des": "RACF",
            "eng": "RACF"
          },
          {
            "no": "12003005006",
            "des": "Remedy",
            "eng": "Remedy"
          },
          {
            "no": "12003005007",
            "des": "SAN/NAS",
            "eng": "SAN/NAS"
          },
          {
            "no": "12003005009",
            "des": "SAS",
            "eng": "SAS"
          },
          {
            "no": "12003005010",
            "des": "Siebel",
            "eng": "Siebel"
          },
          {
            "no": "12003005011",
            "des": "SPSS",
            "eng": "SPSS"
          },
          {
            "no": "12003005012",
            "des": "Teradata",
            "eng": "Teradata"
          },
          {
            "no": "12003005013",
            "des": "TIBCO",
            "eng": "TIBCO"
          },
          {
            "no": "12003005014",
            "des": "Tivoli",
            "eng": "Tivoli"
          },
          {
            "no": "12003005015",
            "des": "VERITAS",
            "eng": "VERITAS"
          },
          {
            "no": "12003005017",
            "des": "金旭飯店管理系統",
            "eng": "金旭飯店管理系統"
          },
          {
            "no": "12003005016",
            "des": "德安飯店餐飲管理系統",
            "eng": "德安飯店餐飲管理系統"
          }
        ],
        "eng": "Information Management"
      }
    ],
    "eng": "Communication/Information Management"
  },
  {
    "no": "12004000000",
    "des": "硬體開發類",
    "n": [
      {
        "no": "12004001000",
        "des": "設計類",
        "n": [
          {
            "no": "12004001001",
            "des": "ADC",
            "eng": "ADC"
          },
          {
            "no": "12004001002",
            "des": "ARM",
            "eng": "ARM"
          },
          {
            "no": "12004001003",
            "des": "ASIC",
            "eng": "ASIC"
          },
          {
            "no": "12004001004",
            "des": "Assembly",
            "eng": "Assembly"
          },
          {
            "no": "12004001005",
            "des": "AVL",
            "eng": "AVL"
          },
          {
            "no": "12004001006",
            "des": "CA",
            "eng": "CA"
          },
          {
            "no": "12004001007",
            "des": "Cadence Allegro",
            "eng": "Cadence Allegro"
          },
          {
            "no": "12004001008",
            "des": "Chipware",
            "eng": "Chipware"
          },
          {
            "no": "12004001009",
            "des": "Circuit Design",
            "eng": "Circuit Design"
          },
          {
            "no": "12004001010",
            "des": "CPLD",
            "eng": "CPLD"
          },
          {
            "no": "12004001011",
            "des": "DEC/VAX",
            "eng": "DEC/VAX"
          },
          {
            "no": "12004001012",
            "des": "Drivers",
            "eng": "Drivers"
          },
          {
            "no": "12004001013",
            "des": "DSP",
            "eng": "DSP"
          },
          {
            "no": "12004001014",
            "des": "EDA",
            "eng": "EDA"
          },
          {
            "no": "12004001015",
            "des": "EMC/EMI",
            "eng": "EMC/EMI"
          },
          {
            "no": "12004001016",
            "des": "Firmware",
            "eng": "Firmware"
          },
          {
            "no": "12004001017",
            "des": "FPGA",
            "eng": "FPGA"
          },
          {
            "no": "12004001018",
            "des": "GEAC",
            "eng": "GEAC"
          },
          {
            "no": "12004001019",
            "des": "LCOS",
            "eng": "LCOS"
          },
          {
            "no": "12004001020",
            "des": "MicroFocus",
            "eng": "MicroFocus"
          },
          {
            "no": "12004001021",
            "des": "MIDI",
            "eng": "MIDI"
          },
          {
            "no": "12004001022",
            "des": "Mobile Network",
            "eng": "Mobile Network"
          },
          {
            "no": "12004001023",
            "des": "MVS",
            "eng": "MVS"
          },
          {
            "no": "12004001024",
            "des": "OrCAD",
            "eng": "OrCAD"
          },
          {
            "no": "12004001025",
            "des": "PADS",
            "eng": "PADS"
          },
          {
            "no": "12004001026",
            "des": "P-CAD",
            "eng": "P-CAD"
          },
          {
            "no": "12004001027",
            "des": "PLC",
            "eng": "PLC"
          },
          {
            "no": "12004001028",
            "des": "PLD",
            "eng": "PLD"
          },
          {
            "no": "12004001029",
            "des": "PowerLogic",
            "eng": "PowerLogic"
          },
          {
            "no": "12004001030",
            "des": "PowerPCB",
            "eng": "PowerPCB"
          },
          {
            "no": "12004001031",
            "des": "Protel",
            "eng": "Protel"
          },
          {
            "no": "12004001032",
            "des": "RTL",
            "eng": "RTL"
          },
          {
            "no": "12004001033",
            "des": "SPICE",
            "eng": "SPICE"
          },
          {
            "no": "12004001034",
            "des": "Synopsys",
            "eng": "Synopsys"
          },
          {
            "no": "12004001035",
            "des": "Tape Operator",
            "eng": "Tape Operator"
          },
          {
            "no": "12004001036",
            "des": "USB技術",
            "eng": "USB技術"
          },
          {
            "no": "12004001037",
            "des": "Verilog",
            "eng": "Verilog"
          },
          {
            "no": "12004001038",
            "des": "VHDL",
            "eng": "VHDL"
          },
          {
            "no": "12004001039",
            "des": "VM",
            "eng": "VM"
          }
        ],
        "eng": "Design"
      },
      {
        "no": "12004002000",
        "des": "產品類",
        "n": [
          {
            "no": "12004002001",
            "des": "DAB數位音頻廣播",
            "eng": "Digital Audio Broadcasting"
          },
          {
            "no": "12004002002",
            "des": "DSC數位攝像機",
            "eng": "Digital Still Camera"
          },
          {
            "no": "12004002003",
            "des": "DVB數位視頻廣播",
            "eng": "Digital Video Broadcasting"
          },
          {
            "no": "12004002004",
            "des": "DVR數位視頻錄像",
            "eng": "Digital Video Recorder"
          },
          {
            "no": "12004002005",
            "des": "GPS全球定位系統",
            "eng": "GPS"
          },
          {
            "no": "12004002006",
            "des": "Mobile phone",
            "eng": "Mobile phone"
          },
          {
            "no": "12004002007",
            "des": "PDA/Handhelds",
            "eng": "PDA/Handhelds"
          },
          {
            "no": "12004002008",
            "des": "WVR無線視頻錄影",
            "eng": "Wireless Video Recorder"
          }
        ],
        "eng": "Product "
      }
    ],
    "eng": "Hardware Development"
  },
  {
    "no": "12005000000",
    "des": "生產製程類",
    "n": [
      {
        "no": "12005001000",
        "des": "品保類",
        "n": [
          {
            "no": "12005001001",
            "des": "6 Sigma",
            "eng": "6 Sigma"
          },
          {
            "no": "12005001002",
            "des": "BS7799",
            "eng": "BS7799"
          },
          {
            "no": "12005001003",
            "des": "HACCP",
            "eng": "HACCP"
          },
          {
            "no": "12005001004",
            "des": "ISO 14000",
            "eng": "ISO 14000"
          },
          {
            "no": "12005001005",
            "des": "ISO 9000",
            "eng": "ISO 9000"
          },
          {
            "no": "12005001006",
            "des": "ISO 45001",
            "eng": "ISO 45001"
          },
          {
            "no": "12005001007",
            "des": "QS9000",
            "eng": "QS9000"
          },
          {
            "no": "12005001008",
            "des": "RoHS",
            "eng": "RoHS"
          },
          {
            "no": "12005001009",
            "des": "SPC",
            "eng": "SPC"
          },
          {
            "no": "12005001010",
            "des": "TS16949",
            "eng": "TS16949"
          },
          {
            "no": "12005001011",
            "des": "ISO 22000食品安全管理系統",
            "eng": "ISO 22000"
          },
          {
            "no": "12005001012",
            "des": "IATF16949",
            "eng": "IATF16949"
          }
        ],
        "eng": "Quality Assurance"
      },
      {
        "no": "12005002000",
        "des": "製程類",
        "n": [
          {
            "no": "12005002001",
            "des": "CAM",
            "eng": "CAM"
          },
          {
            "no": "12005002002",
            "des": "CIM",
            "eng": "CIM"
          },
          {
            "no": "12005002003",
            "des": "CMP化學機械研磨技術",
            "eng": "Chemical-Mechanical Polishing"
          },
          {
            "no": "12005002004",
            "des": "CVD化學氣象沉積",
            "eng": "Chemical vapor deposition"
          },
          {
            "no": "12005002005",
            "des": "Flip Chip構裝製程",
            "eng": "Flip Chip"
          },
          {
            "no": "12005002006",
            "des": "IE工業工程",
            "eng": "Industrial Engineering"
          },
          {
            "no": "12005002007",
            "des": "MES",
            "eng": "MES"
          },
          {
            "no": "12005002008",
            "des": "PCBA",
            "eng": "PCBA"
          },
          {
            "no": "12005002009",
            "des": "SMT",
            "eng": "SMT"
          }
        ],
        "eng": "Process "
      }
    ],
    "eng": "Production Process"
  },
  {
    "no": "12006000000",
    "des": "管理類",
    "n": [
      {
        "no": "12006001000",
        "des": "ERP",
        "n": [
          {
            "no": "12006001001",
            "des": "ACL",
            "eng": "ACL"
          },
          {
            "no": "12006001002",
            "des": "Baan",
            "eng": "Baan"
          },
          {
            "no": "12006001003",
            "des": "Great Plains",
            "eng": "Great Plains"
          },
          {
            "no": "12006001004",
            "des": "Lawson",
            "eng": "Lawson"
          },
          {
            "no": "12006001005",
            "des": "MRP",
            "eng": "MRP"
          },
          {
            "no": "12006001006",
            "des": "Multi Term",
            "eng": "Multi Term"
          },
          {
            "no": "12006001007",
            "des": "Navision",
            "eng": "Navision"
          },
          {
            "no": "12006001008",
            "des": "Oracle ERP",
            "eng": "Oracle ERP"
          },
          {
            "no": "12006001009",
            "des": "QAD－MFG/PRO",
            "eng": "QAD－MFG/PRO"
          },
          {
            "no": "12006001010",
            "des": "SSA-BPCS",
            "eng": "SSA-BPCS"
          },
          {
            "no": "12006001012",
            "des": "用友U8",
            "eng": "U8"
          },
          {
            "no": "12006001013",
            "des": "金蝶",
            "eng": "Kindee"
          },
          {
            "no": "12006001014",
            "des": "浪潮",
            "eng": "Inspur"
          },
          {
            "no": "12006001020",
            "des": "鼎基 ERP",
            "eng": "Dingey ERP"
          },
          {
            "no": "12006001015",
            "des": "SAP",
            "eng": "SAP"
          },
          {
            "no": "12006001016",
            "des": "鼎新",
            "eng": "Digiwin"
          },
          {
            "no": "12006001017",
            "des": "正航",
            "eng": "CHING HANG INFORMATION"
          },
          {
            "no": "12006001018",
            "des": "天心資訊",
            "eng": "Attention System"
          },
          {
            "no": "12006001019",
            "des": "文中系統",
            "eng": "Winton system"
          },
          {
            "no": "12006001021",
            "des": "Microsoft Dynamics AX",
            "eng": "Microsoft Dynamics AX"
          },
          {
            "no": "12006001022",
            "des": "鉅茂",
            "eng": "DATAWIN"
          },
          {
            "no": "12006001023",
            "des": "德安資訊ERP",
            "eng": "Athena Information ERP"
          },
          {
            "no": "12006001024",
            "des": "上華ERP系統",
            "eng": "Amigosoft ERP"
          }
        ],
        "eng": "ERP"
      }
    ],
    "eng": "Management "
  }
];
export const toolList = [
  ...toolJson,
];

