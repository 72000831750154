var certificateJson = [
  {
    "no": "4001000000",
    "des": "語言類",
    "n": [
      {
        "no": "4001001000",
        "des": "英語相關證照",
        "n": [
          {
            "no": "4001001017",
            "des": "FLPT(English)",
            "eng": "FLPT(English)"
          },
          {
            "no": "4001001011",
            "des": "GEPT初級",
            "eng": "basic GEPT"
          },
          {
            "no": "4001001012",
            "des": "GEPT中級",
            "eng": "Intermediate GEPT"
          },
          {
            "no": "4001001013",
            "des": "GEPT中高級",
            "eng": "High intermediate GEPT"
          },
          {
            "no": "4001001014",
            "des": "GEPT高級",
            "eng": "Advanced GEPT"
          },
          {
            "no": "4001001015",
            "des": "GEPT優級",
            "eng": "Superior GEPT"
          },
          {
            "no": "4001001003",
            "des": "GMAT",
            "eng": "GMAT"
          },
          {
            "no": "4001001001",
            "des": "GRE",
            "eng": "GRE"
          },
          {
            "no": "4001001004",
            "des": "IELTS",
            "eng": "IELTS"
          },
          {
            "no": "4001001008",
            "des": "TESL",
            "eng": "TESL"
          },
          {
            "no": "4001001007",
            "des": "TESOL",
            "eng": "TESOL"
          },
          {
            "no": "4001001002",
            "des": "TOEFL",
            "eng": "TOEFL"
          },
          {
            "no": "4001001005",
            "des": "TOEIC (多益測驗)",
            "eng": "TOEIC"
          },
          {
            "no": "4001001010",
            "des": "TOEIC Bridge",
            "eng": "TOEIC Bridge"
          },
          {
            "no": "4001001016",
            "des": "TOPEC",
            "eng": "TOPEC"
          },
          {
            "no": "4001001018",
            "des": "BULATS(劍橋博思國際職場英檢)",
            "eng": "BULATS"
          },
          {
            "no": "4001001019",
            "des": "Cambridge English Exams(劍橋國際英語認證)",
            "eng": "Cambridge English Exams"
          },
          {
            "no": "4001001020",
            "des": "TKT(劍橋英語教師認證)",
            "eng": "TKT"
          },
          {
            "no": "4001001021",
            "des": "BEC中級(劍橋商務英語認證)",
            "eng": "BEC Preliminary"
          },
          {
            "no": "4001001022",
            "des": "BEC中高級(劍橋商務英語認證)",
            "eng": "BEC Vantage "
          },
          {
            "no": "4001001023",
            "des": "BEC高級(劍橋商務英語認證)",
            "eng": "BEC Higher "
          },
          {
            "no": "4001001024",
            "des": "Anglia高級英語測驗(安吉利國際英語評鑑)",
            "eng": "Anglia Exams Advanced Level (CEFR B2)"
          },
          {
            "no": "4001001025",
            "des": "Anglia商業英文(安吉利國際英語評鑑)",
            "eng": "Anglia Business English Exams(Chichester College of England)"
          },
          {
            "no": "4001001026",
            "des": "CITE國際教師英語認證(安吉利國際英語評鑑)",
            "eng": "CITE Exams for Teachers of English"
          },
          {
            "no": "4001001027",
            "des": "TOEIC Speaking Test (多益口說測驗)",
            "eng": "TOEIC Speaking Test"
          },
          {
            "no": "4001001028",
            "des": "TOEIC Speaking and Writing TEST (多益說寫測驗)",
            "eng": "TOEIC Speaking and Writing TEST"
          },
          {
            "no": "4001001029",
            "des": "GET",
            "eng": "GET"
          },
          {
            "no": "4001001030",
            "des": "NETPAW入門級",
            "eng": "NETPAW A1-1"
          },
          {
            "no": "4001001031",
            "des": "NETPAW基礎級",
            "eng": "NETPAW A1-1"
          },
          {
            "no": "4001001032",
            "des": "NETPAW初級",
            "eng": "NETPAW A2"
          },
          {
            "no": "4001001033",
            "des": "NETPAW中級",
            "eng": "NETPAW B1"
          },
          {
            "no": "4001001034",
            "des": "NETPAW中高級",
            "eng": "NETPAW B2"
          },
          {
            "no": "4001001035",
            "des": "NETPAW高級",
            "eng": "NETPAW C1"
          },
          {
            "no": "4001001036",
            "des": "NETPAW專業級",
            "eng": "NETPAW C2"
          },
          {
            "no": "4001001037",
            "des": "G-TELP  Level 1",
            "eng": "G-TELP Level 1"
          },
          {
            "no": "4001001038",
            "des": "G-TELP  Level 2",
            "eng": "G-TELP Level 2"
          },
          {
            "no": "4001001039",
            "des": "G-TELP  Level 3",
            "eng": "G-TELP Level 3"
          },
          {
            "no": "4001001040",
            "des": "G-TELP  Level 4",
            "eng": "G-TELP Level 4"
          },
          {
            "no": "4001001041",
            "des": "G-TELP  Level 5",
            "eng": "G-TELP Level 5"
          },
          {
            "no": "4001001042",
            "des": "PVQC專業英文詞彙能力國際認證",
            "eng": "Professional Vocabulary Quotient Credential"
          },
          {
            "no": "4001001043",
            "des": "明逸生活英語認證",
            "eng": "MiNGYI Daily English"
          },
          {
            "no": "4001001044",
            "des": "CELT-P(兒童英語教學認證)",
            "eng": "CELT-P"
          },
          {
            "no": "4001001045",
            "des": "CELT-S(中學英語教學認證)",
            "eng": "CELT-S"
          },
          {
            "no": "4001001046",
            "des": "PTE Academic",
            "eng": "PTE Academic"
          },
          {
            "no": "4001001047",
            "des": "明逸通用英語能力認證",
            "eng": "English for General Purposes (EGP)"
          },
          {
            "no": "4001001048",
            "des": "Linguaskill General 劍橋領思-實用英檢",
            "eng": "Linguaskill General "
          },
          {
            "no": "4001001049",
            "des": "Linguaskill Business 劍橋領思-職場英檢",
            "eng": "Linguaskill Business "
          },
          {
            "no": "4001001050",
            "des": "明逸兒童英語認證",
            "eng": "MiNGYI Precision Digital Marketing Certification"
          },
          {
            "no": "4001001051",
            "des": "Aptis 普思國際英語檢定 ( Aptis General 通用版/職場版)",
            "eng": "Aptis General "
          },
          {
            "no": "4001001052",
            "des": "Aptis 普思國際英語檢定 ( Aptis Advanced 進階版)",
            "eng": "Aptis Advanced "
          },
          {
            "no": "4001001053",
            "des": "Aptis 普思國際英語檢定 ( Aptis for Teachers 教師版)",
            "eng": "Aptis for Teachers"
          },
          {
            "no": "4001001054",
            "des": "Aptis 普思國際英語檢定 ( Aptis for Teens 兒青版)",
            "eng": "Aptis for Teens"
          },
          {
            "no": "4001001055",
            "des": "國際英語溝通能力檢定",
            "eng": "International Test of English Proficiency"
          },
          {
            "no": "4001001056",
            "des": "EnglishScore英文檢定",
            "eng": "EnglishScore"
          }
        ],
        "eng": "English Language Related Certifications"
      },
      {
        "no": "4001002000",
        "des": "日語相關證照",
        "n": [
          {
            "no": "4001002001",
            "des": "EJU (日本留學試驗)",
            "eng": "EJU "
          },
          {
            "no": "4001002006",
            "des": "FLPT (日語)",
            "eng": "FLPT "
          },
          {
            "no": "4001002002",
            "des": "JLPT N1",
            "eng": "JLPT N1"
          },
          {
            "no": "4001002003",
            "des": "JLPT N2",
            "eng": "JLPT N2"
          },
          {
            "no": "4001002004",
            "des": "JLPT N3",
            "eng": "JLPT N3"
          },
          {
            "no": "4001002005",
            "des": "JLPT N4",
            "eng": "JLPT N4"
          },
          {
            "no": "4001002007",
            "des": "電腦P檢",
            "eng": "The computer P checks"
          },
          {
            "no": "4001002008",
            "des": "J-TEST實用日語檢定",
            "eng": "J-TEST"
          },
          {
            "no": "4001002009",
            "des": "JLPT N5",
            "eng": "JLPT N5"
          },
          {
            "no": "4001002010",
            "des": "BJT商務日語能力",
            "eng": "Business Japanese Proficiency Test"
          }
        ],
        "eng": "Japanese Language Related Certifications"
      },
      {
        "no": "4001003000",
        "des": "其他語言證照",
        "n": [
          {
            "no": "4001003001",
            "des": "DELE (西班牙語文能力測驗)",
            "eng": "DELE (Diplomas of Spanish as a Foreign Language )"
          },
          {
            "no": "4001003002",
            "des": "DELF (法語鑑定文憑)",
            "eng": "DELF (French proficiency examination)"
          },
          {
            "no": "4001003004",
            "des": "FLPT (德語)",
            "eng": "Foreign Language Proficiency Test (German)"
          },
          {
            "no": "4001003005",
            "des": "FLPT (法語)",
            "eng": "Foreign Language Proficiency Test (French)"
          },
          {
            "no": "4001003006",
            "des": "FLPT (西語)",
            "eng": "Foreign Language Proficiency Test (Spanish)"
          },
          {
            "no": "4001003003",
            "des": "ZDaF (德語初級考試)",
            "eng": "ZDaF (basic German Examination)"
          },
          {
            "no": "4001003007",
            "des": "TestDaf(德國大學入學德語鑑定考試)",
            "eng": "Test Deutsch als Fremdsprache"
          },
          {
            "no": "4001003008",
            "des": "TOPIK (韓語)",
            "eng": "Test of Proficieny in Korean"
          }
        ],
        "eng": "Other Language Certifications"
      },
      {
        "no": "4001004000",
        "des": "華語相關證照",
        "n": [
          {
            "no": "4001004002",
            "des": "TCYL少兒漢語教師資格",
            "eng": "TCYL"
          },
          {
            "no": "4001004003",
            "des": "TCBP國際商務漢語教師資格",
            "eng": "TCBP"
          }
        ],
        "eng": "Chinese Language Related Certifications(specifically referring to Mandarin)"
      },
      {
        "no": "4001005000",
        "des": "中文相關證照",
        "n": [
          {
            "no": "4001005001",
            "des": "CWT全民中文能力檢定-基礎等",
            "eng": "Certification of National Chinese Proficiency - Basic"
          },
          {
            "no": "4001005002",
            "des": "CWT全民中文能力檢定-初等",
            "eng": "Certification of National Chinese Proficiency - Elementary"
          },
          {
            "no": "4001005003",
            "des": "CWT全民中文能力檢定-中等",
            "eng": "Certification of National Chinese Proficiency - Intermediate"
          },
          {
            "no": "4001005004",
            "des": "CWT全民中文能力檢定-中高等",
            "eng": "Certification of National Chinese Proficiency - Upper-Intermediate"
          },
          {
            "no": "4001005005",
            "des": "CWT全民中文能力檢定-高等",
            "eng": "Certification of National Chinese Proficiency - Advanced"
          },
          {
            "no": "4001005006",
            "des": "CWT全民中文能力檢定-優等",
            "eng": "Certification of National Chinese Proficiency - Excellent"
          },
          {
            "no": "4001005007",
            "des": "華語文能力測驗-聽讀入門基礎級",
            "eng": "TOCFL-Listening+Reading-Band A"
          },
          {
            "no": "4001005008",
            "des": "華語文能力測驗-聽讀進階高階級",
            "eng": "TOCFL-Listening+Reading-Band B"
          },
          {
            "no": "4001005009",
            "des": "華語文能力測驗-聽讀流利精通級",
            "eng": "TOCFL-Listening+Reading-Band C"
          },
          {
            "no": "4001005010",
            "des": "華語文能力測驗-口語入門基礎級",
            "eng": "TOCFL-Speaking- Band A"
          },
          {
            "no": "4001005011",
            "des": "華語文能力測驗-口語進階高階級",
            "eng": "TOCFL-Speaking- Band B"
          },
          {
            "no": "4001005012",
            "des": "華語文能力測驗-寫作入門基礎級",
            "eng": "TOCFL-Writing- Band A"
          },
          {
            "no": "4001005013",
            "des": "華語文能力測驗-寫作進階高階級",
            "eng": "TOCFL-Writing- Band B"
          },
          {
            "no": "4001005014",
            "des": "LCT中文聽力理解檢定",
            "eng": "LCT Chinese Listening Comprehension Test"
          }
        ],
        "eng": "Chinese Language Related Certifications"
      }
    ],
    "eng": "Language "
  },
  {
    "no": "4002000000",
    "des": "電腦資訊網路類",
    "n": [
      {
        "no": "4002001000",
        "des": "Adobe",
        "n": [
          {
            "no": "4002001001",
            "des": "Acrobat",
            "eng": "Acrobat"
          },
          {
            "no": "4002001006",
            "des": "Adobe Certified Cold Fusion Developer",
            "eng": "Adobe Certified Cold Fusion Developer"
          },
          {
            "no": "4002001005",
            "des": "Adobe Certified Dreamweaver Developer",
            "eng": "Adobe Certified Dreamweaver Developer"
          },
          {
            "no": "4002001003",
            "des": "Adobe Certified Flash Developer",
            "eng": "Adobe Certified Flash Developer"
          },
          {
            "no": "4002001004",
            "des": "Adobe Certified Flash Designer",
            "eng": "Adobe Certified Flash Designer"
          },
          {
            "no": "4002001002",
            "des": "Adobe GoLive",
            "eng": "Adobe GoLive"
          },
          {
            "no": "4002001007",
            "des": "Adobe Certified Associate",
            "eng": "Adobe Certified Associate"
          },
          {
            "no": "4002001008",
            "des": "ACP Photoshop 2021",
            "eng": "ACP Photoshop 2021"
          },
          {
            "no": "4002001009",
            "des": "ACP Illustrator 2020",
            "eng": "ACP Illustrator 2020"
          },
          {
            "no": "4002001010",
            "des": "ACP InDesign 2020",
            "eng": "ACP InDesign 2020"
          },
          {
            "no": "4002001011",
            "des": "ACP Dreamweaver 2020",
            "eng": "ACP Dreamweaver 2020"
          },
          {
            "no": "4002001012",
            "des": "ACP Animate 2020",
            "eng": "ACP Animate 2020"
          },
          {
            "no": "4002001013",
            "des": "ACP Premiere Pro 2021",
            "eng": "ACP Premiere Pro 2021"
          },
          {
            "no": "4002001014",
            "des": "ACP After Effects 2021",
            "eng": "ACP After Effects 2021"
          },
          {
            "no": "4002001015",
            "des": "ACP Photoshop 2022",
            "eng": "ACP Photoshop 2022"
          },
          {
            "no": "4002001016",
            "des": "ACP Illustrator 2022",
            "eng": "ACP Illustrator 2022"
          },
          {
            "no": "4002001017",
            "des": "ACP Premiere Pro 2022",
            "eng": "ACP Premiere Pro 2022"
          },
          {
            "no": "4002001018",
            "des": "ACP After Effects 2022",
            "eng": "ACP After Effects 2022"
          }
        ],
        "eng": "Adobe"
      },
      {
        "no": "4002018000",
        "des": "Apple",
        "n": [
          {
            "no": "4002018005",
            "des": "ACDT",
            "eng": "ACDT"
          },
          {
            "no": "4002018003",
            "des": "ACHDS",
            "eng": "ACHDS"
          },
          {
            "no": "4002018004",
            "des": "ACPT",
            "eng": "ACPT"
          },
          {
            "no": "4002018001",
            "des": "ACSA",
            "eng": "ACSA"
          },
          {
            "no": "4002018002",
            "des": "ACTC",
            "eng": "ACTC"
          }
        ],
        "eng": "Apple"
      },
      {
        "no": "4002019000",
        "des": "Avaya Inc",
        "n": [
          {
            "no": "4002019001",
            "des": "ACE",
            "eng": "ACE"
          },
          {
            "no": "4002019003",
            "des": "ACA",
            "eng": "ACA"
          },
          {
            "no": "4002019002",
            "des": "ACS",
            "eng": "ACS"
          }
        ],
        "eng": "Avaya Inc"
      },
      {
        "no": "4002020000",
        "des": "BEA System",
        "n": [
          {
            "no": "4002020003",
            "des": "BEA Certified Administrator",
            "eng": "BEA Certified Administrator"
          },
          {
            "no": "4002020004",
            "des": "BEA Certified Architect",
            "eng": "BEA Certified Architect"
          },
          {
            "no": "4002020001",
            "des": "BEA Certified Developer",
            "eng": "BEA Certified Developer"
          },
          {
            "no": "4002020002",
            "des": "BEA Certified Specialist: Server",
            "eng": "BEA Certified Specialist: Server"
          }
        ],
        "eng": "BEA System"
      },
      {
        "no": "4002021000",
        "des": "Brocade",
        "n": [
          {
            "no": "4002021002",
            "des": "BCFD",
            "eng": "BCFD"
          },
          {
            "no": "4002021001",
            "des": "BCFP",
            "eng": "BCFP"
          },
          {
            "no": "4002021003",
            "des": "BCSM",
            "eng": "BCSM"
          }
        ],
        "eng": "Brocade"
      },
      {
        "no": "4002022000",
        "des": "Check Point",
        "n": [
          {
            "no": "4002022003",
            "des": "CCAE",
            "eng": "CCAE"
          },
          {
            "no": "4002022007",
            "des": "CCMSE Plus VSX",
            "eng": "CCMSE Plus VSX"
          },
          {
            "no": "4002022008",
            "des": "CCMSE NG with AI",
            "eng": "CCMSE NG with AI"
          },
          {
            "no": "4002022004",
            "des": "CCQE",
            "eng": "CCQE"
          },
          {
            "no": "4002022001",
            "des": "CCSA NGX",
            "eng": "CCSA NGX"
          },
          {
            "no": "4002022002",
            "des": "CCSE NGX",
            "eng": "CCSE NGX"
          },
          {
            "no": "4002022005",
            "des": "CCSE Plus NG with Al",
            "eng": "CCSE Plus NG with Al"
          },
          {
            "no": "4002022006",
            "des": "CCSPA",
            "eng": "CCSPA"
          }
        ],
        "eng": "Check Point"
      },
      {
        "no": "4002006000",
        "des": "Cisco",
        "n": [
          {
            "no": "4002006004",
            "des": "CCDA",
            "eng": "CCDA"
          },
          {
            "no": "4002006006",
            "des": "CCDP",
            "eng": "CCDP"
          },
          {
            "no": "4002006003",
            "des": "CCIE",
            "eng": "CCIE"
          },
          {
            "no": "4002006007",
            "des": "CCIP",
            "eng": "CCIP"
          },
          {
            "no": "4002006001",
            "des": "CCNA",
            "eng": "CCNA"
          },
          {
            "no": "4002006002",
            "des": "CCNP",
            "eng": "CCNP"
          },
          {
            "no": "4002006005",
            "des": "CCSP",
            "eng": "CCSP"
          },
          {
            "no": "4002006008",
            "des": "CCVP",
            "eng": "CCVP"
          },
          {
            "no": "4002006009",
            "des": "CQS",
            "eng": "CQS"
          },
          {
            "no": "4002006010",
            "des": "CCNA Security網路安全認證",
            "eng": "CCNA Security"
          },
          {
            "no": "4002006011",
            "des": "CCENT",
            "eng": "CCENT"
          }
        ],
        "eng": "Cisco"
      },
      {
        "no": "4002023000",
        "des": "Citrix",
        "n": [
          {
            "no": "4002023001",
            "des": "CCA",
            "eng": "CCA"
          },
          {
            "no": "4002023003",
            "des": "CCEA",
            "eng": "CCEA"
          },
          {
            "no": "4002023004",
            "des": "CCIA",
            "eng": "CCIA"
          },
          {
            "no": "4002023002",
            "des": "CCI",
            "eng": "CCI"
          }
        ],
        "eng": "Citrix"
      },
      {
        "no": "4002024000",
        "des": "Comp TIA",
        "n": [
          {
            "no": "4002024001",
            "des": "A+",
            "eng": "A+"
          },
          {
            "no": "4002024007",
            "des": "CTT+",
            "eng": "CTT+"
          },
          {
            "no": "4002024008",
            "des": "CDIA+",
            "eng": "CDIA+"
          },
          {
            "no": "4002024009",
            "des": "e-Biz+",
            "eng": "e-Biz+"
          },
          {
            "no": "4002024011",
            "des": "HTI+",
            "eng": "HTI+"
          },
          {
            "no": "4002024006",
            "des": "i-Net+",
            "eng": "i-Net+"
          },
          {
            "no": "4002024003",
            "des": "Linux+",
            "eng": "Linux+"
          },
          {
            "no": "4002024002",
            "des": "Network+",
            "eng": "Network+"
          },
          {
            "no": "4002024010",
            "des": "Project+",
            "eng": "Project+"
          },
          {
            "no": "4002024004",
            "des": "Security+",
            "eng": "Security+"
          },
          {
            "no": "4002024005",
            "des": "Server+",
            "eng": "Server+"
          }
        ],
        "eng": "Comp TIA"
      },
      {
        "no": "4002025000",
        "des": "CWNP",
        "n": [
          {
            "no": "4002025002",
            "des": "CWAP",
            "eng": "CWAP"
          },
          {
            "no": "4002025005",
            "des": "CWNA",
            "eng": "CWNA"
          },
          {
            "no": "4002025004",
            "des": "CWNE",
            "eng": "CWNE"
          },
          {
            "no": "4002025006",
            "des": "CWNI",
            "eng": "CWNI"
          },
          {
            "no": "4002025003",
            "des": "CWNT",
            "eng": "CWNT"
          },
          {
            "no": "4002025001",
            "des": "CWSP",
            "eng": "CWSP"
          }
        ],
        "eng": "CWNP"
      },
      {
        "no": "4002009000",
        "des": "EC-Council",
        "n": [
          {
            "no": "4002009002",
            "des": "CEA",
            "eng": "CEC"
          },
          {
            "no": "4002009003",
            "des": "CEC",
            "eng": "CEH"
          },
          {
            "no": "4002009004",
            "des": "CEH",
            "eng": "CHFI"
          },
          {
            "no": "4002009001",
            "des": "CEP",
            "eng": "CEA"
          },
          {
            "no": "4002009005",
            "des": "CHFI",
            "eng": "CHFI"
          },
          {
            "no": "4002009006",
            "des": "ECIH 資安危機處理員認證",
            "eng": "EC-Council Certified Incident Handler"
          },
          {
            "no": "4002009007",
            "des": "ECSA 資安分析專家認證",
            "eng": "EC-Council Certified Security Analyst "
          },
          {
            "no": "4002009008",
            "des": "ECSP.NET 安全程式設計師認證",
            "eng": "EC-Council Certified Secure Programmer"
          },
          {
            "no": "4002009009",
            "des": "ECSS 資安基本認證",
            "eng": "EC-Council Certified Security Specialist"
          },
          {
            "no": "4002009010",
            "des": "EDRP 資安災害復原專家認證",
            "eng": "EC-Council Disaster Recovery Professional"
          },
          {
            "no": "4002009011",
            "des": "ENSA 網路安全管理師認證",
            "eng": "EC-Council Network Security Administrator"
          },
          {
            "no": "4002009012",
            "des": "CND 網路防禦專家認證",
            "eng": "EC-Council CND"
          }
        ],
        "eng": "CEP"
      },
      {
        "no": "4002026000",
        "des": "EMC",
        "n": [
          {
            "no": "4002026003",
            "des": "EMC Proven Professional Associate",
            "eng": "EMC Proven Professional Associate"
          },
          {
            "no": "4002026001",
            "des": "EMC Proven Professional Expert",
            "eng": "EMC Proven Professional Expert"
          },
          {
            "no": "4002026002",
            "des": "EMC Proven Professional Specialist",
            "eng": "EMC Proven Professional Specialist"
          }
        ],
        "eng": "EMC"
      },
      {
        "no": "4002027000",
        "des": "Hewlett Packard",
        "n": [
          {
            "no": "4002027004",
            "des": "AIS",
            "eng": "AIS"
          },
          {
            "no": "4002027001",
            "des": "APS",
            "eng": "APS"
          },
          {
            "no": "4002027003",
            "des": "ASE",
            "eng": "ASE"
          },
          {
            "no": "4002027006",
            "des": "HP Certified System Administrator",
            "eng": "HP Certified System Administrator"
          },
          {
            "no": "4002027005",
            "des": "HP Certified Systems Engineer",
            "eng": "HP Certified Systems Engineer"
          },
          {
            "no": "4002027007",
            "des": "HP Master ASP Engineer",
            "eng": "HP Master ASP Engineer"
          },
          {
            "no": "4002027002",
            "des": "Master ASE",
            "eng": "Master ASE"
          }
        ],
        "eng": "Hewlett Packard"
      },
      {
        "no": "4002028000",
        "des": "IBM",
        "n": [
          {
            "no": "4002028009",
            "des": "IBM AIX and eserver pSeries",
            "eng": "IBM AIX and eserver pSeries"
          },
          {
            "no": "4002028006",
            "des": "IBM DB2 Information Management",
            "eng": "IBM DB2 Information Management"
          },
          {
            "no": "4002028007",
            "des": "IBM eserver xSeries",
            "eng": "IBM eserver xSeries"
          },
          {
            "no": "4002028010",
            "des": "IBM i5/OS and eserver iSeries",
            "eng": "IBM i5/OS and eserver iSeries"
          },
          {
            "no": "4002028002",
            "des": "IBM Lotus",
            "eng": "IBM Lotus"
          },
          {
            "no": "4002028005",
            "des": "IBM Rational",
            "eng": "IBM Rational"
          },
          {
            "no": "4002028004",
            "des": "IBM Tivoli",
            "eng": "IBM Tivoli"
          },
          {
            "no": "4002028008",
            "des": "IBM TotalStorage",
            "eng": "IBM TotalStorage"
          },
          {
            "no": "4002028001",
            "des": "IBM XML",
            "eng": "IBM XML"
          },
          {
            "no": "4002028003",
            "des": "IBM WebSphere",
            "eng": "IBM WebSphere"
          }
        ],
        "eng": "IBM"
      },
      {
        "no": "4002031000",
        "des": "(ISC)2",
        "n": [
          {
            "no": "4002031001",
            "des": "CISSP",
            "eng": "CISSP"
          },
          {
            "no": "4002031005",
            "des": "ISSAP",
            "eng": "ISSAP"
          },
          {
            "no": "4002031003",
            "des": "ISSEP",
            "eng": "ISSEP"
          },
          {
            "no": "4002031004",
            "des": "ISSMP",
            "eng": "ISSMP"
          },
          {
            "no": "4002031002",
            "des": "SSCP",
            "eng": "SSCP"
          },
          {
            "no": "4002031006",
            "des": "CAP",
            "eng": "CAP"
          },
          {
            "no": "4002031007",
            "des": "CSSLP",
            "eng": "CSSLP"
          }
        ],
        "eng": "(ISC)2"
      },
      {
        "no": "4002029000",
        "des": "Juniper Networks",
        "n": [
          {
            "no": "4002029005",
            "des": "JNCIA-E",
            "eng": "JNCIA-E"
          },
          {
            "no": "4002029006",
            "des": "JNCIA-ER",
            "eng": "JNCIA-ER"
          },
          {
            "no": "4002029007",
            "des": "JNCIA-EX",
            "eng": "JNCIA-EX"
          },
          {
            "no": "4002029008",
            "des": "JNCIA-FWV",
            "eng": "JNCIA-FWV"
          },
          {
            "no": "4002029009",
            "des": "JNCIA-IDP",
            "eng": "JNCIA-IDP"
          },
          {
            "no": "4002029010",
            "des": "JNCIA-JUNOS",
            "eng": "JNCIA-JUNOS"
          },
          {
            "no": "4002029011",
            "des": "JNCIA-M",
            "eng": "JNCIA-M"
          },
          {
            "no": "4002029012",
            "des": "JNCIA-SSL",
            "eng": "JNCIA-SSL"
          },
          {
            "no": "4002029013",
            "des": "JNCIS-E",
            "eng": "JNCIS-E"
          },
          {
            "no": "4002029014",
            "des": "JNCIS-ER",
            "eng": "JNCIS-ER"
          },
          {
            "no": "4002029015",
            "des": "JNCIS-FWV",
            "eng": "JNCIS-FWV"
          },
          {
            "no": "4002029016",
            "des": "JNCIS-M",
            "eng": "JNCIS-M"
          },
          {
            "no": "4002029017",
            "des": "JNCIS-SEC",
            "eng": "JNCIS-SEC"
          },
          {
            "no": "4002029018",
            "des": "JNCIS-SSL",
            "eng": "JNCIS-SSL"
          },
          {
            "no": "4002029019",
            "des": "JNCIP-E",
            "eng": "JNCIP-E"
          },
          {
            "no": "4002029020",
            "des": "JNCIP-M",
            "eng": "JNCIP-M"
          },
          {
            "no": "4002029021",
            "des": "JNCIE-ER",
            "eng": "JNCIE-ER"
          },
          {
            "no": "4002029022",
            "des": "JNCIE-M",
            "eng": "JNCIE-M"
          }
        ],
        "eng": "Juniper Networks"
      },
      {
        "no": "4002002000",
        "des": "Linux",
        "n": [
          {
            "no": "4002002001",
            "des": "LPIC Level 1",
            "eng": "LPIC Level 1"
          },
          {
            "no": "4002002002",
            "des": "LPIC Level 2",
            "eng": "LPIC Level 2"
          },
          {
            "no": "4002002003",
            "des": "LPIC Level 3",
            "eng": "LPIC Level 3"
          }
        ],
        "eng": "Linux"
      },
      {
        "no": "4002030000",
        "des": "Lotus",
        "n": [
          {
            "no": "4002030002",
            "des": "Certified Lotus Instructor (CLI)",
            "eng": "Certified Lotus Instructor (CLI)"
          },
          {
            "no": "4002030001",
            "des": "CLP",
            "eng": "CLP"
          },
          {
            "no": "4002030003",
            "des": "CLS",
            "eng": "CLS"
          },
          {
            "no": "4002030004",
            "des": "PCLP",
            "eng": "PCLP"
          }
        ],
        "eng": "Lotus"
      },
      {
        "no": "4002004000",
        "des": "Microsoft",
        "n": [
          {
            "no": "4002004011",
            "des": "MCAD",
            "eng": "MCAD"
          },
          {
            "no": "4002004010",
            "des": "MCDBA",
            "eng": "MCDBA"
          },
          {
            "no": "4002004012",
            "des": "MCDST",
            "eng": "MCDST"
          },
          {
            "no": "4002004018",
            "des": "MCITP",
            "eng": "MCITP"
          },
          {
            "no": "4002004015",
            "des": "MCPD",
            "eng": "MCPD"
          },
          {
            "no": "4002004004",
            "des": "MCP",
            "eng": "MCP"
          },
          {
            "no": "4002004005",
            "des": "MCP+I",
            "eng": "MCP+I"
          },
          {
            "no": "4002004013",
            "des": "MCP+SB",
            "eng": "MCP+SB"
          },
          {
            "no": "4002004006",
            "des": "MCSA",
            "eng": "MCSA"
          },
          {
            "no": "4002004007",
            "des": "IT Expert-3D動畫科技藝術-模型製作與材質設定",
            "eng": "IT Expert 3D animation technology & art - scene making"
          },
          {
            "no": "4002004008",
            "des": "MCSD for .NET",
            "eng": "MCSD for .NET"
          },
          {
            "no": "4002004002",
            "des": "MCSE",
            "eng": "MCSE"
          },
          {
            "no": "4002004003",
            "des": "MCSE+I",
            "eng": "MCSE+I"
          },
          {
            "no": "4002004009",
            "des": "MCT",
            "eng": "MCT"
          },
          {
            "no": "4002004014",
            "des": "MCTS",
            "eng": "MCTS"
          },
          {
            "no": "4002004001",
            "des": "MOS",
            "eng": "MOS"
          },
          {
            "no": "4002004020",
            "des": "Microsoft Certified Application Specialist",
            "eng": "Microsoft Certified Application Specialist"
          },
          {
            "no": "4002004021",
            "des": "IC3",
            "eng": "IC3"
          },
          {
            "no": "4002004022",
            "des": "BAP商務專業應用能力國際認證",
            "eng": "Business Application Professionals Programs"
          },
          {
            "no": "4002004023",
            "des": "SSE Excel 2016",
            "eng": "SSE Excel 2016"
          },
          {
            "no": "4002004024",
            "des": "SSE PowerPoint 2016",
            "eng": "SSE PowerPoint 2016"
          },
          {
            "no": "4002004025",
            "des": "SSE Word 2016",
            "eng": "SSE Word 2016"
          },
          {
            "no": "4002004026",
            "des": "Azure基礎知識認證",
            "eng": "Azure Fundamentals "
          },
          {
            "no": "4002004027",
            "des": "Azure Developer Associate",
            "eng": "Azure Developer Associate"
          },
          {
            "no": "4002004028",
            "des": "Azure for SAP Workloads Specialty",
            "eng": "Azure for SAP Workloads Specialty"
          },
          {
            "no": "4002004029",
            "des": "MOS Word 2019 助理級認證",
            "eng": "Microsoft Office Specialist: Word Associate (Office 2019)"
          },
          {
            "no": "4002004030",
            "des": "MOS Word Expert 2019 專家級認證",
            "eng": "Microsoft Office Specialist: Expert (Office 2019)"
          },
          {
            "no": "4002004031",
            "des": "MOS Excel 2019 助理級認證",
            "eng": "Microsoft Office Specialist: Excel Associate (Office 2019)"
          },
          {
            "no": "4002004032",
            "des": "MOS Excel Expert 2019 專家級認證",
            "eng": "Microsoft Office Specialist: Microsoft Excel Expert (Office 2019)"
          },
          {
            "no": "4002004033",
            "des": "MOS Powerpoint 2019 助理級認證",
            "eng": "Microsoft Office Specialist: PowerPoint Associate (Office 2019)"
          },
          {
            "no": "4002004034",
            "des": "MOS Outlook 2019 助理級認證",
            "eng": "Microsoft Office Specialist: Outlook Associate (Office 2019)"
          },
          {
            "no": "4002004035",
            "des": "MOS Access Expert 2019 專業級認證",
            "eng": "Microsoft Office Specialist: Microsoft Access Expert (Office 2019)"
          },
          {
            "no": "4002004036",
            "des": "PL-900 Power Platform 商業智慧應用基礎認證",
            "eng": "PL-900 Microsoft Certified: Power Platform Fundamentals"
          },
          {
            "no": "4002004037",
            "des": "AZ-900 Azure 雲端基礎認證",
            "eng": "AZ-900 Microsoft Certified: Azure Fundamentals"
          },
          {
            "no": "4002004038",
            "des": "AI-900 AI 人工智慧基礎認證",
            "eng": "AI-900 Microsoft Certified: AI Fundamentals"
          },
          {
            "no": "4002004039",
            "des": "DP-900 Azure 資料科學基礎認證",
            "eng": "DP-900 Microsoft Certified: Azure Data Fundamentals"
          },
          {
            "no": "4002004040",
            "des": "SC-900 安全性、合規性及身分識別基礎認證",
            "eng": "SC-900 Microsoft Security"
          },
          {
            "no": "4002004041",
            "des": "MS-900 Microsoft 365 基礎認證",
            "eng": "MS-900 Microsoft 365 Certified: Fundamentals"
          },
          {
            "no": "4002004042",
            "des": "MB-910 Microsoft Dynamics 365 基礎認證 (CRM)",
            "eng": "MB-910 Microsoft Certified: Dynamics 365 Fundamentals (CRM)"
          },
          {
            "no": "4002004043",
            "des": "MB-920 Microsoft Dynamics 365 基礎認證 (ERP)",
            "eng": "MB-920 Microsoft Certified: Dynamics 365 Fundamentals (ERP)"
          },
          {
            "no": "4002004044",
            "des": "DP-100 Azure資料科學家技術師",
            "eng": "DP-100 Azure Data Scientist Associate"
          },
          {
            "no": "4002004045",
            "des": "DP-203 Azure資料工程師技術師",
            "eng": "DP-203 Azure Data Engineer Associate"
          },
          {
            "no": "4002004046",
            "des": "AI-102 AI工程技術師",
            "eng": "AI-102 Azure AI Engineer Associate"
          },
          {
            "no": "4002004047",
            "des": "AZ-104 Azure系統管理技術師",
            "eng": "AZ-104 Azure Administrator"
          }
        ],
        "eng": "Microsoft"
      },
      {
        "no": "4002005000",
        "des": "Certiport",
        "n": [
          {
            "no": "4002005001",
            "des": "Microsoft Office Specialist (Formerly MOUS)",
            "eng": "Microsoft Office Specialist (Formerly MOUS)"
          },
          {
            "no": "4002005002",
            "des": "Microsoft Office Specialist Master Instructor (Formerly MOUS MI)",
            "eng": "Microsoft Office Specialist Master Instructor (Formerly MOUS MI)"
          },
          {
            "no": "4002005003",
            "des": "MTA-微軟專業應用技術國際認證 ",
            "eng": "Microsoft Technology Associate"
          },
          {
            "no": "4002005004",
            "des": "ITS 網路管理與應用核心能力",
            "eng": "ITS: Networking"
          },
          {
            "no": "4002005005",
            "des": "ITS 網路安全管理核心能力",
            "eng": "ITS:Network Security"
          },
          {
            "no": "4002005006",
            "des": "ITS 裝置設定與管理核心能力",
            "eng": "ITS: Device Configuration and Management"
          },
          {
            "no": "4002005007",
            "des": "ITS 資料庫管理核心能力",
            "eng": "ITS:Databases"
          },
          {
            "no": "4002005008",
            "des": "ITS 軟體研發核心能力",
            "eng": "ITS:\tSoftware Development"
          },
          {
            "no": "4002005009",
            "des": "ITS HTML5 應用程式開發核心能力",
            "eng": "ITS:HTML5 Application Development"
          },
          {
            "no": "4002005010",
            "des": "ITS JavaScript 程式語言核心能力",
            "eng": "ITS:JavaScript"
          },
          {
            "no": "4002005011",
            "des": "ITS Java 程式語言核心能力",
            "eng": "ITS:JAVA"
          },
          {
            "no": "4002005012",
            "des": "ITS HTML and CSS 程式語言核心能力",
            "eng": "ITS:HTML5 & CSS"
          },
          {
            "no": "4002005013",
            "des": "ITS Python 程式語言核心能力",
            "eng": "ITS:Python"
          },
          {
            "no": "4002005014",
            "des": "ITS 人工智慧核心能力",
            "eng": "ITS:Artificial Intelligence"
          },
          {
            "no": "4002005015",
            "des": "ITS 雲端運算核心能力",
            "eng": "ITS:\tCloud Computing"
          },
          {
            "no": "4002005016",
            "des": "ITS 運算思維核心能力",
            "eng": "ITS:Computational Thinking"
          },
          {
            "no": "4002005017",
            "des": "ITS 資通安全核心能力",
            "eng": "ITS:Cybersecurity"
          },
          {
            "no": "4002005018",
            "des": "Apple Swift 應用程式開發認證-Associate",
            "eng": "App Development with Swift certification: Associate"
          },
          {
            "no": "4002005019",
            "des": "Apple Swift 應用程式開發認證-User",
            "eng": "App Development with Swift certification: User"
          },
          {
            "no": "4002005020",
            "des": "IC3 計算機綜合能力認證",
            "eng": "Internet and Computing Core Certification"
          },
          {
            "no": "4002005021",
            "des": "ESB 創業經營核心能力指標國際認證",
            "eng": "Entrepreneurship and Small Business Certification"
          },
          {
            "no": "4002005024",
            "des": "Professional Communication商務專業溝通",
            "eng": "CSB Professional Communication"
          }
        ],
        "eng": "Certiport"
      },
      {
        "no": "4002032000",
        "des": "Nortel Networks",
        "n": [
          {
            "no": "4002032001",
            "des": "NNCA",
            "eng": "NNCA"
          },
          {
            "no": "4002032005",
            "des": "NNCDE",
            "eng": "NNCDE"
          },
          {
            "no": "4002032003",
            "des": "NNCDS",
            "eng": "NNCDS"
          },
          {
            "no": "4002032006",
            "des": "NNCFS",
            "eng": "NNCFS"
          },
          {
            "no": "4002032004",
            "des": "NNCSE",
            "eng": "NNCSE"
          },
          {
            "no": "4002032002",
            "des": "NNCSS",
            "eng": "NNCSS"
          }
        ],
        "eng": "Nortel Networks"
      },
      {
        "no": "4002003000",
        "des": "Novell",
        "n": [
          {
            "no": "4002003001",
            "des": "CNA",
            "eng": "CNA"
          },
          {
            "no": "4002003003",
            "des": "CDE",
            "eng": "CDE"
          },
          {
            "no": "4002003008",
            "des": "CLE",
            "eng": "CLE"
          },
          {
            "no": "4002003006",
            "des": "CLP",
            "eng": "CLP"
          },
          {
            "no": "4002003004",
            "des": "CNE",
            "eng": "CNE"
          },
          {
            "no": "4002003005",
            "des": "CNI",
            "eng": "CNI"
          },
          {
            "no": "4002003002",
            "des": "CNS",
            "eng": "CNS"
          },
          {
            "no": "4002003007",
            "des": "Master CNE",
            "eng": "Master CNE"
          },
          {
            "no": "4002003009",
            "des": "NCIP",
            "eng": "NCIP"
          },
          {
            "no": "4002003010",
            "des": "NCLA",
            "eng": "NCLA"
          }
        ],
        "eng": "Novell"
      },
      {
        "no": "4002010000",
        "des": "Oracle",
        "n": [
          {
            "no": "4002010006",
            "des": "OCP AD",
            "eng": "OCP AD"
          },
          {
            "no": "4002010005",
            "des": "OCA DBA",
            "eng": "OCA DBA"
          },
          {
            "no": "4002010007",
            "des": "OCA Web Admin.",
            "eng": "OCA Web Admin."
          },
          {
            "no": "4002010002",
            "des": "OCA AD",
            "eng": "OCA AD"
          },
          {
            "no": "4002010001",
            "des": "OCP DBA",
            "eng": "OCP DBA"
          },
          {
            "no": "4002010009",
            "des": "OCP DBO",
            "eng": "OCP DBO"
          },
          {
            "no": "4002010010",
            "des": "OCP Java Dev.",
            "eng": "OCP Java Dev."
          },
          {
            "no": "4002010004",
            "des": "8i OCP",
            "eng": "8i OCP"
          },
          {
            "no": "4002010008",
            "des": "9i OCM",
            "eng": "9i OCM"
          },
          {
            "no": "4002010003",
            "des": "9i OCP",
            "eng": "9i OCP"
          },
          {
            "no": "4002010011",
            "des": "OCM",
            "eng": "OCM"
          },
          {
            "no": "4002010012",
            "des": "OCAJP",
            "eng": "OCAJP"
          },
          {
            "no": "4002010013",
            "des": "OCPJP",
            "eng": "OCPJP"
          }
        ],
        "eng": "Oracle"
      },
      {
        "no": "4002008000",
        "des": "Prosoft Learning Corp.",
        "n": [
          {
            "no": "4002008002",
            "des": "CIW Associate",
            "eng": "CIW Web Developer"
          },
          {
            "no": "4002008001",
            "des": "CIW Professional",
            "eng": "CIW Associate"
          },
          {
            "no": "4002008003",
            "des": "CIW Web Developer",
            "eng": "Master CIW Administrator"
          },
          {
            "no": "4002008006",
            "des": "CIW Security Analyst",
            "eng": "Master CIW Enterprise Developer"
          },
          {
            "no": "4002008004",
            "des": "Master CIW Administrator",
            "eng": "Master CIW Designer"
          },
          {
            "no": "4002008005",
            "des": "Master CIW Designer",
            "eng": "CIW Security Analyst"
          },
          {
            "no": "4002008007",
            "des": "Master CIW Enterprise Developer",
            "eng": "Master CIW Web Site Manager"
          },
          {
            "no": "4002008008",
            "des": "Master CIW Web Site Manager",
            "eng": "CIW E-Commerce Associate"
          },
          {
            "no": "4002008009",
            "des": "CIW E-Commerce Associate",
            "eng": "EC-Council"
          }
        ],
        "eng": "CIW Professional"
      },
      {
        "no": "4002033000",
        "des": "Red Hat",
        "n": [
          {
            "no": "4002033003",
            "des": "RHCA",
            "eng": "RHCA"
          },
          {
            "no": "4002033001",
            "des": "RHCE",
            "eng": "RHCE"
          },
          {
            "no": "4002033002",
            "des": "RHCT",
            "eng": "RHCT"
          },
          {
            "no": "4002033004",
            "des": "RHCSA",
            "eng": "RHCSA"
          }
        ],
        "eng": "Red Hat"
      },
      {
        "no": "4002015000",
        "des": "RSA Security",
        "n": [
          {
            "no": "4002015001",
            "des": "RSA／CA",
            "eng": "RSA/CA"
          },
          {
            "no": "4002015002",
            "des": "RSA／CI",
            "eng": "RSA/CI"
          },
          {
            "no": "4002015003",
            "des": "RSA／CSE",
            "eng": "RSA/CSE"
          }
        ],
        "eng": "RSA Security"
      },
      {
        "no": "4002016000",
        "des": "SAIR／GNU-Wave",
        "n": [
          {
            "no": "4002016001",
            "des": "LCA",
            "eng": "LCA"
          },
          {
            "no": "4002016002",
            "des": "LCE",
            "eng": "LCE"
          },
          {
            "no": "4002016003",
            "des": "MLCE",
            "eng": "MLCE"
          }
        ],
        "eng": "SAIR/GNU-Wave"
      },
      {
        "no": "4002017000",
        "des": "SANS",
        "n": [
          {
            "no": "4002017004",
            "des": "GCIA",
            "eng": "GCIA"
          },
          {
            "no": "4002017005",
            "des": "GCIH",
            "eng": "GCIH"
          },
          {
            "no": "4002017007",
            "des": "GCFA",
            "eng": "GCFA"
          },
          {
            "no": "4002017008",
            "des": "GCFW",
            "eng": "GCFW"
          },
          {
            "no": "4002017006",
            "des": "GCSC",
            "eng": "GCSC"
          },
          {
            "no": "4002017013",
            "des": "GCUX",
            "eng": "GCUX"
          },
          {
            "no": "4002017012",
            "des": "GCWN",
            "eng": "GCWN"
          },
          {
            "no": "4002017011",
            "des": "GISF",
            "eng": "GISF"
          },
          {
            "no": "4002017010",
            "des": "GSAE",
            "eng": "GSAE"
          },
          {
            "no": "4002017001",
            "des": "GSIP",
            "eng": "GSIP"
          },
          {
            "no": "4002017009",
            "des": "GSNA",
            "eng": "GSNA"
          },
          {
            "no": "4002017002",
            "des": "GSEC",
            "eng": "GSEC"
          },
          {
            "no": "4002017015",
            "des": "GNET",
            "eng": "GNET"
          },
          {
            "no": "4002017003",
            "des": "GSLC",
            "eng": "GSLC"
          },
          {
            "no": "4002017016",
            "des": "GSOC",
            "eng": "GSOC"
          },
          {
            "no": "4002017014",
            "des": "G7799",
            "eng": "G7799"
          }
        ],
        "eng": "SANS"
      },
      {
        "no": "4002014000",
        "des": "SNIA",
        "n": [
          {
            "no": "4002014001",
            "des": "SCA",
            "eng": "SCA"
          },
          {
            "no": "4002014002",
            "des": "SCP",
            "eng": "SCP"
          },
          {
            "no": "4002014003",
            "des": "SCSE",
            "eng": "SCSE"
          },
          {
            "no": "4002014004",
            "des": "SCSN-E",
            "eng": "SCSN-E"
          }
        ],
        "eng": "SNIA"
      },
      {
        "no": "4002013000",
        "des": "Sniffer Technologies",
        "n": [
          {
            "no": "4002013003",
            "des": "SCE",
            "eng": "SCE"
          },
          {
            "no": "4002013001",
            "des": "SCM",
            "eng": "SCM"
          },
          {
            "no": "4002013002",
            "des": "SCP",
            "eng": "SCP"
          }
        ],
        "eng": "Sniffer Technologies"
      },
      {
        "no": "4002007000",
        "des": "Sun Microsystems",
        "n": [
          {
            "no": "4002007015",
            "des": "MySQL CMDEV",
            "eng": "NetBeans IDE"
          },
          {
            "no": "4002007014",
            "des": "MySQL CMDBA",
            "eng": "MySQL CMDEV"
          },
          {
            "no": "4002007007",
            "des": "SCBCD",
            "eng": "SCJD"
          },
          {
            "no": "4002007009",
            "des": "SCDJWS",
            "eng": "SCMAD"
          },
          {
            "no": "4002007001",
            "des": "SCEA",
            "eng": "SCJP"
          },
          {
            "no": "4002007008",
            "des": "SCJD",
            "eng": "SCDJWS"
          },
          {
            "no": "4002007002",
            "des": "SCJP",
            "eng": "SCWCD"
          },
          {
            "no": "4002007010",
            "des": "SCMAD",
            "eng": "SCAJ"
          },
          {
            "no": "4002007004",
            "des": "SCNA",
            "eng": "SCSECA"
          },
          {
            "no": "4002007005",
            "des": "SCSECA",
            "eng": "SCSA"
          },
          {
            "no": "4002007006",
            "des": "SCSA",
            "eng": "SCBCD"
          },
          {
            "no": "4002007003",
            "des": "SCWCD",
            "eng": "SCNA"
          },
          {
            "no": "4002007012",
            "des": "MySQL CMA",
            "eng": "MySQL CMCDBA"
          },
          {
            "no": "4002007013",
            "des": "MySQL CMCDBA",
            "eng": "MySQL CMDBA"
          },
          {
            "no": "4002007016",
            "des": "NetBeans IDE",
            "eng": "SCJA"
          },
          {
            "no": "4002007017",
            "des": "SCJA",
            "eng": "SCSAS"
          },
          {
            "no": "4002007018",
            "des": "SCSAS",
            "eng": "Prosoft Learning Corp."
          }
        ],
        "eng": "Sun Microsystems"
      },
      {
        "no": "4002011000",
        "des": "Sybase",
        "n": [
          {
            "no": "4002011007",
            "des": "Sybase Certified Associate -- Adaptive Server Anywhere Developer",
            "eng": "Sybase Certified Associate -- Adaptive Server Anywhere Developer"
          },
          {
            "no": "4002011008",
            "des": "Sybase Certified Associate -- Adaptive Server Enterprise Admin.",
            "eng": "Sybase Certified Associate -- Adaptive Server Enterprise Admin."
          },
          {
            "no": "4002011006",
            "des": "Sybase Certified Associate -- Adaptive Server Enterprise Developer",
            "eng": "Sybase Certified Associate -- Adaptive Server Enterprise Developer"
          },
          {
            "no": "4002011005",
            "des": "Sybase Certified Associate -- PowerBuilder Developer",
            "eng": "Sybase Certified Associate -- PowerBuilder Developer"
          },
          {
            "no": "4002011004",
            "des": "Sybase Certified Professional -- Adaptive Server Enterprise Admin.",
            "eng": "Sybase Certified Professional -- Adaptive Server Enterprise Admin."
          },
          {
            "no": "4002011002",
            "des": "Sybase Certified Professional -- Adaptive Server Enterprise Developer",
            "eng": "Sybase Certified Professional -- Adaptive Server Enterprise Developer"
          },
          {
            "no": "4002011001",
            "des": "Sybase Certified Professional -- PowerBuilder Developer",
            "eng": "Sybase Certified Professional -- PowerBuilder Developer"
          },
          {
            "no": "4002011003",
            "des": "Sybase Certified Professional -- Replication Server Admin.",
            "eng": "Sybase Certified Professional -- Replication Server Admin."
          }
        ],
        "eng": "Sybase"
      },
      {
        "no": "4002012000",
        "des": "Symantec",
        "n": [
          {
            "no": "4002012003",
            "des": "SCSE",
            "eng": "SCSE"
          },
          {
            "no": "4002012004",
            "des": "SCSP",
            "eng": "SCSP"
          },
          {
            "no": "4002012002",
            "des": "SCTA",
            "eng": "SCTA"
          },
          {
            "no": "4002012001",
            "des": "SPS",
            "eng": "SPS"
          },
          {
            "no": "4002012005",
            "des": "VERITAS Certified Specialist",
            "eng": "VERITAS Certified Specialist"
          },
          {
            "no": "4002012006",
            "des": "Veritas Certified Professional",
            "eng": "Veritas Certified Professional"
          }
        ],
        "eng": "Symantec"
      },
      {
        "no": "4002035000",
        "des": "TQC／EEC",
        "n": [
          {
            "no": "4002035022",
            "des": "EEC系列-企業電子化規劃師(一級)",
            "eng": "EEC series - Corporation E-planner (level 1)"
          },
          {
            "no": "4002035023",
            "des": "EEC系列-企業電子化規劃師(二級)",
            "eng": "EEC series - Corporation E-planner (level 2)"
          },
          {
            "no": "4002035029",
            "des": "EEC-企業電子化助理規劃師",
            "eng": "e-Enterprise Assistant Planner"
          },
          {
            "no": "4002035013",
            "des": "TQC-CA-AutoCAD 2D",
            "eng": "TQC-CA-AutoCAD 2D"
          },
          {
            "no": "4002035014",
            "des": "TQC-CA-AutoCAD 3D",
            "eng": "TQC-CA-AutoCAD 3D"
          },
          {
            "no": "4002035002",
            "des": "TQC-CA-MDT",
            "eng": "TQC-CA-MDT"
          },
          {
            "no": "4002035007",
            "des": "TQC-CA-Pro-e",
            "eng": "TQC-CA-Pro-e"
          },
          {
            "no": "4002035032",
            "des": "TQC-CA-Pro/E進階零件及曲面設計",
            "eng": "TQC-CA-Pro/E enters the rank spare parts and curved face design"
          },
          {
            "no": "4002035011",
            "des": "TQC-DA-資料庫管理系統 Access",
            "eng": "TQC-DA-Access"
          },
          {
            "no": "4002035028",
            "des": "TQC-DA-SQL Server",
            "eng": "TQC-DA-SQL Server"
          },
          {
            "no": "4002035046",
            "des": "TQC-DA-大型資料庫管理系統 MySQL5",
            "eng": "TQC-DA-MySQL"
          },
          {
            "no": "4002035025",
            "des": "TQC-DK-初級會計IFRS",
            "eng": "TQC-DK IFRS"
          },
          {
            "no": "4002035026",
            "des": "TQC-DK電子商務概論",
            "eng": "TQC-DK- Introduction to E-commerce"
          },
          {
            "no": "4002035017",
            "des": "TQC專業資訊管理工程師",
            "eng": "TQC-Professional Information Management Engineer"
          },
          {
            "no": "4002035020",
            "des": "TQC專業網站程式開發工程師",
            "eng": "TQC Professional website programmer"
          },
          {
            "no": "4002035021",
            "des": "TQC專業網站資料庫管理工程師",
            "eng": "TQC-Professional Website Database Management Engineer"
          },
          {
            "no": "4002035010",
            "des": "TQC專業電子商務應用工程師",
            "eng": "TQC-EC- Professional E-commerce application engineer"
          },
          {
            "no": "4002035004",
            "des": "TQC-OA -Excel",
            "eng": "TQC-OA -Excel"
          },
          {
            "no": "4002035009",
            "des": "TQC-OA -Word",
            "eng": "TQC-OA -Word"
          },
          {
            "no": "4002035015",
            "des": "TQC-OA -PowerPoint",
            "eng": "TQC-OA -PowerPoint"
          },
          {
            "no": "4002035006",
            "des": "TQC-OA-電腦會計",
            "eng": "TQC Computer accounting"
          },
          {
            "no": "4002035019",
            "des": "TQC-OA-網際網路應用",
            "eng": "TQC-OA- Internet application"
          },
          {
            "no": "4002035005",
            "des": "TQC-OA -數字輸入",
            "eng": "TQC Number key-in"
          },
          {
            "no": "4002035008",
            "des": "TQC-OA-中文輸入",
            "eng": "TQC Chinese key-in"
          },
          {
            "no": "4002035030",
            "des": "TQC-OA -中文聽打",
            "eng": "TQC-OA- Chinese dictation"
          },
          {
            "no": "4002035012",
            "des": "TQC-OA -英文輸入",
            "eng": "TQC English key-in"
          },
          {
            "no": "4002035031",
            "des": "TQC-OA -日文輸入",
            "eng": "TQC-OA - Japanese key-in"
          },
          {
            "no": "4002035003",
            "des": "TQC-OS-Linux系統管理",
            "eng": "TQC-OS-Linux"
          },
          {
            "no": "4002035033",
            "des": "TQC-OS-Linux網路管理",
            "eng": "TQC-OS-Linux network manages"
          },
          {
            "no": "4002035016",
            "des": "TQC-OS -windows",
            "eng": "TQC-OS -windows"
          },
          {
            "no": "4002035018",
            "des": "TQC-PD-JAVA",
            "eng": "TQC-PD-JAVA"
          },
          {
            "no": "4002035024",
            "des": "TQC-PD-VB軟體開發",
            "eng": "TQC-PD-VB software development"
          },
          {
            "no": "4002035027",
            "des": "TQC-PD-VB程式設計",
            "eng": "TQC-PD-VB program design"
          },
          {
            "no": "4002035042",
            "des": "TQC-專業e-office人員",
            "eng": "TQC-Professional e-Office Personnel"
          },
          {
            "no": "4002035043",
            "des": "TQC專業工程製圖工程師",
            "eng": "TQC-Professional engineering graphics engineer"
          },
          {
            "no": "4002035044",
            "des": "TQC專業機械設計工程師",
            "eng": "TQC-The professional machine designs an engineer"
          },
          {
            "no": "4002035045",
            "des": "TQC專業程式設計工程師",
            "eng": "TQC-Professional program design engineer"
          },
          {
            "no": "4002035001",
            "des": "電腦專業認證講師",
            "eng": "Computer certificate lecturer"
          },
          {
            "no": "4002035047",
            "des": "TQC專業Linux網路管理工程師",
            "eng": "TQC-Professional Mobile Applications Engineer"
          },
          {
            "no": "4002035048",
            "des": "TQC專業Linux系統管理工程師",
            "eng": "TQC-Professional Linux Network Administrator"
          },
          {
            "no": "4002035049",
            "des": "EEC企業電子化規劃師-企業資源規劃",
            "eng": "e-Enterprise Planner- Enterprise Resource Planning"
          },
          {
            "no": "4002035050",
            "des": "EEC企業電子化規劃師-供應鏈管理",
            "eng": "e-Enterprise Planner-Supply Chain Management"
          },
          {
            "no": "4002035051",
            "des": "EEC企業電子化規劃師-企業電子化策略規劃",
            "eng": "e-Enterprise Planner- eBusiness Strategy Planning"
          },
          {
            "no": "4002035052",
            "des": "EEC企業電子化規劃師-網路行銷",
            "eng": "e-Enterprise Planner-Internet Marketing"
          },
          {
            "no": "4002035053",
            "des": "EEC企業電子化規劃師-客戶關係管理",
            "eng": "e-Enterprise Planner-Customer Relationship Management"
          },
          {
            "no": "4002035054",
            "des": "EEC企業電子化規劃師-資訊安全與法律",
            "eng": "e-Enterprise Planner-Information Security and Laws"
          },
          {
            "no": "4002035055",
            "des": "EEC企業電子化軟體應用師-CRM(鼎新)",
            "eng": "e-Enterprise Application Engineer-CRM(Data Systems)"
          },
          {
            "no": "4002035056",
            "des": "EEC企業電子化軟體應用師-ERP(鼎新配銷模組)",
            "eng": "e-Enterprise Application Engineer-ERP Distribution module(Data Systems)"
          },
          {
            "no": "4002035057",
            "des": "EEC企業電子化軟體應用師-ERP(鼎新財務模組)",
            "eng": "e-Enterprise Application Engineer-ERP Financial module (Data Systems)"
          },
          {
            "no": "4002035058",
            "des": "EEC企業電子化軟體應用師-ERP(鼎新生管模組)",
            "eng": "e-Enterprise Application Engineer-ERP Production module (Data Systems)"
          },
          {
            "no": "4002035059",
            "des": "EEC企業電子化軟體顧問師-ERP(鼎新財務模組)",
            "eng": "e-Enterprise Software Consultant-ERP Financial module (Data Systems)"
          },
          {
            "no": "4002035060",
            "des": "EEC企業電子化軟體顧問師-ERP(鼎新配銷模組)",
            "eng": "e-Enterprise Application Engineer-ERP Distribution module(Data Systems)"
          },
          {
            "no": "4002035061",
            "des": "EEC企業電子化軟體顧問師-ERP(鼎新財生管模組)",
            "eng": "e-Enterprise Software Consultant-ERP Production module (Data Systems)"
          },
          {
            "no": "4002035062",
            "des": "企業電子化規劃師-雲端服務規劃",
            "eng": "e-Enterprise Planner-Cloud Service Planning"
          },
          {
            "no": "4002035063",
            "des": "網際網路及行動通訊",
            "eng": "Internet & Mobile 2008"
          },
          {
            "no": "4002035064",
            "des": "程式設計Visual Basic 2008",
            "eng": "Visual Basic 2008 Program Design"
          },
          {
            "no": "4002035065",
            "des": "程式設計JAVA JDK1.4",
            "eng": "Java JDK 1.4"
          },
          {
            "no": "4002035066",
            "des": "實體設計SolidWorks 2009",
            "eng": "Solidworks 2009 Solid Modeling"
          },
          {
            "no": "4002035067",
            "des": "動態網頁程式設計 PHP5",
            "eng": "PHP 5"
          },
          {
            "no": "4002035068",
            "des": "雲端技術及網路服務",
            "eng": "Cloud Technology and Internet Services"
          },
          {
            "no": "4002035069",
            "des": "專案管理軟體應用Project 2007",
            "eng": "Project 2007"
          },
          {
            "no": "4002035070",
            "des": "行動裝置應用",
            "eng": "Mobile Device Application"
          },
          {
            "no": "4002035071",
            "des": "企業電子化資料分析師-巨量資料處理與分析",
            "eng": "AWS Certified Cloud Practitioner"
          },
          {
            "no": "4002035072",
            "des": "企業電子化軟體應用師-CRM(叡揚雲端)",
            "eng": "e-Enterprise Application Engineer-CRM(GSS Cloud)"
          },
          {
            "no": "4002035073",
            "des": "物聯網商務人員",
            "eng": "IoT Commerce Personnel"
          },
          {
            "no": "4002035074",
            "des": "物聯網應用服務人員",
            "eng": "IoT Application Service Personnel"
          },
          {
            "no": "4002035075",
            "des": "物聯網產品企畫人員",
            "eng": "IoT Product Planner"
          },
          {
            "no": "4002035076",
            "des": "物聯網產品行銷人員",
            "eng": "IoT Product Marketer"
          },
          {
            "no": "4002035077",
            "des": "物聯網產品管理人員",
            "eng": "IoT Product Management Personnel"
          },
          {
            "no": "4002035078",
            "des": "初級會計IFRS",
            "eng": "Principles of Accounting (IFRS)"
          },
          {
            "no": "4002035079",
            "des": "電腦會計IFRS",
            "eng": "Accounting Information System (IFRS)"
          },
          {
            "no": "4002035080",
            "des": "物聯網智慧應用及技術",
            "eng": "Internet of Things Application and Technology"
          },
          {
            "no": "4002035081",
            "des": "企業電子化社群行銷管理師-社群行銷",
            "eng": "e-Enterprise Social Marketing Specialist"
          },
          {
            "no": "4002035082",
            "des": "企業電子化跨境電商應用師-跨境電商實務操作(Alibaba)",
            "eng": "Cross-Border e-Commerce Application Engineer Certification"
          },
          {
            "no": "4002035083",
            "des": "商務軟體應用能力 LibreOffice ",
            "eng": "LibreOffice"
          },
          {
            "no": "4002035084",
            "des": "網路管理實務",
            "eng": "Networks Management Practices"
          },
          {
            "no": "4002035085",
            "des": "作業系統 Windows 7 Professional",
            "eng": "Windows 7 Professional"
          },
          {
            "no": "4002035086",
            "des": "作業系統 Windows 8 Professional",
            "eng": "Windows 8 Professional"
          },
          {
            "no": "4002035087",
            "des": "人工智慧應用與技術 (實用級/進階級/專業級)",
            "eng": "Artificial Intelligence Application & Technology (Practical/Advanced/Professional)"
          },
          {
            "no": "4002035088",
            "des": "企業電子化資安管理維運師 (資安實務與技術)",
            "eng": "e-Enterprise Planner Certification (SPT)"
          },
          {
            "no": "4002035089",
            "des": "企業電子化助理智慧健康照護師 (智慧健康照護)",
            "eng": "e-Enterprise Planner Certification (HCA)"
          },
          {
            "no": "4002035090",
            "des": "資訊科技 Python",
            "eng": "Python"
          },
          {
            "no": "4002035091",
            "des": "EEC-網路行銷",
            "eng": "EEC - MKP"
          },
          {
            "no": "4002035092",
            "des": "EEC-巨量資料處理與分析",
            "eng": "EEC - DPA"
          },
          {
            "no": "4002035093",
            "des": "TQC-OS-網路管理實務",
            "eng": "TQC-OS - Network Management Practices"
          },
          {
            "no": "4002035094",
            "des": "TQC-DK-人工智慧應用與技術",
            "eng": "TQC-DK - Artificial Intelligence Applications and Technology"
          },
          {
            "no": "4002035095",
            "des": "TQC-DK-物聯網智慧應用及技術",
            "eng": "TQC-DK - IOT Application and Technology"
          },
          {
            "no": "4002035096",
            "des": "TQC-DK-商務軟體應用能力",
            "eng": "TQC-DK - LibreOffice"
          },
          {
            "no": "4002035097",
            "des": "TQC-DK-資訊科技 Python",
            "eng": "TQC-DK - Information Technology (Python)"
          },
          {
            "no": "4002035098",
            "des": "TQC-OA-行動裝置應用",
            "eng": "TQC-OA - Mobile Device Application"
          },
          {
            "no": "4002035099",
            "des": "TQC-OA-雲端技術及網路服務",
            "eng": "TQC-OA - Cloud Technology and Internet Services"
          },
          {
            "no": "4002035100",
            "des": "TQC-OA-網際網路及行動通訊",
            "eng": "TQC-OA - Internet & Mobile"
          },
          {
            "no": "4002035101",
            "des": "TQC-行動商務人員",
            "eng": "TQC-Mobile Commerce Personnel"
          },
          {
            "no": "4002035102",
            "des": "TQC-雲端服務商務人員",
            "eng": "TQC-Cloud Service Commerce Personnel"
          },
          {
            "no": "4002035103",
            "des": "TQC-物聯網商務人員",
            "eng": "TQC-IoT Commerce Personnel"
          },
          {
            "no": "4002035104",
            "des": "TQC-物聯網應用服務人員",
            "eng": "TQC-IoT Application Service Personnel"
          },
          {
            "no": "4002035105",
            "des": "TQC-物聯網產品企畫人員",
            "eng": "TQC-IoT Product Planner"
          },
          {
            "no": "4002035106",
            "des": "TQC-物聯網產品行銷人員",
            "eng": "TQC-IoT Product Marketer"
          },
          {
            "no": "4002035107",
            "des": "TQC-物聯網產品管理人員",
            "eng": "TQC-IoT Product Management Personnel"
          },
          {
            "no": "4002035108",
            "des": "EEC-電子商務及企業電子化概論",
            "eng": "EEC - Introduction to E-commerce and Enterprise Digitalization"
          },
          {
            "no": "4002035109",
            "des": "EEC-企業資源規劃",
            "eng": "EEC - Enterprise Resource Planning"
          },
          {
            "no": "4002035110",
            "des": "EEC-企業電子化策略規劃",
            "eng": "EEC - Enterprise Digitalization Strategy Planning"
          },
          {
            "no": "4002035111",
            "des": "EEC-供應鏈管理",
            "eng": "EEC - Supply Chain Management"
          },
          {
            "no": "4002035112",
            "des": "EEC-資訊安全與法律",
            "eng": "EEC - Information Security and Legal Issues"
          },
          {
            "no": "4002035113",
            "des": "EEC-客戶關係管理",
            "eng": "EEC - Customer Relationship Management"
          },
          {
            "no": "4002035114",
            "des": "EEC-雲端服務規劃",
            "eng": "EEC - Cloud Service Planning"
          },
          {
            "no": "4002035115",
            "des": "EEC-跨境電商實務操作",
            "eng": "EEC - Cross-Border E-commerce Practical Operations"
          },
          {
            "no": "4002035116",
            "des": "EEC-社群行銷",
            "eng": "EEC - Social Media Marketing"
          },
          {
            "no": "4002035117",
            "des": "EEC-資安實務與技術",
            "eng": "EEC - Information Security Practices and Technology"
          },
          {
            "no": "4002035118",
            "des": "EEC-智慧健康照護",
            "eng": "EEC - Smart Healthcare"
          },
          {
            "no": "4002035119",
            "des": "EEC-人工智慧應用",
            "eng": "EEC - Applications of Artificial Intelligence"
          },
          {
            "no": "4002035120",
            "des": "EEC-資料科學與機器學習",
            "eng": "EEC - Data Science and Machine Learning"
          },
          {
            "no": "4002035121",
            "des": "EEC-CRM軟體應用",
            "eng": "EEC - CRM Software Application"
          },
          {
            "no": "4002035122",
            "des": "EEC-ERP軟體應用-配銷模組",
            "eng": "EEC - ERP Software Application - Distribution Module"
          },
          {
            "no": "4002035123",
            "des": "EEC-ERP軟體應用-財務模組",
            "eng": "EEC - ERP Software Application - Financial Module"
          },
          {
            "no": "4002035124",
            "des": "EEC-ERP軟體應用-生管模組",
            "eng": "EEC - ERP Software Application - Production Management Module"
          },
          {
            "no": "4002035125",
            "des": "EEC-ERP軟體顧問-配銷模組",
            "eng": "EEC - ERP Software Consultant - Distribution Module"
          },
          {
            "no": "4002035126",
            "des": "EEC-ERP軟體顧問-財務模組",
            "eng": "EEC - ERP Software Consultant - Financial Module"
          },
          {
            "no": "4002035127",
            "des": "EEC-ERP軟體顧問-生管模組",
            "eng": "EEC - ERP Software Consultant - Production Management Module"
          },
          {
            "no": "4002035128",
            "des": "EEC-企業電子化規劃師-雲端服務規劃",
            "eng": "e-Enterprise Planner-Cloud Service Planning"
          },
          {
            "no": "4002035129",
            "des": "EEC-企業電子化資料分析師",
            "eng": "e-Enterprise Data Analyst"
          },
          {
            "no": "4002035130",
            "des": "EEC-企業電子化社群行銷管理師",
            "eng": "e-Enterprise Social Marketing Specialist"
          },
          {
            "no": "4002035131",
            "des": "EEC-企業電子化跨境電商應用師",
            "eng": "Cross-Border e-Commerce Application Engineer"
          },
          {
            "no": "4002035132",
            "des": "EEC-企業電子化資安管理維運師",
            "eng": "e-Enterprise IT Security Management Support"
          },
          {
            "no": "4002035133",
            "des": "EEC-企業電子化助理智慧健康照護師",
            "eng": "e-Enterprise Assistant Intelligence Health Caregiver"
          },
          {
            "no": "4002035134",
            "des": "EEC-企業電子化人工智慧應用師",
            "eng": "e-Enterprise Artificial Intelligence Application Engineer Certification"
          },
          {
            "no": "4002035135",
            "des": "EEC-企業電子化資料科學應用師",
            "eng": "e-Enterprise Data Science Application Engineer Certification"
          }
        ],
        "eng": "TQC/EEC"
      },
      {
        "no": "4002034000",
        "des": "趨勢科技",
        "n": [
          {
            "no": "4002034001",
            "des": "TCAE",
            "eng": "TCAE"
          },
          {
            "no": "4002034002",
            "des": "TCSE",
            "eng": "TCSE"
          }
        ],
        "eng": "Trend Micro"
      },
      {
        "no": "4002036000",
        "des": "ITE",
        "n": [
          {
            "no": "4002036001",
            "des": "IT Expert-系統分析專業人員",
            "eng": "IT Expert- Professional personnel for system analysis"
          },
          {
            "no": "4002036002",
            "des": "IT Expert-資訊安全管理專業人員",
            "eng": "IT Expert- Professional personnel for IT safety management"
          },
          {
            "no": "4002036003",
            "des": "IT Expert-資訊管理(應用)專業人員",
            "eng": "IT Expert- Professional personnel for IT management (application)"
          },
          {
            "no": "4002036004",
            "des": "IT Expert-資料庫設計專業人員",
            "eng": "IT Expert- Professional personnel for database design"
          },
          {
            "no": "4002036005",
            "des": "IT Expert-網路通訊專業人員",
            "eng": "IT Expert- Professional personnel for network communication"
          },
          {
            "no": "4002036006",
            "des": "IT Expert-系統分析+電子商務專業人員",
            "eng": "IT Expert- Professional personnel for system analysis + E-commerce"
          },
          {
            "no": "4002036007",
            "des": "IT Expert-專案管理專業人員",
            "eng": "IT Expert- Professional personnel for project management"
          },
          {
            "no": "4002036008",
            "des": "IT Expert-軟體設計專業人員",
            "eng": "IT Expert- Professional personnel for software design"
          },
          {
            "no": "4002036009",
            "des": "IT Expert-網路通訊+網路規劃設計專業人員",
            "eng": "IT Expert- Professional personnel for network communication + network planning & design"
          },
          {
            "no": "4002036010",
            "des": "IT Expert-線上課程帶領專業人員",
            "eng": "IT Expert- Professional personnel for guiding online course"
          },
          {
            "no": "4002036011",
            "des": "IT Expert-系統分析+物件導向專業人員",
            "eng": "IT Expert- Professional personnel for system analysis + object orientation"
          },
          {
            "no": "4002036012",
            "des": "IT Expert-嵌入式系統軟體開發專業人員",
            "eng": "IT Expert- Professional personnel for developing embedded system software"
          },
          {
            "no": "4002036013",
            "des": "IT Expert-數位教學設計專業人員",
            "eng": "IT Expert-digital teaching design professional personnel"
          },
          {
            "no": "4002036014",
            "des": "IT Expert-網路資訊+網路規劃設計專業人員",
            "eng": "Level B technician for for labor health management"
          },
          {
            "no": "4002036015",
            "des": "IT Expert-3D動畫科技藝術-模型製作與材質設定",
            "eng": "Industrial safety of higher examination"
          },
          {
            "no": "4002036016",
            "des": "ITE-Linux 維運管理專業人員",
            "eng": "Industrial engineering of higher examination"
          },
          {
            "no": "4002036054",
            "des": "ITE-資訊安全管理專業人員",
            "eng": "System Security Management IT Expert"
          },
          {
            "no": "4002036017",
            "des": "ITE-OP-Linux服務整合專業人員",
            "eng": "Linux Administration IT Expert"
          },
          {
            "no": "4002036018",
            "des": "ITE-OP-Linux系統進階管理專業人員",
            "eng": "Linux Advance Administration IT Expert"
          },
          {
            "no": "4002036053",
            "des": "物件導向分析方法",
            "eng": "ITE-IOB"
          },
          {
            "no": "4002036021",
            "des": "Linux系統進階管理專業人員",
            "eng": "Linux Advance Administration IT Expert"
          },
          {
            "no": "4002036022",
            "des": "數位內容遊戲概論",
            "eng": "ITE-IGA"
          },
          {
            "no": "4002036023",
            "des": "遊戲企劃",
            "eng": "ITE-IGB"
          },
          {
            "no": "4002036024",
            "des": "遊戲美術",
            "eng": "ITE-IGC"
          },
          {
            "no": "4002036025",
            "des": "嵌入式系統技術",
            "eng": "ITE-IEA"
          },
          {
            "no": "4002036026",
            "des": "嵌入式系統的開發平台與工具(IEX)(Embedded Linux )",
            "eng": "ITE-IEX"
          },
          {
            "no": "4002036027",
            "des": "專案管理",
            "eng": "ITE-IPJ"
          },
          {
            "no": "4002036028",
            "des": "軟體專案管理",
            "eng": "ITE-ISP"
          },
          {
            "no": "4002036029",
            "des": "線上課程帶領方法與技巧",
            "eng": "ITE-IOL"
          },
          {
            "no": "4002036030",
            "des": "數位學習概論",
            "eng": "ITE-IEL"
          },
          {
            "no": "4002036031",
            "des": "數位教學設計",
            "eng": "ITE-IID"
          },
          {
            "no": "4002036032",
            "des": "資料庫系統理論與設計",
            "eng": "ITE-IDD"
          },
          {
            "no": "4002036033",
            "des": "資料庫系統技術與趨勢",
            "eng": "ITE-IRD"
          },
          {
            "no": "4002036034",
            "des": "資料庫系統開發與設計實務",
            "eng": "ITE-IDS"
          },
          {
            "no": "4002036035",
            "des": "通訊網路概論",
            "eng": "ITE-INQ"
          },
          {
            "no": "4002036036",
            "des": "網際網路服務與安全",
            "eng": "ITE-INK"
          },
          {
            "no": "4002036037",
            "des": "網路規劃設計與管理",
            "eng": "ITE-INP"
          },
          {
            "no": "4002036038",
            "des": "資訊管理概論",
            "eng": "ITE-IMX"
          },
          {
            "no": "4002036039",
            "des": "資訊科技概論",
            "eng": "ITE-IMY"
          },
          {
            "no": "4002036040",
            "des": "資訊與網路安全管理概論",
            "eng": "ITE-ISN"
          },
          {
            "no": "4002036041",
            "des": "資訊安全管理系統與風險管理",
            "eng": "ITE-ISK"
          },
          {
            "no": "4002036042",
            "des": "基礎軟體開發",
            "eng": "ITE-IBS"
          },
          {
            "no": "4002036043",
            "des": "基礎軟體設計",
            "eng": "ITE-ISG"
          },
          {
            "no": "4002036044",
            "des": "程式設計語言",
            "eng": "ITE-IJA、ICC、IVB"
          },
          {
            "no": "4002036045",
            "des": "進階軟體設計",
            "eng": "ITE-IAG"
          },
          {
            "no": "4002036046",
            "des": "Linux基礎運作",
            "eng": "ITE-ILA"
          },
          {
            "no": "4002036047",
            "des": "Linux進階系統管理",
            "eng": "ITE-ILB"
          },
          {
            "no": "4002036048",
            "des": "Linux開放式網路應用服務整合",
            "eng": "ITE-ILC"
          },
          {
            "no": "4002036049",
            "des": "Linux基礎攻防管理",
            "eng": "ITE-ILD"
          },
          {
            "no": "4002036050",
            "des": "資訊系統開發知識",
            "eng": "ITE-IDK"
          },
          {
            "no": "4002036051",
            "des": "軟體系統分析與專案管理",
            "eng": "ITE-IAP"
          },
          {
            "no": "4002036052",
            "des": "電子商務",
            "eng": "ITE-IEC"
          },
          {
            "no": "4002036020",
            "des": "進階軟體設計專業人員",
            "eng": "Advance Software Design IT Expert"
          },
          {
            "no": "4002036019",
            "des": "進階資料庫設計專業人員",
            "eng": "Advance Database System Design IT Expert"
          },
          {
            "no": "4002036055",
            "des": "ITE-DC-數位內容遊戲概論",
            "eng": "ITE-DC-IGA"
          },
          {
            "no": "4002036056",
            "des": "ITE-DC-遊戲企劃",
            "eng": "ITE-DC-Game Planning"
          },
          {
            "no": "4002036057",
            "des": "ITE-DC-遊戲美術",
            "eng": "ITE-DC-Game Art Design"
          },
          {
            "no": "4002036058",
            "des": "ITE-ES-嵌入式系統技術",
            "eng": "ITE-ES-Embedded System Technology"
          },
          {
            "no": "4002036059",
            "des": "ITE-ES-嵌入式系統的開發平台與工具",
            "eng": "ITE-ES-Embedded System Development Platforms and Tools"
          },
          {
            "no": "4002036060",
            "des": "ITE-EL-線上課程帶領方法與技巧",
            "eng": "ITE-EL-Online Course Leading Methods and Techniques"
          },
          {
            "no": "4002036061",
            "des": "ITE-EL-數位學習概論",
            "eng": "ITE-EL-Digital Learning Introduction"
          },
          {
            "no": "4002036062",
            "des": "ITE-EL-數位教學設計",
            "eng": "ITE-EL-Digital Instructional Design"
          },
          {
            "no": "4002036063",
            "des": "ITE-NC-通訊網路概論",
            "eng": "ITE-NC-Communication Network Overview"
          },
          {
            "no": "4002036064",
            "des": "ITE-NC-網際網路服務與安全",
            "eng": "ITE-NC-Internet Services and Security"
          },
          {
            "no": "4002036065",
            "des": "ITE-NC-網路規劃設計與管理",
            "eng": "ITE-NC-Network Planning, Design, and Management"
          },
          {
            "no": "4002036066",
            "des": "ITE-IM-資訊管理概論",
            "eng": "ITE-IM-Information Management Introduction"
          },
          {
            "no": "4002036067",
            "des": "ITE-IM-資訊科技概論",
            "eng": "ITE-IM-Information Technology Introduction"
          },
          {
            "no": "4002036068",
            "des": "ITE-IS-資訊與網路安全管理概論",
            "eng": "ITE-IS-Information and Network Security Management Overview"
          },
          {
            "no": "4002036069",
            "des": "ITE-IS-資訊安全管理系統與風險管理",
            "eng": "ITE-IS-Information Security Management System and Risk Management"
          },
          {
            "no": "4002036070",
            "des": "ITE-OP-Linux基礎運作",
            "eng": "ITE-OP-Basic Linux Operations"
          },
          {
            "no": "4002036071",
            "des": "ITE-OP-Linux進階系統管理",
            "eng": "ITE-OP-Advanced Linux System Management"
          },
          {
            "no": "4002036072",
            "des": "ITE-數位內容遊戲企劃專業人員",
            "eng": "ITE-Digital Content Game Planning Professionals"
          },
          {
            "no": "4002036073",
            "des": "ITE-數位內容遊戲美術專業人員",
            "eng": "ITE-Digital Content Game Art Design Professionals"
          },
          {
            "no": "4002036074",
            "des": "ITE-嵌入式系統軟體開發專業人員",
            "eng": "ITE-Embedded System Software Development Professionals"
          },
          {
            "no": "4002036075",
            "des": "ITE-線上課程帶領專業人員",
            "eng": "ITE-Online Course Leading Professionals"
          },
          {
            "no": "4002036076",
            "des": "ITE-數位教學設計專業人員",
            "eng": "ITE-Digital Instructional Design Professionals"
          },
          {
            "no": "4002036077",
            "des": "ITE-網路通訊專業人員",
            "eng": "ITE-Network Communication Professionals"
          },
          {
            "no": "4002036078",
            "des": "ITE-網路通訊+網路規劃設計專業人員",
            "eng": "ITE-Network Communication and Network Planning and Design Specialist"
          },
          {
            "no": "4002036079",
            "des": "ITE-資訊管理(應用)專業人員",
            "eng": "ITE-Information Management IT Expert"
          }
        ],
        "eng": "ITE"
      },
      {
        "no": "4002037000",
        "des": "職訓局",
        "n": [
          {
            "no": "4002037001",
            "des": "丙級電腦軟體設計技術士",
            "eng": "Level C technician for computer software design"
          },
          {
            "no": "4002037002",
            "des": "乙級電腦軟體應用技術士",
            "eng": "Level B technician for computer software application"
          },
          {
            "no": "4002037003",
            "des": "丙級電腦軟體應用技術士",
            "eng": "Level C technician for computer software application"
          },
          {
            "no": "4002037004",
            "des": "乙級電腦硬體裝修技術士",
            "eng": "Level B technician for computer hardware fabrication"
          },
          {
            "no": "4002037005",
            "des": "丙級電腦硬體裝修技術士",
            "eng": "Level C technician for computer hardware fabrication"
          },
          {
            "no": "4002037006",
            "des": "丙級網路架設技術士",
            "eng": "Level C technician for network installation"
          },
          {
            "no": "4002037007",
            "des": "乙級電腦軟體設計技術士-Java",
            "eng": "Level B technician for computer software application - Java"
          },
          {
            "no": "4002037008",
            "des": "乙級電腦軟體設計技術士-C++",
            "eng": "Level B technician for computer software application - C++"
          },
          {
            "no": "4002037009",
            "des": "乙級網路架設技術士",
            "eng": "Level B technician for network installation"
          }
        ],
        "eng": "Workforce Development Agency"
      },
      {
        "no": "4002039000",
        "des": "MySQL AB",
        "n": [
          {
            "no": "4002039001",
            "des": "MySQL- Professional",
            "eng": "MySQL- Professional"
          },
          {
            "no": "4002039002",
            "des": "MySQL- Core",
            "eng": "MySQL- Core"
          }
        ],
        "eng": "MySQL AB"
      },
      {
        "no": "4002038000",
        "des": "其他",
        "n": [
          {
            "no": "4002038001",
            "des": "Associate Computing Professional",
            "eng": "Associate Computing Professional"
          },
          {
            "no": "4002038003",
            "des": "CBE",
            "eng": "CBE"
          },
          {
            "no": "4002038007",
            "des": "CCA",
            "eng": "CCA"
          },
          {
            "no": "4002038002",
            "des": "CCP",
            "eng": "CCP"
          },
          {
            "no": "4002038005",
            "des": "CISA",
            "eng": "CISA"
          },
          {
            "no": "4002038006",
            "des": "CISM",
            "eng": "CISM"
          },
          {
            "no": "4002038015",
            "des": "Clarent ONE Certified",
            "eng": "Clarent ONE Certified"
          },
          {
            "no": "4002038004",
            "des": "CTE／SSD",
            "eng": "CTE/SSD"
          },
          {
            "no": "4002038009",
            "des": "Dell Certified Storage Networking Professional",
            "eng": "Dell Certified Storage Networking Professional"
          },
          {
            "no": "4002038008",
            "des": "ICDL-計算機概論",
            "eng": "ICDL"
          },
          {
            "no": "4002038016",
            "des": "ISO 27001資訊安全管理系統主導稽核員",
            "eng": "Lead inspector for ISO 27001 IT safety management"
          },
          {
            "no": "4002038012",
            "des": "Tivoli Certified Consultant",
            "eng": "Tivoli Certified Consultant"
          },
          {
            "no": "4002038013",
            "des": "Tivoli Certified Enterprise Consultant",
            "eng": "Tivoli Certified Enterprise Consultant"
          },
          {
            "no": "4002038010",
            "des": "ZCE",
            "eng": "ZCE"
          },
          {
            "no": "4002038011",
            "des": "高考資訊技師",
            "eng": "IT of higher examination"
          },
          {
            "no": "4002038017",
            "des": "BS 10012個人資料管理系統主導稽核員",
            "eng": "BS 10012：2009 PIMS Lead Auditor"
          },
          {
            "no": "4002038018",
            "des": "CISM資訊安全經理人",
            "eng": "Certified Information Systems Manager"
          },
          {
            "no": "4002038019",
            "des": "CPE大學程式能力檢定",
            "eng": "Collegiate Programming Examination (CPE)"
          },
          {
            "no": "4002038020",
            "des": "顧客關係管理系統應用師",
            "eng": "Customer Relationship Management System"
          },
          {
            "no": "4002038021",
            "des": "ICT計算機綜合能力國際認證",
            "eng": "Information and Communication Technology Programs"
          },
          {
            "no": "4002038022",
            "des": "Typing 電腦速記師國際專業認證",
            "eng": "Typing Credential"
          },
          {
            "no": "4002038023",
            "des": "經濟部初級巨量資料分析師",
            "eng": "MOEA Certified Big Data Analyst"
          },
          {
            "no": "4002038024",
            "des": "經濟部初級資訊安全工程師",
            "eng": "MOEA Certified Information Security Engineer"
          },
          {
            "no": "4002038025",
            "des": "明逸生活資訊安全認證",
            "eng": "Life Information Security (LIS)"
          },
          {
            "no": "4002038026",
            "des": "明逸計算機能力綜合認證",
            "eng": "MiNGYI Certification of Integrated Computing Competence (MCICC)"
          },
          {
            "no": "4002038027",
            "des": "AI人工智慧核心能力認證",
            "eng": "Artificial Intelligence Fundamentals (AIF)"
          },
          {
            "no": "4002038028",
            "des": "SSE Artificial Intelligence Essentials",
            "eng": "SSE Artificial Intelligence Essentials"
          },
          {
            "no": "4002038029",
            "des": "Artificial Intelligence Knowledge Today",
            "eng": "Artificial Intelligence Knowledge Today"
          },
          {
            "no": "4002038030",
            "des": "SSE Big Data Database: NoSQL國際認證",
            "eng": "Big Data Database: NoSQL"
          },
          {
            "no": "4002038031",
            "des": "SSE Big Data Infrastructure：Hadoop國際認證",
            "eng": "SSE Big Data Infrastructure：Hadoop"
          },
          {
            "no": "4002038032",
            "des": "SSE 大數據國際認證",
            "eng": "Big Data Knowledge Today"
          },
          {
            "no": "4002038033",
            "des": "SSE R 語言國際認證",
            "eng": "R Language Today"
          },
          {
            "no": "4002038034",
            "des": "SSE SAS Knowledge Today國際認證",
            "eng": "SAS Knowledge Today"
          },
          {
            "no": "4002038035",
            "des": "SSE C程式語言國際認證",
            "eng": "C Language Today"
          },
          {
            "no": "4002038036",
            "des": "SSE Java程式語言國際認證",
            "eng": "Java Language Today"
          },
          {
            "no": "4002038037",
            "des": "SSE Python程式語言國際認證",
            "eng": "Python Language Today"
          },
          {
            "no": "4002038038",
            "des": "SSE Computer Knowledge Today國際認證",
            "eng": "Computer Knowledge Today"
          },
          {
            "no": "4002038039",
            "des": "Data Analysis Using Excel國際證照",
            "eng": "Data Analysis Using Excel"
          },
          {
            "no": "4002038040",
            "des": "SSE Data Power Today國際認證",
            "eng": "SSE Data Power Today"
          },
          {
            "no": "4002038041",
            "des": "SSE Statistical Knowledge Today國際認證",
            "eng": "SSE Statistical Knowledge Today"
          },
          {
            "no": "4002038042",
            "des": "明逸Google雲端應用綜合認證",
            "eng": "MiNGYI Google Cloud Application Comprehensive Certification"
          },
          {
            "no": "4002038043",
            "des": "明逸資訊安全專業認證-資訊技術類",
            "eng": "MiNGYI Information Security Professional Certification - Information Technology Category"
          },
          {
            "no": "4002038044",
            "des": "人工智慧應用能力認證",
            "eng": "Artificial Intelligence Application Certification"
          },
          {
            "no": "4002038045",
            "des": "ISO 27701個人資料隱私管理系統主導稽核員",
            "eng": "ISO 27701"
          },
          {
            "no": "4002038046",
            "des": "SSE Linux Knowledge Today 國際認證",
            "eng": "SSE Linux Knowledge Today "
          },
          {
            "no": "4002038047",
            "des": "SSE 機器學習國際認證",
            "eng": "SSE Machine Learning"
          },
          {
            "no": "4002038048",
            "des": "CCA 核心能力國際認證-威力導演創新影音剪輯",
            "eng": "CyberLink Certified Associate(CCA): PowerDirector Creative Video Editing"
          },
          {
            "no": "4002038049",
            "des": "CCP 國際專業認證-威力導演創新影音剪輯",
            "eng": "CyberLink Certified Professional(CCP) : PowerDirector Creative Video Editing"
          },
          {
            "no": "4002038050",
            "des": "計算機程式語言能力國際認證",
            "eng": "Information and Communication Technology Programs"
          },
          {
            "no": "4002038051",
            "des": "GTC全民科技力認證",
            "eng": "Global Technology Credential Certification"
          },
          {
            "no": "4002038056",
            "des": "Databricks Lakehouse Fundamentals",
            "eng": "Databricks Lakehouse Fundamentals"
          },
          {
            "no": "4002038057",
            "des": "Azure Databricks Platform Architect",
            "eng": "Azure Databricks Platform Architect"
          },
          {
            "no": "4002038058",
            "des": "Platform Administrator",
            "eng": "Platform Administrator"
          },
          {
            "no": "4002038059",
            "des": "Generative AI Fundamentals",
            "eng": "Generative AI Fundamentals"
          },
          {
            "no": "4002038060",
            "des": "票卷數位化行銷系統認證",
            "eng": "Ticket Digital Marketing System Certification"
          },
          {
            "no": "4002038061",
            "des": "ICDL-網際網路概論",
            "eng": "ICDL-Online Essentials"
          },
          {
            "no": "4002038062",
            "des": "ICDL-文書處理",
            "eng": "ICDL-Word Processing"
          },
          {
            "no": "4002038063",
            "des": "ICDL-電子試算表",
            "eng": "ICDL-Spreadsheets"
          },
          {
            "no": "4002038064",
            "des": "ICDL-電腦簡報",
            "eng": "ICDL-Presentation"
          },
          {
            "no": "4002038065",
            "des": "ICDL-資訊安全",
            "eng": "ICDL-IT Security"
          },
          {
            "no": "4002038066",
            "des": "ICDL-雲端協同作業",
            "eng": "ICDL-Online Collaboration"
          },
          {
            "no": "4002038067",
            "des": "ICDL-網路資訊管理",
            "eng": "ICDL-Information Literacy"
          },
          {
            "no": "4002038068",
            "des": "ICDL-數位行銷",
            "eng": "ICDL-Digital Marketing"
          },
          {
            "no": "4002038069",
            "des": "ICDL-進階文書處理",
            "eng": "ICDL-Advanced Word Processing"
          },
          {
            "no": "4002038070",
            "des": "ICDL-進階電腦簡報",
            "eng": "ICDL-Advanced Presentation"
          },
          {
            "no": "4002038071",
            "des": "ICDL-進階電子試算表",
            "eng": "ICDL-Advanced Spreadsheets"
          },
          {
            "no": "4002038072",
            "des": "ICDL-專案規劃",
            "eng": "ICDL-Project Planning"
          },
          {
            "no": "4002038073",
            "des": "ICDL-運算思維與Python程式設計",
            "eng": "ICDL-Computing"
          },
          {
            "no": "4002038074",
            "des": "ICDL-電子資料庫",
            "eng": "ICDL-Using Databases"
          },
          {
            "no": "4002038075",
            "des": "ICDL-進階電子資料庫",
            "eng": "ICDL-Advanced Database"
          },
          {
            "no": "4002038076",
            "des": "ICDL-數據分析",
            "eng": "ICDL-Data Analytics"
          },
          {
            "no": "4002038077",
            "des": "ICDL-電腦輔助平面設計",
            "eng": "ICDL-2D Design"
          },
          {
            "no": "4002038078",
            "des": "ICDL-網頁編輯",
            "eng": "ICDL-Web Editing"
          },
          {
            "no": "4002038079",
            "des": "ICDL-影像編輯",
            "eng": "ICDL-Image Editing"
          },
          {
            "no": "4002038080",
            "des": "ICDL-雲端運算",
            "eng": "ICDL-Cloud Computing"
          },
          {
            "no": "4002038081",
            "des": "ICDL-物聯網",
            "eng": "ICDL-Internet of Things"
          },
          {
            "no": "4002038082",
            "des": "ICDL-人工智慧",
            "eng": "ICDL-Artificial Intelligence"
          },
          {
            "no": "4002038083",
            "des": "ICDL-大數據",
            "eng": "ICDL-Big Data"
          },
          {
            "no": "4002038084",
            "des": "ICDL-區塊鏈",
            "eng": "ICDL-Blockchain"
          },
          {
            "no": "4002038085",
            "des": "社會調查師-質性資料分析能力認證",
            "eng": "certified social researcher with concentration on qualitative data analysis"
          },
          {
            "no": "4002038086",
            "des": "社會調查師-數據資料分析能力認證",
            "eng": "certified social researcher with concentration on quantitative data analysis"
          }
        ],
        "eng": "Others"
      },
      {
        "no": "4002040000",
        "des": "CERPS",
        "n": [
          {
            "no": "4002040001",
            "des": "ERP規劃師",
            "eng": "Planner of Enterprise Resource Planning"
          },
          {
            "no": "4002040002",
            "des": "進階ERP規劃師",
            "eng": "Advanced ERP Planner"
          },
          {
            "no": "4002040003",
            "des": "ERP軟體應用師",
            "eng": "ERP Application Engineer"
          },
          {
            "no": "4002040004",
            "des": "ERP軟體顧問師",
            "eng": "ERP Software Consultant"
          },
          {
            "no": "4002040005",
            "des": "ERP導入顧問師",
            "eng": "Implementation Consultant of Enterprise Resource Planning"
          },
          {
            "no": "4002040006",
            "des": "BI軟體應用師",
            "eng": "BI Application Engineer"
          },
          {
            "no": "4002040007",
            "des": "國際物流e化系統應用師",
            "eng": "Application Engineer of e-Business Global Logistics"
          },
          {
            "no": "4002040008",
            "des": "BI規劃師",
            "eng": "BI Planner"
          },
          {
            "no": "4002040009",
            "des": "BI軟體績效管理師",
            "eng": "BI Software Performance Manager"
          },
          {
            "no": "4002040010",
            "des": "ERP基礎檢定考試(術科)",
            "eng": "ERP Basic Examination (Technical Subject)"
          },
          {
            "no": "4002040011",
            "des": "ERP基礎檢定考試(學科)",
            "eng": "ERP Basic Examination (Academic Subject)"
          },
          {
            "no": "4002040012",
            "des": "流通門市管理軟體應用師",
            "eng": "Retail Management Software Specialist"
          },
          {
            "no": "4002040013",
            "des": "旅館資訊系統規劃師",
            "eng": "Hotel Information System Planning Specialist"
          },
          {
            "no": "4002040014",
            "des": "旅館資訊系統應用師",
            "eng": "Hotel Information System Application Specialist"
          },
          {
            "no": "4002040015",
            "des": "商用雲端APP基礎檢定考試(術科)",
            "eng": "Commercial Cloud APP Basic Examination (Technical)"
          },
          {
            "no": "4002040016",
            "des": "商用雲端APP基礎檢定考試(學科)",
            "eng": "Commercial Cloud APP Basic Examination (Academic)"
          },
          {
            "no": "4002040017",
            "des": "商用雲端APP軟體設計師",
            "eng": "Commercial Cloud APP Software Designer"
          },
          {
            "no": "4002040018",
            "des": "國際物流基礎檢定考試",
            "eng": "International Logistics Basic Examination"
          },
          {
            "no": "4002040019",
            "des": "採購管理規劃師",
            "eng": "Procurement Management Planning Specialist"
          },
          {
            "no": "4002040020",
            "des": "餐飲資訊系統基礎檢定考試",
            "eng": "Catering Information System Basic Examination"
          },
          {
            "no": "4002040021",
            "des": "餐飲資訊系統應用師",
            "eng": "Catering Information System Application Specialist"
          }
        ],
        "eng": "CERPS"
      },
      {
        "no": "4002041000",
        "des": "APMG",
        "n": [
          {
            "no": "4002041001",
            "des": "ITIL V3 Foundation",
            "eng": "ITIL V3 Foundation"
          },
          {
            "no": "4002041002",
            "des": "ITIL Expert",
            "eng": "ITIL Expert"
          }
        ],
        "eng": "APMG"
      },
      {
        "no": "4002042000",
        "des": "LabVIEW",
        "n": [
          {
            "no": "4002042001",
            "des": "LabVIEW基礎認證（CLAD）",
            "eng": "CLAD"
          },
          {
            "no": "4002042002",
            "des": "LabVIEW進階認證（CLD）",
            "eng": "CLD"
          },
          {
            "no": "4002042003",
            "des": "LabVIEW高階認證（CLA）",
            "eng": "CLA"
          },
          {
            "no": "4002042004",
            "des": "TestStand進階專業認證（CTD）",
            "eng": "CTD"
          },
          {
            "no": "4002042005",
            "des": "TestStand高階專業認證（CTA）",
            "eng": "CTA"
          },
          {
            "no": "4002042006",
            "des": "LabWindows/CVI 進階專業認證（CCVID）",
            "eng": "Certified LabWindows/CVI Developer (CCVID)"
          }
        ],
        "eng": "LabVIEW"
      },
      {
        "no": "4002043000",
        "des": "VMware",
        "n": [
          {
            "no": "4002043001",
            "des": "VCP",
            "eng": "VCP"
          },
          {
            "no": "4002043002",
            "des": "VCDX",
            "eng": "VCDX"
          },
          {
            "no": "4002043003",
            "des": "VCAP",
            "eng": "VCAP"
          }
        ],
        "eng": "VMware"
      },
      {
        "no": "4002044000",
        "des": "TQC+",
        "n": [
          {
            "no": "4002044001",
            "des": "TQC+ 工程製圖專業人員",
            "eng": "TQC+ Engineering Drawing Expert"
          },
          {
            "no": "4002044002",
            "des": "TQC+ 零件設計專業人員",
            "eng": "TQC+ Parts Design Expert"
          },
          {
            "no": "4002044003",
            "des": "TQC+ 機械設計專業人員",
            "eng": "TQC+ Mechanical Design Expert"
          },
          {
            "no": "4002044004",
            "des": "TQC+ 產品設計專業人員",
            "eng": "TQC+ Product Design Expert"
          },
          {
            "no": "4002044005",
            "des": "TQC+ 平面設計專業人員",
            "eng": "TQC+ Graphic Design Expert"
          },
          {
            "no": "4002044006",
            "des": "TQC+ Flash動畫設計專業人員",
            "eng": "TQC+ Web Design Expert"
          },
          {
            "no": "4002044007",
            "des": "TQC+ 多媒體網頁設計專業人員",
            "eng": "Multimedia Web Design Expert"
          },
          {
            "no": "4002044008",
            "des": "TQC+ Windows Mobile行動裝置程式設計專業人員",
            "eng": "Windows Mobile Smart Handheld Devices Programming Expert"
          },
          {
            "no": "4002044009",
            "des": "TQC+ Java程式設計專業人員",
            "eng": "TQC+ Java Programming Expert"
          },
          {
            "no": "4002044010",
            "des": "TQC+ Android行動裝置程式設計專業人員",
            "eng": "TQC+ Android Smart Handheld Devices Programming Expert"
          },
          {
            "no": "4002044011",
            "des": "TQC+ Android行動裝置進階程式設計專業人員",
            "eng": "TQC+ Android Smart Handheld Devices Advanced Programming Expert"
          },
          {
            "no": "4002044012",
            "des": "網頁設計專業人員",
            "eng": "Web Design Expert"
          },
          {
            "no": "4002044013",
            "des": "室內設計專業人員",
            "eng": "Interior Design Expert"
          },
          {
            "no": "4002044014",
            "des": "建築設計專業人員",
            "eng": "Architecture Design Expert"
          },
          {
            "no": "4002044015",
            "des": "TQC+ C#程式設計專業人員",
            "eng": "TQC+ C# Programming Expert"
          },
          {
            "no": "4002044016",
            "des": "TQC+  ASP.NET網站程式開發專業人員",
            "eng": "TQC+ ASP.NET Web Site Programming Expert"
          },
          {
            "no": "4002044017",
            "des": "行動裝置進階應用程式設計",
            "eng": "Mobile Devices Advance Application Programming"
          },
          {
            "no": "4002044018",
            "des": "行動裝置應用程式設計",
            "eng": "Mobile Devices Application Programming"
          },
          {
            "no": "4002044019",
            "des": "物件導向程式語言 (Java/C#)",
            "eng": "Object-Oriented Programming Language (Java/C#)"
          },
          {
            "no": "4002044020",
            "des": "物件導向視窗及資料庫程式設計 (Java/C#/VisualBasic)",
            "eng": "Object-Oriented Windows and Database Programming Design (Java/C#/VisualBasic)"
          },
          {
            "no": "4002044021",
            "des": "基礎行動裝置應用程式設計",
            "eng": "Mobile Devices Application Programming"
          },
          {
            "no": "4002044022",
            "des": "基礎物件導向程式語言 (Java/C#)",
            "eng": "Basic Object-Oriented Programming Language (Java/C#)"
          },
          {
            "no": "4002044023",
            "des": "基礎物件導向視窗及資料庫程式設計VisualBasic",
            "eng": "Basic Object-Oriented Windows and Database Programming Design (VisualBasic)"
          },
          {
            "no": "4002044024",
            "des": "軟體開發知識",
            "eng": "Software Development Knowledge"
          },
          {
            "no": "4002044025",
            "des": "程式語言 (C/Python)",
            "eng": "Programming Language(C/Python)"
          },
          {
            "no": "4002044026",
            "des": "網頁程式設計HTML5",
            "eng": "Web Programming Design HTML5"
          },
          {
            "no": "4002044027",
            "des": "網站程式設計",
            "eng": "Website Programming"
          },
          {
            "no": "4002044028",
            "des": "室內設計立體製圖SketchUp Pro",
            "eng": "Interior Design 3D Drawing SketchUp Pro"
          },
          {
            "no": "4002044029",
            "des": "建築及室內設計平面製圖AutoCAD",
            "eng": "Architecture & Interior Design 2D Drawing AutoCAD"
          },
          {
            "no": "4002044030",
            "des": "建築設計立體製圖SketchUp Pro",
            "eng": "Architecture Design 3D Drawing"
          },
          {
            "no": "4002044031",
            "des": "建築設計圖學",
            "eng": "Architectural Design Graphics"
          },
          {
            "no": "4002044032",
            "des": "基礎建築及室內設計立體製圖SketchUp Pro",
            "eng": "Basic Architecture & Interior Design 3D Drawing SketchUp Pro"
          },
          {
            "no": "4002044033",
            "des": "TQC+ 數位出版美術編輯專業人員",
            "eng": "TQC+ e-Publish Art Editor Expert"
          },
          {
            "no": "4002044034",
            "des": "TQC+ 視覺傳達設計助理",
            "eng": "TQC+ Visual Communication Design Assistant"
          },
          {
            "no": "4002044035",
            "des": "Flash動畫設計",
            "eng": "Flash Animation Design"
          },
          {
            "no": "4002044036",
            "des": "Flash動畫編輯製作",
            "eng": "Flash Animation Editing"
          },
          {
            "no": "4002044037",
            "des": "非線性剪輯Premiere Pro",
            "eng": "Artisan for water pipe installation"
          },
          {
            "no": "4002044038",
            "des": "基礎媒體匯流與應用",
            "eng": "Artisan with qualified certificate for water pipe installation"
          },
          {
            "no": "4002044039",
            "des": "基礎編排設計InDesign",
            "eng": "Basic Layout Design (InDesign)"
          },
          {
            "no": "4002044040",
            "des": "媒體匯流與應用",
            "eng": "Media Convergence and Application"
          },
          {
            "no": "4002044041",
            "des": "電腦圖像編輯製作Illustrator",
            "eng": "Computer Graphic Editing"
          },
          {
            "no": "4002044042",
            "des": "電腦繪圖設計Illustrator",
            "eng": "Computer Graphic Design"
          },
          {
            "no": "4002044043",
            "des": "電腦繪圖概論與數位色彩配色",
            "eng": "Computer Graphic Concept & Digital Colors"
          },
          {
            "no": "4002044044",
            "des": "網頁設計Dreamweaver",
            "eng": "Web Design (Dreamweaver)"
          },
          {
            "no": "4002044045",
            "des": "網頁編輯製作Dreamweaver",
            "eng": "Web Editing and Creation (Dreamweaver)"
          },
          {
            "no": "4002044046",
            "des": "影像處理Photoshop",
            "eng": "Image Processing Photoshop"
          },
          {
            "no": "4002044047",
            "des": "影像編輯製作Photoshop",
            "eng": "Image Production Editing Photoshop"
          },
          {
            "no": "4002044048",
            "des": "數位媒體出版",
            "eng": "Digital Media Publishing"
          },
          {
            "no": "4002044049",
            "des": "編排設計InDesign",
            "eng": "Layout Design (InDesign)"
          },
          {
            "no": "4002044050",
            "des": "基礎創意App程式設計App Inventor",
            "eng": "Basic Creative APP Programming App Inventor"
          },
          {
            "no": "4002044051",
            "des": "創意App程式設計App Inventor",
            "eng": "Creative APP Programming"
          },
          {
            "no": "4002044052",
            "des": "工程圖學及機械製圖",
            "eng": "Engineering & Mechanical Graphics"
          },
          {
            "no": "4002044053",
            "des": "商品造形設計Rhinoceros",
            "eng": "Basic Product Appearance Design - Rhinoceros"
          },
          {
            "no": "4002044054",
            "des": "基礎商品造形設計Rhinoceros",
            "eng": "Artisan with qualified certificate for gas & fuel guiding pipe installation"
          },
          {
            "no": "4002044055",
            "des": "基礎零件設計 (Creo Parametric/SolidWorks/Inventor)",
            "eng": "Basic Parts Design"
          },
          {
            "no": "4002044056",
            "des": "基礎電腦輔助平面製圖AutoCAD",
            "eng": "AutoCAD- -Basic CAD 2D Drawing"
          },
          {
            "no": "4002044057",
            "des": "基礎電腦輔助立體製圖AutoCAD",
            "eng": "AutoCAD - Basic CAD 3D Drawing"
          },
          {
            "no": "4002044058",
            "des": "造形設計及材質運用",
            "eng": "Level C technician for shoe manufacturing"
          },
          {
            "no": "4002044059",
            "des": "進階零件及曲面設計 (Creo Parametric/SolidWorks)",
            "eng": "Advanced Design of Parts & Surface"
          },
          {
            "no": "4002044060",
            "des": "電腦輔助平面製圖AutoCAD",
            "eng": "Level C technician for plastic injection forming"
          },
          {
            "no": "4002044061",
            "des": "電腦輔助立體製圖AutoCAD",
            "eng": "AutoCAD - CAD 3D Drawing"
          },
          {
            "no": "4002044062",
            "des": "實體設計 (Creo Parametric/SolidWorks)",
            "eng": "Solidworks "
          },
          {
            "no": "4002044063",
            "des": "TQC+ 電路設計專業人員",
            "eng": "TQC+ Circuit Design Expert"
          },
          {
            "no": "4002044064",
            "des": "TQC+ 電路佈線專業人員",
            "eng": "TQC+ Circuit Wiring Expert"
          },
          {
            "no": "4002044065",
            "des": "TQC+ 電路佈局專業人員",
            "eng": "TQC+ Circuit Layout Expert"
          },
          {
            "no": "4002044066",
            "des": "電子電路與數位邏輯設計",
            "eng": "Electronic Circuit & Digital Logic Design"
          },
          {
            "no": "4002044067",
            "des": "電路佈線OrCAD",
            "eng": "Circuit Wiring OrCAD"
          },
          {
            "no": "4002044068",
            "des": "電路設計OrCAD",
            "eng": "Circuit Design OrCAD"
          },
          {
            "no": "4002044069",
            "des": "電路模擬分析OrCAD",
            "eng": "Circuit Simulation and Analysis OrCAD"
          },
          {
            "no": "4002044070",
            "des": "基礎非線性剪輯Premiere Pro",
            "eng": "Basic Non-Linear Editing with Premiere Pro"
          },
          {
            "no": "4002044071",
            "des": "App Inventor程式設計核心能力",
            "eng": "App Inventor Programming Key Competencies"
          },
          {
            "no": "4002044072",
            "des": "物聯網開發核心知識",
            "eng": "Key Knowledge of IoT Development"
          },
          {
            "no": "4002044073",
            "des": "物聯網互動技術 ",
            "eng": "IoT Interactive Technology"
          },
          {
            "no": "4002044074",
            "des": "基礎商品彩現V-Ray for Rhino",
            "eng": "Basic Product Rendering"
          },
          {
            "no": "4002044075",
            "des": "商品彩現 (V-Ray for Rhino/KeyShot)",
            "eng": "Product Rendering (V-Ray for Rhino/KeyShot)"
          },
          {
            "no": "4002044076",
            "des": "基礎程式語言",
            "eng": "Basic Programming Language"
          },
          {
            "no": "4002044077",
            "des": "人工智慧：機器學習 Python",
            "eng": "Machine Learning Python"
          },
          {
            "no": "4002044078",
            "des": "使用者介面設計 Adobe XD",
            "eng": "User Interface Design (Adobe XD)"
          },
          {
            "no": "4002044079",
            "des": "基礎程式設計 Python",
            "eng": "Basic Programming (Python)"
          },
          {
            "no": "4002044080",
            "des": "網頁資料擷取與分析 Python",
            "eng": "Web Data Scraping and Analysis (Python)"
          },
          {
            "no": "4002044081",
            "des": "網頁資料擷取與分析 R",
            "eng": "Web Data Scraping and Analysis ®"
          },
          {
            "no": "4002044082",
            "des": "TQC+ AD-建築設計圖學",
            "eng": "TQC+ AD-Architectural Design Graphics"
          },
          {
            "no": "4002044083",
            "des": "TQC+ AD-建築及室內設計平面製圖",
            "eng": "TQC+ AD-Architecture & Interior Design 2D Drawing"
          },
          {
            "no": "4002044084",
            "des": "TQC+ AD-建築設計立體製圖",
            "eng": "TQC+ AD-Architecture Design 3D Drawing"
          },
          {
            "no": "4002044085",
            "des": "TQC+ AD-室內設計立體製圖",
            "eng": "TQC+ AD-Interior Design 3D Drawing"
          },
          {
            "no": "4002044086",
            "des": "TQC+ AD-基礎建築及室內設計立體製圖",
            "eng": "TQC+ AD-Basic Architecture & Interior Design 3D Drawing"
          },
          {
            "no": "4002044087",
            "des": "TQC+ CD-電子電路與數位邏輯設計",
            "eng": "TQC+ CD-Electronic Circuit & Digital Logic Design"
          },
          {
            "no": "4002044088",
            "des": "TQC+ CD-電路設計",
            "eng": "TQC+ CD-Circuit Design"
          },
          {
            "no": "4002044089",
            "des": "TQC+ CD-電路佈線",
            "eng": "TQC+ CD-Circuit Wiring"
          },
          {
            "no": "4002044090",
            "des": "TQC+ CD-電路模擬分析",
            "eng": "TQC+ CD-Circuit Simulation and Analysis"
          },
          {
            "no": "4002044091",
            "des": "TQC+ ED-工程圖學及機械製圖",
            "eng": "TQC+ ED-Engineering & Mechanical Graphics"
          },
          {
            "no": "4002044092",
            "des": "TQC+ ED-電腦輔助平面製圖CAD 2D",
            "eng": "TQC+ ED-CAD 2D Drawing"
          },
          {
            "no": "4002044093",
            "des": "TQC+ ED-電腦輔助立體製圖CAD 3D",
            "eng": "TQC+ ED-CAD 3D Drawing"
          },
          {
            "no": "4002044094",
            "des": "TQC+ ED-基礎零件設計",
            "eng": "TQC+ ED-Basic Parts Design"
          },
          {
            "no": "4002044095",
            "des": "TQC+ ED-實體設計",
            "eng": "TQC+ ED-Solid Modeling"
          },
          {
            "no": "4002044096",
            "des": "TQC+ ED-進階零件及曲面設計",
            "eng": "TQC+ ED-Advanced Design of Parts & Surface"
          },
          {
            "no": "4002044097",
            "des": "TQC+ ED-基礎電腦輔助平面製圖",
            "eng": "TQC+ ED-Basic CAD 2D Drawing"
          },
          {
            "no": "4002044098",
            "des": "TQC+ ED-基礎電腦輔助立體製圖",
            "eng": "TQC+ ED-Basic CAD 3D Drawing"
          },
          {
            "no": "4002044099",
            "des": "TQC+ ED-商品造形設計",
            "eng": "TQC+ ED-Product Appearance Design"
          },
          {
            "no": "4002044100",
            "des": "TQC+ ED-基礎商品造形設計",
            "eng": "TQC+ ED-Basic Product Appearance Design"
          },
          {
            "no": "4002044101",
            "des": "TQC+ ED-造形設計及材質運用",
            "eng": "TQC+ ED-Formative Design and Application of Material"
          },
          {
            "no": "4002044102",
            "des": "TQC+ ID-基礎創意App程式設計",
            "eng": "TQC+ ID-Basic Creative APP Programming"
          },
          {
            "no": "4002044103",
            "des": "TQC+ ID-創意App程式設計",
            "eng": "TQC+ ID-Creative APP Programming"
          },
          {
            "no": "4002044104",
            "des": "TQC+ ID-物聯網開發核心知識",
            "eng": "TQC+ ID-Key Knowledge of IoT Development"
          },
          {
            "no": "4002044105",
            "des": "TQC+ ID-物聯網互動技術",
            "eng": "TQC+ ID-IoT Interactive Technology"
          },
          {
            "no": "4002044106",
            "des": "TQC+ ID-App Inventor程式設計核心能力",
            "eng": "TQC+ ID-App Inventor Programming Key Competencies"
          },
          {
            "no": "4002044107",
            "des": "TQC+ SD-軟體開發知識",
            "eng": "TQC+ SD-Software Development Knowledge"
          },
          {
            "no": "4002044108",
            "des": "TQC+ SD-物件導向程式語言",
            "eng": "TQC+ SD-Object-oriented Programming Language"
          },
          {
            "no": "4002044109",
            "des": "TQC+ SD-物件導向視窗及資料庫程式設計",
            "eng": "TQC+ SD-Object-oriented GUI and Database Programming"
          },
          {
            "no": "4002044110",
            "des": "TQC+ SD-行動裝置應用程式設計",
            "eng": "TQC+ SD-Mobile Devices Application Programming"
          },
          {
            "no": "4002044111",
            "des": "TQC+ SD-程式語言",
            "eng": "TQC+ SD-Programming Language"
          },
          {
            "no": "4002044112",
            "des": "TQC+ SD-網頁程式設計",
            "eng": "TQC+ SD-Webpage Program Design"
          },
          {
            "no": "4002044113",
            "des": "TQC+ SD-網站程式設計",
            "eng": "TQC+ SD-Website Programming"
          },
          {
            "no": "4002044114",
            "des": "TQC+ SD-基礎物件導向程式語言",
            "eng": "TQC+ SD-Basic Object-oriented Programming Language"
          },
          {
            "no": "4002044115",
            "des": "TQC+ SD-基礎行動裝置應用程式設計",
            "eng": "TQC+ SD-Basic Mobile Devices Application Programming"
          },
          {
            "no": "4002044116",
            "des": "TQC+ SD-基礎物件導向視窗及資料庫程式設計",
            "eng": "TQC+ SD-Basic Object-oriented GUI and Database Programming"
          },
          {
            "no": "4002044117",
            "des": "TQC+ SD-基礎程式語言",
            "eng": "TQC+ SD-Basic Programming Language"
          },
          {
            "no": "4002044118",
            "des": "TQC+ SD-網頁資料擷取與分析",
            "eng": "TQC+ SD-Web Data Extraction and Analysis"
          },
          {
            "no": "4002044119",
            "des": "TQC+ SD-人工智慧：機器學習",
            "eng": "TQC+ SD-Machine Learming"
          },
          {
            "no": "4002044120",
            "des": "TQC+ VD-電腦繪圖概論與數位色彩配色",
            "eng": "TQC+ VD-Computer Graphic Concept & Digital Colors"
          },
          {
            "no": "4002044121",
            "des": "TQC+ VD-電腦繪圖設計",
            "eng": "TQC+ VD-Computer Graphic Concept & Digital Colors"
          },
          {
            "no": "4002044122",
            "des": "TQC+ VD-影像處理",
            "eng": "TQC+ VD-Image Processing"
          },
          {
            "no": "4002044123",
            "des": "TQC+ VD-網頁設計",
            "eng": "TQC+ VD-Web Design"
          },
          {
            "no": "4002044124",
            "des": "TQC+ VD-Flash動畫設計",
            "eng": "TQC+ VD-Flash Animation Design"
          },
          {
            "no": "4002044125",
            "des": "TQC+ VD-非線性剪輯",
            "eng": "TQC+ VD-Non-linear Editing"
          },
          {
            "no": "4002044126",
            "des": "TQC+ VD-使用者介面設計",
            "eng": "TQC+ VD-User Interface Design"
          },
          {
            "no": "4002044127",
            "des": "TQC+ VD-電腦圖像編輯製作",
            "eng": "TQC+ VD-Computer Graphic Editing"
          },
          {
            "no": "4002044128",
            "des": "TQC+ VD-影像編輯製作",
            "eng": "TQC+ VD-Image Production Editing"
          },
          {
            "no": "4002044129",
            "des": "TQC+ VD-網頁編輯製作",
            "eng": "TQC+ VD-Web Production Editing"
          },
          {
            "no": "4002044130",
            "des": "TQC+ VD-Flash動畫編輯製作",
            "eng": "TQC+ VD-Flash Animation Editing"
          },
          {
            "no": "4002044131",
            "des": "TQC+ VD-基礎非線性剪輯",
            "eng": "TQC+ VD-Basic Non-linear Editing"
          },
          {
            "no": "4002044132",
            "des": "TQC+ VD-響應式網頁設計",
            "eng": "TQC+ VD-Responsive Web Design"
          },
          {
            "no": "4002044133",
            "des": "TQC+ VD-響應式網頁編輯製作",
            "eng": "TQC+ VD-Responsive Web Production Editing"
          },
          {
            "no": "4002044134",
            "des": "TQC+ 室內設計專業人員",
            "eng": "TQC+ Interior Design Expert"
          },
          {
            "no": "4002044135",
            "des": "TQC+ 建築設計專業人員",
            "eng": "TQC+ Architecture Design Expert"
          },
          {
            "no": "4002044136",
            "des": "TQC+ Python大數據分析專業人員",
            "eng": "TQC+ Python Data Analyst Expert"
          },
          {
            "no": "4002044137",
            "des": "TQC+ Python機器學習專業人員",
            "eng": "TQC+ Python Machine Learning Expert"
          }
        ],
        "eng": "TQC+"
      },
      {
        "no": "4002045000",
        "des": "D-Link",
        "n": [
          {
            "no": "4002045001",
            "des": "D-Link 業務專員認證",
            "eng": "D-Link Sales Specialist"
          },
          {
            "no": "4002045002",
            "des": "D-Link網路基礎認證",
            "eng": "D-Link Network Associate"
          },
          {
            "no": "4002045003",
            "des": "D-Link認證網路工程師",
            "eng": "D-Link Certified Specialist"
          },
          {
            "no": "4002045004",
            "des": "D-Link認證網路專家",
            "eng": "D-Link Certified Professional"
          }
        ],
        "eng": "D-Link"
      },
      {
        "no": "4002046000",
        "des": "行動裝置",
        "n": [
          {
            "no": "4002046001",
            "des": "經濟部初級遊戲程式設計師-行動遊戲(Android)",
            "eng": "MOEA Certified Android Mobile Game Programming Associate"
          },
          {
            "no": "4002046002",
            "des": "經濟部初級遊戲程式設計師-行動遊戲(iOS)",
            "eng": "MOEA Certified iOS Mobile Game Programming Associate"
          },
          {
            "no": "4002046003",
            "des": "經濟部初級行動裝置程式設計師(Android)",
            "eng": "MOEA Certified Android Mobile App Development Associate"
          },
          {
            "no": "4002046004",
            "des": "經濟部初級行動裝置程式設計師(iOS)",
            "eng": "MOEA Certified iOS Mobile App Development Associate"
          }
        ],
        "eng": "Mobile Device"
      },
      {
        "no": "4002047000",
        "des": "Amazon Web  Service",
        "n": [
          {
            "no": "4002047001",
            "des": "AWS認證的系統操作管理員-助理級認證",
            "eng": "AWS Certified SysOps Administrator - Associate"
          },
          {
            "no": "4002047002",
            "des": "AWS認證的開發人員-助理級認證",
            "eng": "AWS Certified Developer - Associate"
          },
          {
            "no": "4002047003",
            "des": "AWS認證的開發運維工程師-專家級認證",
            "eng": "AWS Certified DevOps Engineer - Professional"
          },
          {
            "no": "4002047004",
            "des": "AWS認證的解決方案架構師-助理級認證",
            "eng": "AWS Certified Solutions Architect - Associate"
          },
          {
            "no": "4002047005",
            "des": "AWS認證的解決方案架構師-專家級認證",
            "eng": "AWS Certified Solutions Architect - Professional"
          },
          {
            "no": "4002047006",
            "des": "AWS認證的雲端從業人員",
            "eng": "AWS Certified Cloud Practitioner"
          },
          {
            "no": "4002047007",
            "des": "AWS認證的高級聯網-專業級認證",
            "eng": "AWS Certified Advanced Networking - Specialty"
          },
          {
            "no": "4002047008",
            "des": "AWS認證的資料分析-專業級認證",
            "eng": "AWS Certified Data Analytics-Specialty"
          },
          {
            "no": "4002047009",
            "des": "AWS認證的數據庫-專業級認證",
            "eng": "AWS Certified Database - Specialty"
          },
          {
            "no": "4002047010",
            "des": "AWS認證的機器學習-專業級認證",
            "eng": "AWS Certified Machine Learning - Specialty"
          },
          {
            "no": "4002047011",
            "des": "AWS認證的安全防護-專業級認證",
            "eng": "AWS Certified Security - Specialty"
          }
        ],
        "eng": "Amazon Web Service"
      },
      {
        "no": "4002048000",
        "des": "Google",
        "n": [
          {
            "no": "4002048001",
            "des": "Google Analytics (分析) 個人認證資格",
            "eng": "Google Analytics (Analysis) Personal Certification Qualification"
          },
          {
            "no": "4002048002",
            "des": "Google Adwords 認證",
            "eng": "Google Adwords Certification"
          },
          {
            "no": "4002048003",
            "des": "Google Ads 搜尋廣告認證",
            "eng": "Google Ads Search Advertising Certification"
          },
          {
            "no": "4002048004",
            "des": "Google Ads 多媒體廣告認證",
            "eng": "Google Ads Multimedia Advertising Certification"
          },
          {
            "no": "4002048005",
            "des": "Google Ads 影片廣告認證",
            "eng": "Google Ads Video Advertising Certification"
          },
          {
            "no": "4002048006",
            "des": "購物廣告認證",
            "eng": "Shopping Advertising Certification"
          },
          {
            "no": "4002048007",
            "des": "Google雲端助理工程師認證",
            "eng": "Google Certified Associate Cloud Engineer"
          },
          {
            "no": "4002048008",
            "des": "Google專業雲端架構師認證",
            "eng": "Google Certified Professional Cloud Architect"
          },
          {
            "no": "4002048009",
            "des": "Google專業資料工程師認證",
            "eng": "Google Certified Professional Data Engineer"
          }
        ],
        "eng": "Google"
      },
      {
        "no": "4002049000",
        "des": "Salesforce",
        "n": [
          {
            "no": "4002049001",
            "des": "Salesforce Administrator",
            "eng": "Salesforce Administrator"
          },
          {
            "no": "4002049002",
            "des": "Salesforce Platform Developer",
            "eng": "Salesforce Platform Developer"
          },
          {
            "no": "4002049003",
            "des": "Salesforce Platform App Builder",
            "eng": "Salesforce Platform App Builder"
          }
        ],
        "eng": "Salesforce"
      },
      {
        "no": "4002050000",
        "des": "TIPCI",
        "n": [
          {
            "no": "4002050001",
            "des": "人工智慧應用能力認證",
            "eng": "Artificial Intelligence Application Certification"
          },
          {
            "no": "4002050002",
            "des": "網際網路實務與應用",
            "eng": "Internet Practice and Application"
          },
          {
            "no": "4002050003",
            "des": "程式設計與軟體開發知識",
            "eng": "Internet Practice and Application"
          },
          {
            "no": "4002050004",
            "des": "雲端技術網路服務",
            "eng": "Cloud Network Services"
          },
          {
            "no": "4002050005",
            "des": "電子商務規劃師",
            "eng": "E-commerce Planner"
          },
          {
            "no": "4002050006",
            "des": "網頁設計規劃師",
            "eng": "Web Design Planner"
          },
          {
            "no": "4002050007",
            "des": "Python程式設計應用認證",
            "eng": "Applications of Python Programming Certification"
          },
          {
            "no": "4002050008",
            "des": "Micro:bit程式設計應用認證",
            "eng": "Applications of Micro：bit Programming Certification"
          },
          {
            "no": "4002050009",
            "des": "網頁設計能力認證",
            "eng": "Web Page Design Application Certification"
          },
          {
            "no": "4002050010",
            "des": "系統設計分析師",
            "eng": "System Design Analyst"
          },
          {
            "no": "4002050011",
            "des": "數位內容行動遊戲規劃師",
            "eng": "Digital Content Action Game Planner"
          }
        ],
        "eng": "TIPCI"
      },
      {
        "no": "4002051000",
        "des": "CNCF",
        "n": [
          {
            "no": "4002051001",
            "des": "Certified Kubernetes Administrator(CKA)",
            "eng": "Certified Kubernetes Administrator(CKA)"
          },
          {
            "no": "4002051002",
            "des": "Certified Kubernetes Security Specialist",
            "eng": "Certified Kubernetes Security Specialist"
          }
        ],
        "eng": "CNCF"
      },
      {
        "no": "4002052000",
        "des": "HashiCorp",
        "n": [
          {
            "no": "4002052001",
            "des": "Infrastructure Automation Certification",
            "eng": "Infrastructure Automation Certification"
          },
          {
            "no": "4002052002",
            "des": "Security Automation Certification",
            "eng": "Security Automation Certification"
          },
          {
            "no": "4002052003",
            "des": "Networking Automation Certification",
            "eng": "Networking Automation Certification"
          }
        ],
        "eng": "HashiCorp"
      },
      {
        "no": "4002053000",
        "des": "Autodesk",
        "n": [
          {
            "no": "4002053001",
            "des": "AutoCAD ACU 原廠國際認證",
            "eng": "Autodesk Certified User: AutoCAD"
          },
          {
            "no": "4002053002",
            "des": "3dsMax ACU 原廠國際認證",
            "eng": "Autodesk Certified User: 3dsMax"
          },
          {
            "no": "4002053003",
            "des": "Revit Architecture ACU 原廠國際認證",
            "eng": "Autodesk Certified User: Revit Architecture"
          },
          {
            "no": "4002053004",
            "des": "Maya ACU 原廠國際認證",
            "eng": "Autodesk Certified User: Maya"
          },
          {
            "no": "4002053005",
            "des": "Inventor ACU 原廠國際認證",
            "eng": "Autodesk Certified User: Inventor"
          },
          {
            "no": "4002053006",
            "des": "Fusion360 ACU 原廠國際認證",
            "eng": "Autodesk Certified User: Fusion 360"
          }
        ],
        "eng": "Autodesk"
      },
      {
        "no": "4002054000",
        "des": "Unity",
        "n": [
          {
            "no": "4002054001",
            "des": "Unity 藝術設計師認證",
            "eng": "Unity Certified User: Artist"
          },
          {
            "no": "4002054002",
            "des": "Unity 程式設計師認證",
            "eng": "Unity Certified User: Programmer"
          },
          {
            "no": "4002054003",
            "des": "VR 虛擬實境開發認證",
            "eng": "VR Developer Certification"
          }
        ],
        "eng": "Unity"
      }
    ],
    "eng": "Computer"
  },
  {
    "no": "4004000000",
    "des": "電子電機類證照",
    "n": [
      {
        "no": "4004001000",
        "des": "電子類證照",
        "n": [
          {
            "no": "4004001001",
            "des": "高級電信工程人員",
            "eng": "Engineering personnel for high level telecommunication"
          },
          {
            "no": "4004001003",
            "des": "無線通訊工程師",
            "eng": "Engineer for wireless communication"
          },
          {
            "no": "4004001004",
            "des": "高考電子工程技師",
            "eng": "Technician of higher examination for electronic engineering"
          },
          {
            "no": "4004001005",
            "des": "特考船舶電信人員",
            "eng": "Telecommunication personnel of special examination for ships"
          },
          {
            "no": "4004001006",
            "des": "丙級工業電子技術士",
            "eng": "Level C technician for industrial electronics"
          },
          {
            "no": "4004001007",
            "des": "乙級電力電子技術士",
            "eng": "Level B technician for power electronics"
          },
          {
            "no": "4004001008",
            "des": "丙級電力電子技術士",
            "eng": "Level C technician for power electronics"
          },
          {
            "no": "4004001009",
            "des": "乙級數位電子技術士",
            "eng": "Level B technician for digital electronics"
          },
          {
            "no": "4004001010",
            "des": "丙級數位電子技術士",
            "eng": "Level C technician for digital electronics"
          },
          {
            "no": "4004001011",
            "des": "有線電話作業員",
            "eng": "Telephone operator"
          },
          {
            "no": "4004001012",
            "des": "甲級視聽電子技術士",
            "eng": "Level A technician for audio electronics"
          },
          {
            "no": "4004001013",
            "des": "乙級視聽電子技術士",
            "eng": "Level B technician for audio electronics"
          },
          {
            "no": "4004001014",
            "des": "丙級視聽電子技術士",
            "eng": "Level C technician for audio electronics"
          },
          {
            "no": "4004001015",
            "des": "乙級工業儀器技術士",
            "eng": "Level B technician for industrial instruments"
          },
          {
            "no": "4004001018",
            "des": "甲級儀表電子技術士",
            "eng": "Level A technician for panel electronics"
          },
          {
            "no": "4004001019",
            "des": "乙級儀表電子技術士",
            "eng": "Level B technician for panel electronics"
          },
          {
            "no": "4004001020",
            "des": "乙級測量儀器修護技術士",
            "eng": "Level B technician for repairing survey instrument"
          },
          {
            "no": "4004001021",
            "des": "丙級測量儀器修護技術士",
            "eng": "Level C technician for repairing survey instrument"
          },
          {
            "no": "4004001022",
            "des": "地面機械員檢定證航空器通信電子維護類",
            "eng": "Ground mechanics rating certificate for communication & electronic maintenance of air craft"
          },
          {
            "no": "4004001016",
            "des": "丙級工業儀器技術士",
            "eng": "Level C technician for industrial instruments"
          },
          {
            "no": "4004001017",
            "des": "丙級事務機器修護技術士",
            "eng": "Level C technician for repairing business machines"
          },
          {
            "no": "4004001023",
            "des": "國際電子製程工程師認證",
            "eng": "Certified Electronic Process Engineer-I "
          },
          {
            "no": "4004001024",
            "des": "甲級電力電子技術士",
            "eng": "Level A technician for power electronics"
          },
          {
            "no": "4004001025",
            "des": "甲級數位電子技術士",
            "eng": "Level A technician for digital electronics"
          },
          {
            "no": "4004001026",
            "des": "乙級工業電子技術士",
            "eng": "Level B technician for industrial electronics"
          },
          {
            "no": "4004001027",
            "des": "甲級工業儀器技術士",
            "eng": "Level A technician for industry instrument"
          },
          {
            "no": "4004001028",
            "des": "乙級通信技術(電信線路)技術士",
            "eng": "Level B technician for communication technology (telecommunication outside plant)"
          },
          {
            "no": "4004001029",
            "des": "丙級通信技術(電信線路)技術士",
            "eng": "Level C technician for communication technology (telecommunication outside plant)"
          },
          {
            "no": "4004001030",
            "des": "單晶片專業級能力認證",
            "eng": "Microcontroller B-class Certification"
          },
          {
            "no": "4004001031",
            "des": "單晶片實用級能力認證",
            "eng": "Microcontroller C-class Certification"
          },
          {
            "no": "4004001032",
            "des": "TQC專業行動裝置應用工程師",
            "eng": "TQC-Professional Mobile Applications Engineer"
          },
          {
            "no": "4004001033",
            "des": "LED工程師基礎能力鑑定",
            "eng": "Professional LED Engineer-Entry Level"
          },
          {
            "no": "4004001034",
            "des": "LED照明工程師能力鑑定",
            "eng": "Professional LED Engineer-Lighting products"
          },
          {
            "no": "4004001035",
            "des": "單晶片專家能力認證",
            "eng": "Microcontroller A-class Certification"
          },
          {
            "no": "4004001036",
            "des": "電子元件拆與銲能力認證－專業級",
            "eng": "Electronic Components Soldering and Unsoldering Certification－Specialist Class"
          },
          {
            "no": "4004001037",
            "des": "電子元件拆與銲能力認證－實用級",
            "eng": "Electronic Components Soldering and Unsoldering Certification－Practician Class"
          },
          {
            "no": "4004001038",
            "des": "數位邏輯設計能力認證－專業級",
            "eng": "Digital Logic Design Certification-Specialist Class"
          },
          {
            "no": "4004001039",
            "des": "數位邏輯設計能力認證－實用級",
            "eng": "Digital Logic Design Certification-Practician Class"
          },
          {
            "no": "4004001040",
            "des": "電路板設計國際能力認證 (PCB LAYOUT)－專業級",
            "eng": "International Certification of the Circuit Board Design-Specialist Class"
          },
          {
            "no": "4004001041",
            "des": "電路板設計國際能力認證 (PCB LAYOUT)－實用級",
            "eng": "International Certification of the Circuit Board Design-Practician Class"
          },
          {
            "no": "4004001042",
            "des": "德州儀器高階微控制器國際能力認證",
            "eng": "Texas Instruments Advanced Microcontroller International Capability Certification"
          },
          {
            "no": "4004001043",
            "des": "經濟部初級電路板製程工程師",
            "eng": "MOEA Certified PCB Process Associate"
          },
          {
            "no": "4004001044",
            "des": "經濟部中級軟性電路板製程工程師",
            "eng": "MOEA Certified Flexible PCB Process Specialist"
          },
          {
            "no": "4004001045",
            "des": "經濟部高級軟性電路板製程工程師",
            "eng": "MOEA Certified Flexible PCB Process Expert"
          },
          {
            "no": "4004001046",
            "des": "經濟部中級硬式電路板製程工程師",
            "eng": "MOEA Certified Rigid PCB Process Specialist"
          },
          {
            "no": "4004001047",
            "des": "經濟部高級硬式電路板製程工程師",
            "eng": "MOEA Certified Rigid PCB Process Expert"
          },
          {
            "no": "4004001048",
            "des": "AMA先進微控制器應用認證",
            "eng": "Advance Microcontroller Application"
          },
          {
            "no": "4004001049",
            "des": "PCB先進電路板設計應用認證",
            "eng": "Advance Printed Circuit Board Layout and Design"
          },
          {
            "no": "4004001050",
            "des": "甲級通信技術(電信線路)技術士",
            "eng": "Level A technician for communication technology (telecommunication outside plant)"
          },
          {
            "no": "4004001051",
            "des": "電子電路專業知識認證",
            "eng": "Electronic Circuits Expertise Certification"
          }
        ],
        "eng": "Electronic Certifications"
      },
      {
        "no": "4004002000",
        "des": "電機類證照",
        "n": [
          {
            "no": "4004002002",
            "des": "高考電機工程技師",
            "eng": "Technician of higher examination for electromechanical engineering"
          },
          {
            "no": "4004002003",
            "des": "甲級工業配線技術士",
            "eng": "Level A technician for industrial wiring work"
          },
          {
            "no": "4004002004",
            "des": "乙級工業配線技術士",
            "eng": "Level B technician for industrial wiring work"
          },
          {
            "no": "4004002005",
            "des": "丙級工業配線技術士",
            "eng": "Level C technician for industrial wiring work"
          },
          {
            "no": "4004002006",
            "des": "高考冷凍空調工程技師",
            "eng": "Technician of higher examination for freezing & air condition engineering"
          },
          {
            "no": "4004002007",
            "des": "甲級室內配線技術士",
            "eng": "Level A technician for interior wiring work"
          },
          {
            "no": "4004002008",
            "des": "乙級室內配線技術士",
            "eng": "Level B technician for interior wiring work"
          },
          {
            "no": "4004002009",
            "des": "丙級室內配線技術士",
            "eng": "Level C technician for interior wiring work"
          },
          {
            "no": "4004002013",
            "des": "乙種電匠",
            "eng": "class B electrician"
          },
          {
            "no": "4004002015",
            "des": "丙級用電設備檢驗技術士",
            "eng": "Level C technician for inspecting power consuming equipment"
          },
          {
            "no": "4004002016",
            "des": "甲種電匠",
            "eng": "class A electrician"
          },
          {
            "no": "4004002017",
            "des": "乙級電器修護技術士",
            "eng": "Level A technician for repairing electrical appliances"
          },
          {
            "no": "4004002018",
            "des": "丙級電器修護技術士",
            "eng": "Level C technician for repairing electrical appliances"
          },
          {
            "no": "4004002019",
            "des": "甲級冷凍空調裝修技術士",
            "eng": "Level A technician for freezing & air condition installation & repair"
          },
          {
            "no": "4004002020",
            "des": "乙級冷凍空調裝修技術士",
            "eng": "Level B technician for freezing & air condition installation & repair"
          },
          {
            "no": "4004002021",
            "des": "丙級冷凍空調裝修技術士",
            "eng": "Level C technician for freezing & air condition installation & repair"
          },
          {
            "no": "4004002022",
            "des": "丙級變電設備裝修技術士",
            "eng": "Level C technician for transforming equipment installation & repair"
          },
          {
            "no": "4004002023",
            "des": "丙級船舶室內配線技術士",
            "eng": "Level C technician for interior wiring of ships"
          },
          {
            "no": "4004002024",
            "des": "乙級變壓器裝修技術士",
            "eng": "Level B technician for transformer installation and repair"
          },
          {
            "no": "4004002025",
            "des": "丙級變壓器裝修技術士",
            "eng": "Level C technician for transformer installation and repair"
          },
          {
            "no": "4004002026",
            "des": "甲級升降機裝修技術士",
            "eng": "Level A technician for lift installation and repair"
          },
          {
            "no": "4004002027",
            "des": "乙級升降機裝修技術士",
            "eng": "Level B technician for lift installation and repair"
          },
          {
            "no": "4004002028",
            "des": "丙級升降機裝修技術士",
            "eng": "Level C technician for lift installation and repair"
          },
          {
            "no": "4004002029",
            "des": "乙級配電電纜裝修技術士",
            "eng": "Level B technician for cable installation and repair"
          },
          {
            "no": "4004002030",
            "des": "丙級配電電纜裝修技術士",
            "eng": "Level C technician for cable installation and repair"
          },
          {
            "no": "4004002031",
            "des": "乙級旋轉電機裝修技術士",
            "eng": "Level B technician for electric rotating machinery installation and repair"
          },
          {
            "no": "4004002032",
            "des": "丙級旋轉電機裝修技術士",
            "eng": "Level C technician for electric rotating machinery installation and repair"
          },
          {
            "no": "4004002033",
            "des": "甲級配電線路裝修技術士",
            "eng": "Level A technician for power distribution wires installation and repair"
          },
          {
            "no": "4004002034",
            "des": "乙級配電線路裝修技術士",
            "eng": "Level B technician for power distribution wires installation and repair"
          },
          {
            "no": "4004002035",
            "des": "丙級配電線路裝修技術士",
            "eng": "Level C technician for power distribution wires installation and repair"
          },
          {
            "no": "4004002036",
            "des": "乙級旋轉電機繞線技術士",
            "eng": "Level B technician for electric rotating machinery winding"
          },
          {
            "no": "4004002037",
            "des": "丙級旋轉電機繞線技術士",
            "eng": "Level C technician for electric rotating machinery winding"
          },
          {
            "no": "4004002038",
            "des": "乙級靜止電機繞線技術士",
            "eng": "Level B technician for stationary machinery winding"
          },
          {
            "no": "4004002039",
            "des": "丙級靜止電機繞線技術士",
            "eng": "Level C technician for stationary machinery winding"
          },
          {
            "no": "4004002040",
            "des": "乙級輸電地下電纜裝修技術士",
            "eng": "Level B technician for underground power cable installation and repair"
          },
          {
            "no": "4004002041",
            "des": "丙級輸電地下電纜裝修技術士",
            "eng": "Level C technician for underground power cable installation and repair"
          },
          {
            "no": "4004002042",
            "des": "乙級輸電架空線路裝修技術士",
            "eng": "Level B technician for catenary power wire installation and repair"
          },
          {
            "no": "4004002043",
            "des": "丙級輸電架空線路裝修技術士",
            "eng": "Level C technician for catenary power wire installation and repair"
          },
          {
            "no": "4004002044",
            "des": "乙級工程泵(幫浦) 類檢修技術士",
            "eng": "Level B technician for repairing engineering pump"
          },
          {
            "no": "4004002045",
            "des": "丙級工程泵(幫浦) 類檢修技術士",
            "eng": "Level C technician for repairing engineering pump"
          },
          {
            "no": "4004002046",
            "des": "初級電控系統工程師",
            "eng": "Electrical/Control System Engineer-Junior Level"
          },
          {
            "no": "4004002047",
            "des": "中級電控系統工程師",
            "eng": "Electrical/Control System Engineer-Junior Level"
          },
          {
            "no": "4004002048",
            "des": "高級電控系統工程師",
            "eng": "Electrical/Control System Engineer-Senior Level"
          },
          {
            "no": "4004002049",
            "des": "乙級用電設備檢驗技術士",
            "eng": "Level B technician for electrical equipment inspection"
          },
          {
            "no": "4004002050",
            "des": "經濟部初級電動車機電整合工程師",
            "eng": "MOEA Certified Electric Vehicle Mechatronics Associate"
          },
          {
            "no": "4004002051",
            "des": "經濟部中級電動車機電整合工程師",
            "eng": "MOEA Certified Electric Vehicle Mechatronics Specialist"
          },
          {
            "no": "4004002052",
            "des": "IRA智慧型機器人應用認證",
            "eng": "Intelligent Robot Application"
          },
          {
            "no": "4004002053",
            "des": "太陽光電設置乙級技術士",
            "eng": "installations of solar photovoltaic system"
          },
          {
            "no": "4004002054",
            "des": "太陽能產業實務認證",
            "eng": "Level B technician for Solar Power Practicality in Industry (SPPI)"
          },
          {
            "no": "4004002055",
            "des": "乙級變電設備裝修技術士",
            "eng": "Level B technician for substation facility installation and maintenance"
          }
        ],
        "eng": "Electromechanical engineering Certification"
      }
    ],
    "eng": "Electrical and Electronic Certifications"
  },
  {
    "no": "4005000000",
    "des": "營建土木類",
    "n": [
      {
        "no": "4005001000",
        "des": "建築／工程相關證照",
        "n": [
          {
            "no": "4005001001",
            "des": "甲級建築工程管理",
            "eng": "Level A technician for architecture engineering management"
          },
          {
            "no": "4005001002",
            "des": "乙級建築工程管理",
            "eng": "Level B technician for architecture engineering management"
          },
          {
            "no": "4005001003",
            "des": "高考建築師",
            "eng": "Architect of higher examination"
          },
          {
            "no": "4005001004",
            "des": "高考結構工程技師",
            "eng": "Technician of higher examination for structural engineering"
          },
          {
            "no": "4005001005",
            "des": "高考土木工程技師",
            "eng": "Technician of higher examination for civil"
          },
          {
            "no": "4005001006",
            "des": "高考水利工程技師",
            "eng": "Technician of higher examination for hydraulic engineering"
          },
          {
            "no": "4005001007",
            "des": "高考大地工程技師",
            "eng": "Technician of higher examination for geotechnical engineering"
          },
          {
            "no": "4005001008",
            "des": "高考都市計畫技師",
            "eng": "Technician of higher examination for urban planning"
          },
          {
            "no": "4005001009",
            "des": "高考水土保持技師",
            "eng": "Technician of higher examination for water & soil conservation"
          },
          {
            "no": "4005001010",
            "des": "甲級營造工程管理",
            "eng": "Level A technician for construction engineering management"
          },
          {
            "no": "4005001011",
            "des": "乙級營造工程管理",
            "eng": "Level B technician for construction engineering management"
          },
          {
            "no": "4005001012",
            "des": "工地主任",
            "eng": "Site Supervisor License"
          },
          {
            "no": "4005001013",
            "des": "SketchUp 專業國際認證",
            "eng": "SketchUp Certified Expert"
          },
          {
            "no": "4005001014",
            "des": "SketchUp 國際認證",
            "eng": "SketchUp Certified Associate"
          }
        ],
        "eng": "Architectural/Engineering Related Certifications"
      },
      {
        "no": "4005002000",
        "des": "營建技術相關證照",
        "n": [
          {
            "no": "4005002001",
            "des": "高考消防設備師",
            "eng": "Engineer of higher examination for fire control equipment"
          },
          {
            "no": "4005002002",
            "des": "特考消防設備士",
            "eng": "Technician of special examination for fire control equipment"
          },
          {
            "no": "4005002003",
            "des": "高考測量技師",
            "eng": "Survey technician of higher examination"
          },
          {
            "no": "4005002004",
            "des": "甲級測量技術士",
            "eng": "Level A survey technician"
          },
          {
            "no": "4005002005",
            "des": "乙級測量技術士",
            "eng": "Level B survey technician"
          },
          {
            "no": "4005002006",
            "des": "丙級測量技術士",
            "eng": "Level C survey technician"
          },
          {
            "no": "4005002007",
            "des": "甲級建築製圖技術士",
            "eng": "Level A technician for architectural drawing"
          },
          {
            "no": "4005002008",
            "des": "乙級建築製圖技術士",
            "eng": "Level B technician for architectural drawing"
          },
          {
            "no": "4005002009",
            "des": "丙級建築製圖技術士",
            "eng": "Level C technician for architectural drawing"
          },
          {
            "no": "4005002010",
            "des": "乙級建築塗裝技術士",
            "eng": "Level B technician for architectural coating"
          },
          {
            "no": "4005002011",
            "des": "丙級建築塗裝技術士",
            "eng": "Level C technician for architectural coating"
          },
          {
            "no": "4005002012",
            "des": "甲級模板技術士",
            "eng": "Level A technician for framework"
          },
          {
            "no": "4005002013",
            "des": "乙級模板技術士",
            "eng": "Level B technician for framework"
          },
          {
            "no": "4005002014",
            "des": "丙級模板技術士",
            "eng": "Level C technician for framework"
          },
          {
            "no": "4005002015",
            "des": "乙級水系統消防安全設備技術士",
            "eng": "Level B technician for water system & fire safety equipment"
          },
          {
            "no": "4005002016",
            "des": "乙級電腦輔助建築製圖技術士",
            "eng": "Level B technician for computer-aided architectural drawing"
          },
          {
            "no": "4005002017",
            "des": "丙級電腦輔助建築製圖技術士",
            "eng": "Level C technician for computer-aided architectural drawing"
          },
          {
            "no": "4005002018",
            "des": "乙級化學系統消防安全設備技術士",
            "eng": "Level B technician for chemical system and fire safety equipment"
          },
          {
            "no": "4005002019",
            "des": "丙級混凝土技術士",
            "eng": "Level C technician for concrete"
          },
          {
            "no": "4005002020",
            "des": "甲級鋼筋技術士",
            "eng": "Level A technician for reinforcement"
          },
          {
            "no": "4005002021",
            "des": "乙級鋼筋技術士",
            "eng": "Level B technician for reinforcement"
          },
          {
            "no": "4005002022",
            "des": "丙級鋼筋技術士",
            "eng": "Level C technician for reinforcement"
          },
          {
            "no": "4005002023",
            "des": "甲級泥水技術士",
            "eng": "Level A technician for sludge water"
          },
          {
            "no": "4005002024",
            "des": "乙級警報系統消防安全設備技術士",
            "eng": "Level B technician for alarm system & fire safety equipment"
          },
          {
            "no": "4005002025",
            "des": "乙級避難系統消防安全設備技術士",
            "eng": "Level B technician for evacuation system & fire safety equipment"
          },
          {
            "no": "4005002026",
            "des": "乙級滅火器消防安全設備技術士",
            "eng": "Level B technician for fire extinguisher & fire safety equipment"
          },
          {
            "no": "4005002027",
            "des": "乙級泥水技術士",
            "eng": "Level B technician for sludge water"
          },
          {
            "no": "4005002028",
            "des": "丙級泥水技術士",
            "eng": "Level C technician for sludge water"
          },
          {
            "no": "4005002029",
            "des": "丙級營建防水",
            "eng": "Level C technician for construction water-proofing"
          },
          {
            "no": "4005002030",
            "des": "地下水鑿井技工考驗",
            "eng": "Groundwater Drilling Technician Examination"
          },
          {
            "no": "4005002031",
            "des": "施工架組配作業主管",
            "eng": "Construction Scaffold Assembly Supervisor"
          }
        ],
        "eng": "Construction Technology Related Certifications"
      },
      {
        "no": "4005003000",
        "des": "室內設計相關證照",
        "n": [
          {
            "no": "4005003001",
            "des": "室內裝潢",
            "eng": "Interior decoration"
          },
          {
            "no": "4005003002",
            "des": "乙級建築物室內設計技術士",
            "eng": "Level C technician for web page design"
          },
          {
            "no": "4005003003",
            "des": "乙級建築物室內裝修工程管理技術士",
            "eng": "Level B technician for building interior installation and repair management"
          },
          {
            "no": "4005003004",
            "des": "乙級裝潢木工技術士",
            "eng": "Level B technician for wooden decoration work"
          },
          {
            "no": "4005003005",
            "des": "丙級裝潢木工技術士",
            "eng": "Level C technician for wooden decoration work"
          }
        ],
        "eng": "Interior Design Related Certifications"
      }
    ],
    "eng": "Construction and Civil Engineering"
  },
  {
    "no": "4006000000",
    "des": "財金／保險／不動產類",
    "n": [
      {
        "no": "4006001000",
        "des": "財務會計相關證照",
        "n": [
          {
            "no": "4006001001",
            "des": "國際專業財務長CFM",
            "eng": "CFM (Certified Financial Manager)"
          },
          {
            "no": "4006001002",
            "des": "美國風險管理師ARM",
            "eng": "ARM (American Risk Manager)"
          },
          {
            "no": "4006001003",
            "des": "國際內部稽核師(CIA)",
            "eng": "CIA (Certified Internal Auditor)"
          },
          {
            "no": "4006001004",
            "des": "國際內控自評師(CCSA)",
            "eng": "CCSA (Certification in Control Self-Assessment)"
          },
          {
            "no": "4006001005",
            "des": "國際政府稽核師(CGAP)",
            "eng": "CGAP (Certified Government Auditing Professional)"
          },
          {
            "no": "4006001006",
            "des": "國際金融稽核師(CFSA)",
            "eng": "CFSA (Certified Financial Services Auditor)"
          },
          {
            "no": "4006001007",
            "des": "國際認證財務顧問師RFC",
            "eng": "RFC (Registered Financial Consultant)"
          },
          {
            "no": "4006001008",
            "des": "美國財務分析師CFA",
            "eng": "CFA (Certified Financial Analyst )"
          },
          {
            "no": "4006001009",
            "des": "美國財務規劃師CFP",
            "eng": "CFP (Certified Financial Planner)"
          },
          {
            "no": "4006001010",
            "des": "美國管理會計師CMA",
            "eng": "CMA (Certified Management Accountant)"
          },
          {
            "no": "4006001011",
            "des": "財務風險管理師FRM",
            "eng": "FRM (Financial Risk Manager)"
          },
          {
            "no": "4006001012",
            "des": "電腦稽核師CISA",
            "eng": "CISA (Certified Information Systems Auditor)"
          },
          {
            "no": "4006001013",
            "des": "高考會計師",
            "eng": "Accountant of higher examination"
          },
          {
            "no": "4006001014",
            "des": "美國會計師CPA",
            "eng": "Certified Public Accountant"
          },
          {
            "no": "4006001015",
            "des": "理財規劃人員",
            "eng": "Financial planner"
          },
          {
            "no": "4006001016",
            "des": "企業風險管理師",
            "eng": "Corporation risk manager"
          },
          {
            "no": "4006001017",
            "des": "個人風險管理師",
            "eng": "Personal risk manager"
          },
          {
            "no": "4006001018",
            "des": "精算師",
            "eng": "Actuary"
          },
          {
            "no": "4006001019",
            "des": "乙級商業計算技術士",
            "eng": "Commercial calculation B"
          },
          {
            "no": "4006001020",
            "des": "丙級商業計算技術士",
            "eng": "Level C technician for commercial calculation"
          },
          {
            "no": "4006001021",
            "des": "美國專業理財顧問師ChFC",
            "eng": "ChFC (Chartered Financial Consultant)"
          },
          {
            "no": "4006001022",
            "des": "金融人員風險管理師",
            "eng": "Risk manager for financial personnel"
          },
          {
            "no": "4006001024",
            "des": "乙級會計事務技術士",
            "eng": "Level B technician for accounting"
          },
          {
            "no": "4006001025",
            "des": "丙級會計事務技術士",
            "eng": "Level C technician for accounting"
          },
          {
            "no": "4006001026",
            "des": "普考記帳士",
            "eng": "Book keeper of general examination"
          },
          {
            "no": "4006001027",
            "des": "CICPA",
            "eng": "CICPA"
          },
          {
            "no": "4006001028",
            "des": "財富管理規劃師FFSI",
            "eng": "Wealth Management Planner FFSI"
          },
          {
            "no": "4006001029",
            "des": "中華民國精算師（退休金類）",
            "eng": "R.O.C. actuary (pension)"
          },
          {
            "no": "4006001030",
            "des": "中華民國精算師（壽險類）",
            "eng": "R.O.C. actuary (life assurance)"
          },
          {
            "no": "4006001031",
            "des": "中華民國精算師（產險類）",
            "eng": "R.O.C. actuary (insurance)"
          },
          {
            "no": "4006001032",
            "des": "TQC專業財會人員",
            "eng": "TQC-Specialized Accounting Personnel"
          },
          {
            "no": "4006001033",
            "des": "中小企業財務人員認證",
            "eng": "Certified Financial Specialist for Small and Medium Enterprises"
          },
          {
            "no": "4006001034",
            "des": "中小企業財務主管認證",
            "eng": "Certified Financial Manager for Small and Medium Enterprisess"
          },
          {
            "no": "4006001035",
            "des": "中小企業財務顧問認證",
            "eng": "Certified Financial Consultant for Small and Medium Enterprises"
          },
          {
            "no": "4006001036",
            "des": "會計能力測驗一級合格證書",
            "eng": "Accounting Proficiency Test Level 1 Certificate"
          },
          {
            "no": "4006001037",
            "des": "會計能力測驗二級合格證書",
            "eng": "Accounting Proficiency Test Level 2 Certificate"
          },
          {
            "no": "4006001038",
            "des": "會計能力測驗三級合格證書",
            "eng": "Accounting Proficiency Test Level 3 Certificate"
          },
          {
            "no": "4006001039",
            "des": "CFE",
            "eng": "CFE"
          },
          {
            "no": "4006001040",
            "des": "國際註冊財務策劃師 IRFP ",
            "eng": "International Registered Financial Planner"
          },
          {
            "no": "4006001041",
            "des": "AIA英國國際會計師執業證書 ",
            "eng": "An Associate Member of AIA"
          },
          {
            "no": "4006001042",
            "des": "AIA財會資格證書",
            "eng": "AIA Examinations Certificate in Accounting"
          },
          {
            "no": "4006001043",
            "des": "AIA財會高級證書",
            "eng": "AIA Examinations Advanced Diploma in Accounting"
          },
          {
            "no": "4006001044",
            "des": "AIA專業證書",
            "eng": "AIA Professional Examinations"
          },
          {
            "no": "4006001045",
            "des": "電腦稽核軟體應用師JCCP",
            "eng": "JCCP(Jacksoft Certified CAATs Practitioner)"
          },
          {
            "no": "4006001046",
            "des": "國際電腦稽核軟體應用師ICCP",
            "eng": "ICCP(International Certified CAATs Practitioner)"
          },
          {
            "no": "4006001047",
            "des": "國際ERP電腦稽核師CEAP",
            "eng": "CEAP(Certified ERP Audit Professional)"
          },
          {
            "no": "4006001048",
            "des": "ACL稽核分析師 ACDA",
            "eng": "ACDA(ACL Certified Data Analyst)"
          },
          {
            "no": "4006001049",
            "des": "IFRS資訊規劃師認證",
            "eng": "IFRS Information Planner Certification"
          },
          {
            "no": "4006001050",
            "des": "Certified Anti-Money Laundering Speciali",
            "eng": "Certified Anti-Money Laundering Speciali"
          },
          {
            "no": "4006001051",
            "des": "國際風險管理確認師(CRMA)",
            "eng": "CRMA (Certification in Risk Management Assurance)"
          },
          {
            "no": "4006001052",
            "des": "企業財務分析師",
            "eng": "Corporate Financial Analyst"
          },
          {
            "no": "4006001053",
            "des": "台灣風險管理師",
            "eng": "Risk Manager"
          },
          {
            "no": "4006001054",
            "des": "台灣風險管理人員",
            "eng": "Risk Management Personnel, Taiwan (TRMP)"
          },
          {
            "no": "4006001055",
            "des": "台灣風險數據分析師",
            "eng": "Risk Data Analyst, Taiwan (TRDA)"
          }
        ],
        "eng": "Financial Accounting Related Certifications"
      },
      {
        "no": "4006002000",
        "des": "保險相關證照",
        "n": [
          {
            "no": "4006002001",
            "des": "美國保險精算師證照FSA",
            "eng": "FSA (Fellow Society of Actuaries)"
          },
          {
            "no": "4006002002",
            "des": "特考財產保險代理人",
            "eng": "Property insurance agent of special examination"
          },
          {
            "no": "4006002003",
            "des": "英國產險核人證照ACII",
            "eng": "ACII (Associated Chartered Insurance Institute)"
          },
          {
            "no": "4006002004",
            "des": "特考人身保險代理人",
            "eng": "Human insurance agent of special examination"
          },
          {
            "no": "4006002005",
            "des": "特考財產保險經紀人",
            "eng": "Property and insurance broker of special examination"
          },
          {
            "no": "4006002006",
            "des": "美國壽險管理師(FLMI)",
            "eng": "FLMI (Fellow"
          },
          {
            "no": "4006002007",
            "des": "基礎客戶服務 (ACS)",
            "eng": "ACS (Associated Customer Service)"
          },
          {
            "no": "4006002008",
            "des": "進階客戶服務(PCS .)",
            "eng": "PCS (Professional Customer Service)"
          },
          {
            "no": "4006002009",
            "des": "年金商品管理(AAPA)",
            "eng": "AAPA (Associated Annuity Products and Administration)"
          },
          {
            "no": "4006002010",
            "des": "保險業務管理(AIAA)",
            "eng": "Certified Marketing Professional"
          },
          {
            "no": "4006002011",
            "des": "再保管理(ARA)",
            "eng": "ARA (Associated Reinsurance Administration)"
          },
          {
            "no": "4006002012",
            "des": "美國理賠管理(ICA)",
            "eng": "ICA (International Claim Association)"
          },
          {
            "no": "4006002013",
            "des": "人壽保險管理人員",
            "eng": "Administration personnel for life assurance"
          },
          {
            "no": "4006002014",
            "des": "保險核保人員",
            "eng": "Assessor for property insurance"
          },
          {
            "no": "4006002015",
            "des": "產物保險核保人員",
            "eng": "Assessor for property insurance"
          },
          {
            "no": "4006002016",
            "des": "產物保險理賠人員",
            "eng": "Claim adjuster for property insurance"
          },
          {
            "no": "4006002017",
            "des": "產物保險業務員",
            "eng": "Property insurance representative"
          },
          {
            "no": "4006002018",
            "des": "人身保險業務員",
            "eng": "Personal insurance representative"
          },
          {
            "no": "4006002019",
            "des": "人身保險業務員中級專業課程",
            "eng": "Intermediate professional courses for personal insurance representative"
          },
          {
            "no": "4006002020",
            "des": "特考人身保險經紀人",
            "eng": "Personal insurance broker of special examination"
          },
          {
            "no": "4006002021",
            "des": "特考一般保險公證人",
            "eng": "General insurance notary of special examination"
          },
          {
            "no": "4006002022",
            "des": "投資型保險商品業務員",
            "eng": "Investment-orientated insurance product representative"
          },
          {
            "no": "4006002023",
            "des": "特考海事保險公證人",
            "eng": "Marine insurance surveyor of special examination"
          },
          {
            "no": "4006002024",
            "des": "美國財產和意外險承保師(CPCU)",
            "eng": "CPCU (Chartered Property and Casualty Underwriter)"
          },
          {
            "no": "4006002025",
            "des": "外幣收付之非投資型人身保險資格證照",
            "eng": "Foreign Currency Receipt and Payment Non-Investment Life Insurance Qualification Certificate"
          },
          {
            "no": "4006002026",
            "des": "財產保險業務員資格",
            "eng": "Property Insurance Salesperson Qualification"
          },
          {
            "no": "4006002027",
            "des": "保險理賠人員",
            "eng": "Insurance Claims Personnel"
          }
        ],
        "eng": "Insurance Related Certifications"
      },
      {
        "no": "4006003000",
        "des": "金融投顧相關證照",
        "n": [
          {
            "no": "4006003001",
            "des": "企業內部控制人員",
            "eng": "Internal controller of corporation"
          },
          {
            "no": "4006003002",
            "des": "外匯人員",
            "eng": "Foreign exchange personnel"
          },
          {
            "no": "4006003003",
            "des": "債券人員",
            "eng": "Debenture personnel"
          },
          {
            "no": "4006003004",
            "des": "股務人員",
            "eng": "Service personnel"
          },
          {
            "no": "4006003005",
            "des": "銀行內部控制與內部稽核",
            "eng": "Bank Internal Control and Audit"
          },
          {
            "no": "4006003006",
            "des": "證券投資分析師",
            "eng": "Stock & investment analyst"
          },
          {
            "no": "4006003007",
            "des": "證券投資分析人員(CSIA)",
            "eng": "Certified Securities Investment Analyst"
          },
          {
            "no": "4006003008",
            "des": "期貨交易分析人員",
            "eng": "Future trading analysis personnel"
          },
          {
            "no": "4006003009",
            "des": "信託業業務人員信託業務專業測驗(信託法規乙科)",
            "eng": "Trust representative"
          },
          {
            "no": "4006003010",
            "des": "證券商業務員",
            "eng": "sales representative in stock company"
          },
          {
            "no": "4006003011",
            "des": "證券商高級業務員",
            "eng": "High level sales representative in stock company"
          },
          {
            "no": "4006003012",
            "des": "期貨商業務員",
            "eng": "sales representative in future company"
          },
          {
            "no": "4006003014",
            "des": "國際投資分析師CIIA",
            "eng": "Certified International Investment Analyst"
          },
          {
            "no": "4006003015",
            "des": "進階授信人員",
            "eng": "Advanced bank lending personnel"
          },
          {
            "no": "4006003016",
            "des": "初階授信人員",
            "eng": "Basic bank lending personnel"
          },
          {
            "no": "4006003017",
            "des": "初階外匯人員",
            "eng": "Basic foreign exchange personnel"
          },
          {
            "no": "4006003018",
            "des": "投信投顧營業員",
            "eng": "Investment trust and consulting operator"
          },
          {
            "no": "4006003019",
            "des": "投信投顧業務員",
            "eng": "Investment trust and consulting representative"
          },
          {
            "no": "4006003020",
            "des": "外匯交易專業能力測驗",
            "eng": "Proficiency test for foreign exchange transaction"
          },
          {
            "no": "4006003021",
            "des": "授信擔保品測驗",
            "eng": "Security test for bank lending"
          },
          {
            "no": "4006003022",
            "des": "票券商業務人員",
            "eng": "Sales representative in note company"
          },
          {
            "no": "4006003023",
            "des": "結構型商品銷售人員資格測驗",
            "eng": "Structured Products Sales Qualification Exam"
          },
          {
            "no": "4006003024",
            "des": "債權委外催收人員專業能力測驗",
            "eng": "Debt Collection Outsourcing Professional Ability Test"
          },
          {
            "no": "4006003025",
            "des": "金融市場常識與職業道德",
            "eng": "Financial Market Common Sense and Professional Ethics"
          },
          {
            "no": "4006003026",
            "des": "投信投顧相關法規（含自律規範）",
            "eng": "Investment Trust and Investment Consulting Related Regulations (Including Self-Regulations)"
          },
          {
            "no": "4006003027",
            "des": "信託業業務人員信託業務專業測驗",
            "eng": "Trust Business Professional Exam for Trust Industry Personnel"
          },
          {
            "no": "4006003028",
            "des": "期貨信託基金銷售機構銷售人員",
            "eng": "Sales Personnel for Futures Trust Funds Sales Institutions"
          },
          {
            "no": "4006003029",
            "des": "AFMA 高級金融管理師證書",
            "eng": "dvanced Financial Management Associate"
          },
          {
            "no": "4006003030",
            "des": "衍生性金融商品銷售人員",
            "eng": "Certification Test for Financial Derivatives Sales Personnel"
          },
          {
            "no": "4006003031",
            "des": "金融科技力知識檢定",
            "eng": "Financial Technology Knowledge Examination"
          },
          {
            "no": "4006003032",
            "des": "防制洗錢與打擊資恐專業人員測驗",
            "eng": "Professional Exam for Anti-Money Laundering and Countering Terrorism Financing Specialist"
          },
          {
            "no": "4006003033",
            "des": "信託業管理人員",
            "eng": "Trust Industry Management Personnel"
          }
        ],
        "eng": "Financial Investment Advisory Related Certifications"
      },
      {
        "no": "4006004000",
        "des": "不動產相關證照",
        "n": [
          {
            "no": "4006004001",
            "des": "普考不動產經紀人",
            "eng": "Real estate broker of general examination"
          },
          {
            "no": "4006004002",
            "des": "土地登記專業代理人",
            "eng": "Professional agent of land registration"
          },
          {
            "no": "4006004003",
            "des": "高考不動產估價師",
            "eng": "Real estate estimator of higher examination"
          },
          {
            "no": "4006004004",
            "des": "普考地政士",
            "eng": "Land administration agent of general examination"
          },
          {
            "no": "4006004005",
            "des": "不動產經紀營業員",
            "eng": "Real estate broking operator"
          },
          {
            "no": "4006004006",
            "des": "國際認證不動產投資師",
            "eng": "CCIM (Certified Commercial Investment Member)"
          }
        ],
        "eng": "Real Estate Related Certifications"
      }
    ],
    "eng": "Finance/Insurance/Real Estate Category"
  },
  {
    "no": "4007000000",
    "des": "機械操作／修護類",
    "n": [
      {
        "no": "4007001000",
        "des": "重機械操作／修護證照",
        "n": [
          {
            "no": "4007001001",
            "des": "甲級重機械修護技術士",
            "eng": "Level A technician for heavy machinery service"
          },
          {
            "no": "4007001002",
            "des": "乙級重機械修護技術士",
            "eng": "Level B technician for heavy machinery service"
          },
          {
            "no": "4007001003",
            "des": "丙級重機械修護技術士",
            "eng": "Level C technician for heavy machinery service"
          },
          {
            "no": "4007001004",
            "des": "堆高機操作技術士",
            "eng": "Technician for fork lift operation"
          },
          {
            "no": "4007001005",
            "des": "移動式起重機操作技術士",
            "eng": "Level C technician for mobile crane operation"
          },
          {
            "no": "4007001006",
            "des": "人臂式起重桿操作技術士",
            "eng": "Level C technician for Derrick crane operation"
          },
          {
            "no": "4007001007",
            "des": "重機械操作技術士",
            "eng": "Technician for heavy machine operation"
          },
          {
            "no": "4007001008",
            "des": "固定式起重機操作技術士",
            "eng": "technician for fixed crane operation"
          },
          {
            "no": "4007001009",
            "des": "固定式(架空)起重機技術士",
            "eng": "Fixed (Overhead) Crane Technician"
          },
          {
            "no": "4007001010",
            "des": "固定式(架空)起重機(地面操控)技術士",
            "eng": "Fixed (Overhead) Crane Technician (Ground Control)"
          },
          {
            "no": "4007001011",
            "des": "固定式(伸臂)起重機技術士證",
            "eng": "Fixed (Telescopic) Crane Technician Certificate"
          },
          {
            "no": "4007001012",
            "des": "高空工作車操作人員",
            "eng": "Aerial Work Vehicle Operator"
          }
        ],
        "eng": "Heavy Machinery Operation/Repair Certification"
      },
      {
        "no": "4007002000",
        "des": "鍋爐操作／修護證照",
        "n": [
          {
            "no": "4007002001",
            "des": "乙級鍋爐操作技術士",
            "eng": "Level B technician for boiler operation"
          },
          {
            "no": "4007002002",
            "des": "丙級鍋爐操作技術士",
            "eng": "Level C technician for boiler operation"
          },
          {
            "no": "4007002003",
            "des": "乙級鍋爐裝修技術士",
            "eng": "Level B technician for boiler installation and repair"
          },
          {
            "no": "4007002004",
            "des": "丙級鍋爐裝修技術士",
            "eng": "Level C technician for boiler installation and repair"
          },
          {
            "no": "4007002005",
            "des": "甲級鍋爐操作技術士",
            "eng": "Level A technician for boiler operation"
          }
        ],
        "eng": "Boiler Operation/Repair Certifications"
      },
      {
        "no": "4007003000",
        "des": "紡織機械修護證照",
        "n": [
          {
            "no": "4007003001",
            "des": "乙級紡紗機械修護技術士",
            "eng": "Level B technician for textile machinery repair"
          },
          {
            "no": "4007003002",
            "des": "丙級紡紗機械修護技術士",
            "eng": "Level C technician for textile machinery repair"
          },
          {
            "no": "4007003003",
            "des": "乙級織布機械修護技術士",
            "eng": "Level B technician for fabric machinery repair"
          },
          {
            "no": "4007003004",
            "des": "丙級織布機械修護技術士",
            "eng": "Level C technician for fabric machinery repair"
          },
          {
            "no": "4007003005",
            "des": "乙級染整機械修護技術士",
            "eng": "Level B technician for dyeing machinery repair"
          },
          {
            "no": "4007003006",
            "des": "乙級針織機械修護技術士",
            "eng": "Level B technician for knitting machinery repair"
          },
          {
            "no": "4007003007",
            "des": "丙級針織機械修護技術士",
            "eng": "Level C technician for knitting machinery repair"
          }
        ],
        "eng": "Textile Machinery Repair Certification"
      },
      {
        "no": "4007004000",
        "des": "機械／核能操作證照",
        "n": [
          {
            "no": "4007004002",
            "des": "第一種壓力容器操作(單一級)",
            "eng": "technician for operating pressure container"
          },
          {
            "no": "4007004003",
            "des": "密封(非密封)放射性物質可發生游離輻射設備操作執照",
            "eng": "Operation license for seal (non-sealed) equipment with radiating substance capable of generating ionizing radiation"
          }
        ],
        "eng": "Machinery/Nuclear Operation Certification"
      },
      {
        "no": "4007005000",
        "des": "農業機械操作／修護證照",
        "n": [
          {
            "no": "4007005001",
            "des": "乙級農業機械修護技術士",
            "eng": "Level B technician for repairing agricultural machinery"
          },
          {
            "no": "4007005002",
            "des": "丙級農業機械修護技術士",
            "eng": "Level C technician for repairing agricultural machinery"
          },
          {
            "no": "4007005003",
            "des": "丙級農用曳引機操作技術士",
            "eng": "Level C technician for operating agricultural tractor"
          }
        ],
        "eng": "Agricultural Machinery Operation/Repair Certification"
      },
      {
        "no": "4007006000",
        "des": "航空修護類證照",
        "n": [
          {
            "no": "4007006001",
            "des": "地面機械員",
            "eng": "Ground mechanic"
          },
          {
            "no": "4007006002",
            "des": "飛航機械員",
            "eng": "Air mechanic"
          },
          {
            "no": "4007006003",
            "des": "航空器維修場、所維修員",
            "eng": "Site maintenance personnel for air craft"
          },
          {
            "no": "4007006004",
            "des": "丙級飛機修護技術士",
            "eng": "Level C technician for repairing airplane"
          },
          {
            "no": "4007006005",
            "des": "地面機械員檢定證航空器發動機維護類",
            "eng": "Ground mechanics rating certificate for engine maintenance of air craft"
          },
          {
            "no": "4007006006",
            "des": "地面機械員檢定證航空器機體維護類",
            "eng": "Ground mechanics rating certificate for airframe maintenance"
          },
          {
            "no": "4007006007",
            "des": "AMT 航空維修技師",
            "eng": "AMT (Aviation Maintenance Technician)"
          },
          {
            "no": "4007006008",
            "des": "AMG 航空機械技師",
            "eng": "AMG (Aviation Mechanic General)"
          },
          {
            "no": "4007006009",
            "des": "AMP 飛機引擎技師",
            "eng": "AMP (Aviation Mechanic Powerplant)"
          },
          {
            "no": "4007006010",
            "des": "AMA 飛機機身技師",
            "eng": "AMA (Aviation Mechanic Airframe)"
          },
          {
            "no": "4007006011",
            "des": "IAR2 飛機檢驗技師",
            "eng": "IAR2 (Inspection Authorization)"
          },
          {
            "no": "4007006012",
            "des": "DME1 維修證照考試官",
            "eng": "DME1 (Designated Mechanic Examiner)"
          },
          {
            "no": "4007006013",
            "des": "乙級飛機修護技術士",
            "eng": "Level B technician for repairing airplane"
          }
        ],
        "eng": "Aviation Repair Certification"
      },
      {
        "no": "4007007000",
        "des": "汽車修護類證照",
        "n": [
          {
            "no": "4007007001",
            "des": "汽車檢驗員",
            "eng": "Automotive inspector"
          },
          {
            "no": "4007007002",
            "des": "汽車技工執照",
            "eng": "Automotive mechanic license"
          },
          {
            "no": "4007007003",
            "des": "甲級汽車修護技術士",
            "eng": "Level A technician for automotive mechanic"
          },
          {
            "no": "4007007004",
            "des": "乙級汽車修護技術士",
            "eng": "Level B technician for automotive mechanic"
          },
          {
            "no": "4007007005",
            "des": "丙級汽車修護技術士",
            "eng": "Level C technician for automotive mechanic"
          },
          {
            "no": "4007007006",
            "des": "乙級車輛塗裝技術士",
            "eng": "Level B technician for vehicle coating"
          },
          {
            "no": "4007007007",
            "des": "丙級車輛塗裝技術士",
            "eng": "Level C technician for vehicle coating"
          }
        ],
        "eng": "Automotive Repair Certification"
      }
    ],
    "eng": "Machinery Operation/Repair Category"
  },
  {
    "no": "4008000000",
    "des": "交通服務類",
    "n": [
      {
        "no": "4008001000",
        "des": "陸上運輸相關證照",
        "n": [
          {
            "no": "4008001001",
            "des": "普通大貨車駕照",
            "eng": "Driving license for general truck"
          },
          {
            "no": "4008001002",
            "des": "職業大貨車駕照",
            "eng": "Driving license for professional truck"
          },
          {
            "no": "4008001003",
            "des": "普通重型機車駕照",
            "eng": "Driving license for general bike"
          },
          {
            "no": "4008001004",
            "des": "大型重型機車駕照",
            "eng": "Driving license for large bike"
          },
          {
            "no": "4008001005",
            "des": "輕型機車駕照",
            "eng": "Driving license for scooter"
          },
          {
            "no": "4008001006",
            "des": "普通客車駕照",
            "eng": "Driving license for general passenger vehicle"
          },
          {
            "no": "4008001007",
            "des": "職業客車駕照",
            "eng": "Driving license for professional passenger vehicle"
          },
          {
            "no": "4008001008",
            "des": "普通小型車駕照",
            "eng": "Driving license for Regular Small Vehicle"
          },
          {
            "no": "4008001017",
            "des": "普通小型車駕照-手排",
            "eng": "Driving license for Regular Small Vehicle - Manual Transmission"
          },
          {
            "no": "4008001010",
            "des": "高考交通工程技師",
            "eng": "Traffic engineering technician of higher examination"
          },
          {
            "no": "4008001009",
            "des": "職業小型車駕照",
            "eng": "Driving license for Professional Small Vehicle"
          },
          {
            "no": "4008001012",
            "des": "職業聯結車證照",
            "eng": "Driving license for professional container truck"
          },
          {
            "no": "4008001013",
            "des": "特考鐵路人員",
            "eng": "Special Examination for Railway Personnel"
          },
          {
            "no": "4008001014",
            "des": "汽車駕駛考驗員檢定",
            "eng": "Automobile Driving Examiner Certification"
          },
          {
            "no": "4008001015",
            "des": "普通大客車駕照",
            "eng": "Driving license for Regular Large Bus"
          },
          {
            "no": "4008001016",
            "des": "職業大客車駕照",
            "eng": "Driving license for Professional Large Bus"
          },
          {
            "no": "4008001011",
            "des": "普通聯結車駕照",
            "eng": "Driving license for Regular Articulated Vehicle"
          },
          {
            "no": "4008001018",
            "des": "大客車職業駕駛人定期訓練證明",
            "eng": "Periodic Training Certification for Professional Bus Drivers"
          }
        ],
        "eng": "Land Transportation Related Certifications"
      },
      {
        "no": "4008002000",
        "des": "海上運輸相關證照",
        "n": [
          {
            "no": "4008002001",
            "des": "特考驗船師",
            "eng": "Ship surveyor of special examination"
          },
          {
            "no": "4008002002",
            "des": "高考造船工程技師",
            "eng": "Naval architecture technician of higher examination"
          },
          {
            "no": "4008002003",
            "des": "特考漁船船員",
            "eng": "Fishing vessel crew of special examination"
          },
          {
            "no": "4008002004",
            "des": "特考甲種引水人",
            "eng": "Class A maritime pilot of special examination"
          },
          {
            "no": "4008002005",
            "des": "特考航海人員(航行員)",
            "eng": "Marine crew (navigator) of special examination"
          },
          {
            "no": "4008002006",
            "des": "港勤公營動力船舶現職副駕駛副司機",
            "eng": "Second pilot in service for public motor ships at harbors"
          },
          {
            "no": "4008002007",
            "des": "特考航海人員(輪機員)",
            "eng": "Marine crew (wheel operator) of special examination"
          },
          {
            "no": "4008002008",
            "des": "甲級艤裝技術士",
            "eng": "Level A technician for outfitting"
          },
          {
            "no": "4008002009",
            "des": "乙級艤裝技術士",
            "eng": "Level B technician for outfitting"
          },
          {
            "no": "4008002010",
            "des": "丙級艤裝技術士",
            "eng": "Level C technician for outfitting"
          },
          {
            "no": "4008002011",
            "des": "特考海岸巡防人員",
            "eng": "Special Examination for Coast Guard Personnel"
          },
          {
            "no": "4008002012",
            "des": "動力小船駕駛執照",
            "eng": "Powered Small Boat Driver License"
          },
          {
            "no": "4008002013",
            "des": "動力小艇駕駛執照",
            "eng": "Powered Dinghy Driver License"
          }
        ],
        "eng": "Maritime Transportation Related Certifications"
      },
      {
        "no": "4008003000",
        "des": "航空運輸相關證照",
        "n": [
          {
            "no": "4008003001",
            "des": "儀器飛航",
            "eng": "Instrument flight"
          },
          {
            "no": "4008003002",
            "des": "飛航教師",
            "eng": "Flight teacher"
          },
          {
            "no": "4008003003",
            "des": "直昇機商用駕駛員",
            "eng": "Commercial pilot for helicopter"
          },
          {
            "no": "4008003004",
            "des": "航空器簽派員",
            "eng": "Air craft dispatcher"
          },
          {
            "no": "4008003005",
            "des": "直昇機民航運輸駕駛員",
            "eng": "Helicopter pilot for domestic air transportation"
          },
          {
            "no": "4008003006",
            "des": "自由氣球商用駕駛員",
            "eng": "Commercial pilot for free balloon"
          },
          {
            "no": "4008003007",
            "des": "飛艇商用駕駛員",
            "eng": "Commercial pilot for airship"
          },
          {
            "no": "4008003008",
            "des": "直昇機自用駕駛員",
            "eng": "Private pilot for helicopter"
          },
          {
            "no": "4008003009",
            "des": "滑翔機商用駕駛員",
            "eng": "Commercial pilot for glider"
          },
          {
            "no": "4008003010",
            "des": "高考航空工程技師",
            "eng": "Aviation engineering technician of higher examination"
          },
          {
            "no": "4008003011",
            "des": "航空器簽派員職業證書",
            "eng": "Occupational certificate for air craft dispatcher"
          },
          {
            "no": "4008003012",
            "des": "自由氣球自用駕駛員",
            "eng": "Private pilot for free balloon"
          },
          {
            "no": "4008003013",
            "des": "固定翼航空器商用駕駛員",
            "eng": "Commercial pilot for fixed-wing air craft"
          },
          {
            "no": "4008003014",
            "des": "固定翼航空器民航運輸駕駛員",
            "eng": "Fixed-wing air craft pilot for domestic air transportation"
          },
          {
            "no": "4008003015",
            "des": "飛艇自用駕駛員",
            "eng": "Private pilot for airship"
          },
          {
            "no": "4008003016",
            "des": "滑翔機自用駕駛員",
            "eng": "Private pilot for glider"
          },
          {
            "no": "4008003017",
            "des": "固定翼航空器自用駕駛員",
            "eng": " Private pilot for fixed-wing air craft"
          },
          {
            "no": "4008003018",
            "des": "SPL",
            "eng": "SPL"
          },
          {
            "no": "4008003019",
            "des": "特考民航人員",
            "eng": "Special Examination for Civil Aviation Personnel"
          }
        ],
        "eng": "Aviation Transportation Related Certifications"
      },
      {
        "no": "4008004000",
        "des": "物流管理類",
        "n": [
          {
            "no": "4008004001",
            "des": "物流初級人員證書",
            "eng": "Foundation Certificate in Logistics"
          },
          {
            "no": "4008004002",
            "des": "物流部門主管證書",
            "eng": "Certificate for Supervisory Managers in Logistics"
          },
          {
            "no": "4008004003",
            "des": "物流營運經理證書",
            "eng": "Diploma for Operational Managers in Logistics"
          },
          {
            "no": "4008004004",
            "des": "物流高階經理證書",
            "eng": "Advanced Diploma for Strategic Managers in Logistics"
          }
        ],
        "eng": "Logistics Management Category"
      }
    ],
    "eng": "Transportation Service Category"
  },
  {
    "no": "4009000000",
    "des": "餐飲旅遊類",
    "n": [
      {
        "no": "4009001000",
        "des": "旅遊相關證照",
        "n": [
          {
            "no": "4009001001",
            "des": "普考導遊人員",
            "eng": "Tourist guide of general examination"
          },
          {
            "no": "4009001002",
            "des": "普考領隊人員",
            "eng": "Tourist leader of general examination"
          },
          {
            "no": "4009001003",
            "des": "導遊人員執業證",
            "eng": "Tourist guide\u2019s occupation license in touring industry"
          },
          {
            "no": "4009001004",
            "des": "華語領隊人員執業證",
            "eng": "Mandarin-speaking Tour Leader License"
          },
          {
            "no": "4009001005",
            "des": "外語領隊人員執業證",
            "eng": "Foreign Language Tour Leader License"
          },
          {
            "no": "4009001006",
            "des": "CIS國際獎勵旅遊認證",
            "eng": "Certified Incentive Specialist"
          },
          {
            "no": "4009001007",
            "des": "HSTP溫泉觀光規畫師",
            "eng": "Hot Spring Tourism Planner"
          },
          {
            "no": "4009001008",
            "des": "LIAD休閒產業活動設計師",
            "eng": "Leisure industry activity Designer"
          },
          {
            "no": "4009001009",
            "des": "TTPD觀光遊程設計師",
            "eng": "Certificate of Travel and Tour Planning Designer"
          },
          {
            "no": "4009001010",
            "des": "SSE 餐旅管理師國際認證",
            "eng": "Lausanne Hospitality"
          },
          {
            "no": "4009001011",
            "des": "SSE 旅館管理師國際認證",
            "eng": "Hotel and Lodging Management"
          },
          {
            "no": "4009001012",
            "des": "DTL 國民旅遊領團人員認證",
            "eng": "Domestic Tour Leader Certification"
          },
          {
            "no": "4009001013",
            "des": "OPC 旅遊產品操作人員認證",
            "eng": "OPC Tourism Product Operator Certification"
          },
          {
            "no": "4009001014",
            "des": "BBB 民宿管家檢定認證",
            "eng": "Bed & Breakfast Butler Certificate"
          },
          {
            "no": "4009001015",
            "des": "CHM 旅館管理專業人員銀階認證",
            "eng": "Certified Hospitality Management"
          },
          {
            "no": "4009001016",
            "des": "IAFT 國際航空票務從業人員認證",
            "eng": "International Air Fares and Ticketing－Foundation Level"
          },
          {
            "no": "4009001017",
            "des": "PTD 旅遊行程設計師認證",
            "eng": "Package Tour Designer Certificate"
          },
          {
            "no": "4009001018",
            "des": "RSG 國際博弈產業服務資格認證",
            "eng": "Responsible Service of Gambling Course Certificate"
          },
          {
            "no": "4009001019",
            "des": "旅宿業經營專業人員 (初階認證)",
            "eng": "Hotel Industry Operations Professional Certification"
          },
          {
            "no": "4009001020",
            "des": "觀光產業人員 (初階認證)",
            "eng": "Tourism Industry Personnel Certification (Elementary)"
          },
          {
            "no": "4009001021",
            "des": "觀光服務管理專業人員 (藍星)",
            "eng": "Certified Tourism Service Management Professional (Blue Star)"
          }
        ],
        "eng": "Tourism Related Certifications"
      },
      {
        "no": "4009002000",
        "des": "餐飲相關證照",
        "n": [
          {
            "no": "4009002001",
            "des": "丙級餐旅服務技術士",
            "eng": "Level C technician for catering and tourism service"
          },
          {
            "no": "4009002002",
            "des": "丙級調酒技術士",
            "eng": "Level C technician for mixing"
          },
          {
            "no": "4009002003",
            "des": "丙級西餐烹調技術士",
            "eng": "Level C technician for western culinary"
          },
          {
            "no": "4009002004",
            "des": "乙級中餐(葷食)烹調技術士",
            "eng": "Level B technician for Chinese culinary"
          },
          {
            "no": "4009002005",
            "des": "丙級中餐(葷食)烹調技術士",
            "eng": "Level C technician for Chinese culinary"
          },
          {
            "no": "4009002025",
            "des": "乙級中餐(素食)烹調技術士",
            "eng": "Level B technician for Chinese Cuisine Cookery-Vegetarian diet"
          },
          {
            "no": "4009002026",
            "des": "丙級中餐(素食)烹調技術士",
            "eng": "Level C technician for Chinese Cuisine Cookery-Vegetarian diet"
          },
          {
            "no": "4009002006",
            "des": "乙級烘焙食品技術士",
            "eng": "Level B technician for baking food"
          },
          {
            "no": "4009002007",
            "des": "丙級烘焙食品技術士",
            "eng": "Level C technician for baking food"
          },
          {
            "no": "4009002008",
            "des": "乙級飲料調製技術士",
            "eng": "Level B technician for beverages preparation"
          },
          {
            "no": "4009002009",
            "des": "丙級飲料調製技術士",
            "eng": "Level C technician for beverages preparation"
          },
          {
            "no": "4009002010",
            "des": "國際咖啡調配師",
            "eng": "International Award in Barista Skills"
          },
          {
            "no": "4009002011",
            "des": "英國City & Guilds 國際調酒師證照",
            "eng": "International Award in Professional Bartending (cocktails)"
          },
          {
            "no": "4009002012",
            "des": "英國City & Guilds國際咖啡烘豆師證照",
            "eng": "International Coffee Roasting Certification"
          },
          {
            "no": "4009002013",
            "des": "英國City & Guilds 國際葡萄酒侍酒師證照",
            "eng": "City & Guilds Wine Sommelier Master"
          },
          {
            "no": "4009002014",
            "des": "茶學初階認證",
            "eng": "Tea Science Certification"
          },
          {
            "no": "4009002015",
            "des": "SSE 精品咖啡師國際認證",
            "eng": "Lausanne barista"
          },
          {
            "no": "4009002016",
            "des": "SSE 咖啡師國際認證",
            "eng": "Elite Barista"
          },
          {
            "no": "4009002017",
            "des": "SSE 調酒師國際認證",
            "eng": "Level B technician for Employment Servic"
          },
          {
            "no": "4009002018",
            "des": "SSE 精品調酒師國際認證",
            "eng": "Elite Bartender"
          },
          {
            "no": "4009002019",
            "des": "SSE 精品茶藝師國際認證",
            "eng": "Elite Tea Specialist"
          },
          {
            "no": "4009002020",
            "des": "SSE 茶藝師國際認證",
            "eng": "Lausanne Tea Specialist"
          },
          {
            "no": "4009002021",
            "des": "茶學中階認證",
            "eng": "Intermediate Tea Studies Certification"
          },
          {
            "no": "4009002022",
            "des": "乙級西餐烹調技術士",
            "eng": "Level B technician for western culinary"
          },
          {
            "no": "4009002023",
            "des": "SSE 飲料調製國際認證",
            "eng": "SSE Beverage Mixing"
          },
          {
            "no": "4009002024",
            "des": "RSA 國際侍酒服務資格認證",
            "eng": "Provide Responsible Service of Alcohol Course Certificate"
          },
          {
            "no": "4009002027",
            "des": "國際英式管家專業服務認證",
            "eng": "UKBG International British butler professional service certification"
          },
          {
            "no": "4009002028",
            "des": "英國管家證照",
            "eng": "Level 3 Diploma for Butlers"
          },
          {
            "no": "4009002029",
            "des": "餐旅美學專業人員 (藍星)",
            "eng": "Hospitality Aesthetics Professional Certification (Blue Star)"
          },
          {
            "no": "4009002030",
            "des": "餐廳後場管理人員認證 (藍星)",
            "eng": "Restaurant Management Professional Certificatio (Blue Star)"
          },
          {
            "no": "4009002031",
            "des": "餐廳管理專業人員認證 (藍星)",
            "eng": "Restaurant Management Professional Certificatio (Blue Star)"
          },
          {
            "no": "4009002032",
            "des": "咖啡師技能(初階)",
            "eng": "SCA CSP Barista Skills-Foundation"
          },
          {
            "no": "4009002033",
            "des": "咖啡師技能(進階)",
            "eng": "SCA CSP Barista Skills-Intermediate"
          },
          {
            "no": "4009002034",
            "des": "咖啡烘焙(初階)",
            "eng": "SCA CSP Roasting-Foundation"
          },
          {
            "no": "4009002035",
            "des": "咖啡烘焙(進階)",
            "eng": "SCA CSP Roasting-Intermediate"
          },
          {
            "no": "4009002036",
            "des": "咖啡感官(初階)",
            "eng": "SCA CSP Sensory Skills-Foundation"
          },
          {
            "no": "4009002037",
            "des": "咖啡感官(進階)",
            "eng": "SCA CSP Sensory Skills-Intermediate"
          },
          {
            "no": "4009002038",
            "des": "金杯萃取(初階)",
            "eng": "SCA CSP Brewing-Foundation"
          },
          {
            "no": "4009002039",
            "des": "金杯萃取(進階)",
            "eng": "SCA CSP Brewing-Intermediate"
          }
        ],
        "eng": "Catering Related Certifications"
      }
    ],
    "eng": "Catering and Tourism Category"
  },
  {
    "no": "4010000000",
    "des": "設計／美工類",
    "n": [
      {
        "no": "4010001000",
        "des": "平面／網頁／多媒體相關證照",
        "n": [
          {
            "no": "4010001001",
            "des": "Adobe After Effects",
            "eng": "Adobe After Effects"
          },
          {
            "no": "4010001002",
            "des": "Adobe Illustrator",
            "eng": "Adobe Illustrator"
          },
          {
            "no": "4010001004",
            "des": "Adobe InDesign",
            "eng": "Adobe InDesign"
          },
          {
            "no": "4010001006",
            "des": "Adobe Pagemaker",
            "eng": "Adobe Pagemaker"
          },
          {
            "no": "4010001007",
            "des": "Corel DRAW",
            "eng": "Corel DRAW"
          },
          {
            "no": "4010001011",
            "des": "TQC-WD網頁設計類-ASP",
            "eng": "TQC-WD- Web page design -ASP"
          },
          {
            "no": "4010001012",
            "des": "TQC-MD多媒體設計類-Flash",
            "eng": "TQC-MD- Multimedia design -Flash"
          },
          {
            "no": "4010001013",
            "des": "TQC-WD網頁設計類-FrontPage",
            "eng": "TQC-WD- Web page design -FrontPage"
          },
          {
            "no": "4010001014",
            "des": "TQC-WD網頁設計類-HTML",
            "eng": "TQC-WD- Web page design -HTML"
          },
          {
            "no": "4010001015",
            "des": "TQC專業網頁設計工程師",
            "eng": "TQC-Professional web page designer"
          },
          {
            "no": "4010001016",
            "des": "TQC-IP影像處理類-PhotoImpact",
            "eng": "TQC-IP- Image process -PhotoImpact"
          },
          {
            "no": "4010001017",
            "des": "TQC專業多媒體網頁設計工程師",
            "eng": "TQC Professional multimedia and web page designer"
          },
          {
            "no": "4010001018",
            "des": "丙級網頁設計技術士",
            "eng": "Level C technician for web page design"
          },
          {
            "no": "4010001019",
            "des": "TQC-WD網頁設計類-JavaScript",
            "eng": "TQC-WD- Web page design -JavaScript"
          },
          {
            "no": "4010001020",
            "des": "TQC-WD網頁設計類-DreamWeaver",
            "eng": "TQC-WD- Web page design -DreamWeaver"
          },
          {
            "no": "4010001021",
            "des": "TQC-EC專業互動式網頁設計工程師",
            "eng": "TQC-EC- Professional interactive web page designer"
          },
          {
            "no": "4010001022",
            "des": "IT Expert 3D動畫科技藝術-模型製作與材質設定專業人員-角色製作",
            "eng": "IT Expert 3D animation technology & art - role making"
          },
          {
            "no": "4010001023",
            "des": "IT Expert 3D動畫科技藝術-模型製作與材質設定專業人員-場景製作",
            "eng": "IT Expert 3D animation technology & art - scene making"
          },
          {
            "no": "4010001024",
            "des": "CSWA",
            "eng": "CSWA"
          },
          {
            "no": "4010001025",
            "des": "CSWP",
            "eng": "CSWP"
          },
          {
            "no": "4010001026",
            "des": "TQC-WD-互動式網頁設計ASP.NET-實用級AN1",
            "eng": "TQC-WD-ASP.NET-AN1"
          },
          {
            "no": "4010001027",
            "des": "TQC-WD-互動式網頁設計ASP.NET-進階級AN2",
            "eng": "TQC-WD-ASP.NET-AN2"
          },
          {
            "no": "4010001028",
            "des": "TQC-WD-互動式網頁設計ASP.NET-專業級AN3",
            "eng": "TQC-WD-ASP.NET-AN3"
          },
          {
            "no": "4010001029",
            "des": "IT Expert 數位內容遊戲企劃專業人員",
            "eng": "IT Expert in Digital Content Game Planning"
          },
          {
            "no": "4010001030",
            "des": "IT Expert 數位內容遊戲美術專業人員",
            "eng": "IT Expert in Digital Content Game Art Design"
          },
          {
            "no": "4010001031",
            "des": "TQC-IP-影像處理Photoshop-實用級PT1",
            "eng": "TQC-IP-Photoshop-PT1"
          },
          {
            "no": "4010001032",
            "des": "TQC-IP-影像處理Photoshop-進階級PT2",
            "eng": "TQC-IP-Photoshop-PT2"
          },
          {
            "no": "4010001034",
            "des": "AutoCAD國際認證",
            "eng": "Autodesk AutoCAD Certified Professional"
          },
          {
            "no": "4010001035",
            "des": "Inventor 國際認證",
            "eng": "Autodesk Inventor Certified Professional"
          },
          {
            "no": "4010001036",
            "des": "TQC-IP-影像處理Photoshop-專業級PT3",
            "eng": "TQC-IP-Photoshop-PT3"
          },
          {
            "no": "4010001037",
            "des": "3ds Max Design國際認證",
            "eng": "Autodesk 3ds Max Design Certified Professional"
          },
          {
            "no": "4010001038",
            "des": "Revit國際認證",
            "eng": "Revit Architecture Certified Professional"
          },
          {
            "no": "4010001039",
            "des": "3ds Max - 建模動畫國際認證",
            "eng": "Autodesk 3ds Max Certified Professional Models to Motion"
          },
          {
            "no": "4010001040",
            "des": "3ds Max - 視覺開發國際認證",
            "eng": "Autodesk 3ds Max Certified Professional Surface and Look Development"
          },
          {
            "no": "4010001041",
            "des": "Maya - 建模動畫國際認證",
            "eng": "Autodesk Maya Certified Professional Models to Motion"
          },
          {
            "no": "4010001042",
            "des": "Maya - 視覺特效國際認證",
            "eng": "Autodesk Maya 2011 Visual Effects and Simulation"
          },
          {
            "no": "4010001043",
            "des": "Unity應用能力認證(4.x版本)",
            "eng": "Unity Certified User (4.x version)"
          },
          {
            "no": "4010001044",
            "des": "Unity專業能力認證(4.x版本)",
            "eng": "Unity Certified Professional (4.x version)"
          },
          {
            "no": "4010001045",
            "des": "SSE 3D Game using 3D Studio Max",
            "eng": "SSE 3D Game using 3D Studio Max"
          },
          {
            "no": "4010001046",
            "des": "SSE Cinema 4D Essential",
            "eng": "SSE Cinema 4D Essential"
          },
          {
            "no": "4010001047",
            "des": "Designing 3D Animation Game Using Maya",
            "eng": "Designing 3D Animation Game Using Maya"
          },
          {
            "no": "4010001048",
            "des": "Designing 3D Animation Games Using Unity",
            "eng": "Designing 3D Animation Games Using Unity"
          },
          {
            "no": "4010001049",
            "des": "SSE Adobe Dreamweaver CC",
            "eng": "SSE Adobe Dreamweaver CC"
          },
          {
            "no": "4010001050",
            "des": "SSE Adobe Illustrator CC",
            "eng": "SSE Adobe Illustrator CC"
          },
          {
            "no": "4010001051",
            "des": "SSE Adobe InDesign CC",
            "eng": "SSE Adobe InDesign CC"
          },
          {
            "no": "4010001052",
            "des": "SSE Adobe Photoshop CC",
            "eng": "SSE Adobe Photoshop CC"
          },
          {
            "no": "4010001053",
            "des": "SSE Adobe After Effects CC",
            "eng": "SSE Adobe After Effects CC"
          },
          {
            "no": "4010001054",
            "des": "SSE Adobe Premiere CC",
            "eng": "SSE Adobe Premiere CC"
          },
          {
            "no": "4010001055",
            "des": "SSE AutoCAD Essential",
            "eng": "SSE AutoCAD Essential"
          },
          {
            "no": "4010001056",
            "des": "SSE Interior Design Using AutoCAD",
            "eng": "SSE Interior Design Using AutoCAD"
          },
          {
            "no": "4010001057",
            "des": "SSE CSS3國際認證",
            "eng": "CSS3 Knowledge Today"
          },
          {
            "no": "4010001058",
            "des": "SSE HTML5國際認證",
            "eng": "Web Design Using HTML5"
          },
          {
            "no": "4010001059",
            "des": "乙級網頁設計技術士",
            "eng": "Level B technician for Web Design"
          },
          {
            "no": "4010001060",
            "des": "Adobe Photoshop",
            "eng": "Adobe Photoshop"
          }
        ],
        "eng": "Graphic/Web/Multimedia Certification"
      },
      {
        "no": "4010002000",
        "des": "設計相關證照",
        "n": [
          {
            "no": "4010002002",
            "des": "乙級金銀珠寶飾品加工技術士",
            "eng": "Level B technician for jewelry processing"
          },
          {
            "no": "4010002003",
            "des": "丙級金銀珠寶飾品加工技術士",
            "eng": "Level C technician for jewelry processing"
          },
          {
            "no": "4010002004",
            "des": "SSE 色彩學國際認證",
            "eng": "Chromatics"
          },
          {
            "no": "4010002005",
            "des": "SSE User Interface Design國際認證",
            "eng": "User Interface Design"
          },
          {
            "no": "4010002006",
            "des": "SSE 視覺傳達國際認證",
            "eng": "Visual Communication"
          }
        ],
        "eng": "Design Related Certifications"
      }
    ],
    "eng": "Design/Art Categorie"
  },
  {
    "no": "4011000000",
    "des": "業務貿易／門市服務類",
    "n": [
      {
        "no": "4011001000",
        "des": "國貿相關證照",
        "n": [
          {
            "no": "4011001001",
            "des": "保稅證照",
            "eng": "Bonded certificate"
          },
          {
            "no": "4011001002",
            "des": "特考專責報關人員",
            "eng": "Custom clearance agent of special examination"
          },
          {
            "no": "4011001003",
            "des": "乙級國貿業務技術士",
            "eng": "Level B technician for International Trade Management"
          },
          {
            "no": "4011001004",
            "des": "丙級國貿業務技術士",
            "eng": "Level C technician for International Trade Management"
          },
          {
            "no": "4011001005",
            "des": "貿易經營師證照",
            "eng": "Trade Management Certification"
          },
          {
            "no": "4011001006",
            "des": "國際貿易大會考",
            "eng": "International Trade Examination"
          },
          {
            "no": "4011001007",
            "des": "特考稅務人員",
            "eng": "Special Examination for Tax Personnel"
          },
          {
            "no": "4011001008",
            "des": "特考關務人員",
            "eng": "Special Examination for Customs Personnel"
          },
          {
            "no": "4011001009",
            "des": "特考國際經濟商務人員",
            "eng": "Special Examination for International Economic and Commercial Personnel"
          },
          {
            "no": "4011001010",
            "des": "國際經濟貿易人才認證",
            "eng": "International Economic and Trade Talent Certification"
          }
        ],
        "eng": "International Trade Related Certifications"
      },
      {
        "no": "4011002000",
        "des": "門市服務相關證照",
        "n": [
          {
            "no": "4011002001",
            "des": "乙級門市服務技術士",
            "eng": "Level B technician for Chain Store Service"
          },
          {
            "no": "4011002002",
            "des": "丙級門市服務技術士",
            "eng": "Level C technician for Chain Store Service"
          },
          {
            "no": "4011002003",
            "des": "商業管理基礎知能",
            "eng": "Business Management Certification Basic"
          }
        ],
        "eng": "Retail Service Related Certifications"
      }
    ],
    "eng": "Business Trade/Retail Service Category"
  },
  {
    "no": "4012000000",
    "des": "行銷／廣告／企劃類",
    "n": [
      {
        "no": "4012001000",
        "des": "企劃相關證照",
        "n": [
          {
            "no": "4012001003",
            "des": "TQC專業企畫人員",
            "eng": "TQC-Professional Planner"
          },
          {
            "no": "4012001004",
            "des": "TQC專業行銷人員",
            "eng": "TQC-Professional Marketer"
          },
          {
            "no": "4012001005",
            "des": "TBSA商務企劃能力初級檢定",
            "eng": "TBSA Certificate of Business Planning Proficiency ( Elementary )"
          },
          {
            "no": "4012001006",
            "des": "TBSA商務企劃能力進階檢定",
            "eng": "TBSA Certificate of Business Planning Proficiency ( Advanced )"
          },
          {
            "no": "4012001007",
            "des": "CIM全球行銷中階主管資格認證",
            "eng": "CIM Professional Diploma in Marketing"
          },
          {
            "no": "4012001008",
            "des": "CIM全球行銷高階主管資格認證",
            "eng": "Professional Postgraduate Diploma in Marketing"
          },
          {
            "no": "4012001009",
            "des": "WBSA初階商務企劃員認證",
            "eng": "WBSA Business Planning Certification Elementary"
          },
          {
            "no": "4012001010",
            "des": "WBSA中階行銷企劃師認證",
            "eng": "WBSA Business Planning Certification Specialist"
          },
          {
            "no": "4012001011",
            "des": "WBSA高階商務策劃師認證",
            "eng": "WBSA Business Planning Certification Expert"
          },
          {
            "no": "4012001012",
            "des": "青年活動企劃師證照",
            "eng": "Youth Activity Planning Certification"
          },
          {
            "no": "4012001013",
            "des": "乙級廣告設計技術士",
            "eng": "Level B technician for Advertising Design"
          },
          {
            "no": "4012001014",
            "des": "丙級廣告設計技術士",
            "eng": "Level C technician for Advertising Design"
          },
          {
            "no": "4012001015",
            "des": "會議展覽專業人員認證",
            "eng": "CERTIFICATION IN MICE PROFESSIONAL"
          },
          {
            "no": "4012001016",
            "des": "會議展覽專業人員進階認證",
            "eng": "Advanced Certification for Conference and Exhibition Professionals"
          },
          {
            "no": "4012001017",
            "des": "CMP行銷企劃師認證",
            "eng": "Certified Marketing Professional"
          },
          {
            "no": "4012001018",
            "des": "WBSA數位行銷專業人員國際認證",
            "eng": "WBSA Certified Digital Marketing Planner"
          },
          {
            "no": "4012001019",
            "des": "經濟部初級行動APP企劃師",
            "eng": "MOEA Certified Mobile APP Planning Associate"
          },
          {
            "no": "4012001020",
            "des": "CEM國際展覽認證",
            "eng": "Certified in Exhibition Management (CEM)"
          },
          {
            "no": "4012001021",
            "des": "CMP國際會議認證考試",
            "eng": "The Certified Meeting Professional"
          },
          {
            "no": "4012001022",
            "des": "創新創意規劃師",
            "eng": "Creative Planner"
          },
          {
            "no": "4012001023",
            "des": "會展行銷管理師",
            "eng": "Exhibition Marketing Manager"
          },
          {
            "no": "4012001024",
            "des": "台灣簡報認證-入門級",
            "eng": "Taiwan Presentation Certification Associate"
          },
          {
            "no": "4012001025",
            "des": "台灣簡報認證-中級",
            "eng": "Taiwan Presentation Certification Specialist"
          },
          {
            "no": "4012001026",
            "des": "台灣簡報認證-高級",
            "eng": "Taiwan Presentation Certification"
          },
          {
            "no": "4012001027",
            "des": "TBSA商務企劃能力初級檢定(行銷企劃初階認證)",
            "eng": "TBSA Certificate of Business Planning Proficiency (Elementary)"
          },
          {
            "no": "4012001028",
            "des": "TBSA商務企劃能力進階檢定(行銷企劃進階認證)",
            "eng": "TBSA Certificate of Business Planning Proficiency (Advanced)"
          }
        ],
        "eng": "Planning Related Certifications"
      },
      {
        "no": "4012002000",
        "des": "行銷相關證照",
        "n": [
          {
            "no": "4012002001",
            "des": "行銷管理師",
            "eng": "International Introductory Award in Selling"
          },
          {
            "no": "4012002002",
            "des": "TMC初階行銷傳播認證",
            "eng": "TMC Entry-level Marketing & Communication Certification"
          },
          {
            "no": "4012002003",
            "des": "國際行銷初級人才認證檢定",
            "eng": "LEVEL 1 IN INTERNATIONAL MARKETING"
          },
          {
            "no": "4012002004",
            "des": "TIMS行銷企劃證照",
            "eng": "Certificate of Marketing Planning"
          },
          {
            "no": "4012002005",
            "des": "TIMS網路行銷證照",
            "eng": "Certificate of Internet Marketing"
          },
          {
            "no": "4012002006",
            "des": "TIMS全球行銷證照",
            "eng": "Certificate of Global Marketing"
          },
          {
            "no": "4012002007",
            "des": "TIMS觀光行銷證照",
            "eng": "Certificate of Tourism Marketing"
          },
          {
            "no": "4012002008",
            "des": "TIMS運動行銷證照",
            "eng": "Certificate of Sports Marketing"
          },
          {
            "no": "4012002009",
            "des": "TIMS海峽兩岸營銷證照",
            "eng": "Cross-Strait Marketing Proficiency Certificate"
          },
          {
            "no": "4012002010",
            "des": "TIMS中階行銷分析證照",
            "eng": "Certificate of Marketing Analysis"
          },
          {
            "no": "4012002011",
            "des": "TIMS中階行銷決策證照",
            "eng": "Certificate of Marketing Decision"
          },
          {
            "no": "4012002012",
            "des": "ITI外貿協會培訓中心國企班一年期英語組結業",
            "eng": "Certificate for the one-year English Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002013",
            "des": "ITI外貿協會培訓中心國企班一年期越語組結業",
            "eng": "Certificate for the one-year Vietnamese Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002014",
            "des": "ITI外貿協會培訓中心國企班一年期經貿組結業",
            "eng": "Certificate for the one-year International Trade Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002036",
            "des": "ITI外貿協會培訓中心國企班一年期泰語組結業",
            "eng": "Certificate of one-year Thai-language majors IBAP program from ITI"
          },
          {
            "no": "4012002037",
            "des": "ITI外貿協會培訓中心國企班一年期數位跨域專班結業",
            "eng": "Certificate of one-year E-marketing majors IBAP program from ITI"
          },
          {
            "no": "4012002015",
            "des": "ITI外貿協會培訓中心國企班二年期日語組結業",
            "eng": "Certificate for the two-year Japanese Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002016",
            "des": "ITI外貿協會培訓中心國企班二年期印尼語組結業",
            "eng": "Certificate for the two-year Indonesian Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002017",
            "des": "ITI外貿協會培訓中心國企班二年期西語組結業",
            "eng": "Certificate for the two-year Spanish Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002018",
            "des": "ITI外貿協會培訓中心國企班二年期法語組結業",
            "eng": "Certificate for the two-year French Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002019",
            "des": "ITI外貿協會培訓中心國企班二年期阿語組結業",
            "eng": "Certificate for the two-year Arabic Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002020",
            "des": "ITI外貿協會培訓中心國企班二年期俄語組結業",
            "eng": "Certificate for the two-year Russian Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002021",
            "des": "ITI外貿協會培訓中心國企班二年期英語組結業",
            "eng": "Certificate for the two-year English Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002022",
            "des": "ITI外貿協會培訓中心國企班二年期越語組結業",
            "eng": "Certificate for the two-year Vietnamese Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002023",
            "des": "ITI外貿協會培訓中心國企班二年期泰語組結業",
            "eng": "Certificate for the two-year Thai Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002024",
            "des": "ITI外貿協會培訓中心國企班二年期葡語組結業",
            "eng": "Certificate for the two-year Portuguese Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002025",
            "des": "ITI外貿協會培訓中心國企班二年期德語組結業",
            "eng": "Certificate for the two-year German Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002026",
            "des": "ITI外貿協會培訓中心國企班二年期韓語組結業",
            "eng": "Certificate for the two-year Korean Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002027",
            "des": "ITI外貿協會培訓中心國企特訓班結業",
            "eng": "Certificate for the Special Training Program in International Business Administration from ITI"
          },
          {
            "no": "4012002028",
            "des": "ITI外貿協會培訓中心國企在職班結業",
            "eng": "Certificate for the On-the-Job Program in International Business Administration from ITI"
          },
          {
            "no": "4012002029",
            "des": "IMSA整合行銷策略分析師",
            "eng": "Integrated Marketing Strategy Analyst"
          },
          {
            "no": "4012002030",
            "des": "MBMP微電影品牌行銷規劃師",
            "eng": "Micro film Brand Marketing Planner"
          },
          {
            "no": "4012002031",
            "des": "MICEA會議展覽行銷應用師",
            "eng": "MICE Marketing Application/ Planne"
          },
          {
            "no": "4012002032",
            "des": "數位精準行銷認證",
            "eng": "MiNGYI English Certification (MEC)"
          },
          {
            "no": "4012002033",
            "des": "文案設計與媒體行銷認證",
            "eng": "Copywriting Design and Media Marketing Certification"
          },
          {
            "no": "4012002034",
            "des": "市場行銷規劃師",
            "eng": "Marketing Planner"
          },
          {
            "no": "4012002035",
            "des": "自媒體產業知識認證",
            "eng": "We Media Professional Knowledge Certification ( WMP-K )"
          },
          {
            "no": "4012002038",
            "des": "行銷管理企劃師",
            "eng": "Marketing Management Planner"
          },
          {
            "no": "4012002039",
            "des": "網路行銷管理師",
            "eng": "Internet Marketing Manager"
          },
          {
            "no": "4012002040",
            "des": "ITI外貿協會培訓中心國企班一年期日語組結業",
            "eng": "Certificate for the one-year Japanese Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002041",
            "des": "ITI外貿協會培訓中心國企班一年期經貿實戰組結業",
            "eng": "Certificate for the one-year Business Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002042",
            "des": "ITI外貿協會培訓中心國企班一年期數位跨域組結業",
            "eng": "Certificate for the one-year E-Marketing Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002043",
            "des": "ITI外貿協會培訓中心國企班一年期西語組結業",
            "eng": "Certificate for the one-year Spanish Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002044",
            "des": "ITI外貿協會培訓中心國企班一年期德語組結業",
            "eng": "Certificate for the one-year German Major-International Business Administration Program from ITI"
          },
          {
            "no": "4012002045",
            "des": "LINE企業解決方案大師",
            "eng": "LINE Biz-Solutions - Master"
          },
          {
            "no": "4012002046",
            "des": "LINE成效型廣告專家",
            "eng": "LINE Ads Platform - Expert"
          },
          {
            "no": "4012002047",
            "des": "LINE官方帳號專家",
            "eng": "LINE Official Account - Expert"
          },
          {
            "no": "4012002048",
            "des": "新媒體行銷管理師",
            "eng": "New Media Marketing Manager"
          }
        ],
        "eng": "Marketing Related Certifications"
      }
    ],
    "eng": "Marketing/Advertising/Planning Category"
  },
  {
    "no": "4013000000",
    "des": "化工／化學類",
    "n": [
      {
        "no": "4013001000",
        "des": "化工相關證照",
        "n": [
          {
            "no": "4013001001",
            "des": "乙級化工技術士",
            "eng": "Level B technician for chemical engineering"
          },
          {
            "no": "4013001002",
            "des": "丙級化工技術士",
            "eng": "Level C technician for chemical engineering"
          },
          {
            "no": "4013001003",
            "des": "特考化學工程技師",
            "eng": "Chemical engineering technician of special examination"
          },
          {
            "no": "4013001004",
            "des": "特定化學物質作業主管",
            "eng": "Supervisor for Specific Chemical Substances Operations"
          }
        ],
        "eng": "Chemical Engineering Related Certifications"
      },
      {
        "no": "4013002000",
        "des": "化學相關證照",
        "n": [
          {
            "no": "4013002001",
            "des": "甲級化學技術士",
            "eng": "Level A technician for chemistry"
          },
          {
            "no": "4013002002",
            "des": "乙級化學技術士",
            "eng": "Level B technician for chemistry"
          },
          {
            "no": "4013002003",
            "des": "丙級化學技術士",
            "eng": "Level C technician for chemistry"
          },
          {
            "no": "4013002004",
            "des": "乙級石油化學技術士",
            "eng": "Level B technician for petroleum chemistry"
          },
          {
            "no": "4013002005",
            "des": "甲級石油化學技術士",
            "eng": "Level A technician for petroleum chemistry"
          }
        ],
        "eng": "Chemistry Related Certifications"
      }
    ],
    "eng": "Chemical Engineering/Chemistry Category"
  },
  {
    "no": "4014000000",
    "des": "醫療護理照顧類",
    "n": [
      {
        "no": "4014001000",
        "des": "醫療專業證照",
        "n": [
          {
            "no": "4014001001",
            "des": "高考中醫師",
            "eng": "Chinese medicine practitioner of higher examination"
          },
          {
            "no": "4014001002",
            "des": "特考中醫師",
            "eng": "Chinese medicine practitioner of special examination"
          },
          {
            "no": "4014001003",
            "des": "專科醫師",
            "eng": "Exclusive doctor"
          },
          {
            "no": "4014001004",
            "des": "高考牙醫師",
            "eng": "Dentist of higher examination"
          },
          {
            "no": "4014001005",
            "des": "高考醫師(一)",
            "eng": "Medical Licensing Examination (Level 1)"
          },
          {
            "no": "4014001006",
            "des": "高考醫師(二)",
            "eng": "Medical Licensing Examination (Level 2)"
          },
          {
            "no": "4014001007",
            "des": "特考法醫師",
            "eng": "Forensic Medical Specialist Examination"
          },
          {
            "no": "4014001008",
            "des": "醫藥行銷師",
            "eng": "Medical Representative(MR)"
          },
          {
            "no": "4014001009",
            "des": "USMLE",
            "eng": "USMLE"
          },
          {
            "no": "4014001010",
            "des": "專門職業及技術人員高等考試公共衛生師",
            "eng": "Senior Professional and Technical Examinations for Public Health Specialists"
          }
        ],
        "eng": "Medical Professional Certifications"
      },
      {
        "no": "4014002000",
        "des": "醫助相關證照",
        "n": [
          {
            "no": "4014002001",
            "des": "高考營養師",
            "eng": "Dietitian of higher examination"
          },
          {
            "no": "4014002002",
            "des": "高考藥師",
            "eng": "Pharmacist of higher examination"
          },
          {
            "no": "4014002003",
            "des": "醫事檢驗師",
            "eng": "Medical laboratory scientist"
          },
          {
            "no": "4014002004",
            "des": "普考助產士",
            "eng": "Midwife of general examination"
          },
          {
            "no": "4014002005",
            "des": "普考物理治療生",
            "eng": "Physiotherapy trainee of general examination"
          },
          {
            "no": "4014002006",
            "des": "高考醫事放射師",
            "eng": "Medical radiation technologist of higher examination"
          },
          {
            "no": "4014002007",
            "des": "高考物理治療師",
            "eng": "Physiotherapist of higher examination"
          },
          {
            "no": "4014002008",
            "des": "高考呼吸治療師",
            "eng": "Breath therapist of higher examination"
          },
          {
            "no": "4014002009",
            "des": "特考呼吸治療師",
            "eng": "Breath therapist of special examination"
          },
          {
            "no": "4014002010",
            "des": "高考臨床心理師",
            "eng": "Clinical psychologist of higher examination"
          },
          {
            "no": "4014002011",
            "des": "特考臨床心理師",
            "eng": "Clinical psychologist of special examination"
          },
          {
            "no": "4014002012",
            "des": "高考社會工作師",
            "eng": "Senior social worker of higher examination"
          },
          {
            "no": "4014002013",
            "des": "高考職能治療師",
            "eng": "Occupational therapist of higher examination"
          },
          {
            "no": "4014002014",
            "des": "高考諮商心理師",
            "eng": "Consulting psychologist of higher examination"
          },
          {
            "no": "4014002015",
            "des": "特考諮商心理師",
            "eng": "Consulting psychologist of special examination"
          },
          {
            "no": "4014002016",
            "des": "普考職能治療生",
            "eng": "Occupational therapy trainee of general examination"
          },
          {
            "no": "4014002017",
            "des": "高考助產師",
            "eng": "Midwife of higher examination"
          },
          {
            "no": "4014002018",
            "des": "高考語言治療師",
            "eng": "Speech Therapists of higher examination"
          },
          {
            "no": "4014002019",
            "des": "特考語言治療師",
            "eng": "Speech Therapists of special examination"
          },
          {
            "no": "4014002020",
            "des": "特考牙體技術人員",
            "eng": "Dental Technicians of special examination"
          },
          {
            "no": "4014002021",
            "des": "特考法醫鑑識",
            "eng": "Forensic Scientists of special examination"
          },
          {
            "no": "4014002022",
            "des": "特考社會福利工作人員",
            "eng": "Social Welfare Workers of special examination"
          },
          {
            "no": "4014002023",
            "des": "特考聽力師",
            "eng": "Audiologists of special examination"
          },
          {
            "no": "4014002024",
            "des": "輻射防護師及格證書",
            "eng": "Radiation Protection Officer Certificate"
          },
          {
            "no": "4014002025",
            "des": "輻射防護員及格證書",
            "eng": "Radiation Protection Worker Certificate"
          },
          {
            "no": "4014002026",
            "des": "高考聽力師",
            "eng": "Audiologists of higher examination"
          },
          {
            "no": "4014002027",
            "des": "高考牙體技術師",
            "eng": "Dental Technicians of higher examination"
          },
          {
            "no": "4014002028",
            "des": "高(普)考驗光生",
            "eng": "Optometry Students of higher(general) examination"
          },
          {
            "no": "4014002029",
            "des": "特考驗光生",
            "eng": "Optometry Students of higher examination"
          },
          {
            "no": "4014002030",
            "des": "高(普)考驗光師",
            "eng": "Optometrists of higher(general) examination"
          },
          {
            "no": "4014002031",
            "des": "特考驗光師",
            "eng": "Optometrists of special examination"
          },
          {
            "no": "4014002032",
            "des": "醫學資訊管理師",
            "eng": "Medical Information Management Specialist"
          }
        ],
        "eng": "Medical Assistant Related Certifications"
      },
      {
        "no": "4014003000",
        "des": "護理照顧相關證照",
        "n": [
          {
            "no": "4014003001",
            "des": "照顧服務員單一(丙)級技術士",
            "eng": "Technician for caring service"
          },
          {
            "no": "4014003002",
            "des": "普考護士執照",
            "eng": "Nurse license of general examination"
          },
          {
            "no": "4014003003",
            "des": "高考護理師執照",
            "eng": "Nurse license of higher examination"
          },
          {
            "no": "4014003004",
            "des": "CPR證照",
            "eng": "CPR certificate"
          },
          {
            "no": "4014003005",
            "des": "初級救護技術員（EMT1）",
            "eng": "EMT1"
          },
          {
            "no": "4014003006",
            "des": "專科護理師",
            "eng": "Specialized Nursing Practitioner"
          },
          {
            "no": "4014003007",
            "des": "園藝治療技士國際認證 (HTA)",
            "eng": "Asia Pacific Association of Therapeutic Horticulture (APATH)"
          },
          {
            "no": "4014003008",
            "des": "CHT園藝治療師認證",
            "eng": "Certified Horticultural Therapist"
          },
          {
            "no": "4014003009",
            "des": "糖尿病衛教師執照-CDE",
            "eng": "Certified Diabetes Educator"
          },
          {
            "no": "4014003010",
            "des": "勞工健康服務護理人員",
            "eng": "Occupational Health Service Nursing Staff"
          },
          {
            "no": "4014003011",
            "des": "照顧服務員訓練結業證明",
            "eng": "Caregiver Training Completion Certificate"
          },
          {
            "no": "4014003012",
            "des": "居家服務督導員",
            "eng": "Home Service Supervisor"
          },
          {
            "no": "4014003013",
            "des": "國際IGQA註冊C級 PHM專業健康管理師",
            "eng": "C Class Prognostic and Health Manager Certification"
          }
        ],
        "eng": "Nursing Care Related Certifications"
      },
      {
        "no": "4014004000",
        "des": "獸醫相關",
        "n": [
          {
            "no": "4014004001",
            "des": "高考獸醫師執照",
            "eng": "Veterinarian license of higher examination"
          },
          {
            "no": "4014004002",
            "des": "普考獸醫佐執照",
            "eng": "Veterinarian assistant license of general examination"
          }
        ],
        "eng": "Veterinary Related"
      }
    ],
    "eng": "Medical and Nursing Care Category"
  },
  {
    "no": "4015000000",
    "des": "保全警衛類",
    "n": [
      {
        "no": "4015001000",
        "des": "保全／警衛相關證照",
        "n": [
          {
            "no": "4015001001",
            "des": "防火管理人員",
            "eng": "Fire fighting management personnel"
          },
          {
            "no": "4015001002",
            "des": "總幹事證照",
            "eng": "Certificate for chief executive"
          },
          {
            "no": "4015001003",
            "des": "特考警察人員",
            "eng": "Police Officers of special examination"
          },
          {
            "no": "4015001004",
            "des": "治安管理師認證",
            "eng": "Legal Certification"
          }
        ],
        "eng": "Security Guard Related Certifications"
      }
    ],
    "eng": "Security Guard Category"
  },
  {
    "no": "4016000000",
    "des": "環境保護類",
    "n": [
      {
        "no": "4016001000",
        "des": "環保相關證照",
        "n": [
          {
            "no": "4016001001",
            "des": "高考環境工程技師",
            "eng": "Environmental engineering technician of higher examination"
          },
          {
            "no": "4016001003",
            "des": "乙級下水道設施操作維護技術士",
            "eng": "Level B technician for operation & maintenance of sewage facilities"
          },
          {
            "no": "4016001004",
            "des": "甲級化學性因子作業環境測定技術士",
            "eng": "Level A technician for testing operational environment of chemical factors"
          },
          {
            "no": "4016001005",
            "des": "乙級化學性因子作業環境測定技術士",
            "eng": "Level B technician for testing operational environment of chemical factors"
          },
          {
            "no": "4016001006",
            "des": "甲級物理性因子作業環境測定技術士",
            "eng": "Level A technician for testing operational environment of physical factors"
          },
          {
            "no": "4016001007",
            "des": "乙級物理性因子作業環境測定技術士",
            "eng": "Level B technician for testing operational environment of physical factors"
          },
          {
            "no": "4016001008",
            "des": "廢水處理專責人員合格證書",
            "eng": "Qualified certificate for exclusive personnel of waste water treatment"
          },
          {
            "no": "4016001009",
            "des": "空氣污染防制專責人員合格證書",
            "eng": "Qualified certificate for exclusive personnel of air pollution prevention"
          },
          {
            "no": "4016001011",
            "des": "甲級毒性化學物質管理合格證書",
            "eng": "Level A Toxic Chemical Substance Management Certificate"
          },
          {
            "no": "4016001012",
            "des": "乙級毒性化學物質管理合格證書",
            "eng": "Level B Toxic Chemical Substance Management Certificate"
          },
          {
            "no": "4016001013",
            "des": "丙級毒性化學物質管理合格證書",
            "eng": "Level C Toxic Chemical Substance Management Certificate"
          },
          {
            "no": "4016001014",
            "des": "環境教育人員認證",
            "eng": "Environmental Education Personnel Certification"
          },
          {
            "no": "4016001015",
            "des": "甲級廢棄物清除(處理)技術員合格證書",
            "eng": "Level A Qualified certificate for technical personnel of waste disposal (treatment)"
          },
          {
            "no": "4016001016",
            "des": "乙級廢棄物清除(處理)技術員合格證書",
            "eng": "Level B Qualified certificate for technical personnel of waste disposal (treatment)"
          },
          {
            "no": "4016001017",
            "des": "國際標準ISO14064-1組織溫室氣體盤查內部查證員",
            "eng": "International Standard ISO14064-1 Organization Greenhouse Gas Inventory Internal Verifier"
          },
          {
            "no": "4016001018",
            "des": "國際標準ISO14064-2溫室氣體減量抵換專案主導審查員",
            "eng": "International Standard ISO14064-2 Greenhouse Gas Reduction Offset Project Lead Auditor"
          },
          {
            "no": "4016001019",
            "des": "國際標準ISO14067碳足跡主任稽核員",
            "eng": "International Standard ISO14067 Carbon Footprint Chief Auditor"
          },
          {
            "no": "4016001020",
            "des": "ESG永續管理師",
            "eng": "ESG Sustainability Manager"
          }
        ],
        "eng": "Environmental Protection Related Certifications"
      },
      {
        "no": "4016002000",
        "des": "測量驗證類",
        "n": [
          {
            "no": "4016002001",
            "des": "CMVP 國際量測驗證師",
            "eng": "Certified Measurement & Verification Professional program (CMVP)"
          }
        ],
        "eng": "Measurement Verification Category"
      }
    ],
    "eng": "Environmental Protection Category"
  },
  {
    "no": "4017000000",
    "des": "娛樂演藝類",
    "n": [
      {
        "no": "4017001000",
        "des": "演藝娛樂相關證照",
        "n": [
          {
            "no": "4017001001",
            "des": "導演證",
            "eng": "Certificate of director"
          }
        ],
        "eng": "Performing Arts and Entertainment Related Certifications"
      },
      {
        "no": "4017002000",
        "des": "舞蹈／運動類",
        "n": [
          {
            "no": "4017002001",
            "des": "Primary Group Exercise Certification",
            "eng": "Primary Group Exercise Certification"
          },
          {
            "no": "4017002002",
            "des": "Weight Training Certification",
            "eng": "Weight Training Certification"
          },
          {
            "no": "4017002003",
            "des": "Personal Fitness Trainer Certification",
            "eng": "Personal Fitness Trainer Certification"
          },
          {
            "no": "4017002004",
            "des": "Step Certification",
            "eng": "Step Certification"
          },
          {
            "no": "4017002005",
            "des": "KickBoxing Certification ",
            "eng": "KickBoxing Certification "
          },
          {
            "no": "4017002006",
            "des": "MAT Science Certification ",
            "eng": "MAT Science Certification "
          }
        ],
        "eng": "Dance/Sports Category"
      }
    ],
    "eng": "Entertainment and Performing Arts Category"
  },
  {
    "no": "4018000000",
    "des": "媒體傳播類",
    "n": [
      {
        "no": "4018001000",
        "des": "媒體／出版",
        "n": [
          {
            "no": "4018001001",
            "des": "丙級照相技術士",
            "eng": "Level C technician for photography"
          },
          {
            "no": "4018001002",
            "des": "微電影數位社群經營",
            "eng": "Micro-film digital community management"
          }
        ],
        "eng": "Media/Publishing"
      },
      {
        "no": "4018002000",
        "des": "訊息傳播",
        "n": [
          {
            "no": "4018002001",
            "des": "特考外交領事人員",
            "eng": "Diplomatic and Consular Personnel of special examination"
          },
          {
            "no": "4018002002",
            "des": "特考國際新聞人員",
            "eng": "International Journalists of special examination"
          }
        ],
        "eng": "Information Communication"
      }
    ],
    "eng": "Media Communication Category"
  },
  {
    "no": "4019000000",
    "des": "品管安規類",
    "n": [
      {
        "no": "4019001000",
        "des": "品管安規證照",
        "n": [
          {
            "no": "4019001001",
            "des": "品質管理師(CQM)",
            "eng": "certified Quality Manager"
          },
          {
            "no": "4019001002",
            "des": "國際標準ISO 9000主導稽核員",
            "eng": "Chief inspector for ISO (International organization of standard) 9000"
          },
          {
            "no": "4019001003",
            "des": "國際標準ISO 14000主導稽核員",
            "eng": "Chief inspector for ISO (International organization of standard) 14000"
          },
          {
            "no": "4019001004",
            "des": "品質工程師(CQE)",
            "eng": "CQE (Certified qualified engineer)"
          },
          {
            "no": "4019001005",
            "des": "軟體測試工程師(CSTE)",
            "eng": "Software testing technician"
          },
          {
            "no": "4019001006",
            "des": "軟體品質工程師(CSQE)",
            "eng": "Software quality technician"
          },
          {
            "no": "4019001007",
            "des": "品質技術師(CQT)",
            "eng": "CQT (Certified Quality Technician)"
          },
          {
            "no": "4019001008",
            "des": "可靠度工程師(CRE)",
            "eng": "CRE (Certified Reliability Engineer)"
          },
          {
            "no": "4019001009",
            "des": "服務業品質專業師(CSQP)",
            "eng": "Quality specialist for service industry"
          },
          {
            "no": "4019001010",
            "des": "國際製造管理師",
            "eng": "Certified Manufacturing Management Expert: Fully Qualified"
          },
          {
            "no": "4019001011",
            "des": "國際製造管理師認證-生物管職能",
            "eng": "Certified Manufacturing Management Specialist: Production Material Management"
          },
          {
            "no": "4019001012",
            "des": "國際製造管理師認證-品工管職能",
            "eng": "Certified Manufacturing Management Specialist: Quality Management /Engineering"
          },
          {
            "no": "4019001013",
            "des": "國際製造管理師認證-設施/系統設計",
            "eng": "Certified Manufacturing Management Specialist: Facility Systems Design"
          },
          {
            "no": "4019001014",
            "des": "工業工程師證照",
            "eng": "Chinese Institute of Industrial Engineers"
          },
          {
            "no": "4019001015",
            "des": "初級非破壞檢測員",
            "eng": "Non-Destructive Tester Associate"
          },
          {
            "no": "4019001016",
            "des": "中級非破壞檢測師",
            "eng": "Non-Destructive Tester Specialist"
          },
          {
            "no": "4019001017",
            "des": "高級非破壞檢測師",
            "eng": "Non-Destructive Tester Expert"
          },
          {
            "no": "4019001018",
            "des": "公共工程品質管理人員",
            "eng": "Public Construction Quality Management Personnel"
          },
          {
            "no": "4019001020",
            "des": "國際萃智基礎創新專員",
            "eng": "MA TRIZ Certification Level 1"
          },
          {
            "no": "4019001021",
            "des": "國際萃智進階創新專員",
            "eng": "MA TRIZ Certification Level 2"
          },
          {
            "no": "4019001022",
            "des": "國際萃智專業創新工程師",
            "eng": "MA TRIZ Certification Level 3"
          },
          {
            "no": "4019001023",
            "des": "ISO 20000-1:2005主導稽核員",
            "eng": "ISO 20000-1:2005 Lead Auditor"
          },
          {
            "no": "4019001024",
            "des": "品質管理技術師證照",
            "eng": "CERTIFICATE of Quality management technologist"
          },
          {
            "no": "4019001025",
            "des": "國際標準ISO 22000主導稽核員",
            "eng": "International Standard ISO 22000 Lead Auditor"
          },
          {
            "no": "4019001026",
            "des": "經濟部初級食品品保工程師",
            "eng": "MOEA Certified Food Quality Assurance Associate"
          },
          {
            "no": "4019001027",
            "des": "經濟部中級食品品保工程師",
            "eng": "MOEA Certified Food Quality Assurance Specialist"
          },
          {
            "no": "4019001028",
            "des": "CQPE®品質實務工程師",
            "eng": "Certified Quality Practice Engineer"
          },
          {
            "no": "4019001029",
            "des": "ISO 45001 職業安全衛生管理系統主導稽核員",
            "eng": "ISO 45001 Occupational Health and Safety Management System Lead Auditor"
          }
        ],
        "eng": "Quality Control and Safety Regulations Certifications"
      }
    ],
    "eng": "Quality Control and Safety Regulations Category"
  },
  {
    "no": "4020000000",
    "des": "人事／法務／教育類",
    "n": [
      {
        "no": "4020001000",
        "des": "人力資源相關證照",
        "n": [
          {
            "no": "4020001045",
            "des": "招募選才管理師-專員級認證",
            "eng": "Recruitment and Selection Management Specialist - Associate Level Certification"
          },
          {
            "no": "4020001046",
            "des": "招募選才管理師-經理級認證",
            "eng": "Recruitment and Selection Management Specialist - Manager Level Certification"
          },
          {
            "no": "4020001048",
            "des": "薪酬管理師-專員級認證",
            "eng": "Compensation Management Specialist - Associate Level Certification"
          },
          {
            "no": "4020001049",
            "des": "薪酬管理師-經理級認證",
            "eng": "Compensation Management Specialist - Manager Level Certification"
          },
          {
            "no": "4020001051",
            "des": "績效管理師-專員級認證",
            "eng": "Performance Management Specialist - Associate Level Certification"
          },
          {
            "no": "4020001052",
            "des": "績效管理師-經理級認證",
            "eng": "Performance Management Specialist - Manager Level Certification"
          },
          {
            "no": "4020001055",
            "des": "訓練發展管理師-專員級認證",
            "eng": "Training and Development Management - Professional"
          },
          {
            "no": "4020001056",
            "des": "訓練發展管理師-經理級認證",
            "eng": "Training and Development Management - Expert"
          },
          {
            "no": "4020001041",
            "des": "數位人資管理師-專員級認證",
            "eng": "Digital HR Management Specialist - Associate Level Certification"
          },
          {
            "no": "4020001042",
            "des": "數位人資管理師-經理級認證",
            "eng": "Digital HR Management Specialist - Manager Level Certification"
          },
          {
            "no": "4020001044",
            "des": "企業人才診斷師-專員級",
            "eng": "Corporate Talent Diagnostic Specialist - Associate Level"
          },
          {
            "no": "4020001043",
            "des": "企業人才診斷師-經理級",
            "eng": "Corporate Talent Diagnostic Specialist - Manager Level"
          },
          {
            "no": "4020001047",
            "des": "企業人才診斷師-人資長級",
            "eng": "Corporate Talent Diagnostic Specialist - HR Director Level"
          },
          {
            "no": "4020001053",
            "des": "小微型企業人力資源管理師",
            "eng": "Human Resources Professional for Small Business"
          },
          {
            "no": "4020001054",
            "des": "人資系統管理師-企業數位轉型認證",
            "eng": "Human Resource Manager- Enterprise Digital Transformation Certification"
          },
          {
            "no": "4020001002",
            "des": "知識管理管理師",
            "eng": "Knowledge manager"
          },
          {
            "no": "4020001003",
            "des": "策略性人力資源管理師",
            "eng": "Strategic management on human resource"
          },
          {
            "no": "4020001004",
            "des": "訓練發展管理師",
            "eng": "Training development manager"
          },
          {
            "no": "4020001005",
            "des": "職能管理師認證",
            "eng": "Competency Management Certification Program"
          },
          {
            "no": "4020001006",
            "des": "員工關係管理師認證",
            "eng": "Employee Relations Management Certification Program"
          },
          {
            "no": "4020001007",
            "des": "SPHR",
            "eng": "SPHR"
          },
          {
            "no": "4020001008",
            "des": "員工褔利管理師",
            "eng": "Staff welfare manager"
          },
          {
            "no": "4020001009",
            "des": "員工薪酬管理師認證",
            "eng": "Compensation Administration Certification Program"
          },
          {
            "no": "4020001010",
            "des": "總務行政管理師",
            "eng": "General affairs & administration manager"
          },
          {
            "no": "4020001011",
            "des": "大陸人力資源管理師",
            "eng": "Human resource manager in China"
          },
          {
            "no": "4020001012",
            "des": "人力資源主管",
            "eng": "Middle manager of human resource"
          },
          {
            "no": "4020001013",
            "des": "勞動法令管理師認證",
            "eng": "Labor Laws & Regulations Certification Program"
          },
          {
            "no": "4020001014",
            "des": "員工任用管理師",
            "eng": "Personnel recruitment manager"
          },
          {
            "no": "4020001015",
            "des": "生涯發展諮商師",
            "eng": "Career development consultant"
          },
          {
            "no": "4020001016",
            "des": "E-HR管理師",
            "eng": "E-HR manager"
          },
          {
            "no": "4020001017",
            "des": "員工發展管理師",
            "eng": "Employees Development Administrator"
          },
          {
            "no": "4020001018",
            "des": "職涯輔導管理師",
            "eng": "Career Development Administrator"
          },
          {
            "no": "4020001019",
            "des": "生涯發展經理人",
            "eng": "Completion of Career Management and Development Certificate"
          },
          {
            "no": "4020001020",
            "des": "生涯發展規劃暨輔導師",
            "eng": "Career Management and Development Certificate"
          },
          {
            "no": "4020001021",
            "des": "全方位基礎人力資源管理師認證",
            "eng": "Fundamental Human Resource Management Certification Program"
          },
          {
            "no": "4020001022",
            "des": "績效管理師",
            "eng": "Performance Administrator"
          },
          {
            "no": "4020001023",
            "des": "TQC專業人事人員",
            "eng": "TQC-Professional Human Resources Personnel"
          },
          {
            "no": "4020001024",
            "des": "乙級就業服務技術士",
            "eng": "Level B technician for Employment Service"
          },
          {
            "no": "4020001025",
            "des": "GPHR",
            "eng": "GPHR"
          },
          {
            "no": "4020001026",
            "des": "HRMP",
            "eng": "HRMP"
          },
          {
            "no": "4020001027",
            "des": "HRBP",
            "eng": "HRBP"
          },
          {
            "no": "4020001028",
            "des": "國際生涯發展諮詢師",
            "eng": "Career Development Advisor"
          },
          {
            "no": "4020001029",
            "des": "勞健保暨勞基法管理師認證",
            "eng": "Labor Insurance"
          },
          {
            "no": "4020001030",
            "des": "招募甄選僱用暨引導管理師認證",
            "eng": "Employee Recruiting"
          },
          {
            "no": "4020001031",
            "des": "訓練規劃暨執行管理師認證",
            "eng": "Training Planning and Implementation Certification Program"
          },
          {
            "no": "4020001032",
            "des": "企業內部講師認證",
            "eng": "Train the Trainer Certification Program"
          },
          {
            "no": "4020001033",
            "des": "薪資結算作業管理師認證",
            "eng": "Payroll Administration Certification Program"
          },
          {
            "no": "4020001034",
            "des": "人才發展管理師認證",
            "eng": "Talent Management Certification Program"
          },
          {
            "no": "4020001035",
            "des": "雇主品牌與招募策略管理師認證",
            "eng": "Employer Branding & Recruiting Strategy Certification Program"
          },
          {
            "no": "4020001036",
            "des": "HR策略夥伴認證",
            "eng": "Strategic HR Business Partner Certification Program"
          },
          {
            "no": "4020001037",
            "des": "績效管理師認證",
            "eng": "Performance Management Certification Program"
          },
          {
            "no": "4020001038",
            "des": "人資專案管理師認證",
            "eng": "HR Project Management Professional Certification Program"
          },
          {
            "no": "4020001050",
            "des": "人力資源與薪酬管理師",
            "eng": "Human Resources and Compensation Manager"
          },
          {
            "no": "4020001040",
            "des": "GCDF全球職涯發展師",
            "eng": "GCDF"
          },
          {
            "no": "4020001039",
            "des": "IHRM創新人力資源管理師",
            "eng": "IHRM"
          },
          {
            "no": "4020001001",
            "des": "PHR",
            "eng": "PHR"
          }
        ],
        "eng": "Human Resources Related Certifications"
      },
      {
        "no": "4020002000",
        "des": "法律相關證照",
        "n": [
          {
            "no": "4020002001",
            "des": "高考律師",
            "eng": "Attorney of higher examination"
          },
          {
            "no": "4020002002",
            "des": "專利分析師",
            "eng": "Patent Analyst"
          },
          {
            "no": "4020002003",
            "des": "高考民間公證人",
            "eng": "Private Notary of higher examination"
          },
          {
            "no": "4020002004",
            "des": "高考專利師",
            "eng": "Patent Attorney of higher examination"
          },
          {
            "no": "4020002005",
            "des": "智慧財產人員能力認證",
            "eng": "Intellectual Property Capacity"
          },
          {
            "no": "4020002006",
            "des": "特考司法人員 ",
            "eng": "Judicial Personnel of higher examination"
          },
          {
            "no": "4020002007",
            "des": "特考司法官",
            "eng": "Judges of higher examination"
          },
          {
            "no": "4020002008",
            "des": "特考司法事務官",
            "eng": "Judicial Administrators of higher examination"
          },
          {
            "no": "4020002009",
            "des": "特考法院書記官",
            "eng": "Court Clerks of higher examination"
          },
          {
            "no": "4020002010",
            "des": "特考監獄官",
            "eng": "Prison Officers of higher examination"
          },
          {
            "no": "4020002011",
            "des": "特考檢察事務官",
            "eng": "Prosecutorial Administrators of higher examination"
          },
          {
            "no": "4020002012",
            "des": "軍法官考試",
            "eng": "Military Judge Examination"
          },
          {
            "no": "4020002013",
            "des": "生活法律認證",
            "eng": "Everyday Law"
          }
        ],
        "eng": "Legal Related Certifications"
      },
      {
        "no": "4020003000",
        "des": "行政相關證照",
        "n": [
          {
            "no": "4020003001",
            "des": "TQC專業文書人員",
            "eng": "TQC-Professional Document Developer"
          },
          {
            "no": "4020003002",
            "des": "TQC專業中文秘書人員",
            "eng": "TQC-Professional Chinese Secretary"
          },
          {
            "no": "4020003003",
            "des": "TQC專業英文秘書人員",
            "eng": "TQC-Professional English Secretary"
          },
          {
            "no": "4020003004",
            "des": "TQC專業日文秘書人員",
            "eng": "TQC-Professional Japanese Secretary"
          },
          {
            "no": "4020003005",
            "des": "特考外交行政人員",
            "eng": "Diplomatic Administrative Personnel of higher examination"
          },
          {
            "no": "4020003006",
            "des": "特考移民行政人員",
            "eng": "Immigration Administrative Personnel of higher examination"
          },
          {
            "no": "4020003007",
            "des": "雲端服務商務人員",
            "eng": "Cloud Service Commerce Personnel"
          },
          {
            "no": "4020003008",
            "des": "行動商務人員",
            "eng": "Mobile Commerce Personnel"
          },
          {
            "no": "4020003009",
            "des": "行政管理師認證",
            "eng": "Administration Officer Certification Program"
          }
        ],
        "eng": "Administrative Related Certifications"
      },
      {
        "no": "4020004000",
        "des": "教育輔導相關證照",
        "n": [
          {
            "no": "4020004001",
            "des": "幼稚園教師證",
            "eng": "Kindergarten Teachers Certificate"
          },
          {
            "no": "4020004002",
            "des": "特殊教育教師證",
            "eng": "Special Education Teachers Certificate"
          },
          {
            "no": "4020004003",
            "des": "國民小學教師證",
            "eng": "Elementary School Teachers Certificate"
          },
          {
            "no": "4020004004",
            "des": "中等學校教師證",
            "eng": "Junior High School and Senior Secondary School Teachers Certificate"
          },
          {
            "no": "4020004005",
            "des": "戊類所長",
            "eng": "Class E Nursery Director"
          },
          {
            "no": "4020004006",
            "des": "CBD兒童全腦開發訓練師認證",
            "eng": "Certified Children Brain Development"
          },
          {
            "no": "4020004007",
            "des": "TAYL兒童美術師資認證",
            "eng": "Certificate in Teaching Art to Young Learners"
          },
          {
            "no": "4020004008",
            "des": "公幼教保員",
            "eng": "Public Kindergarten Caregiver"
          },
          {
            "no": "4020004009",
            "des": "兒童及少年福利機構托育人員",
            "eng": "Child and Youth Welfare Institution Childcare Provider"
          },
          {
            "no": "4020004010",
            "des": "兒童及少年福利機構早期療育教保人員",
            "eng": "Child and Youth Welfare Institution Early Intervention Educator"
          },
          {
            "no": "4020004011",
            "des": "兒童及少年福利機構早期療育助理教保人員",
            "eng": "Child and Youth Welfare Institution Early Intervention Assistant Educator"
          },
          {
            "no": "4020004012",
            "des": "兒童及少年福利機構保育人員",
            "eng": "Child and Youth Welfare Institution Childcare Assistant"
          },
          {
            "no": "4020004013",
            "des": "兒童及少年福利機構助理保育人員",
            "eng": "Child and Youth Welfare Institution Assistant Childcare Provider"
          },
          {
            "no": "4020004014",
            "des": "兒童及少年福利機構生活輔導人員",
            "eng": "Child and Youth Welfare Institution Life Guidance Counselor"
          },
          {
            "no": "4020004015",
            "des": "兒童及少年福利機構助理生活輔導人員",
            "eng": "Child and Youth Welfare Institution Assistant Life Guidance Counselor"
          },
          {
            "no": "4020004016",
            "des": "兒童及少年福利機構心理輔導人員",
            "eng": "Child and Youth Welfare Institution Psychological Counselor"
          },
          {
            "no": "4020004017",
            "des": "兒童及少年福利機構社會工作人員",
            "eng": "Child and Youth Welfare Institution Social Worker"
          },
          {
            "no": "4020004018",
            "des": "兒童及少年福利機構主管人員",
            "eng": "Child and Youth Welfare Institution Supervisor"
          }
        ],
        "eng": "Education and Counseling Related Certifications"
      },
      {
        "no": "4020005000",
        "des": "情報收集",
        "n": [
          {
            "no": "4020005001",
            "des": "特考國家安全情報人員",
            "eng": "National Security Intelligence Personnel of higher examination"
          },
          {
            "no": "4020005002",
            "des": "特考調查局調查人員",
            "eng": "Investigation Bureau Investigators of higher examination"
          }
        ],
        "eng": "Intelligence Collection"
      }
    ],
    "eng": "Human Resources/Legal Services/Education"
  },
  {
    "no": "4021000000",
    "des": "農林漁牧類",
    "n": [
      {
        "no": "4021001000",
        "des": "農業相關證照",
        "n": [
          {
            "no": "4021001001",
            "des": "高考園藝技師",
            "eng": "Gardening technician of higher examination"
          },
          {
            "no": "4021001002",
            "des": "高考農藝技師",
            "eng": "Agricultural technician of higher examination"
          },
          {
            "no": "4021001003",
            "des": "丙級園藝技術士",
            "eng": "Level C technician for gardening"
          },
          {
            "no": "4021001004",
            "des": "丙級農藝技術士",
            "eng": "Level C technician for agriculture"
          },
          {
            "no": "4021001005",
            "des": "丙級農田灌溉排水技術士",
            "eng": "Level C technician for farm irrigation & drainage"
          },
          {
            "no": "4021001006",
            "des": "種苗生產技術士",
            "eng": "Seedling production"
          },
          {
            "no": "4021001007",
            "des": "農藥代噴技術士",
            "eng": "Pesticides spray"
          },
          {
            "no": "4021001008",
            "des": "ISA 樹藝師",
            "eng": "ISA Certified Arborist"
          }
        ],
        "eng": "Agricultural Related Certifications"
      },
      {
        "no": "4021002000",
        "des": "林業相關證照",
        "n": [
          {
            "no": "4021002001",
            "des": "高考林業技師",
            "eng": "Forestry technician of higher examination"
          },
          {
            "no": "4021002002",
            "des": "丙級造園施工技術士",
            "eng": "Level C technician for gardening construction"
          },
          {
            "no": "4021002003",
            "des": "乙級造園景觀技術士",
            "eng": "Level B technician for gardening landscape"
          }
        ],
        "eng": "Forestry Related Certifications"
      },
      {
        "no": "4021003000",
        "des": "漁業相關證照",
        "n": [
          {
            "no": "4021003001",
            "des": "高考水產養殖技師",
            "eng": "Aquacultural technician of higher examination"
          },
          {
            "no": "4021003002",
            "des": "高考漁撈技師",
            "eng": "Fishing technician of higher examination"
          },
          {
            "no": "4021003003",
            "des": "乙級漁具製作技術士",
            "eng": "Level B technician for manufacturing fishing gear"
          },
          {
            "no": "4021003004",
            "des": "丙級漁具製作技術士",
            "eng": "Level C technician for manufacturing fishing gear"
          },
          {
            "no": "4021003005",
            "des": "丙級水族養殖技術士",
            "eng": "Level C technician for aquatic breeding"
          }
        ],
        "eng": "Fishing Related Certifications"
      },
      {
        "no": "4021004000",
        "des": "牧業相關證照",
        "n": [
          {
            "no": "4021004001",
            "des": "高考畜牧技師",
            "eng": "Livestock technician of higher examination"
          },
          {
            "no": "4021004002",
            "des": "丙級畜產技術士",
            "eng": "Level C technician for livestock production"
          }
        ],
        "eng": "Livestock Related Certifications"
      }
    ],
    "eng": "Agriculture"
  },
  {
    "no": "4022000000",
    "des": "採礦冶金類",
    "n": [
      {
        "no": "4022001000",
        "des": "採礦相關證照",
        "n": [
          {
            "no": "4022001001",
            "des": "高考應用地質技師",
            "eng": "Applied geotechnical technician of higher examination"
          },
          {
            "no": "4022001002",
            "des": "高考採礦工程技師",
            "eng": "Mining engineering technician of higher examination"
          },
          {
            "no": "4022001003",
            "des": "高考礦業安全技師",
            "eng": "Mining safety technician of higher examination"
          },
          {
            "no": "4022001004",
            "des": "高考工礦衛生技師",
            "eng": "Miner health technician of higher examination"
          }
        ],
        "eng": "Mining Related Certifications"
      },
      {
        "no": "4022002000",
        "des": "冶金相關證照",
        "n": [
          {
            "no": "4022002001",
            "des": "高考冶金工程技師",
            "eng": "Metallurgy engineering technician of higher examination"
          }
        ],
        "eng": "Metallurgy Related Certifications"
      }
    ],
    "eng": "Mining and Metallurgy "
  },
  {
    "no": "4023000000",
    "des": "管理類",
    "n": [
      {
        "no": "4023001000",
        "des": "專案管理",
        "n": [
          {
            "no": "4023001002",
            "des": "助理國際專案管理師CAPM",
            "eng": "Certified Associate in Project Management"
          },
          {
            "no": "4023001001",
            "des": "國際專案管理師PMP",
            "eng": "Project Management Professional"
          },
          {
            "no": "4023001004",
            "des": "中華專案管理師CPMP",
            "eng": "CPMP"
          },
          {
            "no": "4023001008",
            "des": "IPMA Level A，Project Director",
            "eng": "IPMA Level A"
          },
          {
            "no": "4023001009",
            "des": "IPMA Level B，Senior Project Manager",
            "eng": "IPMA Level B"
          },
          {
            "no": "4023001010",
            "des": "IPMA Level C，Project Manager",
            "eng": "IPMA Level C"
          },
          {
            "no": "4023001011",
            "des": "IPMA Level D，Project Associate",
            "eng": "IPMA Level D"
          },
          {
            "no": "4023001012",
            "des": "TQC-DK-專案管理概論",
            "eng": "TQC-DK - Introduction to Project Management - Professional Level PM3"
          },
          {
            "no": "4023001013",
            "des": "研發專案管理師認證",
            "eng": "Certified Research/Development Project Management Professional"
          },
          {
            "no": "4023001014",
            "des": "國際計畫管理師PgMP",
            "eng": "Program Management Professional"
          },
          {
            "no": "4023001015",
            "des": "APMP Level A，Projects Executive",
            "eng": "APMP Level A"
          },
          {
            "no": "4023001016",
            "des": "APMP Level B， Project Professional ",
            "eng": "APMP Level B"
          },
          {
            "no": "4023001017",
            "des": "APMP Level C， Project Supervisor ",
            "eng": "APMP Level C"
          },
          {
            "no": "4023001018",
            "des": "TQC專業專案管理人員",
            "eng": "Professional Project Management Personnel"
          },
          {
            "no": "4023001019",
            "des": "NPDP-新產品開發專家",
            "eng": "New product development professional (NPDP)"
          },
          {
            "no": "4023001020",
            "des": "C級一般專案經理",
            "eng": "Level C General Project Manager"
          },
          {
            "no": "4023001021",
            "des": "B級研發專案經理",
            "eng": "Level B R & D Project Manager"
          },
          {
            "no": "4023001022",
            "des": "B級行銷專案經理",
            "eng": "Level B Marketing Project Manager"
          },
          {
            "no": "4023001023",
            "des": "B級營建專案經理",
            "eng": "Level B Construction Project Manager"
          },
          {
            "no": "4023001024",
            "des": "B級活動專案經理",
            "eng": "Level B Event Project Manager"
          },
          {
            "no": "4023001025",
            "des": "B級經營專案經理",
            "eng": "Level B Administration Project Manager"
          },
          {
            "no": "4023001026",
            "des": "A級複雜專案經理",
            "eng": "Level A Complex Project Manager"
          },
          {
            "no": "4023001027",
            "des": "A+級大型專案經理",
            "eng": "Level A+ Program Manager"
          },
          {
            "no": "4023001028",
            "des": "專案管理師",
            "eng": "Project Manager"
          },
          {
            "no": "4023001029",
            "des": "敏捷專案管理師PMI-ACP",
            "eng": "PMI Agile Certified Practitioner"
          },
          {
            "no": "4023001030",
            "des": "敏捷專業證照 (CSM)",
            "eng": "Certified Scrum Master"
          },
          {
            "no": "4023001031",
            "des": "PMI-PBA國際商業分析師",
            "eng": "PMI Professional in Business Analysis"
          }
        ],
        "eng": "Project Management"
      },
      {
        "no": "4023002000",
        "des": "採購管理",
        "n": [
          {
            "no": "4023002001",
            "des": "基礎採購檢定A.P.S.",
            "eng": "Accredited Purchasing Specialist"
          },
          {
            "no": "4023002002",
            "des": "認證採購管理師C.P.P.",
            "eng": "Certified Purchasing Manager"
          },
          {
            "no": "4023002003",
            "des": "認證採購經理C.P.M.",
            "eng": "Certified Purchasing Manager"
          },
          {
            "no": "4023002004",
            "des": "認證供應管理專家CPSM",
            "eng": "Certified Professional in Supply Management"
          }
        ],
        "eng": "Procurement Management"
      },
      {
        "no": "4023003000",
        "des": "企業管理",
        "n": [
          {
            "no": "4023003001",
            "des": "國際企業管理師",
            "eng": "Foundation Award in Management Principles"
          },
          {
            "no": "4023003002",
            "des": "衝突管理國際專業人才",
            "eng": "International Introductory Award in Conflict Handling"
          },
          {
            "no": "4023003003",
            "des": "C&G 顧客關係管理師",
            "eng": "C & G International Introductory Award in Customer Service"
          },
          {
            "no": "4023003004",
            "des": "顧客關係管理助理管理師",
            "eng": "Customer Relationship Management Assistant"
          },
          {
            "no": "4023003005",
            "des": "WE職場倫理認證",
            "eng": "Workplace Ethics Certification"
          },
          {
            "no": "4023003006",
            "des": "CGAP總務採購管理師認證",
            "eng": "Certified General Administration Professional"
          },
          {
            "no": "4023003007",
            "des": "初級產業分析師",
            "eng": "APIAA Certified Junior Industrial Analyst"
          },
          {
            "no": "4023003008",
            "des": "產業分析師",
            "eng": "APIAA Certified Industrial Analyst"
          },
          {
            "no": "4023003009",
            "des": "資深產業分析師",
            "eng": "APIAA Certified Senior Industrial Analyst"
          },
          {
            "no": "4023003010",
            "des": "產業顧問",
            "eng": "APIAA Certified Industrial Consultant"
          },
          {
            "no": "4023003011",
            "des": "DSCRMA流通服務與顧客關係管理分析師",
            "eng": "Distribution Service & Customer Relationship Management Analyst"
          },
          {
            "no": "4023003012",
            "des": "HSOM溫泉產業經營管理師",
            "eng": "Hot Spring Industry Operate Management"
          },
          {
            "no": "4023003013",
            "des": "ACCBE 經營分析師證照",
            "eng": "ACCBE Strategic Planning Certified Associate"
          },
          {
            "no": "4023003014",
            "des": "微型創業經營與管理職能認證",
            "eng": "Small Business Operating and Management"
          },
          {
            "no": "4023003015",
            "des": "GCA 客戶關係管理CRM核心能力認證",
            "eng": "GSS CERTIFIED ASSOCIATE (GCA) : Vital CRM"
          },
          {
            "no": "4023003016",
            "des": "GCP 客戶關係管理CRM專業能力認證",
            "eng": "GSS CERTIFIED PROFESSIONAL (GCP) : Vital CRM"
          },
          {
            "no": "4023003017",
            "des": "TIPCI 顧客關係管理師",
            "eng": "Customer Relationship Manager"
          },
          {
            "no": "4023003018",
            "des": "消費者行為分析師",
            "eng": "Consumer Behavior Analyst"
          },
          {
            "no": "4023003019",
            "des": "職業倫理與職場道德",
            "eng": "Professional and Workplace Ethics"
          },
          {
            "no": "4023003020",
            "des": "企業永續管理師",
            "eng": "Certified Professional in Corporate Sustainability Management"
          }
        ],
        "eng": "Business Management"
      }
    ],
    "eng": "Management Categories"
  },
  {
    "no": "4024000000",
    "des": "其他類",
    "n": [
      {
        "no": "4024001000",
        "des": "其他證照",
        "n": [
          {
            "no": "4024001006",
            "des": "丙級美容技術士",
            "eng": "Level C technician for beauty"
          },
          {
            "no": "4024001007",
            "des": "體適能教練證照",
            "eng": "HealthFitness Instructor"
          },
          {
            "no": "4024001008",
            "des": "救生員證照",
            "eng": "Life guard certificate"
          },
          {
            "no": "4024001009",
            "des": "乙級按摩技術士",
            "eng": "Level B technician for massage"
          },
          {
            "no": "4024001010",
            "des": "丙級按摩技術士",
            "eng": "Level C technician for massage"
          },
          {
            "no": "4024001011",
            "des": "乙級職業潛水技術士",
            "eng": "Level B technician for professional diving"
          },
          {
            "no": "4024001012",
            "des": "丙級職業潛水技術士",
            "eng": "Level C technician for professional diving"
          },
          {
            "no": "4024001013",
            "des": "技術士技能檢定托育人員職類單一級",
            "eng": "Technician for baby sitting"
          },
          {
            "no": "4024001014",
            "des": "乙級女子美髮技術士",
            "eng": "Level B technician for female hairdressing"
          },
          {
            "no": "4024001015",
            "des": "丙級女子美髮技術士",
            "eng": "Level C technician for female hairdressing"
          },
          {
            "no": "4024001016",
            "des": "乙級男子理髮技術士",
            "eng": "Level B technician for male hairdressing"
          },
          {
            "no": "4024001017",
            "des": "丙級男子理髮技術士",
            "eng": "Level C technician for male hairdressing"
          },
          {
            "no": "4024001018",
            "des": "丙級手語翻譯技術士",
            "eng": "Level C technician for sign language interpretation"
          },
          {
            "no": "4024001019",
            "des": "甲級眼鏡鏡片製作技術士",
            "eng": "Level A technician for manufacturing glass lenses"
          },
          {
            "no": "4024001020",
            "des": "乙級眼鏡鏡片製作技術士",
            "eng": "Level B technician for manufacturing glass lenses"
          },
          {
            "no": "4024001021",
            "des": "丙級眼鏡鏡片製作技術士",
            "eng": "Level C technician for manufacturing glass lenses"
          },
          {
            "no": "4024001022",
            "des": "鋼琴調音技術士",
            "eng": "Level C technician for piano tuning"
          },
          {
            "no": "4024001005",
            "des": "乙級美容技術士",
            "eng": "Level B technician for beauty"
          },
          {
            "no": "4024001001",
            "des": "研究寶石學家文憑(G.G.)",
            "eng": "Graduate Gemologist Diploma"
          },
          {
            "no": "4024001002",
            "des": "個人指導證",
            "eng": "Personal instruction certificate"
          },
          {
            "no": "4024001003",
            "des": "個人指導員",
            "eng": "Personal instructor"
          },
          {
            "no": "4024001024",
            "des": "乙級喪禮服務技術士",
            "eng": "Level B technician for funeral service"
          },
          {
            "no": "4024001025",
            "des": "丙級喪禮服務技術士",
            "eng": "Level C technician for funeral service"
          },
          {
            "no": "4024001026",
            "des": "美國NAHA國家整體芳療協會芳香療法師",
            "eng": "National Association for Holistic Aromatherapy (NAHA)"
          },
          {
            "no": "4024001027",
            "des": "寶石鑑定師 FGA / DGA",
            "eng": "FGA (Fellowship of Gemological Association)、DGA (Diamond Member of Gemological Association)"
          },
          {
            "no": "4024001028",
            "des": "國際澳洲芳療師 初階",
            "eng": "Certificate I in Aromatherapy"
          },
          {
            "no": "4024001029",
            "des": "國際澳洲芳療師 進階",
            "eng": "Certificate II in Aromatherapy"
          },
          {
            "no": "4024001030",
            "des": "CWP婚禮顧問師認證",
            "eng": "Certified Wedding Planner"
          },
          {
            "no": "4024001031",
            "des": "PFD寵物臨終服務師認證",
            "eng": "Pet Funeral Director"
          },
          {
            "no": "4024001032",
            "des": "經濟部初級無形資產評價師",
            "eng": "MOEA Certified Intangible Asset Valuation Associate"
          },
          {
            "no": "4024001033",
            "des": "鑽石畢業文憑",
            "eng": "Graduate Diamonds Diploma"
          },
          {
            "no": "4024001034",
            "des": "有色寶石畢業文憑",
            "eng": "Graduate Colored Stones Diploma"
          },
          {
            "no": "4024001035",
            "des": "PCP寵物照護員認證",
            "eng": "Pet Care Professional"
          },
          {
            "no": "4024001036",
            "des": "天上聖母文化素養認證",
            "eng": "Cultural Literacy for Religion - Holy Mother Empress of the Heaven"
          },
          {
            "no": "4024001037",
            "des": "生活佛學文化素養認證",
            "eng": "MiNGYI Folk Religion and Culture Certification"
          },
          {
            "no": "4024001038",
            "des": "西王母文化素養認證",
            "eng": "Cultural Literacy for Religion - Xiwangmu"
          },
          {
            "no": "4024001039",
            "des": "廣澤尊王文化素養認證",
            "eng": "Cultural Literacy for Religion - Guangze Zunwang"
          },
          {
            "no": "4024001040",
            "des": "關聖帝君文化素養認證",
            "eng": "Cultural Literacy for Religion - Guan Shengdi"
          },
          {
            "no": "4024001041",
            "des": "攝影美學認證",
            "eng": "Certification of Aesthetic in Photography"
          },
          {
            "no": "4024001042",
            "des": "EIEPT國際禮儀初階能力檢定",
            "eng": "Elementary International Etiquette Test"
          },
          {
            "no": "4024001043",
            "des": "IIEPT國際禮儀能力檢定",
            "eng": "Intermediate International Etiquette Test"
          },
          {
            "no": "4024001044",
            "des": "SSE 攝影國際證照",
            "eng": "SSE Photography Certificate"
          },
          {
            "no": "4024001045",
            "des": "SSE 專業攝影術科國際認證",
            "eng": "Skills Test in Photography"
          },
          {
            "no": "4024001046",
            "des": "天上聖母文化專業認證",
            "eng": "Cultural Literacy for Religion - Holy Mother Empress of the Heaven"
          },
          {
            "no": "4024001047",
            "des": "乙級手語翻譯技術士",
            "eng": "Level B technician for sign language interpretation"
          },
          {
            "no": "4024001048",
            "des": "寵物教養初級職證",
            "eng": "Basic Professional Certification in Pet Training"
          },
          {
            "no": "4024001049",
            "des": "寵物禮儀初級職能證照",
            "eng": "Basic Professional Certification in Pet Etiquette"
          },
          {
            "no": "4024001050",
            "des": "寵膳食育初級職證",
            "eng": "Basic Professional Certification in Pet Nutritional Education"
          },
          {
            "no": "4024001051",
            "des": "民俗調理業傳統整復推拿技術士",
            "eng": "Folk manipulative service traditional complex massage"
          },
          {
            "no": "4024001052",
            "des": "宗教文化服務管理師",
            "eng": "Religious and cultural service manager"
          },
          {
            "no": "4024001053",
            "des": "104職涯學院-TOP企業優質人才學用培育課程結業證書",
            "eng": "104 Career Academy- TOP (Talent Optimization Program) Certificate of Completion"
          },
          {
            "no": "4024001054",
            "des": "農藥管理人員證書",
            "eng": "Certificate for Pesticide Management Personnel"
          },
          {
            "no": "4024001055",
            "des": "HIT頭皮養護師認證(初階)",
            "eng": "Hair care institute of trichology(Elementary)"
          },
          {
            "no": "4024001056",
            "des": "HIT頭皮養護師認證(進階)",
            "eng": "Hair care institute of trichology(Advanced)"
          },
          {
            "no": "4024001057",
            "des": "104 Giver職涯引導師認證班-職涯引導師結業證書",
            "eng": "104 Career Development Facilitator"
          },
          {
            "no": "4024001058",
            "des": "開放水域潛水員 (OW) 證照",
            "eng": "Open Water Diver"
          },
          {
            "no": "4024001059",
            "des": "國際禮儀專業服務認證",
            "eng": "International Etiquette Service Certification (IESC)"
          },
          {
            "no": "4024001060",
            "des": "IFA國際芳療師認證",
            "eng": "IFA Aromatherapy Diploma"
          },
          {
            "no": "4024001061",
            "des": "自然療法師證書",
            "eng": "Naturopath Certificate"
          }
        ],
        "eng": "Other Certificates"
      }
    ],
    "eng": "Other Categories"
  },
  {
    "no": "4003000000",
    "des": "生產製造類",
    "n": [
      {
        "no": "4003008000",
        "des": "印刷／製版證照",
        "n": [
          {
            "no": "4003008001",
            "des": "甲級網版印刷技術士",
            "eng": "Level A technician for screen printing"
          },
          {
            "no": "4003008002",
            "des": "乙級網版印刷技術士",
            "eng": "Level B technician for screen printing"
          },
          {
            "no": "4003008003",
            "des": "丙級網版印刷技術士",
            "eng": "Level C technician for screen printing"
          },
          {
            "no": "4003008004",
            "des": "甲級製訂及加工技術士",
            "eng": "Level A technician for customization and processing"
          },
          {
            "no": "4003008005",
            "des": "乙級製訂及加工技術士",
            "eng": "Level B technician for customization and processing"
          },
          {
            "no": "4003008006",
            "des": "丙級製訂及加工技術士",
            "eng": "Level C technician for customization and processing"
          },
          {
            "no": "4003008007",
            "des": "甲級凸版印刷技術士",
            "eng": "Level A technician for letterpress printing"
          },
          {
            "no": "4003008008",
            "des": "乙級凸版印刷技術士",
            "eng": "Level B technician for letterpress printing"
          },
          {
            "no": "4003008009",
            "des": "丙級凸版印刷技術士",
            "eng": "Level C technician for letterpress printing"
          },
          {
            "no": "4003008010",
            "des": "甲級網版製版技術士",
            "eng": "Level A technician for screen graphic"
          },
          {
            "no": "4003008011",
            "des": "乙級網版製版技術士",
            "eng": "Level B technician for screen graphic"
          },
          {
            "no": "4003008012",
            "des": "丙級網版製版技術士",
            "eng": "Level C technician for screen graphic"
          },
          {
            "no": "4003008013",
            "des": "甲級圖文組版技術士",
            "eng": "Level A technician for graphic art and page making"
          },
          {
            "no": "4003008014",
            "des": "乙級圖文組版技術士",
            "eng": "Level B technician for graphic art and page making"
          },
          {
            "no": "4003008015",
            "des": "丙級圖文組版技術士",
            "eng": "Level C technician for graphic art and page making"
          },
          {
            "no": "4003008016",
            "des": "甲級平版印刷技術士",
            "eng": "Level A technician for off-set printing"
          },
          {
            "no": "4003008017",
            "des": "乙級平版印刷技術士",
            "eng": "Level B technician for off-set printing"
          },
          {
            "no": "4003008018",
            "des": "丙級平版印刷技術士",
            "eng": "Level C technician for off-set printing"
          },
          {
            "no": "4003008019",
            "des": "甲級平版製版技術士",
            "eng": "Level A technician for off-set graphics"
          },
          {
            "no": "4003008020",
            "des": "乙級平版製版技術士",
            "eng": "Level B technician for off-set graphics"
          },
          {
            "no": "4003008021",
            "des": "丙級平版製版技術士",
            "eng": "Level C technician for off-set graphics"
          },
          {
            "no": "4003008022",
            "des": "甲級製版照相技術士",
            "eng": "Level A technician for graphic photography"
          },
          {
            "no": "4003008023",
            "des": "乙級製版照相技術士",
            "eng": "Level B technician for graphic photography"
          },
          {
            "no": "4003008024",
            "des": "丙級製版照相技術士",
            "eng": "Level C technician for graphic photography"
          },
          {
            "no": "4003008025",
            "des": "甲級凸版製版技術士",
            "eng": "Level A technician for letterpress graphics"
          },
          {
            "no": "4003008026",
            "des": "乙級凸版製版技術士",
            "eng": "Level B technician for letterpress graphics"
          },
          {
            "no": "4003008027",
            "des": "丙級凸版製版技術士",
            "eng": "Level C technician for letterpress graphics"
          },
          {
            "no": "4003008028",
            "des": "甲級凹版印刷技術士",
            "eng": "Level A technician for intaglio printing"
          },
          {
            "no": "4003008029",
            "des": "乙級凹版印刷技術士",
            "eng": "Level B technician for intaglio printing"
          },
          {
            "no": "4003008030",
            "des": "丙級凹版印刷技術士",
            "eng": "Level C technician for intaglio printing"
          },
          {
            "no": "4003008031",
            "des": "甲級凹版製版技術士",
            "eng": "Level A technician for intaglio graphics"
          },
          {
            "no": "4003008032",
            "des": "乙級凹版製版技術士",
            "eng": "Level B technician for intaglio graphics"
          },
          {
            "no": "4003008033",
            "des": "丙級凹版製版技術士",
            "eng": "Level C technician for intaglio graphics"
          },
          {
            "no": "4003008034",
            "des": "乙級印前製程技術士",
            "eng": "Level B technician for prepress process"
          },
          {
            "no": "4003008035",
            "des": "丙級印前製程技術士 - 圖文組版",
            "eng": "Level C technician for prepress process-image and text pagination"
          }
        ],
        "eng": "Printing/Platemaking Certification"
      },
      {
        "no": "4003009000",
        "des": "焊接、配管類",
        "n": [
          {
            "no": "4003009001",
            "des": "自來水管承裝技工",
            "eng": "Artisan for water pipe installation"
          },
          {
            "no": "4003009002",
            "des": "自來水管承裝技工考驗合格證書",
            "eng": "Artisan with qualified certificate for water pipe installation"
          },
          {
            "no": "4003009003",
            "des": "甲級工業用管配管技術士",
            "eng": "Level A technician for industrial piping"
          },
          {
            "no": "4003009004",
            "des": "乙級工業用管配管技術士",
            "eng": "Level B technician for industrial piping"
          },
          {
            "no": "4003009005",
            "des": "丙級工業用管配管技術士",
            "eng": "Level C technician for industrial piping"
          },
          {
            "no": "4003009006",
            "des": "甲級氣銲技術士",
            "eng": "Level A technician for gas welding"
          },
          {
            "no": "4003009007",
            "des": "乙級氣銲技術士",
            "eng": "Level B technician for gas welding"
          },
          {
            "no": "4003009008",
            "des": "丙級氣銲技術士",
            "eng": "Level C technician for gas welding"
          },
          {
            "no": "4003009009",
            "des": "乙級自來水管配管技術士",
            "eng": "Level B technician for water piping"
          },
          {
            "no": "4003009010",
            "des": "丙級自來水管配管技術士",
            "eng": "Level C technician for water piping"
          },
          {
            "no": "4003009011",
            "des": "氣體燃料導管裝管技工考驗合格證書",
            "eng": "Artisan with qualified certificate for gas & fuel guiding pipe installation"
          },
          {
            "no": "4003009012",
            "des": "甲級一般手工電銲技術士",
            "eng": "Level A technician for general welding"
          },
          {
            "no": "4003009013",
            "des": "乙級一般手工電銲技術士",
            "eng": "Level B technician for general welding"
          },
          {
            "no": "4003009014",
            "des": "丙級一般手工電銲技術士",
            "eng": "Level C technician for general welding"
          },
          {
            "no": "4003009015",
            "des": "丙級半自動電銲技術士",
            "eng": "Level C technician for semi-automatic welding"
          },
          {
            "no": "4003009016",
            "des": "丙級氣體燃料導管配管技術士",
            "eng": "Level C technician for gas & fuel guiding piping"
          },
          {
            "no": "4003009017",
            "des": "氬氣鎢極電銲技術士",
            "eng": "Technician for Tungsten Inert Gas (TIG) Welding"
          },
          {
            "no": "4003009018",
            "des": "乙級氣體燃料導管配管技術士",
            "eng": "Level B technician for gas piping"
          },
          {
            "no": "4003009019",
            "des": "焊接檢驗師 (CWI)",
            "eng": "Certified Welding Inspector (CWI)"
          }
        ],
        "eng": "Welding and Piping Category"
      },
      {
        "no": "4003010000",
        "des": "其他製造證照",
        "n": [
          {
            "no": "4003010001",
            "des": "乙級製鞋（製面）技術士",
            "eng": "Level B technician for Shoemaking-Making shoe upper"
          },
          {
            "no": "4003010002",
            "des": "丙級製鞋技術士",
            "eng": "Level C technician for shoe manufacturing"
          },
          {
            "no": "4003010003",
            "des": "丙級塑膠射出成形技術士",
            "eng": "Level C technician for plastic injection forming"
          },
          {
            "no": "4003010004",
            "des": "丙級塑膠押出成形技術士",
            "eng": "Level C technician for plastic injection forming"
          },
          {
            "no": "4003010005",
            "des": "甲級製鞋技術士",
            "eng": "Level A technician for shoe manufacturing"
          },
          {
            "no": "4003010006",
            "des": "乙級製鞋（製配底）技術士",
            "eng": "Level B technician for Shoemaking-Making shoe sole"
          },
          {
            "no": "4003010007",
            "des": "經濟部初級天線設計工程師",
            "eng": "MOEA Certified Antenna Design Associate"
          },
          {
            "no": "4003010008",
            "des": "經濟部中級天線設計工程師",
            "eng": "MOEA Certified Antenna Design Specialist"
          },
          {
            "no": "4003010009",
            "des": "初級複合材料工程師",
            "eng": "Composite Materials Engineer Associate"
          }
        ],
        "eng": "Other Manufacturing Certifications"
      },
      {
        "no": "4003001000",
        "des": "製造管理證照",
        "n": [
          {
            "no": "4003001001",
            "des": "甲級職業安全管理技術士",
            "eng": "Level A technician for for labor safety management"
          },
          {
            "no": "4003001004",
            "des": "高考工業安全技師",
            "eng": "Industrial safety of higher examination"
          },
          {
            "no": "4003001005",
            "des": "高考工業工程技師",
            "eng": "Industrial engineering of higher examination"
          },
          {
            "no": "4003001002",
            "des": "甲級職業衛生管理技術士",
            "eng": "Level A technician for for labor safety & health management"
          },
          {
            "no": "4003001003",
            "des": "乙級職業安全衛生管理技術士",
            "eng": "Level B technician for for labor health management"
          },
          {
            "no": "4003001006",
            "des": "國際供應鏈管理師",
            "eng": "Certified Supply Chain Professional (CSCP)"
          },
          {
            "no": "4003001007",
            "des": "CPIM",
            "eng": "CPIM"
          },
          {
            "no": "4003001008",
            "des": "生產與作業管理技術師證照",
            "eng": "CERTIFICATE of Production and operations management technologist"
          },
          {
            "no": "4003001009",
            "des": "自動化工程師證照",
            "eng": "Automation Engineer Certification"
          },
          {
            "no": "4003001010",
            "des": "甲種職業安全衛生業務主管",
            "eng": "Safety and health education and training for class-1 manager of Occupational safety and health affairs"
          },
          {
            "no": "4003001011",
            "des": "乙種職業安全衛生業務主管",
            "eng": "Safety and health education and training for class-2 manager of Occupational safety and health affairs"
          },
          {
            "no": "4003001012",
            "des": "丙種職業安全衛生業務主管",
            "eng": "Safety and health education and training for class-3 manager of Occupational safety and health affairs"
          },
          {
            "no": "4003001013",
            "des": "職業安全管理師",
            "eng": "Occupational Safety Manager"
          },
          {
            "no": "4003001014",
            "des": "職業衛生管理師",
            "eng": "Occupational Hygiene Manager"
          },
          {
            "no": "4003001015",
            "des": "乙級職業安全衛生管理員",
            "eng": "Class B Occupational Safety and Health Manager"
          },
          {
            "no": "4003001016",
            "des": "營造業甲種勞工安全衛生業務主管",
            "eng": "Construction Industry Class A Labor Safety and Health Supervisor"
          },
          {
            "no": "4003001017",
            "des": "營造業乙種勞工安全衛生業務主管",
            "eng": "Construction Industry Class B Labor Safety and Health Supervisor"
          },
          {
            "no": "4003001018",
            "des": "營造業丙種勞工安全衛生業務主管",
            "eng": "Construction Industry Class C Labor Safety and Health Supervisor"
          },
          {
            "no": "4003001019",
            "des": "高壓氣體特定設備操作技術士",
            "eng": "Operating Specific Equipment for high Pressure Gases"
          },
          {
            "no": "4003001020",
            "des": "CPIM-供應鏈計劃及庫存管理師",
            "eng": "Certified in Planning & Inventory Management"
          },
          {
            "no": "4003001021",
            "des": "供應鏈管理師",
            "eng": "Certified Supply Chain Professional (CSCP)"
          },
          {
            "no": "4003001022",
            "des": "國際物流運籌管理師",
            "eng": "Certified in Logistics"
          },
          {
            "no": "4003001023",
            "des": "供應鏈變革管理師",
            "eng": "Certified in Transformation for Supply Chain (CTSC)"
          }
        ],
        "eng": "Manufacturing Management Certification"
      },
      {
        "no": "4003002000",
        "des": "板金、工具類證照",
        "n": [
          {
            "no": "4003002001",
            "des": "甲級板金技術士",
            "eng": "Level A technician for sheet metal work"
          },
          {
            "no": "4003002002",
            "des": "乙級板金技術士",
            "eng": "Level B technician for sheet metal work"
          },
          {
            "no": "4003002003",
            "des": "丙級板金技術士",
            "eng": "Level C technician for auto-body repair"
          },
          {
            "no": "4003002004",
            "des": "丙級機械板金技術士",
            "eng": "Level C technician for mechanical panel-beating"
          },
          {
            "no": "4003002005",
            "des": "甲級沖壓模具工技術士",
            "eng": "Level A technician for press forming mold"
          },
          {
            "no": "4003002006",
            "des": "乙級沖壓模具工技術士",
            "eng": "Level B technician for press forming mold"
          },
          {
            "no": "4003002007",
            "des": "丙級沖壓模具工技術士",
            "eng": "Level C technician for press forming mold"
          },
          {
            "no": "4003002008",
            "des": "甲級車床工技術士",
            "eng": "Level A technician for lathe"
          },
          {
            "no": "4003002009",
            "des": "乙級車床工技術士",
            "eng": "Level B technician for lathe"
          },
          {
            "no": "4003002010",
            "des": "丙級車床工技術士",
            "eng": "Level C technician for lathe"
          },
          {
            "no": "4003002011",
            "des": "乙級食品用金屬罐捲封技術士",
            "eng": "Level B technician for metal food can sealing"
          },
          {
            "no": "4003002012",
            "des": "丙級食品用金屬罐捲封技術士",
            "eng": "Level C technician for metal food can sealing"
          },
          {
            "no": "4003002013",
            "des": "甲級銑床工技術士",
            "eng": "Level A technician for toolroom"
          },
          {
            "no": "4003002014",
            "des": "乙級銑床工技術士",
            "eng": "Level B technician for toolroom"
          },
          {
            "no": "4003002015",
            "des": "丙級銑床工技術士",
            "eng": "Level C technician for toolroom"
          },
          {
            "no": "4003002016",
            "des": "丙級塑膠射出模具技術士",
            "eng": "Level C technician for plastic injection mold"
          },
          {
            "no": "4003002017",
            "des": "乙級汽車車體板金技術士",
            "eng": "Level B technician for auto-body repair"
          },
          {
            "no": "4003002018",
            "des": "丙級汽車車體板金技術士",
            "eng": "Level C technician for auto-body repair"
          },
          {
            "no": "4003002019",
            "des": "甲級打型板金技術士",
            "eng": "Level A technician for panel beating"
          },
          {
            "no": "4003002020",
            "des": "乙級打型板金技術士",
            "eng": "Level B technician for panel beating"
          },
          {
            "no": "4003002021",
            "des": "丙級打型板金技術士",
            "eng": "Level C technician for panel beating"
          },
          {
            "no": "4003002022",
            "des": "甲級電腦數值控制車床技術士",
            "eng": "Level A technician for computer numerical control lathe"
          },
          {
            "no": "4003002023",
            "des": "乙級電腦數值控制車床技術士",
            "eng": "Level B technician for computer numerical control lathe"
          },
          {
            "no": "4003002024",
            "des": "丙級電腦數值控制車床技術士",
            "eng": "Level C technician for computer numerical control lathe"
          },
          {
            "no": "4003002025",
            "des": "甲級電腦數值控制銑床技術士",
            "eng": "Level A technician for computer numerical control tool room"
          },
          {
            "no": "4003002026",
            "des": "乙級電腦數值控制銑床技術士",
            "eng": "Level B technician for computer numerical control tool room"
          },
          {
            "no": "4003002027",
            "des": "丙級電腦數值控制銑床技術士",
            "eng": "Level C technician for computer numerical control tool room"
          },
          {
            "no": "4003002028",
            "des": "乙級塑膠射出模具技術士",
            "eng": "Level B technician for plastics injection mold"
          }
        ],
        "eng": "Sheet Metal and Tooling Certifications"
      },
      {
        "no": "4003003000",
        "des": "機械製造類證照",
        "n": [
          {
            "no": "4003003001",
            "des": "高考機械工程技師",
            "eng": "Mechanical engineering of higher examination"
          },
          {
            "no": "4003003002",
            "des": "丙級電腦輔助立體製圖",
            "eng": "Level C technician for computer assisted isometric drawing"
          },
          {
            "no": "4003003003",
            "des": "甲級精密機械工技術士",
            "eng": "Level A technician for precision mechanical work"
          },
          {
            "no": "4003003004",
            "des": "乙級精密機械工技術士",
            "eng": "Level B technician for precision mechanical work"
          },
          {
            "no": "4003003005",
            "des": "丙級精密機械工技術士",
            "eng": "Level C technician for precision mechanical work"
          },
          {
            "no": "4003003006",
            "des": "甲級機械製圖技術士",
            "eng": "Level A technician for mechanical drawing"
          },
          {
            "no": "4003003007",
            "des": "乙級機械製圖技術士",
            "eng": "Level B technician for mechanical drawing"
          },
          {
            "no": "4003003008",
            "des": "丙級機械製圖技術士",
            "eng": "Level C technician for mechanical drawing"
          },
          {
            "no": "4003003011",
            "des": "甲級木模技術士",
            "eng": "Level A technician for wooden mold"
          },
          {
            "no": "4003003012",
            "des": "乙級木模技術士",
            "eng": "Level B technician for wooden mold"
          },
          {
            "no": "4003003013",
            "des": "丙級木模技術士",
            "eng": "Level C technician for wooden mold"
          },
          {
            "no": "4003003014",
            "des": "甲級冷作技術士",
            "eng": "Level A technician for cold work"
          },
          {
            "no": "4003003015",
            "des": "乙級冷作技術士",
            "eng": "Level B technician for cold work"
          },
          {
            "no": "4003003016",
            "des": "丙級冷作技術士",
            "eng": "Level C technician for cold work"
          },
          {
            "no": "4003003017",
            "des": "乙級油壓技術士",
            "eng": "Level B technician for hydraulic"
          },
          {
            "no": "4003003018",
            "des": "丙級油壓技術士",
            "eng": "Level C technician for hydraulic"
          },
          {
            "no": "4003003019",
            "des": "乙級機電整合技術士",
            "eng": "Level B technician for electromechanical"
          },
          {
            "no": "4003003020",
            "des": "丙級機電整合技術士",
            "eng": "Level C technician for electromechanical"
          },
          {
            "no": "4003003021",
            "des": "乙級金屬塗裝技術士",
            "eng": "Level B technician for metal coating"
          },
          {
            "no": "4003003022",
            "des": "丙級金屬塗裝技術士",
            "eng": "Level C technician for metal coating"
          },
          {
            "no": "4003003023",
            "des": "乙級氣壓技術士",
            "eng": "Level B technician for pneumatic"
          },
          {
            "no": "4003003024",
            "des": "丙級氣壓技術士",
            "eng": "Level C technician for pneumatic"
          },
          {
            "no": "4003003025",
            "des": "甲級鉗工技術士",
            "eng": "Level A technician for bench work"
          },
          {
            "no": "4003003026",
            "des": "乙級鉗工技術士",
            "eng": "Level B technician for bench work"
          },
          {
            "no": "4003003027",
            "des": "丙級鉗工技術士",
            "eng": "Level C technician for bench work"
          },
          {
            "no": "4003003028",
            "des": "甲級鑄造技術士",
            "eng": "Level A technician for molding"
          },
          {
            "no": "4003003029",
            "des": "乙級鑄造技術士",
            "eng": "Level B technician for molding"
          },
          {
            "no": "4003003030",
            "des": "丙級鑄造技術士",
            "eng": "Level C technician for molding"
          },
          {
            "no": "4003003031",
            "des": "乙級機器腳踏車修護技術士",
            "eng": "Level B technician for motorcycle repairing"
          },
          {
            "no": "4003003032",
            "des": "丙級機器腳踏車修護技術士",
            "eng": "Level C technician for motorcycle repairing"
          },
          {
            "no": "4003003033",
            "des": "乙級熱處理技術士",
            "eng": "Level B technician for thermal process"
          },
          {
            "no": "4003003034",
            "des": "丙級熱處理技術士",
            "eng": "Level C technician for thermal process"
          },
          {
            "no": "4003003035",
            "des": "丙級特定瓦斯器具裝修技術士",
            "eng": "Level C technician for specific gas equipment installation & repairing"
          },
          {
            "no": "4003003036",
            "des": "乙級齒輪製造技術士",
            "eng": "Level B technician for gear manufacturing"
          },
          {
            "no": "4003003037",
            "des": "丙級齒輪製造技術士",
            "eng": "Level C technician for gear manufacturing"
          },
          {
            "no": "4003003038",
            "des": "丙級機械停車設備裝修技術士",
            "eng": "Level C technician for mechanical parking equipment installation & repairing"
          },
          {
            "no": "4003003039",
            "des": "甲級電鍍技術士",
            "eng": "Level A technician for plating"
          },
          {
            "no": "4003003040",
            "des": "乙級電鍍技術士",
            "eng": "Level B technician for plating"
          },
          {
            "no": "4003003041",
            "des": "丙級電鍍技術士",
            "eng": "Level C technician for plating"
          },
          {
            "no": "4003003042",
            "des": "甲級平面磨床工技術士",
            "eng": "Level A technician for plane grinding work"
          },
          {
            "no": "4003003043",
            "des": "乙級平面磨床工技術士",
            "eng": "Level B technician for plane grinding work"
          },
          {
            "no": "4003003044",
            "des": "丙級平面磨床工技術士",
            "eng": "Level C technician for plane grinding work"
          },
          {
            "no": "4003003045",
            "des": "甲級圓筒磨床工技術士",
            "eng": "Level A technician for cylindrical girding work"
          },
          {
            "no": "4003003046",
            "des": "乙級圓筒磨床工技術士",
            "eng": "Level B technician for cylindrical girding work"
          },
          {
            "no": "4003003047",
            "des": "丙級圓筒磨床工技術士",
            "eng": "Level C technician for cylindrical girding work"
          },
          {
            "no": "4003003048",
            "des": "乙級牛頭鉋床工技術士",
            "eng": "Level B technician for shaping"
          },
          {
            "no": "4003003049",
            "des": "丙級牛頭鉋床工技術士",
            "eng": "Level C technician for shaping"
          },
          {
            "no": "4003003050",
            "des": "甲級煉鋼電弧爐技術士",
            "eng": "Level A technician for steel arc furnace"
          },
          {
            "no": "4003003051",
            "des": "乙級煉鋼電弧爐技術士",
            "eng": "Level B technician for steel arc furnace"
          },
          {
            "no": "4003003052",
            "des": "丙級煉鋼電弧爐技術士",
            "eng": "Level C technician for steel arc furnace"
          },
          {
            "no": "4003003053",
            "des": "甲級製銑電爐技術士",
            "eng": "Level A technician for milling furnace"
          },
          {
            "no": "4003003054",
            "des": "乙級製銑電爐技術士",
            "eng": "Level B technician for milling furnace"
          },
          {
            "no": "4003003009",
            "des": "乙級電腦輔助機械製圖技術士",
            "eng": "Level B technician for computer assisted mechanical drawing"
          },
          {
            "no": "4003003010",
            "des": "丙級電腦輔助機械製圖技術士",
            "eng": "Level C technician for computer assisted mechanical drawing"
          },
          {
            "no": "4003003063",
            "des": "乙級電腦輔助機械設計製圖技術士",
            "eng": "Level B technician for computer-aided design and drafting"
          },
          {
            "no": "4003003064",
            "des": "丙級電腦輔助機械設計製圖技術士",
            "eng": "Level C technician for computer-aided design and drafting"
          },
          {
            "no": "4003003055",
            "des": "乙級特定瓦斯器具裝修技術士",
            "eng": "Level B technician for Gas Appliances Technology"
          },
          {
            "no": "4003003056",
            "des": "機械工程師基礎能力鑑定",
            "eng": "Mechanical Engineer - Entry Level"
          },
          {
            "no": "4003003057",
            "des": "初級機械設計工程師",
            "eng": "Mechanical Design Engineer Junior Level"
          },
          {
            "no": "4003003058",
            "des": "中級機械設計工程師",
            "eng": "Mechanical Design Engineer Middle Level"
          },
          {
            "no": "4003003059",
            "des": "高級機械設計工程師",
            "eng": "Mechanical Design Engineer Senior Level"
          },
          {
            "no": "4003003060",
            "des": "乙級機械停車設備裝修技術士",
            "eng": "Class B Mechanical Parking Equipment Repair Technician"
          },
          {
            "no": "4003003061",
            "des": "經濟部初級工具機機械設計工程師",
            "eng": "MMOEA Certified Mechanical Design of Machine Tools Associate"
          },
          {
            "no": "4003003062",
            "des": "Certified SOLIDWORKS Professional (CSWP)",
            "eng": "Certified SOLIDWORKS Professional (CSWP)"
          }
        ],
        "eng": "Mechanical Manufacturing Certifications"
      },
      {
        "no": "4003004000",
        "des": "木材／家具製造證照",
        "n": [
          {
            "no": "4003004001",
            "des": "甲級家具木工技術士",
            "eng": "Level A technician for wooden furniture work"
          },
          {
            "no": "4003004002",
            "des": "乙級家具木工技術士",
            "eng": "Level B technician for wooden furniture work"
          },
          {
            "no": "4003004003",
            "des": "丙級家具木工技術士",
            "eng": "Level C technician for wooden furniture work"
          },
          {
            "no": "4003004004",
            "des": "甲級鋁門窗工技術士",
            "eng": "Level A technician for aluminum door & window work"
          },
          {
            "no": "4003004005",
            "des": "丙級鋁門窗工技術士",
            "eng": "Level C technician for aluminum door & window work"
          },
          {
            "no": "4003004006",
            "des": "甲級門窗木工技術士",
            "eng": "Level A technician for door & window work"
          },
          {
            "no": "4003004007",
            "des": "乙級門窗木工技術士",
            "eng": "Level B technician for door & window work"
          },
          {
            "no": "4003004008",
            "des": "丙級門窗木工技術士",
            "eng": "Level C technician for door & window work"
          }
        ],
        "eng": "Wood/Furniture Manufacturing Certification"
      },
      {
        "no": "4003005000",
        "des": "織品／毛皮／紡織製造證照",
        "n": [
          {
            "no": "4003005001",
            "des": "高考紡織工程技師",
            "eng": "Textile engineering technician of higher examination"
          },
          {
            "no": "4003005002",
            "des": "甲級國服技術士",
            "eng": "Level A technician for national costume"
          },
          {
            "no": "4003005003",
            "des": "乙級國服技術士",
            "eng": "Level B technician for national costume"
          },
          {
            "no": "4003005004",
            "des": "丙級國服技術士",
            "eng": "Level C technician for national costume"
          },
          {
            "no": "4003005005",
            "des": "甲級男裝技術士",
            "eng": "Level A technician for men wear"
          },
          {
            "no": "4003005006",
            "des": "乙級男裝技術士",
            "eng": "Level B technician for men wear"
          },
          {
            "no": "4003005007",
            "des": "丙級男裝技術士",
            "eng": "Level C technician for men wear"
          },
          {
            "no": "4003005008",
            "des": "甲級女裝技術士",
            "eng": "Level A technician for women wear"
          },
          {
            "no": "4003005009",
            "des": "乙級女裝技術士",
            "eng": "Level B technician for women wear"
          },
          {
            "no": "4003005010",
            "des": "丙級女裝技術士",
            "eng": "Level C technician for women wear"
          },
          {
            "no": "4003005011",
            "des": "丙級電繡技術士",
            "eng": "Level C technician for electric embroidery"
          }
        ],
        "eng": "Textile/Fur/Fabric Manufacturing Certification"
      },
      {
        "no": "4003006000",
        "des": "玻璃陶瓷製造證照",
        "n": [
          {
            "no": "4003006001",
            "des": "乙級陶瓷技術士-石膏模",
            "eng": "Level B technician for ceramics - gypsum mold"
          },
          {
            "no": "4003006002",
            "des": "丙級陶瓷技術士-石膏模",
            "eng": "Level C technician for ceramics - gypsum mold"
          }
        ],
        "eng": "Glass and Ceramic Manufacturing Certification"
      },
      {
        "no": "4003007000",
        "des": "食品製造證照",
        "n": [
          {
            "no": "4003007001",
            "des": "高考食品技師",
            "eng": "Food technician of higher examination"
          },
          {
            "no": "4003007002",
            "des": "乙級食品檢驗分析技術士",
            "eng": "Level B technician for food inspection & analysis"
          },
          {
            "no": "4003007003",
            "des": "丙級食品檢驗分析技術士",
            "eng": "Level C technician for food inspection & analysis"
          },
          {
            "no": "4003007004",
            "des": "丙級製茶技術技術士",
            "eng": "Level C technician for tea manufacturing technology"
          },
          {
            "no": "4003007005",
            "des": "丙級水產食品加工技術士",
            "eng": "Level C technician for processing aquatic food"
          },
          {
            "no": "4003007006",
            "des": "丙級中式米食加工技術士",
            "eng": "Level C technician for processing Chinese rice"
          },
          {
            "no": "4003007007",
            "des": "乙級中式麵食加工技術士",
            "eng": "Level B technician for processing Chinese noodle"
          },
          {
            "no": "4003007008",
            "des": "丙級中式麵食加工技術士",
            "eng": "Level C technician for processing Chinese noodle"
          },
          {
            "no": "4003007009",
            "des": "丙級肉製品加工技術士",
            "eng": "Level C technician for processing meat product"
          },
          {
            "no": "4003007010",
            "des": "保健食品初級工程師能力鑑定",
            "eng": "Professional Health Food Engineer-Entry"
          }
        ],
        "eng": "Food Manufacturing Certification"
      }
    ],
    "eng": "Manufacturing Category"
  }
];
export const certificateList = [
  ...certificateJson,
];

